import config from 'config';

const useConfig = () => {
  const { origin } = window.location;

  const updatedConfig = { ...config };
  if (origin.includes('quidco')) {
    updatedConfig.SITE_URL = origin;
  }

  return updatedConfig;
};

export default useConfig;
