import React from 'react';
import PropTypes from 'prop-types';
import { 
  P,
  Wrapper,
  Row,
  TableHeading,
  TableValue,
  DynamicRow,
  PriceP,
  PriceSpan,
  CoverP,  
} from './styles.js';
import { usePrice } from 'hooks';

const SummaryFooter = () => {
  const { totalPrice, coverTypeWording } = usePrice();
  return (
    <Wrapper>
      <Row>
        <TableHeading>
          <P>Policy cost and cover</P>
        </TableHeading>
        <TableValue>
          <DynamicRow>
            <PriceP><PriceSpan>£{totalPrice.toFixed(2)}</PriceSpan></PriceP>
            <CoverP>{coverTypeWording}</CoverP>
          </DynamicRow>
        </TableValue>
      </Row>
    </Wrapper>
  )
}

SummaryFooter.propTypes = {
    price: PropTypes.string,
    covertype: PropTypes.string
};

export default SummaryFooter;
