import { BaseDriverFormData } from 'components/@forms/DriverDetails/DriverDetails.types'
import { parseISO } from 'date-fns'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { InitialStateType, RiskDataState } from '../../initialState'

const dateBuilder = (dateOfBirth: Date) => {
  const dobYear = dateOfBirth.getFullYear().toString()
  const dobMonth = (dateOfBirth.getMonth() + 1).toString() // Month is zero based
  const dobDay = dateOfBirth.getDate().toString()

  return {
    dobYear,
    dobMonth,
    dobDay,
  }
}

interface DrivingLicenceData {
  dateOfBirth: Date
  title: string
  firstName: string
  surname: string
}

const prefillDrivingLicenceNumber = (data: DrivingLicenceData) => {
  const { dobYear, dobMonth, dobDay } = dateBuilder(data.dateOfBirth)
  let licenceNumber = ''
  if (data.surname) {
    licenceNumber += `${data?.surname
      .toUpperCase()
      .replace(/^MAC/, 'MC')
      .replace(/[^a-z]/gi, '')}99999`.substring(0, 5)
  } else {
    licenceNumber += '99999'
  }

  // Character 6
  licenceNumber += dobYear.substring(2, 3)

  // Characters 7-8
  if (data.title === 'Mr') {
    licenceNumber = licenceNumber + (+dobMonth < 10 ? '0' : '') + dobMonth
  } else {
    licenceNumber += +dobMonth + 50
  }

  // Characters 9-10
  licenceNumber = licenceNumber + (+dobDay < 10 ? '0' : '') + dobDay

  // Character 11
  licenceNumber += dobYear.substring(3, 4)

  // Character 12
  licenceNumber += `${data.firstName}A`.substring(0, 1).toUpperCase()

  return licenceNumber
}

const buildDrivingLicenceNumber = (
  riskData: RiskDataState,
  formData?: BaseDriverFormData,
) => {
  let licenceNumber = ''

  if (riskData.Motorbike) {
    return riskData.DrivingLicenceNumber.toUpperCase()
  }

  if (formData) {
    if (formData.dateOfBirth) {
      const utcDate = moment(formData.dateOfBirth, 'DD/MM/YYYY')
        .utcOffset(60)
        .format()

      const formattedDate = parseISO(utcDate)

      const data: DrivingLicenceData = {
        dateOfBirth: formattedDate,
        title: formData.title,
        firstName: formData.firstName,
        surname: formData.surname,
      }

      licenceNumber = prefillDrivingLicenceNumber(data)
    }
  } else {
    if (riskData.DateOfBirth) {
      const utcDate = moment(riskData.DateOfBirth, 'YYYY-MM-DD')
        .utcOffset(60)
        .format()
      const formattedDate = parseISO(utcDate)

      const data: DrivingLicenceData = {
        dateOfBirth: formattedDate,
        title: riskData.Title.value,
        firstName: riskData.Forename,
        surname: riskData.Surname,
      }

      licenceNumber = prefillDrivingLicenceNumber(data)
    }
  }

  if (
    riskData.DrivingLicenceNumber &&
    riskData.DrivingLicenceNumber.length > 0
  ) {
    // Adding last 4 characters to return
    licenceNumber += riskData.DrivingLicenceNumber.toUpperCase()
  }

  return licenceNumber
}

const useIdentity = (formData?: BaseDriverFormData) => {
  const identityCheck = useSelector(
    (state: InitialStateType) =>
      state.validation.riskDataCollectionPage.IdentityCheck,
  )

  const riskData = useSelector((state: InitialStateType) => state.riskData)

  const licenceNumber = buildDrivingLicenceNumber(riskData, formData)

  return {
    identityCheck,
    licenceNumber,
  }
}

export default useIdentity
