import styled from 'styled-components';

export const ProgressionContent = styled.div `
  color : black;
  margin: 0 auto;
  padding: 0 0 0 10px;
  vertical-align:middle;
  text-align:left;

  h4 {
    text-align:left;
    font-size: ${props => props.theme.progressionStep.contentDesktopFontSize};
    font-weight: 400;
    vertical-align: top;
    margin: 0;
  }

  @media (max-width: 768px){
    h4{
      font-size:${props => props.theme.progressionStep.contentFontSize};
    }
  }
`;