import React from 'react';
import PropTypes from 'prop-types';
import {RegInput, GBIcon} from './styles.js'

const VehicleReg = ({reg}) => {  
    return(
      <div>      
        <RegInput>
          <GBIcon>GB</GBIcon>
          {reg}
        </RegInput>
      </div>  
    )
}

VehicleReg.propTypes = {
  reg: PropTypes.string
};

export default VehicleReg;
