export const getCookie = (name: string) => {
  const nameEQ = `${name}=`
  const ca = document.cookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    // eslint-disable-next-line
    while (c.charAt(0) == ' ') {
      c = c.substring(1, c.length)
    }
    if (c.indexOf(nameEQ) === 0) {
      return c.substring(nameEQ.length, c.length)
    }
  }
  return null
}

export const setCookie = (name: string, value: string, seconds: number) => {
  let expires = ''
  if (seconds) {
    const date = new Date()
    date.setTime(date.getTime() + seconds * 1000)
    expires = `; expires=${date.toUTCString()}`
  }
  document.cookie = `${name}=${value}${expires}; path=/`
}

export const COOKIE_MANAGEMENT_NAME = 'tcCookieManagement'
export const CA_LOGGED_IN_COOKIE = 'tc_account_logged_in'
