import React from 'react';
import styled from 'styled-components';

import Spinner from '../Spinner';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
import { H1 } from '../common/H1';

export const Heading = styled(H1)`
  color: ${props => props.theme.spinner.primaryColour};
  margin-top: 50px; 
`
export const Wrapper = styled.div`
    text-align: center;
    margin-top: 80px;
`

const LoadingScreen = ({message, theme}) => {    
        return (
            <Wrapper>
                <Spinner size='medium' color={theme.spinner.primaryColour} />
                <Heading>{message}</Heading>
            </Wrapper> 
        )
    }

export default withTheme(LoadingScreen);

LoadingScreen.defaultProps = {
    message: 'Please wait'
}
LoadingScreen.propTypes = {
    message: PropTypes.string    
};
  