import styled from 'styled-components';
import { DurationButton } from '../../../../PolicyDurationModal/styles';

export const SecondaryDurationButton = styled(DurationButton)`
  border: 1px solid ${(props) => props.theme.durationOptions.primaryColour};
  color: ${(props) => props.theme.durationOptions.primaryColour};
  :disabled {
    background-color: ${(props) => props.theme.durationOptions.primaryColour};
    color: #fff;
  }
`;

const Wrapper = styled.div`
  text-align: center;
`;

export const DurationTypesWrapper = styled.div`
  text-align: center;
  button {
    width: 80px;
  }
`;

export const DurationsWrapper = styled(Wrapper)`
    width: 100%;
    margin: 0 auto;
    text-align: left;

    &.weeks{
      text-align: center;
    }

    &.days{
      max-width: ${48 * 7}px;

      @media (max-width: ${(48 * 7) + 40}px){
        max-width: ${48 * 6}px;
      }
  
      @media (max-width: ${(48 * 6) + 40}px){
        max-width: ${48 * 5}px;
      }
    }

    &.hours{
      max-width: ${48 * 6}px;

      @media (max-width: ${(48 * 6) + 40}px){
        max-width: ${48 * 4}px;
      }
    }
`;
