import React, { useMemo, useState, ChangeEvent, useEffect, ChangeEventHandler } from 'react';
import moment, { Moment } from 'moment';
import { SelectStyled } from '../Select/Select.styles';
import { TimeBox, TimeRow, TimeColon, TimeText, ColonDivider, RadioGroup, RadioField, RadioSelect, TimeContainer, DateTimeContainer } from './DateTimePicker.styles';
import { HOURS, MINUTES, MOMENT_DATE_FORMAT } from './DateTimePicker.contants';
import { ErrorMessage } from 'components/common/ErrorMessage';

interface DateTimePickerProps {
  value: string;
  name: string;
  startDate: string;
  endDate: string;
  onDateTimeChange: (dateString: string) => void;
  onManualChange: (isManual: boolean) => void;
  title?: string;
  showTime?: boolean;
  isManuallyChanged?: boolean;
  hasError: boolean;
  errorMessage: string;
  setError: (msg: string) => void;
  setTouched: (isTouched: boolean, validate: boolean) => void;
}

export const DateTimePicker: React.FC<DateTimePickerProps> = ({
  value, 
  name, 
  title, 
  onDateTimeChange, 
  onManualChange, 
  startDate, 
  endDate, 
  showTime = true, 
  isManuallyChanged = false, 
  hasError, 
  errorMessage, 
  setError, 
  setTouched
}) => {
  const now = moment();
  const valueMoment = moment(value);

  const dateOptions = useMemo(() => {
    const dates: Moment[] = [];
    const lastDate = moment(endDate).clone();
    let currDate = moment(startDate);
    
    while(lastDate.diff(currDate, 'days') > 0) {
      dates.push(moment(currDate));
      currDate = currDate.add(1, 'days');
    }

    return dates.map(d => {
      const dateVal = d.format('YYYY-MM-DD'); 
      const dateId = d.format('[date]YYYYMMDD')
      const dateDescription = d.format(MOMENT_DATE_FORMAT);

      return {
        id: dateId,
        value: dateVal,
        description: dateDescription,
      }
    });
  }, [endDate, startDate, valueMoment, now]);

  const hourOptions = HOURS.map((h) => {
    return {
      id: h,
      value: h,
      description: h.toString().padStart(2, '0'),
    }
  });

  const minOptions = MINUTES.map((m) => {
    return {
      id: m,
      value: m,
      description: m.toString().padStart(2, '0'),
    }
  });

  const calculatedMinute = useMemo(() => {
    const valueMinute = valueMoment.minute();
    if (valueMinute % 5 === 0) {
      return valueMinute
    } else {
      const roundedMinute = Math.ceil(valueMinute / 5) * 5;

      return roundedMinute < 60 ? roundedMinute : 0 ;
    }
  }, [valueMoment])


  const selectedDate = isManuallyChanged && value ? valueMoment.format('YYYY-MM-DD') : '';
  const selectedHour = isManuallyChanged && selectedDate ? hourOptions.find((h) => h.value === valueMoment.hour())?.value ?? null : null;
  const selectedMinute = isManuallyChanged && selectedDate ? minOptions.find((m) => m.value === calculatedMinute)?.value ?? null : null;

  const [date, setDate] = useState<string>(selectedDate);
  const [hour, setHour] = useState<number | null>(selectedHour);
  const [minute, setMinute] = useState<number | null>(selectedMinute);

  const updateStartDate = (date: string, hour: number | null, minute: number | null) => {
    setDate(date);
    setHour(hour);
    setMinute(minute); 

    if (date){
      let dateString = date;
    
      if (showTime && hour !== null && minute !== null) {
        dateString +=`T${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;
        const dateMoment = moment(dateString);

        if (dateMoment.isAfter(now)) {
          setError('');
        } else {
          setError('Please enter a start date in the future');
        }
      }

      setTouched(true, false);
      onDateTimeChange(dateString);
    }
  }

  const updateHour = (e: ChangeEvent<HTMLSelectElement>) => {
    updateStartDate(date, +e.target.value, minute);
  }

  const updateMinute = (e: ChangeEvent<HTMLSelectElement>) => {
    updateStartDate(date, hour, +e.target.value);
  }

  const onDropDownChange:ChangeEventHandler<HTMLSelectElement> = (event) => {
    if (event.target.value) {
      onManualChange(true);
      updateStartDate(event.target.value, hour, minute);
    }
  }

  const onRadioClick = () => {
      onManualChange(false);
  }

  const onDropDownFocus = () => {
    if(date){
      onManualChange(true);
      updateStartDate(date, hour, minute);
    }
  }

  const onLabelClick = () => {
    onManualChange(false);
  }

  useEffect(() => {
    if  (!isManuallyChanged && date && !value) {
      setDate('');
      setHour(null);
      setMinute(null);
    }
  }, [value, date])

  useEffect(() => {
    if(isManuallyChanged && date && value){
      updateStartDate(date, hour, minute);
    }
  }, [])

  return(
    <div>
      {title && (
        <div>
          <h3>{title}</h3>
        </div>
      )}
        <RadioGroup>
          <RadioField isActive={!isManuallyChanged}>
            <input type='radio' name='manuallyChanged' value='false' id='coverStartImmediate' checked={!isManuallyChanged} readOnly />
            <div className='radio-field-contents' style={{width: '100%'}} onClick={onRadioClick}>
              <label htmlFor='coverStartImmediate' onClick={onLabelClick}>Immediately</label>
            </div>
          </RadioField>
          <RadioField className='radio-field' isActive={isManuallyChanged}>
            <input type='radio' name='manuallyChanged' value='true' id='coverStartThen' checked={isManuallyChanged} readOnly />
            <div className='radio-field-contents' style={{width: '100%'}}>
                <RadioSelect
                  isValueInOptions
                  id={`${name}DatePicker`}
                  name={name}
                  value={date}
                  onFocus={onDropDownFocus}
                  onChange={onDropDownChange}
                >
                  <option disabled value=''>Select day</option>
                  {dateOptions.map((option) => (
                    <option
                      key={option.id}
                      value={option.value}
                    >
                      {option.description}
                    </option>
                  ))}
                </RadioSelect>
            </div>
          </RadioField>
        </RadioGroup>
        {showTime && (
          <TimeContainer hasError={hasError}>
            <TimeBox>
              <TimeText>What time do you need the cover to start?</TimeText>
              <TimeRow>
                <SelectStyled
                  isValueInOptions
                  id={`${name}HourPicker`}
                  name={`${name}HourPicker`}
                  value={hour?.toString() || ''}
                  onChange={updateHour}
                  style={{width: 'auto', margin: 0}}
                >
                  <option disabled value=''>HH</option>
                  {hourOptions.map((option) => (
                    <option
                      key={option.id}
                      value={option.value.toString()}
                    >
                      {option.description}
                    </option>
                  ))}
                </SelectStyled>
                <ColonDivider>
                  <TimeColon>:</TimeColon>
                </ColonDivider>
                <SelectStyled
                  isValueInOptions
                  name={`${name}MinPicker`}
                  id={`${name}MinPicker`}
                  value={minute?.toString() || ''}
                  onChange={updateMinute}
                  style={{width: 'auto', margin: 0}}
                > 
                  <option disabled value=''>MM</option> 
                  {minOptions.map((option) => (
                    <option
                      key={option.id}
                      value={option.value.toString()}
                    >
                      {option.description}
                    </option>
                  ))}
                </SelectStyled>
              </TimeRow>
            </TimeBox>
            <div>
              {hasError  && <ErrorMessage style={{textAlign: 'left', marginTop: '1em'}}>{errorMessage}</ErrorMessage>}
            </div>
          </TimeContainer>
        )}
    </div>
  );
}