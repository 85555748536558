import { useQuery } from 'react-query'
import { useSelector } from 'react-redux'
import addressApi from '../../api/addressApi'
import { InitialStateType } from '../../initialState'
import CustomerAccountApi from 'api/customerAccount'

const useCheckQuoteHasCustomer = (
  encryptedQuoteId: string,
  loggedIn: boolean,
) => {
  const query = useQuery(
    ['CheckQuoteHasCustomerAccount', encryptedQuoteId],
    () => CustomerAccountApi.CheckQuoteHasCustomerAccount(encryptedQuoteId),
    {
      enabled: !!encryptedQuoteId && !loggedIn,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
  )

  return {
    query,
  }
}

export default useCheckQuoteHasCustomer
