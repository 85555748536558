export const WHITE_LABEL = 'BrokerWhiteLabel';
export const SEMI_BRANDED = 'BrokerSemiBranded';
export const NON_BRANDED = 'BrokerNonBranded';

export const APLAN = 'A-Plan';
export const APLAN_REF = 'aplan';

export const BRIGHTSIDE = 'Brightside';
export const BRIGHTSIDE_REF = 'BS354BL302216BS';

export const ONE_ANSWER = 'One Answer Insurance Ltd';
export const ONE_ANSWER_REF = 'BN266JF432453';

export const RIGHT_CHOICE = 'Right Choice Insurance Brokers Ltd';
export const RIGHT_CHOICE_REF = 'RM13NH475620';

export const STRIDE = 'Stride Limited';
export const STRIDE_REF = 'PO76XP304823';

export const SWINTON = 'Swinton Group Ltd';
export const SWINTON_REF = 'M15SW309599';

export const ARNOLD_CLARK = 'Arnold Clark Insurance Services';
export const ARNOLD_CLARK_REF = 'G524FH308092';

export const MOTOR_EASY = 'MotorEasy Ltd';
export const MOTOR_EASY_REF = 'RG30724690';

export const QUIDCO = 'Quidco';
export const QUIDCO_REF = 'Quidco';

export const CTM = 'CompareTheMarket';
export const CTM_REF = 'PE26YS778488';

export const WHITELABEL_LOGOS: { [key: string]: string; } = {
  [APLAN_REF.toUpperCase()]: `/images/affiliates/${APLAN_REF}.png`,
  [BRIGHTSIDE_REF.toUpperCase()]: `/images/affiliates/${BRIGHTSIDE_REF}.png`,
  [ONE_ANSWER_REF.toUpperCase()]: `/images/affiliates/${ONE_ANSWER_REF}.png`,
  [RIGHT_CHOICE_REF.toUpperCase()]: `/images/affiliates/${RIGHT_CHOICE_REF}.png`,
  [STRIDE_REF.toUpperCase()]: `/images/affiliates/${STRIDE_REF}.png`,
  [SWINTON_REF.toUpperCase()]: `/images/affiliates/${SWINTON_REF}.png`,
  [ARNOLD_CLARK_REF.toUpperCase()]: `/images/affiliates/${ARNOLD_CLARK_REF}.png`,
  [MOTOR_EASY_REF.toUpperCase()]: `/images/affiliates/${MOTOR_EASY_REF}.png`,
  [QUIDCO_REF.toUpperCase()]: `/images/affiliates/${QUIDCO_REF}.svg`,
};
