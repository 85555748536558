import fetchAntiForgeryTokenSaga from '../App/sagas/antiForgerySaga';
import fetchLinksSaga from '../App/sagas/linksSaga';
import { setVehicleSearchedReg, setVehicleError, requestVehicleSuccess } from '../Vehicle/actions';
import moment from 'moment';
import { put, takeLatest, select, call } from 'redux-saga/effects';
import queryString from 'query-string';
import { INIT_INVALID_REG_PAGE } from './constants';
import { vehicleLinkSelector } from '../Vehicle/selector';
import vehicleApi from '../../api/vehicleApi';
import { handleQueryString } from '../../services/handlers';
import { updateRiskData } from '../RiskDataCollectionPage/actions';
import { initInvalidRegPageSuccess, initInvalidRegPageFailure } from './actions';
import { isLoading, updateDurationValue } from '../App/actions';
import { history } from '../../';
import getOpenSchemeSaga from 'containers/App/sagas/schemeSaga';
import featureTogglesSaga from 'containers/App/sagas/featureTogglesSaga';
import getAffiliate from 'containers/App/sagas/affiliateSaga';

function* initInvalidRegPage() {
  try {
    yield put(isLoading(true));
    yield* fetchAntiForgeryTokenSaga();
    yield* fetchLinksSaga();
    yield* getOpenSchemeSaga();
    yield* featureTogglesSaga();
    const parsedQueryString = queryString.parse(window.location.search);
    if (parsedQueryString.ref) {
      yield* getAffiliate(parsedQueryString.ref);
    }


    const duration = handleQueryString().Duration;
    const durationType = handleQueryString().DurationType;
    const vehicleReg = handleQueryString().RegistrationNumber;
    const vehicleType = handleQueryString().UnknownVehicle.VehicleType;
    const isExpat = handleQueryString().Expat;
    const isLearner = handleQueryString().Learner;
    const isMotorbike = handleQueryString().Motorbike;
    const isImpounded = handleQueryString().Impounded;
    const isCourier = handleQueryString().Courier;

    // Sets risk data based on URL before API call for vehicle lookup incase it returns a 404 for unknown reg
    yield* setStateFromParams(duration, durationType, vehicleReg, vehicleType, null, isExpat, isLearner, isMotorbike, isImpounded,isCourier);

    const vehicleLinkSelect = yield select(vehicleLinkSelector);
    const vehicleLink = vehicleLinkSelect.Href.replace(/(\{.+\})/g, vehicleReg);
    const vehicle = yield call(vehicleApi.getVehicle, vehicleLink);
    const foundVehicleType = vehicle.data.VehicleType;

    yield put(requestVehicleSuccess(vehicle.data));

    if (history.location.state && history.location.state.error === 'VehicleNotFound') {
      yield put(setVehicleError('NotFound'));
    } else {
      yield put(setVehicleError(false));
    }
    yield* setStateFromParams(duration, durationType, vehicleReg, vehicleType, foundVehicleType, isExpat, isLearner, isMotorbike, isImpounded, isCourier);

    yield put(initInvalidRegPageSuccess());
    yield put(isLoading(false));
  } catch (e) {
    yield put(initInvalidRegPageFailure(e));
    yield put(isLoading(false));
  }
}

export default function* invalidRegPageSaga() {
  yield takeLatest(INIT_INVALID_REG_PAGE, initInvalidRegPage);
}

function* setStateFromParams(duration, durationType, vehicleReg, vehicleType, foundVehicleType, isExpat, isLearner, isMotorbike, isImpounded, isCourier) {
  const searchedVehicleType = vehicleType || foundVehicleType;

  yield put(
    updateDurationValue({
      value: duration,
      type: durationType
    })
  );
  yield put(
    updateRiskData({
      PolicyStartDate: { value: moment().format('YYYY-MM-DDTHH:mm') },
      Expat: isExpat,
      Learner: isLearner,
      Motorbike: isMotorbike,
      Impounded: isImpounded,
      Courier: isCourier
    })
  );
  yield put(
    setVehicleSearchedReg(vehicleReg, searchedVehicleType));
}
