import { Formik, FormikHelpers, useFormikContext } from 'formik';
import React, { ReactNode, useEffect } from 'react';

interface Props<T> {
  initialValues: T;
  children: ReactNode;
  onSubmit: (data: T, helpers?: FormikHelpers<T>) => void;
  // TODO: Work out resolver typing
  validationSchema: any;
  onKeyDown?: (e: React.KeyboardEvent<HTMLFormElement>) => void;
}

const scrollAboveElement = (element: HTMLElement, offset = 50) => {
  const y = element.getBoundingClientRect().top + window.pageYOffset - offset;

  window.scrollTo({ top: y, behavior: 'smooth' });
}

const FocusError = () => {
  const { errors, isSubmitting, isValidating } = useFormikContext();

  useEffect(() => {
    if (isSubmitting && !isValidating) {
      const keys = Object.keys(errors);

      if (keys.length > 0) {
        const nameSelector = `[name=${keys[0]}]`;
        const idSelector = `[id=${keys[0]}]`;
        const errorNameElement = document.querySelector(nameSelector) as HTMLElement;
        const errorIdElement = document.querySelector(idSelector) as HTMLElement;

        if (errorNameElement) {
          scrollAboveElement(errorNameElement);
        } else if (errorIdElement) {
          scrollAboveElement(errorIdElement);
        }
      }
    }
  }, [errors, isSubmitting, isValidating]);

  return null;
};

export const Form = <FormDataObject extends {}>({
  initialValues,
  children,
  onSubmit,
  validationSchema,
  onKeyDown,
}: Props<FormDataObject>) => {
  const initialTouched = {};

  Object.entries(initialValues).forEach(([key, value]) => {
    // @ts-ignore
    initialTouched[key] = !!value;
  });

  return (
    <Formik
      initialValues={initialValues}
      initialTouched={initialTouched}
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={onSubmit}
      enableReinitialize={false}
    >
      {({ handleSubmit }) => (
        <form
          onKeyDown={onKeyDown}
          onSubmit={handleSubmit}
        >
          {children}
          <FocusError />
        </form>
      )}
    </Formik>
  );
};

export default Form;
