export const VEHICLE_OWNERS = [
    {
        id: 2,
        description: "Myself",
        value: "Myself",
    },
    {
        id: 3,
        description: "Spouse",
        value: "Spouse",
    },
    {
        id: 4,
        description: "Son or daughter",
        value: "SonOrDaughter",
    },
    {
        id: 5,
        description: "Parent",
        value: "Parent",
    },
    {
        id: 6,
        description: "Other family member",
        value: "OtherFamilyMember",
    },
    {
        id: 7,
        description: "Garage - courtesy vehicle",
        value: "Garage",
    },
    {
        id: 8,
        description: "Vehicle leasing company",
        value: "VehicleLeasingCompany",
    },
    {
        id: 9,
        description: "Other",
        value: "Other",
    },
    {
        id: 10,
        description: "Hire company",
        value: "HireCompany",
    }
];