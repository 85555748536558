import styled from 'styled-components';

export const AutoCompleteWrapper = styled.ul`
  list-style: none;
  border: 1px solid #7a9cd3;
  padding: 0;
  width: auto;
  max-height: 200px;
  overflow-y: auto;
  margin-top: -11px;
  text-align: left;
`;

export default AutoCompleteWrapper;
