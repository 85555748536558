import {
  UPDATE_MYLICENCE_DATA,
  REQUEST_MYLICENCE_STATUS,
  REQUEST_MYLICENCE_STATUS_SUCCESS
} from './constants';

export function updateMyLicenceData(payload){
  return{
    type: UPDATE_MYLICENCE_DATA,
    payload
  }
}

export function requestMyLicenceStatus() {
  return {
    type: REQUEST_MYLICENCE_STATUS
  }
}

export function requestMyLicenceStatusSuccess(data) {
  return {
    type: REQUEST_MYLICENCE_STATUS_SUCCESS,
    data
  }
}