import experimentApi from 'api/experimentApi';
import useRiskData from 'hooks/useRiskData';
import { InitialStateType } from 'initialState';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { showExperiment } from 'store/experiment/experiment.actions';
import { LogExperimentRepresentation } from 'types/webAPI.definition';

const logExperiment = async (data: LogExperimentRepresentation) => {
  try {
    await experimentApi.logExperiment(data);
    return true;
  } catch {
    return false;
  }
};

const handleABExperiment = async (
  experimentID: string,
  setVariant: React.Dispatch<React.SetStateAction<string>>,
) => {
  // TODO: Sort out typing on this
  // @ts-ignore
  if (window.dataLayer) {

    // @ts-ignore
    await window.dataLayer.push({ event: 'optimize.activate' });
  }
  const intervalId = setInterval(() => {
    // @ts-ignore
    if (window.google_optimize !== undefined) {
      // @ts-ignore
      const variantResult = window.google_optimize.get(experimentID);
      setVariant(variantResult);
      clearInterval(intervalId);
    } else {
      // In this case google optimize is down / blocked
      // So we set variant to be the default
      setVariant('0');
      clearInterval(intervalId);
    }
  }, 100);
};

const useABExperiment = (
  experimentID: string,
  description: string,
) => {
  const experimentsStore = useSelector((state: InitialStateType) => state.experiments);

  const dispatch = useDispatch();
  const experimentInState = experimentsStore[experimentID];

  const [variant, setVariant] = useState<string>('');

  if (typeof (experimentInState) !== 'number') {
    // @ts-ignore
    handleABExperiment(experimentID, setVariant);
  }

  const { quoteId } = useRiskData();
  useEffect(() => {
    if (variant !== '' && typeof (experimentInState) !== 'number') {
      let variantNumber: number | null = null;
      if (!Number.isNaN(parseInt(variant, 10))) {
        variantNumber = +variant;
      }

      if (variantNumber !== null && quoteId) {
        logExperiment({
          Description: description,
          ExternalExperimentId: experimentID,
          QuoteId: {
            Value: quoteId,
          },
          Variant: variantNumber,
        });
        // store experiment in store so we dont re-call the AB logic
        dispatch(showExperiment({ [experimentID]: variantNumber }));
      }
    }
  }, [variant, experimentInState, description, experimentID, quoteId, dispatch]);

  useEffect(() => {
    if (typeof (experimentInState) === 'number') {
      setVariant(experimentInState.toString());
    }
  }, [experimentInState]);

  return {
    variant,
  };
};

export default useABExperiment;
