import defaultTheme from "./default";

let rightChoiceTheme = {...defaultTheme};   
const primaryColour = '#2276C2';
const secondaryColour = '#f80';
rightChoiceTheme.primaryColour = primaryColour;
rightChoiceTheme.primaryTextColour = '#333';
rightChoiceTheme.headerFontColour = primaryColour;
rightChoiceTheme.breadcrumb = {
  ...defaultTheme.breadcrumb,
    activeColor: "#ffffff",
    activeBorderColor: primaryColour,
    defaultColor: "#ffffff",
    defaultBorderColor: "#CCCCCC",
    defaultBarColor: "#CCCCCC",
    completeColor: primaryColour,
    completeBorderColor: primaryColour,
    completeBarColor: primaryColour
  }
  rightChoiceTheme.spinner = {
    ...defaultTheme.spinner,
    primaryColour: primaryColour,
    textColour: secondaryColour
  }
  rightChoiceTheme.durationOptions ={
  ...defaultTheme.durationOptions,
  primaryColour: primaryColour,
  secondaryColour: secondaryColour
}
rightChoiceTheme.button = {
  ...defaultTheme.button,
    backgroundColour: secondaryColour
}
rightChoiceTheme.header = {
  ...defaultTheme.header,
  background: primaryColour,
  borderBottom: "none",
}
  rightChoiceTheme.headerLinkStyled={
    ...defaultTheme.headerLinkStyled,
    padding: "0px",
    hover: {
      textDecoration: "underline",
      color: '#fff',
      backgroundColour: primaryColour,
      padding: "0px"
    }
  }

rightChoiceTheme.gradient = {
  ...defaultTheme.gradient,
  background: '#E8F0FF'
}
rightChoiceTheme.icon = {
  ...defaultTheme.icon,
  color: primaryColour,
  backgroundColor: '#E9F0FF'
}
rightChoiceTheme.GbIcon = '#fff';
rightChoiceTheme.formControlValidIcon = {
  ...defaultTheme.formControlValidIcon,
  colour: '#fff',
  backgroundColour: primaryColour
}
rightChoiceTheme.headerFontColour = primaryColour;
rightChoiceTheme.inputBorderColour = '#ddd';
rightChoiceTheme.successColour = primaryColour;
rightChoiceTheme.marketingPreferencesColour = secondaryColour;
rightChoiceTheme.svg = {
  ...defaultTheme.svg,
  primaryColour: '#0170bb',
  accentColour: '#8CD5FF',
}

export default rightChoiceTheme;
