import styled from 'styled-components';

const HeaderWrapper = styled.header` 
    background-color: ${props => props.theme.header.background};
    border-bottom: ${props => props.theme.header.borderBottom};
    background-image: ${props => props.theme.header.backgroundImage};
    background-repeat: no-repeat;
    background-size: cover;
    &:hover: ${props => props.theme.header.hover};
    height: 70px;
    padding: 1rem 0;
    display: flex;
    align-items: center;
    position: relative;

    @media (max-width: 1024px) {
        height: 36px;
        padding: 14px 0 15px;
    }
`;

export default HeaderWrapper;