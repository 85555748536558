import { updateStep } from 'containers/App/actions'
import { useQueryString, useWindowSize } from 'hooks'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Stepper from 'react-stepper-horizontal'
import { useTheme } from 'styled-components'
// @ts-ignore
import useCustomerAccount from 'hooks/useCustomerAccount'
import tick from '../../images/breadcrumb-tick-white.png'
import { Wrapper } from './Breadcrumb.styles'

interface Props {
  step: number
  disabled?: boolean
}

const BreadCrumb: React.FC<Props> = ({ step, disabled = false }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { queryString } = useQueryString()
  const { isCustomerAccountQuote } = useCustomerAccount()

  const handleClick = (e: MouseEvent, pathname: string, stepNumber: number) => {
    e.preventDefault()

    if (!disabled) {
      dispatch(updateStep(stepNumber))
      history.push({ pathname, search: queryString })
    }
  }

  const [steps] = useState<
    {
      icon: string | undefined
      href: string
      onClick: (e: MouseEvent) => void
      title?: string
    }[]
  >([
    {
      icon: tick,
      href: !isCustomerAccountQuote
        ? '/quote/driver-details'
        : '/quote/driver-details/logged-in',
      onClick: (e) =>
        handleClick(
          e,
          !isCustomerAccountQuote
            ? '/quote/driver-details'
            : '/quote/driver-details/logged-in',
          0,
        ),
      title: 'The vehicle',
    },
    {
      icon: tick,
      href: '/quote/driving-licence',
      onClick: (e) => handleClick(e, '/quote/driving-licence', 1),
      title: 'Your quote',
    },
    {
      icon: tick,
      href: '/quote/legal-declaration',
      onClick: (e) => handleClick(e, '/quote/legal-declaration', 2),
      title: "What's covered",
    },
    {
      icon: tick,
      href: '/quote/payment',
      onClick: (e) => handleClick(e, '/quote/payment', 3),
      title: 'Payment',
    },
  ])

  const theme = useTheme()
  const { isMobile } = useWindowSize()

  return (
    <Wrapper id='Breadcrumb'>
      <Stepper
        steps={steps}
        activeStep={step}
        // @ts-ignore
        activeColor={theme.breadcrumb.activeColor}
        activeBorderStyle='solid'
        // @ts-ignore
        activeBorderColor={theme.breadcrumb.activeBorderColor}
        completeBorderStyle='solid'
        defaultBorderStyle='solid'
        titleFontSize={isMobile ? 13 : 16}
        // @ts-ignore
        defaultTitleColor={theme.breadcrumb.defaultTitleColor}
        // @ts-ignore
        defaultBorderColor={theme.breadcrumb.defaultBorderColor}
        // @ts-ignore
        defaultBarColor={theme.breadcrumb.defaultBarColor}
        // @ts-ignore
        defaultColor={theme.breadcrumb.defaultColor}
        // @ts-ignore
        completeBarColor={theme.breadcrumb.completeBarColor}
        // @ts-ignore
        completeBorderColor={theme.breadcrumb.completeBorderColor}
        // @ts-ignore
        completeColor={theme.breadcrumb.completeColor}
        // @ts-ignore
        size={theme.breadcrumb.circleSize}
        // @ts-ignore
        circleTop={theme.breadcrumb.circleMarginTop}
      />
    </Wrapper>
  )
}

export default BreadCrumb
