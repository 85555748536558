import styled from "styled-components";

export const MessageHeader = styled.h2`
  font-size: 22px;
  font-family: ${props => props.theme.fontFamily};
  font-weight: normal;
  text-align: left;
  margin-bottom: 5px;
  margin-top: 10px;
`;
