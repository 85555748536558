import moment from 'moment';

export const handleDateOfBirthValidation = (
  value: string | undefined,
  minAge: number,
  maxAge: number,
) => {
  const dob = moment(value, 'D/M/YYYY');
  const monthDiff = moment().diff(moment(value, 'D/M/YYYY'), 'months');
  return dob.isValid() && monthDiff >= minAge * 12 && monthDiff <= maxAge * 12;
};
