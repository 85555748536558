import React from 'react';
import { BannerText, BannerWrapper } from './DiscountBanner.styles';


const DiscountBanner: React.FC = ({ children }) => (
    <BannerWrapper>
        <BannerText>{children}</BannerText>
    </BannerWrapper>
)

export default DiscountBanner