import { useSelector } from 'react-redux';
import { formatDateMoment } from '../../utils/handle-date-format';
import { calculateAge } from '../../utils/handle-date-calculations';
import { InitialStateType } from 'initialState';
import { useMemo } from 'react';
import moment from 'moment';

const useRiskData = () => {
  const riskData = useSelector((state: InitialStateType) => state.riskData);
  const duration = useSelector((state: InitialStateType) => state.riskData.Duration);
  const durationType = useSelector((state: InitialStateType) => state.riskData.DurationType);
  const occupation = useSelector((state: InitialStateType) => state.riskData.Occupation);
  const quoteId = useSelector((state: InitialStateType) => state.riskData.QuoteId);
  const policyStartDate = useSelector((state: InitialStateType) => state.riskData.PolicyStartDate);
  const isRiskDataValid = useSelector((state: InitialStateType) => state.validation.riskDataCollectionPage.AllFieldsValid)

  const formattedPolicyStartDate = useMemo(() => {
    if (!policyStartDate.manuallyEdited) {
      return 'Policy starts immediately';
    }
    if (riskData.PolicyStartDate?.value) {
      return moment(riskData.PolicyStartDate.value).format('DD/MM/YYYY HH:mm');
    }
    return 'Policy starts immediately';
  }, [policyStartDate.manuallyEdited, policyStartDate.value]);

  const { DateOfBirth: dateOfBirth } = riskData;
  const age = useMemo(() => {
    if (dateOfBirth) {
      return calculateAge(formatDateMoment(dateOfBirth, 'YYYY-MM-DD'), moment().format('YYYY-MM-DD'));
    } else {
      return null
    }
  }, [dateOfBirth]);

  return {
    riskData,
    duration,
    durationType,
    occupation,
    quoteId,
    policyStartDate,
    formattedPolicyStartDate,
    age,
    isRiskDataValid,
  };
};

export default useRiskData;
