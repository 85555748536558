import http from './http';

export default class identityApi {
  static identityCheck(endpoint) {
    return http.get(endpoint, {
      headers: {
        'Content-Type':
          'application/tempcover.ProposerIdentityResultRepresentation+json',
      },
    });
  }

  static drivingLicenceCheck(endpoint, data) {
    return http.post(endpoint, data, {
      headers: {
        'Content-Type':
          'application/tempcover.drivinglicencecheckrepresentation+json',
      },
    });
  }

  static passportCheck(endpoint, data) {
    return http.post(endpoint, data, {
      headers: {
        'Content-Type':
          'application/tempcover.passportcheckrepresentation+json',
      },
    });
  }
}
