export const store = {
  _antiForgeryToken: '',
  _authToken: '',

  set antiForgeryToken(token) {
    this._antiForgeryToken = token
    localStorage.setItem('antiForgeryToken', token)
  },

  set authToken(token) {
    this._authToken = token
    localStorage.setItem('authToken', token)
  },

  get authToken() {
    return this._authToken || localStorage.getItem('authToken')
  },

  get antiForgeryToken() {
    return this._antiForgeryToken || localStorage.getItem('antiForgeryToken')
  },
}
