import styled from 'styled-components';

export const LegalDeclarationPageWrapper = styled.div`
    #ShortDeclaration {
        cursor: pointer;
        font-weight: ${props => props.theme.fontWeight} !important;
    }
    ul {
        padding: 15px;
        color: #000000 !important;
        font-size: ${props => props.theme.generalFontSize} !important;
    }
    ul :first-child {
        color: ${props => props.theme.headerFontColour};
        font-size: 20px;
        padding: 3px 0 !important;
        font-weight: bold !important;
    }
    ul li ul li:first-child {
        color: #000000 !important;
        font-size: ${props => props.theme.generalFontSize} !important;
        font-weight: ${props => props.theme.fontWeight} !important;
    }
    ul li:nth-child(2) {
        color: ${props => props.theme.headerFontColour};
        font-size: 20px;
        padding: 3px 0 !important;
        font-weight: bold !important;
    }
    ul li ul li:nth-child(2) {
        color: #000000 !important;
        font-size: ${props => props.theme.generalFontSize} !important;
        font-weight: ${props => props.theme.fontWeight} !important;
    }
    ul li ul li :last-child {
        color: #000000 !important;
        font-size: ${props => props.theme.generalFontSize};
    }
    li {
        padding: 3px 0;
        list-style: none;
        border-top: 1px solid ${props => props.theme.primaryColour};
        font-family: ${props => props.theme.fontFamily};
        font-style: ${props => props.theme.fontStyle};
        font-weight: ${props => props.theme.fontWeight};
        font-size: ${props => props.theme.generalFontSize};
    }

    #DisallowedOccupationsLink {
        color: ${props => props.theme.primaryColour};
        display: inline;
        cursor: pointer;
        text-decoration: underline;
    }
`
