import { useSelector } from 'react-redux';
import { InitialStateType } from 'initialState';

const usePayment = () => {
  const payment = useSelector((state: InitialStateType) => state.payment);
  return {
    payment,
  };
};

export default usePayment;
