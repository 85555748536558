import {
  REQUEST_OCCUPATIONS,
  REQUEST_OCCUPATIONS_SUCCESS,
  REQUEST_OCCUPATIONS_FAILED,
  SELECT_OCCUPATION,
  UPDATE_OCCUPATION_SEARCH
} from './constants';

export function requestOccupations(occupationSearch) {
  return {
    type: REQUEST_OCCUPATIONS,
    occupationSearch
  }
}

export function requestOccupationsSuccess(occupations) {
  return {
    type: REQUEST_OCCUPATIONS_SUCCESS,
    occupations
  }
}

export function requestOccupationsFailed(message) {
  return {
    type: REQUEST_OCCUPATIONS_FAILED,
    message
  }
}

export function selectOccupation(occupation) {
  return {
    type: SELECT_OCCUPATION,
    occupation
  }
}

export function updateOccupationSearch(occupationSearch) {
  return {
    type: UPDATE_OCCUPATION_SEARCH,
    occupationSearch
  }
}