import { appendToHead, appendToBody } from './handlers';

export const appendSwintonGtmTags = function() {
  appendToHead(`
      <!-- Swinton Google Tag Manager -->
        <script>
          (function(w, d, s, l, i) {
            w[l] = w[l] || [];
            w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
            var f = d.getElementsByTagName(s)[0],
              j = d.createElement(s),
              dl = l != 'dataLayer' ? '&l=' + l : '';
            j.async = true;
            j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
            f.parentNode.insertBefore(j, f);
          })(window, document, 'script', 'dataLayer', 'GTM-MPQ6NM3');
        </script>
      <!-- End Swinton Google Tag Manager -->`);
  appendToBody(`
      <!-- Swinton Google Tag Manager (noscript) -->
        <noscript
          ><iframe
            src="https://www.googletagmanager.com/ns.html?id=GTM-MPQ6NM3"
            height="0"
            width="0"
            style="display:none;visibility:hidden"
          ></iframe
        ></noscript>
      <!-- End Swinton Google Tag Manager (noscript) -->`);
};