import initialState from '../../../initialState';
import {
    UPDATE_STEP
} from '../constants';

function stepReducer(state = initialState.step, action) {
    switch (action.type) {
        case UPDATE_STEP:
            return state = action.step
        default:
            return state;
    }
}

export default stepReducer;