import {
    CREATE_PAYPAL_PAYMENT,
    CANCEL_PAYPAL_PAYMENT,    
    PAYPAL_PAYMENT_ERROR
} from './constants';

import {
    createPayPalPaymentSuccess,
    createPayPalPaymentFailure,
    cancelPayPayPaymentSuccess,
    cancelPayPayPaymentFailure,
    payPalPaymentErrorSuccess,
    payPalPaymentErrorFailure
} from './actions';

import {
    call,
    takeLatest,
    select,
    put
} from 'redux-saga/effects';
import paymentApi from '../../api/paymentApi';
import quoteApi from '../../api/quoteApi';

import {
    getQuoteId,    
    getPayPalPaymentLink,
    getPaymentCancellationLink,
    getPayPalFailedLink
} from './selector';
import { history } from '../..';
import { getQueryString } from '../RiskDataCollectionPage/selector';


function* createPayPalPayment({paymentDetails}) {       
    const quoteId = yield select(getQuoteId);   
    const queryString = yield select(getQueryString);
    try {
       
        const payPalPaymentLink = yield select(getPayPalPaymentLink);                      
              
        const data = {
            OrderId: paymentDetails.orderId,
            CaptureId: paymentDetails.captureId,
            ResponseMerchantReference: paymentDetails.referenceId,
            Amount: paymentDetails.amount,
            QuoteId: {
                Value: quoteId
            }
        }  

        yield call(paymentApi.createPayPalPayment, payPalPaymentLink.Href, data);   
        yield put(createPayPalPaymentSuccess())
        
        history.push(`/policy/confirmation${queryString}`);
    } catch (e) {
        if(e.response.status === 403) {
            history.push({
                pathname: `/quote/driving-licence`,
                search: `${queryString}`,
                state: {fraudFailure: true}
              });
          } else {
            yield put(createPayPalPaymentFailure(e.message))        
          }

    }
}

function* cancelPayPalPayment() {       
    try {
        const quoteId = yield select(getQuoteId);                 
        const paymentCancellationLink = yield select(getPaymentCancellationLink);
        const data = {     
                Value: quoteId 
        }  

        yield call(quoteApi.unlockQuote, paymentCancellationLink.Href.replace(/{paymentProvider}/, 'PayPal'), data);
        yield put(cancelPayPayPaymentSuccess('We have not taken payment at this time. Please try again.'))
      
    } catch (e) {
        yield put(cancelPayPayPaymentFailure(e.message))        
    }
}

function* paypalPaymentError({errorDetails}) {       
    try {
        const quoteId = yield select(getQuoteId);                 
        const paypalFailedLink = yield select(getPayPalFailedLink);
        const data = {     
            ResponseMerchantReference: errorDetails.requestMerchantReference,
                QuoteId: {
                    Value: quoteId 
                }
        }  
         yield call(paymentApi.failedPayment, paypalFailedLink.Href.replace(/{paymentProvider}/, 'PayPal'), data);
        yield put(payPalPaymentErrorSuccess())
        
    } catch (e) {
        yield put(payPalPaymentErrorFailure(e.message))        
    }
}

export default function* payPalSaga() {
    yield takeLatest(CREATE_PAYPAL_PAYMENT, createPayPalPayment);
    yield takeLatest(CANCEL_PAYPAL_PAYMENT, cancelPayPalPayment);
    yield takeLatest(PAYPAL_PAYMENT_ERROR, paypalPaymentError);
}

