import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { DateTimePicker } from '../@common/DateTimePicker';
import { useField } from 'formik';

const PolicyDateTimePicker: React.FC = () => {
  const [validMinimumDate, setMinDate] = useState<string>(moment().format());
  const [validMaximumDate, setMaxDate] = useState<string>(moment().format());
  const [field, meta, helpers] = useField('policyStartTime');
  const [isManualField, isManualMeta, isManualHelpers] = useField('manuallyChanged');

  useEffect(() => {
    const now = moment();
    let timeWithDelayAndInterval;

    if (now.minute() % 5 === 0) {
      timeWithDelayAndInterval = moment(now).add(10, 'minutes');
    } else {
      const intervalDifference = 5 - (now.minute() % 5);
      timeWithDelayAndInterval = moment(now).add(
        5 + intervalDifference,
        'minutes'
      );
    }

    const minDate = timeWithDelayAndInterval.format('YYYY-MM-DDTHH:mm');
    const maxDate = timeWithDelayAndInterval
      .add(30, 'day')
      .hour(23)
      .minute(55)
      .format('YYYY-MM-DDTHH:mm');

      setMinDate(minDate);
      setMaxDate(maxDate);
  }, []);

  const onDateTimeChange = (dateTimeString: string) => {
    helpers.setValue(dateTimeString, false);
  };

  return (
    <>
      <DateTimePicker
        name="policyStartTime"
        value={field.value}
        startDate={validMinimumDate}
        endDate={validMaximumDate}
        onDateTimeChange={onDateTimeChange}
        isManuallyChanged={isManualField.value}
        onManualChange={(isManual: boolean) => isManualHelpers.setValue(isManual)}
        showTime={isManualField.value}
        hasError={!!meta.error}
        errorMessage={meta.error || ''}
        setError={helpers.setError}
        setTouched={helpers.setTouched}
      />
    </>
  );
}

export default PolicyDateTimePicker;
