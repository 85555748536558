import { P } from 'components/common/P';
import styled from 'styled-components';
import { H2 } from '../../components/common/H2';

export const PaymentHeader = styled(H2)`
  margin: 0;
`;

export const StripePaymentWrapper = styled.div`
  margin-bottom: 7em;
`;

export const TimerWrapper = styled.div`
  margin-bottom: 16px;
`;
export const OrderSummaryWrapper = styled.div`
  width: 80%;
  margin: 8px auto;

  @media (max-width: 768px) {
    width: 95%;
  }
`;

export const LegalText = styled(P)`
  font-size: 14px;
  @media (max-width: 768px) {
    font-size: 12px;
  }
`;
