import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import config from 'config';
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe(config.STRIPE_KEY);

const StripeElementsWrapper: React.FC = ({ children }) => (
    <Elements stripe={stripePromise}>
        {children}
    </Elements>
);

export default StripeElementsWrapper;
