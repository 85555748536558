import {
    POLICY_PRE_PURCHASE_LINK,
    PAYPAL_PAYMENT_LINK,
    PAYMENT_CANCELLATION_LINK,
    PAYMENT_FAILED_LINK
} from './constants';

export const getQuoteId = state => state.riskData.QuoteId;
export const getPolicyPrePurchaseLink = state => state.links.filter(link => link.Rel === POLICY_PRE_PURCHASE_LINK)[0];
export const getPayPalPaymentLink = state => state.links.filter(link => link.Rel === PAYPAL_PAYMENT_LINK)[0];
export const getPayPalFailedLink = state => state.links.filter(link => link.Rel === PAYMENT_FAILED_LINK)[0];
export const getPaymentCancellationLink = state => state.links.filter(link => link.Rel === PAYMENT_CANCELLATION_LINK)[0];