import React from 'react';
import { Button } from '../common/Button';
import { H1 } from '../common/H1';
import styled from 'styled-components';
import { useConfig } from 'hooks';
import GridContainerWrapper from 'components/@common/GridContainerWrapper';

const Heading = styled(H1)`
  text-align: left;
`;

const ErrorPage = () => {
  const siteUrl = useConfig().SITE_URL;
  return (
    <GridContainerWrapper>
      <Heading>Sorry, there seems to be a problem with your quote.</Heading>
      <Button
        id='HomePageButton'
        onClick={() => {
          window.location.href = siteUrl;
        }}
      >
        New Quote
      </Button>
    </GridContainerWrapper>
  );
};

ErrorPage.propTypes = {};

export default ErrorPage;
