import React from 'react';
import { LogoProps } from '..';
// @ts-ignore
import TrustPilotStarsImg from '../../../images/trustpilotreview-star.png';

const TrustPilotStars: React.FC<LogoProps> = ({ width, height }) => (
  <img src={TrustPilotStarsImg} width={width} height={height} alt="Trustpilot stars" />
);

export default TrustPilotStars;
