import React from 'react';
import PropTypes from 'prop-types';
import {P, Span} from './styles.js';
import { Grid, GridContainer } from 'unsemantic';

const PolicyRefSection = ({policyId}) => {  
    return(
        <GridContainer>       
            <Grid desktop='20' tablet='20' mobile='30'>
                <P>Policy reference number</P>
            </Grid>
            <Grid desktop='80' tablet='80' mobile='70'>
                <P><Span>{policyId}</Span></P>
            </Grid>                     
        </GridContainer>  
    )
}

PolicyRefSection.propTypes = {
    policyId: PropTypes.string
};

export default PolicyRefSection;
