import React from 'react';
import { LogoProps } from '..';
// @ts-ignore
import TrustPilotLogo from '../../../images/Trustpilot_logo.png';

const Trustpilot: React.FC<LogoProps> = ({ width, height }) => (
  <img src={TrustPilotLogo} alt="Trustpilot logo" width={width} height={height} />
);

export default Trustpilot;
