import { P } from 'components/common/P';
import styled from 'styled-components';

export const CashbackValueWrapper = styled.div`
  box-sizing: border-box;
  padding: 10px;
  margin: 20px 0px 40px 0px;
  border-right: 1px solid #1d7bce;
  border-left: 1px solid #1d7bce;
  background-color: ${(props) => props.theme.primaryColour};
  color: #fff;
  @media (max-width: 344px) {
    padding: 0 10px;
  }
  @media (max-width: 768px){
    padding: 15px;
    text-align: left;
  }
  position: relative;
  ::before {
    position:absolute;
    right:100%;
    top:0;
    content:'';
    height:100%;
    width:50vw;
    background:${(props) => props.theme.primaryColour};
  }
  ::after {
    position:absolute;
    left:100%;
    top:0;
    content:'';
    height:100%;
    width:50vw;
    background:${(props) => props.theme.primaryColour};
    overflow:hidden;
  }
`;

export const CashbackText = styled(P)`
  font-size: 16px;
  margin-bottom: 5px;
  @media (max-width: 768px){
    font-size:14px;
  }
`;

export const CashbackTextBold = styled(CashbackText)`
  display: inline 
  font-weight: bold;
`;

export const YourQuotes = styled.h2`
  font-size: 26px;
  margin-top: 0px;
`;
