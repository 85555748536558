import initialState from '../../initialState';
import {
  CHECK_MARKETING_PREFERENCES_OPT_IN_STATE_SUCCESS,
  CHECK_MARKETING_PREFERENCES_OPT_IN_STATE_FAILURE,
  SET_MARKETING_PREFERENCES_OPT_IN_STATE,
  RESET_MARKETING_PREFERENCES_OPT_IN_STATE
} from './constants';

export function marketingPreferencesReducer(state = initialState.marketingPreferences, action) {
  switch (action.type) {
    case CHECK_MARKETING_PREFERENCES_OPT_IN_STATE_SUCCESS:
      return {
        ...state,
        loaded: true,
        ...action.data,
        TelephoneNumberOptIn: action.data.TelephoneNumberOptIn == null ? true : action.data.TelephoneNumberOptIn,
        EmailAddressOptIn: action.data.EmailAddressOptIn == null ? true : action.data.EmailAddressOptIn,
        PreviousCustomer: action.data.TelephoneNumberOptIn != null || action.data.EmailAddressOptIn != null
      };
    case CHECK_MARKETING_PREFERENCES_OPT_IN_STATE_FAILURE:
      return {
        ...state,
        loaded: false
      };
    case SET_MARKETING_PREFERENCES_OPT_IN_STATE:
      return {
        ...state,
        ...action.vals
      };
    case RESET_MARKETING_PREFERENCES_OPT_IN_STATE:
      return {
        ...state,
        loaded: false,
        TelephoneNumber: null,
        TelephoneNumberOptIn: true,
        EmailAddress: null,
        EmailAddressOptIn: true
      };
    default:
      return state;
  }
}

export default marketingPreferencesReducer;
