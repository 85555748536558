var primaryColour = '#264B94'
var secondaryColour = primaryColour
const defaultTheme = {
  primaryColour: primaryColour,
  primaryTextColour: primaryColour,
  backgroundColour: '#E8F0FF',
  formControlBorderRadius: '6px',
  formControlHorizontalPadding: '10px',
  formControlBottomMargin: '10px',
  generalFontSize: '18px',
  formControlHeight: '45px',
  formControlValidIcon: {
    colour: '#fff',
    backgroundColour: primaryColour,
  },
  // fontFamily: 'freight-sans-pro, sans-serif',
  fontFamily: 'Lato',
  fontStyle: 'normal',
  fontWeight: '300',
  labelFontSize: '14px',
  largeHeaderFontSize: '40px',
  mobileLabelFontSize: '12px',
  labelTextColour: '#000000',
  headerFontFamily: 'BrandonText-Bold',
  headerFontColour: primaryColour,
  labelFontFamily: 'BrandonText',
  numberPlateFontFamily: 'UKNumberPlate',
  disabledButtonColour: '#d2d2d2',
  buttonFontSize: '14px',
  buttonTextTransform: 'uppercase',
  buttonTextColour: '#FFFFFF',
  buttonTextAlign: 'center',
  linkFontSize: '12px',
  linkColour: primaryColour,
  linkUnderline: primaryColour,
  errorBackgroundColour: '#FFEDE8',
  infoBackgroundColour: '#5bc0de',
  errorBorderProperties: '2px solid #EF5831',
  infoBorderProperties: '2px solid #264694',
  errorIconColour: '#EF5831',
  infoIconColour: primaryColour,
  messageBorderRadius: '6px',
  vehicleDetailBorderRadius: '16px',
  regFont: 'Charles-Wright',
  validIcon: '\f00c',
  secondaryColour: '#779E00',
  successColour: '#779E00',
  inputBorderColour: primaryColour,
  inputActiveBorderColour: '#000000',
  icons: {
    border: '1px solid #264694',
    height: '32px',
    width: '43px',
  },
  multiPrice: {
    fontSize: '16px',
    mobile: {
      coverTypeFontSize: '12px',
      mobileCoverTypeFontSize: '12px',
      lowestPriceBannerFontSize: '12px',
      continueButtonFontSize: '22px',
    },
    continueButtonFontSize: '22px',
    button: {
      backgroundColour: primaryColour,
      disabledFont: '#fff',
    },
  },
  button: {
    backgroundColour: primaryColour,
    disabledBackground: '#d2d2d2',
    disabledFont: '#fff',
    fontSize: '16px',
    fontFamily: 'BrandonText-Bold',
    textTransform: 'uppercase',
    textColour: '#FFFFFF',
    textAlign: 'center',
    hover: 'none',
    addon: {
      backgroundColour: primaryColour,
      disabledBackground: '#808080',
      disabledFont: '#fff',
    },
  },
  progressionStep: {
    titleDesktopFontSize: '20px',
    titleFontSize: '18px',
    contentDesktopFontSize: '14px',
    contentFontSize: '12px',
    subContentFontSize: '10px',
    subContentExtraSmallFontSize: '8px',
    subContentIconDesktopSize: '15px',
    subContentIconSize: '15px',
  },
  GbIcon: '#fff',
  marketingPreferencesColour: '#264B94',
  icon: {
    color: primaryColour,
    backgroundColor: '#E9F0FF',
  },
  editIconColour: '#CCC',
  breadcrumb: {
    activeColor: '#ffffff',
    activeBorderColor: '#1E3172',
    defaultColor: '#ffffff',
    defaultBorderColor: '#CCCCCC',
    defaultBarColor: '#CCCCCC',
    completeColor: '#779E00',
    completeBorderColor: '#779E00',
    completeBarColor: '#CCCCCC',
    defaultTitleColor: '#000',
    circleSize: 20,
    circleMarginTop: 10,
  },
  priceComparison: {
    textColour: '#264694',
    headerColour: '#264694',
  },
  spinner: {
    primaryColour: primaryColour,
    textColour: primaryColour,
  },
  drivingLicence: {
    primaryColour: primaryColour,
  },
  durationOptions: {
    primaryColour: primaryColour,
    secondaryColour: '#779E00',
  },
  header: {
    background: '#1E3172',
    borderBottom: 'none',
    backgroundImage: '',
  },
  headerLinkStyled: {
    padding: '0px',
    hover: {
      textDecoration: 'underline',
      color: '#fff',
      backgroundColour: primaryColour,
      padding: '0px',
    },
  },
  gradient: {
    background: '#F3F8FF',
  },
  svg: {
    primaryColour: primaryColour,
    accentColour: '#ccdcff',
  },
  confused: {
    darkColour: '#223153',
    headerFontFamily: 'Poppins, Helvetica, Arial, sans-serif',
    formControlBorderRadius: '0',
    button: {
      backgroundColour: '#a7d840',
      secondaryBackgroundColour: '#1074d7',
      disabledBackground: '#d2d2d2',
      disabledFont: '#fff',
      fontSize: '22px',
      textColour: '#000000',
      secondaryTextColour: '#FFFFFF',
      textAlign: 'left',
      fontWeight: 'bold',
      hover: 'none',
      hoverColour: '#a7d840',
    },
  },
}

export default defaultTheme
