import React from 'react';
import Modal from '../@common/Modal';
import { Grid, GridContainer } from 'unsemantic';
import { CameraAccessButton } from './CameraAccessButton';
import { I, IconWrapper } from './styles';
import correctLicenceImage from '../../images/licence-scan-helper-correct.png';
import incorrectLicenceImage from '../../images/licence-scan-helper-incorrect.png';
import styled from "styled-components";

export const CenteredParagraph = styled.p`
    text-align: center;
`

export const ImageExample = styled.img`
width: 100%;
`


export const IncorrectLicenceScanExample = styled(ImageExample)`
    margin-top: 10px;
    margin-bottom: 12px;
`


export const CameraAccessModal = ({ handleClick, closeModal, isOpen }) => {
    return (
        <Modal id="cameraAccessModal" isVisible={isOpen} onClose={closeModal} header='Scanning your licence' headerAlignment='center'>
            <CenteredParagraph>As much as we'd love to see what you look like, can you focus your scan on the text on your licence - that's all we need.</CenteredParagraph>
            <GridContainer>
                <Grid mobile='40' tablet='40' parent>
                    <IncorrectLicenceScanExample src={incorrectLicenceImage} alt="Incorrect licence scan example" />

                </Grid>
                <Grid mobile-prefix='5' mobile='55' tablet-prefix='5' tablet='55' parent>
                    <ImageExample src={correctLicenceImage} alt="Correct licence scan example" />

                </Grid>
            </GridContainer>
            <GridContainer >
                <Grid mobile='40' tablet='40' parent>
                    <IconWrapper>
                        <I className="fas fa-times-circle" color='#EF5831' />
                    </IconWrapper>
                </Grid>
                <Grid mobile-prefix='5' mobile='55' tablet-prefix='5' tablet='55' parent>
                    <IconWrapper>
                        <I className="fas fa-check-circle" color='#264694' />
                    </IconWrapper>
                </Grid>
            </GridContainer>
            <CameraAccessButton id='TeslaCameraAccessNextButton' onClick={handleClick}>Continue</CameraAccessButton>
        </Modal >
    )
}