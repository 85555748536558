import { createStore,  applyMiddleware, compose } from 'redux';
import createReducer from './reducers';
import thunk from 'redux-thunk';
import createSagaMiddleware  from 'redux-saga';
import rootSaga from './sagas';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import persistState from 'redux-localstorage'

export const history = createBrowserHistory()

export default (initialState) => {

    // Create the store with middlewares        
    const sagaMiddleware = createSagaMiddleware()
    const router = routerMiddleware(history);
    const middlewares = [thunk, sagaMiddleware, router];
    const enhancers = [applyMiddleware(...middlewares)];

      // If Redux DevTools Extension is installed use it, otherwise use Redux compose
  /* eslint-disable no-underscore-dangle, indent */
  const composeEnhancers =
    process.env.NODE_ENV !== 'production' &&
    typeof window === 'object' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
          // TODO: Try to remove when `react-router-redux` is out of beta, LOCATION_CHANGE should not be fired more than once after hot reloading
          // Prevent recomputing reducers for `replaceReducer`
          shouldHotReload: false,
        })
      : compose;

  
    const store = createStore(
        createReducer(history),        
        initialState,
        composeEnhancers(...enhancers, persistState('session', {key: 'SessionData'})),
    );

    sagaMiddleware.run(rootSaga)

    return store;
}