import initialState from '../../initialState';
import { ALLOW_CAMERA } from './constants';

function cameraReducer(state = initialState.cameraEnabled, action) {
    switch (action.type) {
        case ALLOW_CAMERA:
            return state = action.allowCamera
        default:
            return state;
    }
}

export default cameraReducer;