import React from 'react';
import PropTypes from 'prop-types';
import { BaseInput } from './BaseInput';

export const Input = ({
  id,
  placeholder,
  error,
  onChange,
  onBlur,
  onFocus,
  name,
  value,
  type,
  style}) => {
  return (
    <BaseInput
      placeholder={placeholder}
      error={error}
      onChange={onChange}
      onBlur={onBlur}
      onFocus={onFocus}
      name={name}
      id={id}
      value={value}
      type={type}
      style={style}
      autoComplete='off'
      autoCorrect='off'
    />
  );
};

Input.propTypes = {
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  error: PropTypes.string,
  onBlur: PropTypes.func,
  name: PropTypes.string,
  id: PropTypes.string,
  value: PropTypes.string,
  type: PropTypes.string
};
