import * as yup from 'yup'

export const ReasonForPurchaseOtherTextValidation = yup
  .string()
  .typeError('Please enter a valid reason')
  .matches(
    /^([a-zA-Z ' " ? . ,]*)$/,
    'Please enter a valid reason',
  )
  .min(2, 'Please enter a valid reason')
  .max(160, 'Please enter a valid reason')
