import { BaseDriverFormData } from 'components/@forms/DriverDetails/DriverDetails.types';
import { updateRiskData } from 'containers/RiskDataCollectionPage/actions';
import { useFormikContext } from 'formik';
import React from 'react';
import MailCheck from 'react-mailcheck';
import { useDispatch } from 'react-redux';
import TextField from '../@common/TextField';
// TODO: Evaluate this approach for styling
import { P } from '../common/P';
import { IconStyled, SuggestionText } from './Email.styles';

interface Props {
  onBlur? : () => void;
}

const Email:React.FC<Props> = ({ onBlur }) => {
  const { values, setFieldValue } = useFormikContext<BaseDriverFormData>();
  const dispatch = useDispatch();

  const handleSuggestion = (suggestion: string) => {
    setFieldValue('emailAddress', suggestion, true);

    if (onBlur) {
      onBlur();
      dispatch(updateRiskData({
        EmailAddress: suggestion,
      }))
    }
  };

  return (
    <MailCheck email={values.emailAddress}>
      {(suggestion: { full: string }) => (
        <>
          <TextField
            id="EmailAddress"
            onBlur={onBlur}
            name="emailAddress"
            placeholder="My email is..."
            type="email"
            icon={<IconStyled className="fas fa-envelope" />}
          />
          {suggestion && (
            <P>
              Did you mean
              {' '}
              <SuggestionText onClick={() => handleSuggestion(suggestion.full)}>
                {suggestion.full}
                ?
              </SuggestionText>
            </P>
          )}
        </>
      )}
    </MailCheck>
  );
};

export default Email;
