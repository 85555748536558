import { useAffiliate, usePrice, useSystemFeatures } from 'hooks';
import React from 'react';
import { CashbackText, CashbackTextBold, CashbackValueWrapper, YourQuotes } from './CashbackBanner.styles';

const CashbackBanner = () => {
  const { affiliate } = useAffiliate();
  const { price } = usePrice();
  const { IsCashbackEnabled } = useSystemFeatures();

  if (!price.AffiliateCashbackValue || !IsCashbackEnabled || !affiliate.IsCashbackEnabled) {
    return <></>
  }
  return (
    <CashbackValueWrapper>
      <YourQuotes>Your quotes</YourQuotes>
      <CashbackText>Select your preferred quote from the options below and purchase cover to receive
        <CashbackTextBold> £{price.AffiliateCashbackValue} cashback </CashbackTextBold>into your {affiliate.DisplayName} account</CashbackText>
    </CashbackValueWrapper>
  )
};

export default CashbackBanner;
