import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Elements, StripeProvider } from 'react-stripe-elements'
import { Grid } from 'unsemantic'
import {
  useABExperiment,
  useAffiliate,
  useConfig,
  usePrice,
  useQueryString,
  useQuote,
  usePayment,
  useDataLayer,
} from 'hooks'
import GridContainerWrapper from 'components/@common/GridContainerWrapper'
import { useDispatch, useSelector } from 'react-redux'
import { updateStep } from 'containers/App/actions'
import {
  priceCheckPaymentPage,
  priceMatch,
} from 'containers/PaymentPage/actions'
import { history } from 'index'
import Card from 'containers/Stripe/Card'
import KlarnaButton from 'components/KlarnaButton'
import KlarnaModal from 'components/KlarnaModal'
import { PaymentHeader } from 'containers/PaymentPage/styles'
import { InitialStateType } from 'initialState'
import { TextFieldStyled } from 'components/@common/TextField/TextField.styles'
import logFullStoryCustomEvent from 'utils/fullstory-custom-event'
import useCustomerAccountFeatureFlag from 'hooks/useCustomerAccountFeatureFlag'
import { PaymentContent } from './components'
import { DiscountVoucher } from './components/DiscountVoucher'
import {
  KlarnaButtonWrapper,
  KlarnaText,
  PaymentHeaderWrapper,
  StripePaymentWrapper,
} from './Payment.styles'

const Payment: React.FC = () => {
  const [isKlarnaModalVisible, setKlarnaModalVisible] = useState(false)

  const config = useConfig()
  const { isPriceComparison } = useAffiliate()
  const { price } = usePrice()
  const { quote } = useQuote()
  const { queryString } = useQueryString()
  const dispatch = useDispatch()
  const {
    payment: { klarnaPaymentOptionAvailable },
  } = usePayment()

  const multiPriceVariant = useSelector(
    (state: InitialStateType) =>
      state.experiments[config.AB_TESTS.PRICE_COMPARISON_EXPERIMENT_ID],
  )
  const { variant: klarnaVariant } = useABExperiment(
    config.AB_TESTS.KLARNA_EXPERIMENT_ID,
    'Klarna experiment',
  )

  const { data: featureFlagEnabled, isLoading: featureFlagLoaded } =
    useCustomerAccountFeatureFlag('MentionMePocEnabled')

  const { pushQuoteReferenceNumber } = useDataLayer()

  useEffect(() => {
    dispatch(updateStep(3))
    pushQuoteReferenceNumber()
  }, [dispatch, pushQuoteReferenceNumber])

  useEffect(() => {
    if (!isPriceComparison && multiPriceVariant === 0) {
      if (
        quote.Underwriter?.toLowerCase() !== price.Underwriter?.toLowerCase()
      ) {
        // show message on driving licence page & update premium units
        dispatch(priceCheckPaymentPage())
        history.push({
          pathname: '/quote/driving-licence',
          search: `${queryString}`,
        })
      } else if (quote.TotalPrice !== price.TotalPrice) {
        // show message on payment page & update premium units
        dispatch(priceMatch(false))
        dispatch(priceCheckPaymentPage())
      }
    }

    if (!featureFlagLoaded && featureFlagEnabled && featureFlagEnabled?.data) {
      const script = document.createElement('script')

      const body = document.getElementsByTagName('body')[0]

      const scriptPath = `${config.MENTION_ME_URL}/api/v2/refereefind/${config.MENTION_ME_ID}?situation=checkout&locale=en_GB`

      script.src = scriptPath

      body.appendChild(script)
    }
  }, [
    isPriceComparison,
    quote,
    price,
    multiPriceVariant,
    dispatch,
    queryString,
    config.MENTION_ME_URL,
    config.MENTION_ME_ID,
    featureFlagLoaded,
    featureFlagEnabled,
  ])

  const handleModalOpen = useCallback(() => {
    logFullStoryCustomEvent('Klarna - Open Modal')
    setKlarnaModalVisible(true)
  }, [])

  const handleModalClose = useCallback(() => {
    setKlarnaModalVisible(false)
  }, [])

  return (
    <GridContainerWrapper>
      <StripeProvider apiKey={config.STRIPE_KEY}>
        <>
          <PaymentContent />
          <div id='mmWrapper' />

          {!featureFlagLoaded &&
            featureFlagEnabled &&
            featureFlagEnabled?.data && <DiscountVoucher />}

          {/* KEEP COMMENTED AS THIS WILL GET BROUGHT BACK IN WITH ANNUAL UPSELL */}
          {/* <P>By proceeding to pay for the policy you understand that we may pass your details on to carefully selected third parties to contact you about related products and services. You can opt-out from this at any time by contacting us on <a href='mailto:contactus@tempcover.com'>contactus@tempcover.com</a></P> */}
          {klarnaPaymentOptionAvailable && klarnaVariant === '1' && (
            <>
              <PaymentHeaderWrapper>
                <PaymentHeader>Choose your payment method</PaymentHeader>
              </PaymentHeaderWrapper>
              <KlarnaButtonWrapper>
                <KlarnaButton onClick={handleModalOpen} />
                <KlarnaText>Pay in 30 days</KlarnaText>
              </KlarnaButtonWrapper>
              <KlarnaModal
                isVisible={isKlarnaModalVisible}
                onClose={handleModalClose}
              />
              <p>OR</p>
            </>
          )}
          <Grid desktop='100' mobile='100' tablet='100'>
            <StripePaymentWrapper>
              <Elements>
                <Card />
              </Elements>
            </StripePaymentWrapper>
          </Grid>
          {/* {this.state.canUseApple &&
          <>
            <p style={{margin: 0}}>or</p>
            <Grid desktop='100' mobile='100' tablet='100'>
              <ApplePay onSuccess={this.onSuccess} />
            </Grid>
          </>
        } */}
        </>
      </StripeProvider>
    </GridContainerWrapper>
  )
}

export default Payment
