import { AA } from 'components/SVG/add-on-icons/AA'
import { requestAddons } from 'containers/App/actions'
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useDispatch } from 'react-redux'
import { useAddons, useWindowSize } from '../../hooks'
import { AddonMoreInfo } from './AddonMoreInfo/AddonMoreInfo'
import {
  AddonButton,
  AddonButtonContainer,
  AddonContentContainer,
  AddonError,
  AddonLogoContainer,
  AddonPriceContainer,
  AddonSection,
  Content,
  Heading,
  Price,
  ProvidedByContainer,
  ProvidedByText,
  RemoveButton,
} from './Addons.styles'

interface AddonProps {
  type: string
  heading: string
  subheading?: string
  content: ReactElement
  logo: ReactElement
  price: string
  selected: boolean
  disabled: boolean
  providedByLogo?: ReactElement
  provider?: string
  error: boolean
}

const AddonContainer: React.FC<AddonProps> = ({
  type,
  heading,
  content,
  selected,
  disabled,
  logo,
  price,
  error,
}) => {
  const {
    isMobile,
    windowSize: { width },
  } = useWindowSize()
  const { selectedAddons } = useAddons()
  const [isSelected, setSelected] = useState(selected)
  const dispatch = useDispatch()

  const isLoading = useMemo(
    () => selected !== isSelected,
    [selected, isSelected],
  )

  const buildNewAddonSelections = (newSelection: boolean) => {
    const selections = selectedAddons.map((addon) => ({
      [addon.type]: addon.selected,
    }))
    const includedAddons = Object.assign({}, ...selections)

    switch (type) {
      case 'excess':
        includedAddons.ExcessReductionIncluded = newSelection
        break
      case 'breakdown':
        includedAddons.BreakdownIncluded = newSelection
        break
      case 'legalexpenses':
        includedAddons.LegalExpensesIncluded = newSelection
        break
      default:
        break
    }

    return includedAddons
  }

  const ProvidedBy = () => {
    if (type === 'breakdown') {
      return (
        <ProvidedByContainer>
          {width! > 480 ? (
            <ProvidedByText active={isSelected}>Provided By:</ProvidedByText>
          ) : null}
          <AA width={15} height={15} />
        </ProvidedByContainer>
      )
    }

    return <></>
  }

  useEffect(() => {
    if (isLoading) {
      const addonOptions = buildNewAddonSelections(isSelected)
      dispatch(requestAddons(addonOptions, type))
    }
  }, [isSelected])

  const handleClick = useCallback(() => {
    setSelected(!isSelected)
  }, [isSelected])

  const AddonButtonComponent = () => {
    if (isSelected) {
      return (
        <AddonButtonContainer>
          <RemoveButton
            id={`addon-remove-${type}`}
            onClick={handleClick}
            disabled={disabled}
          >
            Remove
          </RemoveButton>
          <AddonButton
            id={`addon-addbtn-${type}`}
            active={isSelected}
            disabled={disabled}
          >
            ADDED
          </AddonButton>
        </AddonButtonContainer>
      )
    }

    return (
      <AddonButton
        id={`addon-addbtn-${type}`}
        onClick={handleClick}
        disabled={disabled}
      >
        ADD
      </AddonButton>
    )
  }

  const PriceComponent = () => {
    const priceText = isSelected ? price : `+${price}`

    return (
      <Price id={`addon-price-${type}`} active={isSelected}>
        {priceText}
      </Price>
    )
  }

  return (
    <>
      {error && (
        <AddonError>Unable to update your selection, try again.</AddonError>
      )}
      <AddonSection
        id={`addon-container-${type}`}
        active={isSelected}
        error={error}
      >
        {!isMobile ? <AddonLogoContainer>{logo}</AddonLogoContainer> : null}
        <AddonContentContainer>
          <Heading>{heading}</Heading>
          <Content>{content}</Content>
          <AddonMoreInfo type={type} isPaymentPage={false} />
        </AddonContentContainer>
        <AddonPriceContainer>
          <ProvidedBy />
          <PriceComponent />
          <AddonButtonComponent />
        </AddonPriceContainer>
      </AddonSection>
    </>
  )
}

export default AddonContainer
