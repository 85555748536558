import React from 'react'
import styled from 'styled-components'
import appBannerDesktop from '../../images/Tempcover-App-Banner-Desktop.jpg'
import appBannerMobile from '../../images/Tempcover-App-Banner-Mobile.jpg'

const AppMarketingWrapper = styled.div`
  align-content: center;
  justify-content: center;
  text-align: center;
  margin-top: 24px;
`

const TempcoverAppMarketingImage = styled.div`
  height: 0;
  padding-bottom: 35%;
  background-repeat: no-repeat;
  background-size: cover;
  display: block;
  margin: 0 auto;
  border-radius: ${(props) => props.theme.formControlBorderRadius};
  background-image: url(${appBannerDesktop});
  @media (max-width: 1024px) {
    background-image: url(${appBannerMobile});
  }
`

const AppStoreLink = styled.a`
  display: inline-block;
  overflow: hidden;
  background: url(https://linkmaker.itunes.apple.com/en-us/badge-lrg.svg?releaseDate=2018-08-11&kind=iossoftware&bubble=ios_apps)
    no-repeat;
  width: 135px;
  height: 40px;
  vertical-align: middle;
  margin-left: 10px;

  @media (max-width: 330px) {
    margin-top: 10px;
    margin-left: 0;
  }
`

export const AppMarketingSection = () => {
  return (
    <AppMarketingWrapper>
      <TempcoverAppMarketingImage />
      <AppStoreLink
        id='appStoreDownload'
        href='https://apps.apple.com/gb/app/tempcover/id1339625620?mt=8'
      />
      <a
        id='googlePlayDownload'
        href='https://play.google.com/store/apps/details?id=com.tempcover.app.production&gl=GB&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'
      >
        <img
          alt='Get it on Google Play'
          src='https://play.google.com/intl/en_gb/badges/static/images/badges/en_badge_web_generic.png'
          style={{
            verticalAlign: 'middle',
            height: '60px',
          }}
        />
      </a>
    </AppMarketingWrapper>
  )
}
