import styled from 'styled-components';
import { P } from 'components/common/P';
import { H2 } from 'components/common/H2';

export const PaymentHeader = styled(H2)`
    margin: 0;
`

export const LegalText = styled(P)`
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: 620px;
    font-size:14px;
    font-family: ${props => props.theme.fontFamily};
    @media (max-width: 768px) {
        font-size:12px;
    }
`

export const BoldLegalText = styled(LegalText)`
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: 475px;
    font-weight: bold;
`

export const FreightSansText = styled(P)`
    font-size:14px;
    font-family: ${props => props.theme.fontFamily};
    @media (max-width: 768px) {
        font-size:12px;
    }
`

export const BrandonText = styled(P)`
    font-size:22px;
    font-family: ${props => props.theme.headerFontFamily};
    color: ${props => props.theme.primaryTextColour};
    @media (max-width: 768px) {
        font-size:14px;
    }
`

export const TotalPriceText = styled(BrandonText)`
    font-size:44px;
    @media (max-width: 768px) {
        font-size:26px;
    }
`

export const PriceWrapper = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 5px;
  align-items: baseline;
  justify-content: center;

  p {
    margin: 0;
    line-height: 1em;
  }
  
  svg {
    color: ${(props) => props.theme.primaryColour};
  }
`

export const BreakdownTotalPriceWrapper = styled(PriceWrapper)`
    border-bottom: 2px solid ${({theme}) => theme.primaryColour};
    padding-bottom: 8px;
    margin-bottom: 8px;
`

export const PriceBreakdownTableWrapper = styled.div`
    margin: 0 auto;
    width: 40%;
`;

export const PriceBreakdownDiscount = styled(LegalText)`
    color: green;
    display: inline;
`

export const AddonsWrapper = styled.div`
    margin-bottom: 16px;
`