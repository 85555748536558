import styled from 'styled-components'
import { H2 } from '../common/H2'

export const WhiteLabelHeader = styled(H2)`
  font-size: 26px;
  margin-top: 0.2em;
  color: ${(props) => props.theme.primaryColour};
  text-transform: uppercase;
`

export const HeaderContainer = styled.div`
  width: 80%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 70px;
  grid-column-gap: 10px;

  @media (max-width: 1024px) {
    width: 80%;
  }

  @media (max-width: 768px) {
    width: 100%;
    grid-template-columns: 0.75fr 70px;
  }

  @media (max-width: 500px) {
    width: 100%;
    grid-template-columns: 0.6fr 70px;
    display: inline-block;
  }

  @media (max-width: 375px) {
    display: flex;
  }
`

export const TeslaBrand = styled.div`
  &:after {
    content: ' ';
    clear: both;
  }

  @media (max-width: 768px) {
    float: left;
  }

  @media (max-width: 375px) {
    display: flex;
    align-items: center;
    width: 170px;
  }
`

export const HeaderLinkStyled = styled.a`
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  font-family: ${(props) => props.theme.labelFontFamily};
  text-decoration: none;
  padding: ${(props) => props.theme.headerLinkStyled.padding}
  &:hover{
    text-decoration: ${(props) =>
      props.theme.headerLinkStyled.hover.textDecoration}
    color: ${(props) => props.theme.headerLinkStyled.hover.color}
    background-color: ${(props) =>
      props.theme.headerLinkStyled.hover.backgroundColour}
  }

  @media(max-width: 768px) {
    font-size: 12px;
  }
`
export const HeaderLinkWrapper = styled.div`
  float: right;
  display: flex;
  align-items: center;
`
