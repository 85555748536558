import { UppercaseInputStyled } from '../../components/common/Input/UppercaseInput';
import styled from 'styled-components';
import { P } from 'components/common/P';

export const PreviousRiddenInput = styled(UppercaseInputStyled)`
  font-family: ${props => props.theme.regFont };
  color: #000;
  letter-spacing: 1px;
  font-size: 26px;
  line-height: 48px;
  text-align: center;
  border-radius: ${props => props.theme.formControlBorderRadius };
  border: 1px solid ${props => props.theme.primaryColour};
  box-sizing: border-box;
  margin: 0 0 0.5em 0;

  ::placeholder{
    text-transform: none;
    letter-spacing: 0;
     font-family: ${props => props.theme.fontFamily};
     font-size: 26px;
  }
`

export const ManualBuilderLink = styled(P)`
  color: ${props => props.theme.primaryColour};
  text-decoration: underline;
  cursor: pointer;
`;

export const PreviousRiddenWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
  cursor: default;
  width: 70%;
  background: #fff;
  color: ${(props) => props.theme.primaryTextColour};
  box-shadow: 0px 0px 15px 0px #707070;
  border-radius: 6px;
  z-index: 2;
`;