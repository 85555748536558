import React from 'react';
import Webcam from 'react-webcam';
import './camera.css';

export class Camera extends React.Component {
  render() {
    return (
      <Webcam
        screenshotFormat='image/jpeg'
        audio={false}
        ref={this.props.cameraRef}
        height={this.props.height}
        width={this.props.width}
        minScreenshotWidth={this.props.minWidth}
        minScreenshotHeight={this.props.minHeight}
        videoConstraints={this.props.videoConstraints}
        className='camera'
        onUserMedia={this.props.onUserMedia}
        onUserMediaError={this.props.onUserMediaError}
      />
    );
  }
}
