export const RESIDENCY_DURATION_TYPES = [
  {
    id: 1,
    description: 'Never',
    value: 'Never',
  },
  {
    id: 2,
    description: '1 to 9 years',
    value: 'OneToNineYears',
  },
  {
    id: 3,
    description: '10 years or more',
    value: 'TenYearsOrMore',
  },
];
