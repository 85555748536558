import React, { Component } from 'react';
import { Grid } from 'unsemantic';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  updateValidation,
  updateRiskData
} from '../RiskDataCollectionPage/actions';
import { Label } from '../../components/common/Label';
import UseOfVehicle from '../../components/UseOfVehicle';
import BodyType from '../../components/BodyType';
import Occupation from '../../containers/Occupation';
import { useOfVehicleOptions } from '../../components/UseOfVehicle/constants';

class VanDetails extends Component {
  handleSelect = event => {
    const index = event.nativeEvent.target.selectedIndex;
    this.props.actions.updateRiskData({
      [event.target.name]: {
        value: event.nativeEvent.target[index].value,
        description: event.nativeEvent.target[index].text
      }
    });
  };

  handleValidation = (field, message) => {
    this.props.actions.updateValidation({
      [`${field}Error`]: message
    });
  };
  render() {
    return (
      <>
        <Grid desktop='100' parent>
          <Label>Body type</Label>
          <BodyType
            onChange={this.handleSelect}
            value={this.props.riskData.BodyType.value}
            handleValidation={this.handleValidation}
            error={this.props.validation.fields.BodyTypeError}
          />
        </Grid>
        {!this.props.riskData.Impounded &&    //If NOT impounded, show the following elements
        <>
          <Grid desktop='100' parent>
              <Label>Use of vehicle</Label>
              <UseOfVehicle
                name='UseOfVehicle'
                placeholder={'I am using van for...'}
                onChange={this.handleSelect}
                value={this.props.riskData.UseOfVehicle.value}
                handleValidation={this.handleValidation}
                options={useOfVehicleOptions}
                error={this.props.validation.fields.UseOfVehicleError}
              />
            </Grid>
            <Grid desktop='100' parent>
              <Occupation />
            </Grid>
          </>}
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    validation: state.validation.riskDataCollectionPage,
    riskData: state.riskData
  };
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        updateRiskData,
        updateValidation
      },
      dispatch
    )
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VanDetails);
