import { useFormikContext } from 'formik';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { checkMarketingOptInStateAndForward } from '../../../../../containers/MarketingPreferencesContainer/actions';
import {
  isButtonDisabled,
  updateRiskData,
} from '../../../../../containers/RiskDataCollectionPage/actions';
import { InitialStateType } from '../../../../../initialState';
import ContinueButton from '../../../../ContinueButton';
import { BaseDriverFormData } from '../../DriverDetails.types';

interface Props {
  onSubmit?: () => void;
}

const MarketingPreferencesButton: React.FC<Props> = ({ onSubmit = () => {} }) => {
  const dispatch = useDispatch();

  const isMarketingPreferencesDisabled = useSelector(
    (state: InitialStateType) => state.formButtons.marketingPreferences.disabled,
  );

  const {
    validateForm, values, isValid, touched, initialTouched, setSubmitting, setFieldError,
  } = useFormikContext<BaseDriverFormData>();

  // TODO: Refactor this to not use redux/ sagas
  const handleCheckMarketingOptIn = () => {
    const { emailAddress, mobile } = values;
    dispatch(
      isButtonDisabled({ buttonName: 'marketingPreferences', disabled: true }),
    );
    dispatch(
      updateRiskData({
        EmailAddress: emailAddress,
        Mobile: mobile,
      }),
    );
    dispatch(checkMarketingOptInStateAndForward());
    setSubmitting(false);
    onSubmit();
  };

  const handleSubmit = async () => {
    const touchedState = { ...initialTouched, ...touched };
    await validateForm();
    setSubmitting(true);
    // @ts-ignore
    if (touchedState.residencyType) {
      // @ts-ignore
      touchedState.ukResidencyDuration = true;
    }


    if (isValid && !Object.values(touchedState).includes(false)) {
      return handleCheckMarketingOptIn();
    }

    return null;
  };

  return (
    <ContinueButton
      id="LoadMarketingPrefs"
      type="button"
      fullWidth
      disabled={isMarketingPreferencesDisabled}
      loading={isMarketingPreferencesDisabled}
      onClick={handleSubmit}
    >
      Continue
    </ContinueButton>
  );
};

export default MarketingPreferencesButton;
