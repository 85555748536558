import FormField from 'components/@common/FormField';
import Select from 'components/@common/Select';
import React from 'react';
import { SelectType } from '../types';
import { LICENCE_TYPES } from './LicenceType.constants';

interface Props extends SelectType {}

const LicenceType: React.FC<Props> = ({ onSelect }) => (
  <FormField
    name="licenceType"
    label="Licence type"
    isValidIcon={false}
  >
    <Select
      id="LicenceType"
      onChange={onSelect}
      name="licenceType"
      options={LICENCE_TYPES}
    />
  </FormField>
);

export default LicenceType;
