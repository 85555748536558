import styled from 'styled-components';
import { H2 } from '../../components/common/H2';
import { Button } from '../../components/common/Button';
import { LicenceHeader } from '../../pages/DrivingLicence/DrivingLicence.styles';

export const LicenceCameraWrapper = styled.div`
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
`;

export const ZoomingLicenceGuide = styled.div`
  height: 30vh;
  width: 100vw;
  position: relative;
  top: 28vh;
  right: 15vw;
  background-color: #fcd0e4;
  -webkit-animation: zoomLicence 6s;
  animation: zoomLicence 6s;
  -webkit-transform: scale(0.25, 0.25);
  transform: scale(0.25, 0.25);
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  border-radius: 30px;
  @media (orientation: landscape) {
    display: none;
  }
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    top: 20vh;
    height: 50vh;
  }
  div.uk-icon {
    background-color: ${(props) => props.theme.drivingLicence.primaryColour};
    width: 12%;
    height: 10%;
    margin-left: 5%;
    top: -6%;
    color: #fff;
    position: absolute;
    padding: 5px;
  }

  .fa-user-alt {
    float: left;
    font-size: 80px;
    position: absolute;
    top: 77px;
    left: 12px;
  }

  div {
    margin-top: 25px;
  }

  span.driving-licence-header {
    font-family: 'Times New Roman', Times, serif;
    color: ${(props) => props.theme.drivingLicence.primaryColour};
    font-size: 16px;
    font-weight: bold;
    position: relative;
    left: -30px;
  }

  span.licence-text {
    position: relative;
    top: -20px;
    color: black;
    font-size: 10px;
    font-weight: bold;
    margin-left: 30%;
    display: block;
    width: 100%;
    text-align: left;
    line-height: 12px;
    -webkit-animation: hideText 6s;
    animation: hideText 6s;

    @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
      line-height: 25px;
      top: 0;
    }
  }

  span.licence-text-top-margin-full {
    margin-top: 1.5%;
    @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
      margin-top: 20px;
    }
  }

  span.licence-text-top-margin-half {
    margin-top: 10px;
  }

  @-webkit-keyframes zoomLicence {
    60% {
      opacity: 0.4;
      -webkit-transform: scale(1.5, 1.5);
    }
    100% {
      opacity: 0;
      -webkit-transform: scale(1.5, 1.5);
    }
  }

  @keyframes zoomLicence {
    60% {
      opacity: 0.4;
      -webkit-transform: scale(1.5, 1.5);
    }
    100% {
      opacity: 0;
      -webkit-transform: scale(1.5, 1.5);
    }
  }
`;

export const LicenceMask = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  /* border: solid rgba(0, 0, 0, 0.2); */
  height: 100%;
  width: 100%;
  /* background: url(./transparency.png) 50% / cover border-box padding-box; */
  box-sizing: border-box;

  ::before {
    position: absolute;
    z-index: -1;
    top: -190px;
    bottom: -190px;
    left: -30px;
    right: -30px;
    border: inherit;
    /* border-color: transparent; */
    background: inherit;
    background-clip: border-box;
    content: '';
    /* -webkit-filter: blur(125px);
    filter: blur(125px); */
  }

  @media (max-width: 767px) and (orientation: portrait) {
    border-top-width: 25vh;
    border-bottom-width: 25vh;
    border-left-width: 0;
    border-right-width: 0;
  }
  @media (max-width: 850px) and (orientation: landscape) {
    border-top-width: 18vh;
    border-bottom-width: 18vh;
    border-left-width: 20vw;
    border-right-width: 20vw;
  }

  @media (min-width: 767px) and (max-width: 1024px) and (orientation: portrait) {
    border-top-width: 100px;
    border-bottom-width: 100px;
    border-left-width: 0;
    border-right-width: 0;
  }

  @media (min-width: 850px) and (max-width: 1024px) and (orientation: landscape) {
    border-top-width: 170px;
    border-bottom-width: 170px;
    border-left-width: 220px;
    border-right-width: 220px;
  }
`;

export const LicenceMaskBorder = styled.div`
  /* background: -moz-linear-gradient(45deg, #e9f0ff 0%, #bad1ff 0%, #fcd0e4 51%, #bad1ff 100%);
  background: -webkit-linear-gradient(45deg, #e9f0ff 0%,#bad1ff 0%,#fcd0e4 51%,#bad1ff 100%);
  background: linear-gradient(45deg, #e9f0ff 0%,#bad1ff 0%,#fcd0e4 51%,#bad1ff 100%);
  opacity:0.5; */
  @media (orientation: landscape) {
    display: none;
  }
`;

export const LicenceMaskTop = styled(LicenceMaskBorder)`
  position: relative;
  width: 100%;
  height: 15%;
  z-index: 2;
  text-align: center;

  @media (min-width: 767px) and (max-width: 1024px) and (orientation: portrait) {
    height: 10%;
  }
`;

export const LicenceMaskBlueIcon = styled.div`
  position: relative;
  top: 5px;
  left: -15px;
  width: 10vw;
  height: 94%;
  z-index: 3;
  background: darkblue;
`;

export const LicenceCameraHeader = styled(H2)`
  position: absolute;
  left: 0;
  right: 0;
  top: 2%;
  color: #fff;
  z-index: 1;
  padding-left: 4px;
  padding-right: 4px;

  @media (max-width: 767px) and (orientation: landscape) {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;

    color: #fff;
    z-index: 1;
  }
  @media (min-width: 767px) and (max-width: 1024px) and (orientation: portrait) {
    top: 0;
  }
`;

export const LicenceCameraButton = styled(Button)`
  position: absolute;
  bottom: 5%;
  z-index: 2;
  width: 40%;
  border: 3px solid ${(props) => props.theme.buttonTextColour};
  transform: translateX(-50%);

  @media (max-width: 850px) and (orientation: landscape) {
    position: absolute;
    bottom: 5%;
  }
`;

export const DrivingLicenceCameraHeader = styled(LicenceHeader)`
  margin-top: 1em;
  font-size: 20px;
  text-align: center;
  position: absolute;
  top: 0;
  left: 30%;
  margin-bottom: 0;

  @media (min-width: 767px) and (max-width: 1024px) and (orientation: portrait) {
    font-size: 32px;
  }

  @media screen and (min-width: 320px) and (max-width: 812px) and (orientation: landscape) {
    html {
      transform: rotate(-90deg);
      transform-origin: left top;
      width: 100vh;
      overflow-x: hidden;
      position: absolute;
      top: 100%;
      left: 0;
    }
  }
`;
