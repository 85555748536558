import Button from 'components/@common/Button';
import { P } from 'components/common/P';
import { ErrorIcon } from 'components/Message/ErrorIcon';
import styled from 'styled-components';

export const SchemeSwitchWrapper = styled.div`
    border: 1px solid ${({ theme }) => theme.secondaryColour};
    border-radius: ${({ theme }) => theme.formControlBorderRadius};
    padding-top: 8px;
    padding-bottom: 8px;
`;

export const SchemeSwitchButton = styled(Button)`
  background-color: ${({ theme }) => theme.secondaryColour};
`

export const SchemeSwitchText = styled(P)``

export const SchemeSwitchIcon = styled(ErrorIcon)`
    color:  ${({ theme }) => theme.secondaryColour};
    border: 2px solid  ${({ theme }) => theme.secondaryColour};
    margin-right: 8px;
    align-self: center;
`

export const SchemeSwitchInformationWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 8px;
    padding-left: 8px;
    padding-right: 8px;
`