import styled from 'styled-components';
import { Button } from '../../components/common/Button';

export const MoreDetailsHelperImage = styled.img`
    width: 50%;
    height: 30%;
    margin: 1em 0;
    @media (max-width: 767px) 
    and (orientation: portrait) {
        width: 70%;
    height: 50%;
    }
    @media (max-width: 850px) and (orientation: landscape) {
        width: 40%;
    height: 30%;
    }
  
`

export const MoreDetailsButton = styled(Button)`
width: 100%;
`