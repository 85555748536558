import styled from 'styled-components';

export interface Props {
  isValueInOptions: boolean;
}

export const SelectStyled = styled.select<Props>`
  border: 1px solid ${({ theme }) => theme.inputBorderColour};
  border-radius: ${({ theme }) => theme.formControlBorderRadius};
  padding: 0 ${({ theme }) => theme.formControlHorizontalPadding};
  margin-bottom: ${({ theme }) => theme.formControlBottomMargin};
  color: ${({ isValueInOptions, theme }) => (isValueInOptions ? theme.primaryTextColour : '#777')};
  font-size: ${(props) => props.theme.generalFontSize};
  height: ${(props) => props.theme.formControlHeight};
  width: 100%;
  font-family: ${(props) => props.theme.fontFamily};
  font-style: ${(props) => props.theme.fontStyle};
  font-weight: ${(props) => props.theme.fontWeight};
  background: #FFF;

  &.placeholder,
  option[value=""] {
    color: #777;
  }

  option:not([value=""]) {
    color: ${(props) => props.theme.primaryTextColour};
  }
`;
