import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';

export const ExcessCover = ({ width = null, height = null, scale = 1 }) => {
    const opts = {};
    const { svg } = useContext(ThemeContext);

    if (width) {
        opts.width = width;
    }

    if (height) {
        opts.height = height;
    }

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="105.89" height="105.89" viewBox="0 0 105.89 105.89" style={{ transform: `scale(${scale})` }}>
            <g id="Group_1063" data-name="Group 1063" transform="translate(-685 -1452.077)">
                <path id="Path_537" data-name="Path 537" d="M107.973,226.311a12.973,12.973,0,0,1-10.191-21.005A12.974,12.974,0,1,0,116,223.525a12.917,12.917,0,0,1-8.028,2.786" transform="translate(614.833 1293.851)" fill={svg.accentColour} />
                <rect id="Rectangle_800" data-name="Rectangle 800" width="4.326" height="4.326" transform="translate(737.945 1517.999)" fill={svg.accentColour} />
                <rect id="Rectangle_801" data-name="Rectangle 801" width="25.954" height="2.163" transform="translate(737.945 1520.162)" fill={svg.accentColour} />
                <rect id="Rectangle_802" data-name="Rectangle 802" width="4.326" height="4.325" transform="translate(742.27 1513.673)" fill={svg.accentColour} />
                <rect id="Rectangle_803" data-name="Rectangle 803" width="25.954" height="2.162" transform="translate(742.271 1515.836)" fill={svg.accentColour} />
                <rect id="Rectangle_804" data-name="Rectangle 804" width="4.326" height="4.326" transform="translate(737.945 1509.348)" fill={svg.accentColour} />
                <rect id="Rectangle_805" data-name="Rectangle 805" width="25.954" height="2.163" transform="translate(737.945 1511.511)" fill={svg.accentColour} />
                <rect id="Rectangle_806" data-name="Rectangle 806" width="4.326" height="4.326" transform="translate(746.596 1505.022)" fill={svg.accentColour} />
                <rect id="Rectangle_807" data-name="Rectangle 807" width="25.954" height="2.163" transform="translate(746.596 1507.185)" fill={svg.accentColour} />
                <rect id="Rectangle_808" data-name="Rectangle 808" width="4.326" height="4.325" transform="translate(740.107 1500.697)" fill={svg.accentColour} />
                <rect id="Rectangle_809" data-name="Rectangle 809" width="25.954" height="2.163" transform="translate(740.107 1502.859)" fill={svg.accentColour} />
                <rect id="Rectangle_810" data-name="Rectangle 810" width="4.326" height="4.326" transform="translate(735.782 1496.371)" fill={svg.accentColour} />
                <rect id="Rectangle_811" data-name="Rectangle 811" width="25.954" height="2.163" transform="translate(735.782 1498.534)" fill={svg.accentColour} />
                <rect id="Rectangle_812" data-name="Rectangle 812" width="4.326" height="4.326" transform="translate(740.107 1492.045)" fill={svg.accentColour} />
                <rect id="Rectangle_813" data-name="Rectangle 813" width="25.954" height="2.163" transform="translate(740.107 1494.208)" fill={svg.accentColour} />
                <rect id="Rectangle_814" data-name="Rectangle 814" width="4.326" height="4.326" transform="translate(735.782 1487.72)" fill={svg.accentColour} />
                <rect id="Rectangle_815" data-name="Rectangle 815" width="25.954" height="2.163" transform="translate(735.782 1489.883)" fill={svg.accentColour} />
                <g id="Group_554" data-name="Group 554" transform="translate(685 1452.077)">
                    <path id="Path_538" data-name="Path 538" d="M52.945,118.371A52.945,52.945,0,0,1,15.507,27.988a52.945,52.945,0,1,1,74.875,74.875,52.6,52.6,0,0,1-37.438,15.507m0-103.815a50.87,50.87,0,1,0,50.87,50.87,50.927,50.927,0,0,0-50.87-50.87" transform="translate(0 -12.481)" fill={svg.primaryColour} />
                    <path id="Path_539" data-name="Path 539" d="M239.589,284.63H213.636a1.038,1.038,0,0,1-1.038-1.038v-4.326a1.038,1.038,0,0,1,1.038-1.038h25.954a1.038,1.038,0,0,1,1.038,1.038v4.326a1.038,1.038,0,0,1-1.038,1.038m-24.916-2.075h23.878V280.3H214.673Z" transform="translate(-160.691 -213.345)" fill={svg.primaryColour} />
                    <path id="Path_540" data-name="Path 540" d="M257.306,266.914H231.353a1.038,1.038,0,0,1-1.038-1.038v-4.326a1.038,1.038,0,0,1,1.038-1.038h25.954a1.038,1.038,0,0,1,1.038,1.038v4.326a1.038,1.038,0,0,1-1.038,1.038m-24.916-2.075h23.878v-2.25H232.39Z" transform="translate(-174.082 -199.954)" fill={svg.primaryColour} />
                    <path id="Path_541" data-name="Path 541" d="M239.589,249.2H213.636a1.038,1.038,0,0,1-1.038-1.038v-4.326a1.038,1.038,0,0,1,1.038-1.038h25.954a1.038,1.038,0,0,1,1.038,1.038v4.326a1.038,1.038,0,0,1-1.038,1.038m-24.916-2.075h23.878v-2.25H214.673Z" transform="translate(-160.691 -186.563)" fill={svg.primaryColour} />
                    <path id="Path_542" data-name="Path 542" d="M275.023,231.48H249.069a1.038,1.038,0,0,1-1.038-1.038v-4.326a1.038,1.038,0,0,1,1.038-1.038h25.954a1.038,1.038,0,0,1,1.038,1.038v4.326a1.038,1.038,0,0,1-1.038,1.038M250.106,229.4h23.878v-2.25H250.106Z" transform="translate(-187.473 -173.172)" fill={svg.primaryColour} />
                    <path id="Path_543" data-name="Path 543" d="M248.448,213.765H222.495a1.038,1.038,0,0,1-1.038-1.038V208.4a1.038,1.038,0,0,1,1.038-1.038h25.954a1.038,1.038,0,0,1,1.038,1.038v4.325a1.038,1.038,0,0,1-1.038,1.038m-24.916-2.075h23.878v-2.25H223.532Z" transform="translate(-167.387 -159.782)" fill={svg.primaryColour} />
                    <path id="Path_544" data-name="Path 544" d="M230.732,196.048H204.778a1.038,1.038,0,0,1-1.038-1.038v-4.326a1.038,1.038,0,0,1,1.038-1.038h25.954a1.038,1.038,0,0,1,1.038,1.038v4.326a1.038,1.038,0,0,1-1.038,1.038m-24.916-2.075h23.878v-2.25H205.815Z" transform="translate(-153.996 -146.391)" fill={svg.primaryColour} />
                    <path id="Path_545" data-name="Path 545" d="M248.448,178.331H222.495a1.038,1.038,0,0,1-1.038-1.038v-4.326a1.038,1.038,0,0,1,1.038-1.038h25.954a1.038,1.038,0,0,1,1.038,1.038v4.326a1.038,1.038,0,0,1-1.038,1.038m-24.916-2.075h23.878v-2.25H223.532Z" transform="translate(-167.387 -132.999)" fill={svg.primaryColour} />
                    <path id="Path_546" data-name="Path 546" d="M230.732,160.614H204.778a1.038,1.038,0,0,1-1.038-1.038v-4.326a1.038,1.038,0,0,1,1.038-1.038h25.954a1.038,1.038,0,0,1,1.038,1.038v4.326a1.038,1.038,0,0,1-1.038,1.038m-24.916-2.075h23.878v-2.25H205.815Z" transform="translate(-153.996 -119.608)" fill={svg.primaryColour} />
                    <path id="Path_547" data-name="Path 547" d="M141.113,298.021H71.9a1.038,1.038,0,1,1,0-2.075h69.21a1.038,1.038,0,1,1,0,2.075" transform="translate(-53.564 -226.736)" fill={svg.primaryColour} />
                    <path id="Path_548" data-name="Path 548" d="M102.6,217.676a14.014,14.014,0,1,1,14.014-14.015A14.03,14.03,0,0,1,102.6,217.676m0-25.954a11.939,11.939,0,1,0,11.939,11.939A11.953,11.953,0,0,0,102.6,191.722" transform="translate(-66.954 -146.391)" fill={svg.primaryColour} />
                    <path id="Path_549" data-name="Path 549" d="M136.074,235.806a1.038,1.038,0,0,1-1.038-1.038v-6.146l-.391.391a1.038,1.038,0,0,1-1.468-1.467l2.163-2.163a1.038,1.038,0,0,1,1.771.733v8.651a1.038,1.038,0,0,1-1.038,1.038" transform="translate(-100.432 -173.172)" fill={svg.primaryColour} />
                    <path id="Path_550" data-name="Path 550" d="M138.237,262.588h-4.326a1.038,1.038,0,1,1,0-2.075h4.326a1.038,1.038,0,1,1,0,2.075" transform="translate(-100.432 -199.954)" fill={svg.primaryColour} />
                </g>
            </g>
        </svg>


    )
}



