import {
  IS_BUTTON_DISABLED,
  ENABLE_ALL_BUTTONS
} from './constants';
import initialState from '../../initialState';


function formButtonsReducer(state = initialState.formButtons, action) {
  switch (action.type) {
    case IS_BUTTON_DISABLED:
      return {
        ...state,
        [action.payload.buttonName]: {
          disabled: action.payload.disabled
        }
      }
    case ENABLE_ALL_BUTTONS:
      return {
        ...state,
        driverDetails: {
          disabled: false
        },
        driverLicence: {
          disabled: false
        },
        legalDeclaration: {
          disabled: false
        },
        vanInfo: {
          disabled: false
        },
        paymentButton: {
          disabled: false
        },
        addressSearch: {
          disabled: false
        },
        moreDetails: {
          disabled: false
        },
        marketingPreferences: {
          disabled: false
        }
      }
    default:
      return state;
  }
}

export default formButtonsReducer;
