import {
  UPDATE_POLICY,
  REQUEST_POLICY,
  UPDATE_REASON_FOR_TEMPCOVER,
  INIT_POLICY_CONFIRMATION,
  UPDATE_ANALYSIS_DATA
} from './constants';

import queryString from 'query-string';

import { UPDATE_VALIDATION_POLICY_CONFIRMATION } from './constants';

import {
  toggleFormIsValid,
  requestPolicySuccess,
  requestPolicyFailure,
  updatePolicySuccess,
  updatePolicyFailure,
  enablePolicyHasEmailAddress,
  updateReasonForTempcoverSuccess,
  updateReasonForTempcoverFailure,
  getDocumentsSuccess,
  getAnalysisDataSuccess,
  updateAnalysisDataSuccess,
  updateAnalysisDataFailure
} from './actions';

import { isLoading, setQueryString } from '../App/actions';

import { call, put, takeLatest, select } from 'redux-saga/effects';

import policyApi from '../../api/policyApi';

import {
  updatePolicyLinkSelector,
  getPolicy,
  getValidation,
  getRiskData,
  getReasonforTempcoverLinkSelector,
  getPrice,
  getAddress,
  getAffiliate
} from './selector';
import fetchAntiForgeryTokenSaga from '../App/sagas/antiForgerySaga';
import fetchLinksSaga from '../App/sagas/linksSaga';
import moment from 'moment';
import { getVehicle } from '../RiskDataCollectionPage/selector';
import getAffiliateSaga from '../App/sagas/affiliateSaga';
import { fetchQuoteSummary, fetchQuote } from '../App/sagas/quoteSaga';
import { getDurationData } from '../App/selector';
import featureTogglesSaga from '../App/sagas/featureTogglesSaga';
import getOpenSchemeSaga from '../App/sagas/schemeSaga';

export function* initPolicyConfirmationPage() {
  try {
    yield put(isLoading(true));
    yield* fetchAntiForgeryTokenSaga();
    yield* fetchLinksSaga();
    yield* featureTogglesSaga();
    const parsedQueryString = queryString.parse(window.location.search);
    yield put(setQueryString('?q=' + parsedQueryString.q + (parsedQueryString.ref ? '&ref=' + parsedQueryString.ref : '')));
    if (parsedQueryString.ref) {
      yield* getAffiliateSaga(parsedQueryString.ref);
    }

    let policyId;
    if (parsedQueryString.q) {
      policyId = parsedQueryString.q
      yield* getOpenSchemeSaga();
      yield* fetchQuoteSummary(policyId);
      yield* fetchQuote();
      yield* requestPolicy(policyId);
      yield* updateAnalysisData(policyId);
      yield* getDocuments(policyId);
      yield put(isLoading(false));
      return;
    }
    yield put(isLoading(false));
  } catch (e) {
  }
}

function* requestPolicy(policyId) {
  try {
    const getPolicyLink = '/api/motorQuotePolicies/{encryptedPolicyId}/summary'.replace(/{[encryptedPolicyId}]*}/,
      policyId)
    const policy = yield call(policyApi.getPolicy, getPolicyLink);
    yield put(requestPolicySuccess(policy.data, policyId));
    if (!policy.data.SentToAnalytics) {
      yield put(getAnalysisDataSuccess(policy.data.DataLayer));
    }
  } catch (e) {
    yield put(requestPolicyFailure(e.message));
  }
}

function* getDocuments(policyId) {
  try {
    const getDocumentsLink = '/api/motorQuotePolicies/{encryptedPolicyId}/documents'.replace(/{[encryptedPolicyId}]*}/,
      policyId)
    const policyDocuments = yield call(policyApi.getDocuments, getDocumentsLink);
    const affiliate = yield select(getAffiliate);
    const newDocuments = policyDocuments.data;

    yield put(getDocumentsSuccess(newDocuments));
  }
  catch (e) {
    console.log('error');
  }
}

function* updateAnalysisData(policyId) {

  try {
    const policy = yield select(getPolicy);
    const parsedQueryString = queryString.parse(window.location.search);
    if (policy.analysisSent || !parsedQueryString.t || (Math.floor(Date.now() - parsedQueryString.t) / 1000) > 60) return;
    const vehicle = yield select(getVehicle);
    const riskData = yield select(getRiskData);
    const duration = yield select(getDurationData);
    const price = yield select(getPrice);
    const address = yield select(getAddress);
    const affiliate = yield select(getAffiliate);

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'uniquePurchase',
      'productType': vehicle.scheme,
      'policyNumber': policy.PolicyId,
      'premium': price.TotalPrice,
      'age': moment().diff(riskData.DateOfBirth, 'years'),
      'title': riskData.Title,
      'policyDuration': duration.value + " " + duration.type,
      'vehicleOwner': 'Not required',
      'licenceHeldFor': riskData.LicenceHeldDuration.description,
      'postcode': address.Postcode,
      'campaignRef': price.campaign.Ref
    });
    window.dataLayer.push({
      'event': 'productPurchaseDataLayerPushEvent',
      ecommerce: {
        purchase: {
          actionField: {
            id: policy.PolicyId,
            affiliation: affiliate,
            revenue: parseFloat(price.commission),
            tax: parseFloat(price.Ipt),
            shipping: 0,
            coupon: '',
            list: "Quoting Underwriters List"
          },
          products: [
            {
              id: policy.PolicyId,
              name: vehicle.scheme,
              price: parseFloat(price.TotalPrice),
              quantity: 1,
              category: 'Temporary/Motor/' + vehicle.scheme,
              variant: 'Uninitialised',
              brand: price.Underwriter
            }
          ]
        }
      }
    })
    yield put(updateAnalysisDataSuccess());
  } catch (e) {
    yield put(updateAnalysisDataFailure());
  }
  finally {
    const updateAnalysisLink = '/api/motorQuotePolicies/{encryptedPolicyId}/analysis'.replace(/{[encryptedPolicyId}]*}/,
      policyId)
    yield call(policyApi.updateAnalysis, updateAnalysisLink);
  }
}

function* updatePolicy() {
  try {
    const updatePolicyEndpoint = yield select(updatePolicyLinkSelector);
    const policy = yield select(getPolicy);
    yield call(policyApi.updatePolicy, updatePolicyEndpoint, policy);
    if (policy.ContactDetails.EmailAddress.length > 0) {
      yield put(enablePolicyHasEmailAddress());
    }
    yield put(updatePolicySuccess());
  } catch (e) {
    yield put(updatePolicyFailure(e.message));
  }
}

function* formValidation() {
  const validation = yield select(getValidation);
  const fields = validation.fields;
  const validForm = Object.keys(fields).every(key => {
    return fields[key] === '';
  });
  yield put(
    toggleFormIsValid({ area: 'policyConfirmationPage', valid: validForm })
  );
}

function* updateReasonForTempcover({ reasonForTempcover }) {
  try {
    const riskData = yield select(getRiskData);
    const updateReasonForTempcoverLink = yield select(getReasonforTempcoverLinkSelector);
    const data = {
      policyId: {
        Value: riskData.QuoteId
      },
      reasonForTempcover: reasonForTempcover.value
    };
    yield call(
      policyApi.updateReasonForTempcover,
      updateReasonForTempcoverLink.Href,
      data
    );
    yield put(updateReasonForTempcoverSuccess());
  } catch (e) {
    yield put(updateReasonForTempcoverFailure(e));
  }
}

export function* policyConfirmationSaga() {
  yield takeLatest(INIT_POLICY_CONFIRMATION, initPolicyConfirmationPage)
  yield takeLatest(REQUEST_POLICY, requestPolicy);
  yield takeLatest(UPDATE_VALIDATION_POLICY_CONFIRMATION, formValidation);
  yield takeLatest(UPDATE_POLICY, updatePolicy);
  yield takeLatest(UPDATE_REASON_FOR_TEMPCOVER, updateReasonForTempcover);
  yield takeLatest(UPDATE_ANALYSIS_DATA, updateAnalysisData)
}
