export const bodyTypeOptions = [{
    id: 1,
    value: 'Van',
    description: 'Van',
}, {
    id: 2,
    value: 'PickupSingleCab',
    description: 'Pickup - Single Cab'
}, {
    id: 3,
    value: 'PickupDoubleCab',
    description: 'Pickup - Double Cab'
}, {
    id: 4,
    value: 'BoxVan',
    description: 'Box Van'
}, {
    id: 5,
    value: 'LutonVan',
    description: 'Luton Van'
}, {
    id: 6,
    value: 'RefrigeratedInsulated',
    description: 'Refrigerated and insulated'
}, {
    id: 7,
    value: 'Minibus',
    description: 'Minibus'
}, {
    id: 8,
    value: 'IceCreamVan',
    description: 'Ice cream van'
}, {
    id: 9,
    value: 'MotorCaravan',
    description: 'Motor caravan'
}, {
    id: 10,
    value: 'Taxi',
    description: 'Taxi'
}, {
    id: 11,
    value:'DustCart',
    description: 'Dust cart'
}, {
    id: 12,
    value: 'Horsebox',
    description: 'Horsebox'
}, {
    id: 13,
    value: 'LivestockCarrier',
    description: 'Livestock carrier'
}, {
    id: 14,
    value: 'MilkFloat',
    description: 'Milk float'
}, {
    id: 15,
    value: 'MobileShop',
    description: 'Mobile shop'
}, {
    id: 16,
    value: 'RefuseVehicle',
    description: 'Refuse vehicle'
}, {
    id: 17,
    value: 'RoadSweeper',
    description: 'Road sweeper'
}, {
    id: 18,
    value: 'SkipCarrier',
    description: 'Skip carrier'
}, {
    id: 19,
    value: 'VehicleTransporter',
    description: 'Vehicle transporter'
}, {
    id: 20,
    value: 'Tipper',
    description: 'Tipper'
}]
