import styled from 'styled-components';

export const ProgressionOption = styled.div `
  border: 1px solid ${props => props.theme.primaryColour};
  border-radius: 12px;
  cursor: pointer;
  user-select: none;
  transition: 0.5s ease-out;
  padding: 10px 0;
  & i {
    font-size: ${props => props.theme.progressionStep.subContentIconDesktopSize};
  }
  &:hover {
    background: #f2f2f2;
  }
  @media (max-width: 768px) {
    & i {
      font-size: ${props => props.theme.progressionStep.subContentIconSize};
    }
    & h2 {
      font-size: 20px;
      font-weight: 700;
      text-decoration: none;
      
    }
    & h4{
      font-size: 20px;
      font-weight: 600;
    }
  }
`;