import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import pluralize from 'pluralize';
import Vehicle from '../Vehicle';
import { history } from '../..';
import { isLoading } from '../App/actions';
import { initInvalidRegPage } from './actions';
import LoadingScreen from '../../components/LoadingScreen';
import { Message } from '../../components/Message';
import { setVehicleListener, createUnkownVehicle } from '../Vehicle/actions';
import GridContainerWrapper from 'components/@common/GridContainerWrapper';

export class InvalidRegPage extends React.Component {
  constructor(props) {
    super(props);
    this.handleConfirm = this.handleConfirm.bind(this);
  }

  componentDidMount = () => {
    if (!window.isHoldingPageOn) {
      this.props.actions.initInvalidRegPage();
    }
  }

  handleConfirm() {
    let url = `/?registration=${this.props.vehicle.searched.RegistrationNumber}`;
    if (this.props.vehicle.searched.VehicleFound) {
      this.props.actions.setVehicleListener(this.props.vehicle.searched)

      if (this.props.riskData.Learner) {
        if (this.props.duration.value < 7) {
          this.props.duration.value = 7;
          this.props.duration.type = 'days'
        }

        url = url.concat(`&learner=true`)
      }
      if (this.props.riskData.Courier) {
        this.props.vehicle.Courier= true;
        this.props.actions.setVehicleListener(this.props.vehicle)
        url = url.concat(`&courier=true`);
      }
    }
    else {
      this.props.actions.createUnkownVehicle();
      if (this.props.vehicle.searched.Make !== null) url = url.concat(`&make=${this.props.vehicle.searched.Make}`);
      if (this.props.vehicle.searched.Model !== null) url = url.concat(`&model=${this.props.vehicle.searched.Model}`);
      if (this.props.vehicle.searched.Abi !== null) url = url.concat(`&variant=${this.props.vehicle.searched.Abi}`);
      if (this.props.vehicle.searched.GrossWeight !== null) url = url.concat(`&weight=${this.props.vehicle.searched.GrossWeight}`);
      if (this.props.vehicle.searched.YearOfManufacture !== null) url = url.concat(`&year=${this.props.vehicle.searched.YearOfManufacture}`);
      if (this.props.riskData.Impounded) {
        this.props.vehicle.Impounded = true;
        this.props.actions.setVehicleListener(this.props.vehicle)
        url = url.concat(`&impounded=true`);
      }
      if (this.props.riskData.Expat) {
        this.props.vehicle.Expat = true;
        this.props.actions.setVehicleListener(this.props.vehicle)
        url = url.concat(`&expat=true`);
      }
      if (this.props.riskData.Courier) {
        this.props.vehicle.Courier= true;
        this.props.actions.setVehicleListener(this.props.vehicle)
        url = url.concat(`&courier=true`);
      }
      if (this.props.riskData.Learner) {
        this.props.actions.setVehicleListener(this.props.vehicle.searched)

        if (this.props.duration.value < 7) {
          this.props.duration.value = 7;
          this.props.duration.type = 'days'
        }

        url = url.concat(`&learner=true`)
      }
    }

    const durationType = parseInt(this.props.duration.value) === 1 ? pluralize.singular(this.props.duration.type) : this.props.duration.type;
    this.props.actions.isLoading(true);
    history.push(url + `&duration=${this.props.duration.value}+${durationType}`)
  }
  render() {
    return (
      <>
        {this.props.loading ? (
          <LoadingScreen />
        ) : (
          <GridContainerWrapper>
            {!this.props.vehicle.searched.VehicleFound && (
              <Message heading="Sorry!" content="There was an issue with your registration number. Please check below and try again." type="info" />
            )}
            <Vehicle handleConfirm={this.handleConfirm} />
          </GridContainerWrapper>
        )}
      </>
    );
  }
}

export const mapStateToProps = state => {
  return {
    riskData: state.riskData,
    duration: state.duration,
    vehicle: state.vehicle,
    loading: state.isLoading,
  }
}
export const mapDispatchtoProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        isLoading,
        initInvalidRegPage,
        setVehicleListener,
        createUnkownVehicle
      },
      dispatch
    )
  };
};


export default connect(
  mapStateToProps,
  mapDispatchtoProps
)(InvalidRegPage);
