import { useState, useEffect, useCallback } from 'react';

interface Size {
    width: number | undefined;
    height: number | undefined;
  }

const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState<Size>({
    width: undefined,
    height: undefined,
  });

  const MOBILE_SIZE = 768;

  const handleResize = useCallback(() => {
  // Set window width/height to state
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  }, []);

  useEffect(() => {
    // Add event listener
    window.addEventListener('resize', handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  const isMobile = windowSize.width! <= MOBILE_SIZE;
  return {
    windowSize,
    isMobile,
  };
};

export default useWindowSize;
