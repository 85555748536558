import styled from 'styled-components'

export const StyledCustomerAccountQuote = styled.div`
  text-align: center;
  max-width: 420px;
  width: 100%;
  margin: auto;

  & h2 {
    color: #264b94;
    font-size: 18px;
  }

  & ul {
    text-align: left;
  }

  & button {
    background: #779e00;
    width: 100%;
  }

  & ul {
    margin-top: 0px;
  }

  & li {
    list-style: none;
    padding: 5px 0;
  }

  & ul li div {
    text-align: left;
  }

  & ul li div i {
    color: #264b94;
    font-size: 20px;
    margin-right: 10px;
  }

  & .no-header {
    padding-top: 20px;
  }
`
