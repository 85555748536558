import http from '../api/http';

export default class PolicyApi {

    static getPolicy(endpoint) {
        return http.get(endpoint, {
            headers: {
                'Content-Type': 'application/tempcover.MotorPolicySummaryRepresentation+json'
            }
        })
    }
    static getDocuments(endpoint) {
        return http.get(endpoint, {
            headers: {
                'Content-Type': 'application/tempcover.PolicyDocumentsRepresentation+json'
            }
        })
    }

    static getAnalysis(endpoint) {
        return http.get(endpoint, {
            headers: {
                'Content-Type': 'application/tempcover.PolicyAnalysisRepresentation+json'
            }
        })
    }

    static updateAnalysis(endpoint) {
        return http.put(endpoint)
    }

    static updateReasonForTempcover(endpoint, data) {
        return http.put(endpoint, data, {
            headers: {
                'Content-Type': 'application/tempcover.reasonfortempcovercarrepresentation+json'
            },
        })
    }

    static requestAnnualCallback(endpoint, data) {
        return http.put(endpoint, data, { validateStatus: () => true })
    }
}