import DriverDetailsCard from 'components/DriverDetailsCard';
import React from 'react';
import { GridContainer } from 'unsemantic';
import { Wrapper } from './DrivingLicenceAB.styles';

const DrivingLicenceAB = () => (
  <GridContainer>
    <Wrapper>
      <DriverDetailsCard />
    </Wrapper>
  </GridContainer>
);

export default DrivingLicenceAB;
