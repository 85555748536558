import React from 'react';
import { H1 } from '../common/H1';
import styled from 'styled-components';
import { Button } from '../common/Button';
import { useConfig } from 'hooks';
import GridContainerWrapper from 'components/@common/GridContainerWrapper';

const Heading = styled(H1)`
  text-align: left;
`;

const HoldingPage = () => {
  const siteUrl = useConfig().SITE_URL;
  return (
    <GridContainerWrapper>
      <Heading>Site down for essential maintenance</Heading>
      <p>Please bear with us while we upgrade our site.</p>
      <p>
        If you would like us to call you when we are back up and running please
        email{' '}
        <a href='mailto:contactus@tempcover.com'>contactus@tempcover.com</a> and
        one of our team will get back to you.
      </p>
      <Button
        onClick={() => {
          window.location.href = siteUrl;
        }}
      >
        Return to home page
      </Button>
    </GridContainerWrapper>
  );
};

HoldingPage.propTypes = {};

export default HoldingPage;
