import Modal from 'components/@common/Modal';
import Klarna from 'components/Klarna';
import { useWindowSize } from 'hooks';
// @ts-ignore
import { ReactComponent as KlarnaLogo } from 'images/Payment/klarna-logo.svg';
import { InitialStateType } from 'initialState';
import React from 'react';
import { useSelector } from 'react-redux';
import { KlarnaText } from './KlarnaModal.styles';

interface Props {
    isVisible: boolean;
    onClose: () => void;
}

const KlarnaModal: React.FC<Props> = ({ isVisible, onClose }) => {

    // Function if we want to do extra when we close the modal
    const handleClose = () => {
        onClose();
    }

    const { isMobile } = useWindowSize();

    const isPaymentInProgress = useSelector((state: InitialStateType) => state.payment.paymentInProgress);
    return (
        <Modal
            allowOutsideClose={false}
            hasHeaderBorder
            id='KlarnaModal'
            fullscreen={isMobile}
            header="Pay with Klarna"
            HeaderLogo={<KlarnaLogo />}
            isVisible={isVisible}
            onClose={handleClose}
            hasCloseButton={!isPaymentInProgress}
        >
            <KlarnaText>You can choose to buy your insurance now and defer your payment by up to 30 days by using the Pay later option provided by Klarna. If you choose this option you’ll have a credit agreement with Klarna. However you won’t ever pay any fees or charges for this, and it won’t affect your credit score. Please read Klarna's terms below before choosing this option to ensure it is the right product for you.</KlarnaText>
            <KlarnaText>No commissions are payable to Tempcover. Tempcover will pay Klarna a fee if credit is provided. This fee will not affect the amount payable by you.  </KlarnaText>
            <Klarna onFailure={handleClose} />
        </Modal>
    )
}

export default KlarnaModal
