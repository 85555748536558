import React, { useState } from 'react';
import { CardElement, injectStripe } from 'react-stripe-elements';
import { connect, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  createStripePayment,
  createStripePaymentFailure,
  getPriceInfo,
} from './actions';
import {
  isButtonDisabled,
  enableAllButtons,
} from '../RiskDataCollectionPage/actions';
import { ButtonSpinner } from '../../components/common/ButtonSpinner';
import { StripePaymentButton } from './styles';
import {
  fraudFailure,
  setPaymentInProgress,
  setPaymentNotInProgress,
  priceMatch,
} from '../PaymentPage/actions';
import LoadingScreen from '../../components/LoadingScreen';
import './stripe.css';
import { useABExperiment, usePrice, useRiskData } from 'hooks';
import useMyLicence from 'hooks/useMyLicence';
import useFormButtons from 'hooks/useFormButtons';
import { EllipsisLoader } from 'components/EllipsisLoader';
import config from '../../config';

const createOptions = () => {
  return {
    style: {
      base: {
        fontSize: '16px',
        color: '#424770',
        fontFamily: 'Open Sans, sans-serif',
        letterSpacing: '0.025em',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#c23d4b',
      },
    },
  };
};

interface AddonProps {
  stripe?: any;
  actions?: any;
}

const Card: React.FC<AddonProps> = (props) => {
  const [cardElement, setCardElement] = useState(null);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const { price } = usePrice();
  const { riskData } = useRiskData();
  const { myLicence } = useMyLicence();
  const {
    formButtons: { paymentButton },
  } = useFormButtons();

  const { variant: multiPriceVariant } = useABExperiment(config.AB_TESTS.PRICE_COMPARISON_EXPERIMENT_ID, 'Price comparison experiment');

  const submit = async () => {
    if (cardElement) {
      dispatch(createStripePaymentFailure(''));
      dispatch(
        isButtonDisabled({
          buttonName: 'paymentButton',
          disabled: true,
        })
      );
      dispatch(
        getPriceInfo({
          stripeActions: props.stripe,
          actions: props.actions,
          stripeElement: cardElement,
          isMultiPrice: Boolean(multiPriceVariant),
        })
      );
    }
  };

  const handleReady = (element: any) => {
    setCardElement(element);
    setLoading(false);
  };

  return (
    <>
      {loading && <LoadingScreen message='Loading' />}
      <CardElement
        {...createOptions}
        hidePostalCode={true}
        onReady={handleReady}
      />
      {!loading && !!price.TotalPrice && price.TotalPrice > 0 && (
        <StripePaymentButton
          id='StripePaymentButton'
          onClick={submit}
          disabled={paymentButton.disabled || price.IsAddonUpdating}
          data-email={riskData.EmailAddress}
          className={
            myLicence.DisabledBySetting
              ? `uw_${price.Underwriter}`
              : myLicence.Enabled
                ? `ab_mylicence_on uw_${price.Underwriter}`
                : `ab_mylicence_off uw_${price.Underwriter}`
          }
        >
          {!price.IsAddonUpdating && (
            <>
              Pay £{price.TotalPrice.toFixed(2)}
              {paymentButton.disabled && (
                <ButtonSpinner disabled={paymentButton.disabled} />
              )}
            </>
          )}
          {price.IsAddonUpdating && <EllipsisLoader />}
        </StripePaymentButton>
      )}
    </>
  );
};

export const mapDispatchtoProps = (dispatch: any) => {
  return {
    actions: bindActionCreators(
      {
        createStripePayment,
        isButtonDisabled,
        enableAllButtons,
        createStripePaymentFailure,
        fraudFailure,
        setPaymentInProgress,
        setPaymentNotInProgress,
        getPriceInfo,
        priceMatch,
      },
      dispatch
    ),
  };
};

export default connect(null, mapDispatchtoProps)(injectStripe(Card));
