import {
  REQUEST_OCR,
  REQUEST_OCR_REAR_SUCCESS,
  REQUEST_OCR_FRONT_SUCCESS,
  ALLOW_CAMERA,
  REQUEST_OCR_ADDRESS_FAILURE,
  REQUEST_OCR_ADDRESS_SUCCESS,
  REQUEST_OCR_ADDRESS
} from './constants';

export function requestOcr(images, orientation, uuid) {
  return {
    type: REQUEST_OCR,
    images,
    orientation,
    uuid
  }
}

export function requestOcrFrontSuccess(response) {
  return {
    type: REQUEST_OCR_FRONT_SUCCESS,
    response
  }
}

export function requestOcrRearSuccess(response) {
  return {
    type: REQUEST_OCR_REAR_SUCCESS,
    response
  }
}

export function allowCamera(allowCamera) {
  return {
    type: ALLOW_CAMERA,
    allowCamera
  }
}

export function requestAddress() {
  return {
    type: REQUEST_OCR_ADDRESS
  }
}

export function requestOcrAddressSuccess(addresses) {
  return {
    type: REQUEST_OCR_ADDRESS_SUCCESS,
    addresses
  }
}

export function requestOcrAddressFailure(message) {
  return {
    type: REQUEST_OCR_ADDRESS_FAILURE,
    message
  }
}