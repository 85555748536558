import styled from "styled-components";
import { Button } from "../common/Button";
import { ErrorIcon } from "../Message/ErrorIcon";

export const ProvisionalLicenceButton = styled(Button)`
  background-color: ${props => props.theme.secondaryColour};
  margin-bottom: 15px;
  width: 95%;
`;

export const ProvisionalLicenceIcon = styled(ErrorIcon)`
  color: ${props => props.theme.secondaryColour};
  border: 2px solid ${props => props.theme.secondaryColour};
  border-radius: 50%;
  padding: 5px;
  padding-bottom: 10px;
`;
