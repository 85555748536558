export const removeDecimalPoint = (value: number) => {
    if (value.toString().indexOf('.') > -1) {
        return Math.round(value * 100);
    } else {
        return value;
    }
}

export const addDecimalPoint = (value: number) => {
    return value / 100;
}