import React, { useEffect, useState } from 'react'
import queryString from 'query-string'
import Logo from '../Logo'
import HeaderWrapper from './HeaderWrapper'
import {
  HeaderContainer,
  TeslaBrand,
  HeaderLinkWrapper,
  HeaderLinkStyled,
} from './styles'
import { addMissingUtmTags } from '../../history'
import { useConfig, useWindowSize } from 'hooks'
import { getCookie, CA_LOGGED_IN_COOKIE } from 'utils/cookie-helpers'
import LinkButton from 'components/@common/LinkButton'
import config from 'config'

const Header = ({ affiliate, isLoading, campaignRef }) => {
  const [linkLogin, setLinkLogin] = useState(
    config.CUSTOMER_ACCOUNT_URL_NOT_LOGGED_IN,
  )
  const [isSignedIn, setIsSignedIn] = useState(false)
  const queries = addMissingUtmTags(new Object(null))
  const siteUrl = useConfig().SITE_URL
  const loggedInCookie = getCookie(CA_LOGGED_IN_COOKIE)

  useEffect(() => {
    if (loggedInCookie) {
      setIsSignedIn(JSON.parse(loggedInCookie))
      if (isSignedIn) {
        setLinkLogin(config.CUSTOMER_ACCOUNT_URL)
      } else {
        setLinkLogin(config.CUSTOMER_ACCOUNT_URL_NOT_LOGGED_IN)
      }
    } else {
      setLinkLogin(config.CUSTOMER_ACCOUNT_URL_NOT_LOGGED_IN)
    }
  }, [loggedInCookie, isSignedIn])

  return (
    <HeaderWrapper>
      <HeaderContainer>
        <TeslaBrand>
          <Logo affiliate={affiliate} campaignRef={campaignRef} />
        </TeslaBrand>

        <HeaderLinkWrapper>
          {!affiliate.Ref && !isLoading && (
            <LinkButton id='accountBtn' url={linkLogin} linkName='account' />
          )}
          <HeaderLinkStyled
            href={queryString.stringifyUrl({
              url: `${siteUrl}/who-we-cover-eligibility`,
              query: { ...queries },
            })}
            target='_blank'
            id='eligibilityLnk'
          >
            Eligibility
          </HeaderLinkStyled>
        </HeaderLinkWrapper>
      </HeaderContainer>
    </HeaderWrapper>
  )
}

export default Header
