import styled from 'styled-components';

export const BaseInput = styled.input`
    border: 1px solid ${props => props.theme.inputBorderColour};
    border-radius: ${props => props.theme.formControlBorderRadius};
    padding: 0 ${props => props.theme.formControlHorizontalPadding};
    margin-bottom: ${props => props.theme.formControlBottomMargin};
    color: ${props => props.theme.primaryTextColour};
    font-size: ${props => props.theme.generalFontSize};
    height: ${props => props.theme.formControlHeight};
    width: 100%;
    font-family: ${props => props.theme.fontFamily};
    font-style: ${props => props.theme.fontStyle};
    font-weight: ${props => props.theme.fontWeight};
    :focus{
        border: 1px solid ${props => props.theme.inputActiveBorderColour} !important;
        outline: 1px solid ${props => props.theme.inputActiveBorderColour} !important;
      }
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;         /* Opera/IE 8+ */

    ::-ms-clear{
        display: none;
    }
`;