import GridContainerWrapper from 'components/@common/GridContainerWrapper';
import React from 'react';

export class Secure extends React.Component {
  componentDidMount() {
    var form = document.getElementById('ThreeDPaymentForm');
    form.submit();
  }
  render() {
    return (
      <GridContainerWrapper>
        <form
          action={this.props.location.state.securePaymentInfo.AcsUrl}
          method='post'
          target='acsframe'
          id='ThreeDPaymentForm'
        >
          <input
            type='text'
            name='MD'
            value={this.props.location.state.securePaymentInfo.Md}
            hidden
            readOnly
          />
          <input
            type='text'
            name='PaReq'
            value={this.props.location.state.securePaymentInfo.PaReq}
            hidden
            readOnly
          />
          <input
            type='text'
            name='TermUrl'
            value={this.props.location.state.securePaymentInfo.TermUrl}
            hidden
            readOnly
          />
        </form>
        <div
          className='iframe-row text-center spacer-top'
          style={{ border: 0 }}
        >
          <iframe
            id='acsframe'
            name='acsframe'
            height='800'
            width='400'
            src=''
            target='_parent'
            frameBorder='0'
            title='acsframe'
          >
            Please use a browser that supports iframes
          </iframe>
        </div>
      </GridContainerWrapper>
    )
  }
}
