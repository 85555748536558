export const VEHICLE_VALUES = [
    {
        id: 1,
        value: 5,
        description: "£1,500 to £5,000"
    },
    {
        id: 2,
        value: 6,
        description: "£5,001 to £10,000"
    },
    {
        id: 3,
        value: 7,
        description: "£10,001 to £20,000"
    },
    {
        id: 4,
        value: 8,
        description: "£20,001 to £30,000"
    },
    {
        id: 5,
        value: 9,
        description: "£30,001 to £40,000"
    },
    {
        id: 6,
        value: 10,
        description: "£40,001 to £50,000"
    },
    {
        id: 7,
        value: 11,
        description: "£50,001 to £65,000"
    },
    {
        id: 8,
        value: 12,
        description: "£65,001 and over"
    }
];

export const MOTORCYCLE_VEHICLE_VALUES = [
    {
        id: 2,
        value: '_500To2000',
        description: "£500 to £2,000"
    },
    {
        id: 3,
        value: '_2001To4000',
        description: "£2,001 to £4,000"
    },
    {
        id: 4,
        value: '_4001To6000',
        description: "£4,001 to £6,000"
    },
    {
        id: 5,
        value: '_6001To10000',
        description: "£6,001 to 10,000"
    },
    {
        id: 6,
        value: '_10001AndOver',
        description: "£10,001 and over"
    }
];