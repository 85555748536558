import {
  SEND_EMAIL_QUOTE,
  SEND_EMAIL_QUOTE_SUCCESS,
  SEND_EMAIL_QUOTE_FAILURE,
  HANDLE_EMAIL_VALIDATION,
  UPDATE_EMAIL_RISK_DATA,
  UPDATE_EMAIL_RISK_DATA_SUCCESS,
  UPDATE_EMAIL_RISK_DATA_FAILURE
} from "./constants";

export function handleEmailValidation(message) {
  return {
    type: HANDLE_EMAIL_VALIDATION,
    message
  }
}

export function sendEmailQuote() {
  return {
    type: SEND_EMAIL_QUOTE
  };
}

export function sendEmailQuoteSuccess(response) {
  return {
    type: SEND_EMAIL_QUOTE_SUCCESS,
    response
  };
}

export function sendEmailQuoteFailure(error) {
  return {
    type: SEND_EMAIL_QUOTE_FAILURE,
    error
  };
}

export function updateEmailRiskData() {
  return {
    type: UPDATE_EMAIL_RISK_DATA
  };
}

export function updateEmailRiskDataSuccess(response) {
  return {
    type: UPDATE_EMAIL_RISK_DATA_SUCCESS,
    response
  };
}

export function updateEmailRiskDataFailure(error) {
  return {
    type: UPDATE_EMAIL_RISK_DATA_FAILURE,
    error
  };
}