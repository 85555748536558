import React from 'react';
import { Grid, GridContainer } from 'unsemantic';
import styled from 'styled-components';
import  {H2}  from '../../../components/common/H2';

const Wrapper = styled.div`
    background-color:${props => props.theme.primaryColour};
    color:#fff;
    text-align:center;   
    padding: 10px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
`;

const Heading = styled(H2)`  
    color:#fff;
    margin-top: 0;
`;


const DocumentHeader = () => {  
    return(
        <Wrapper>
            <GridContainer>                                               
                <Grid desktop='100' mobile='100'>
                <Heading>Policy documents</Heading> 
                </Grid>                                      
            </GridContainer> 
        </Wrapper>
    )
}

export default DocumentHeader;
