import styled from 'styled-components';

export const ProgressionSubContent = styled.div `
  color : black;  
  height: 100%;
  margin: 0;
  vertical-align:middle;
  text-align:left;
  font-size:0.5rem;

  @media (max-width: 768px) {

    h4 {
      font-size:18px;
    }

  }

  h5 {
    font-size: ${props => props.theme.progressionStep.subContentFontSize};
    line-height: ${props => props.theme.progressionStep.subContentIconDesktopSize};

    @media screen and (max-width: 340px) {
      font-size: ${props => props.theme.progressionStep.subContentExtraSmallFontSize};
    }

    @media (max-width: 768px) {      
      line-height: ${props => props.theme.progressionStep.subContentIconSize};
    }
  }
`;