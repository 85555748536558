import React from 'react';

export const PrivateCar = ({width, height}) => {
    const opts = {};

    if(width){
        opts.width = width;
    }

    if(height){
        opts.height = height;
    }

    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 135 135.001" {...opts}>
            <g id="Group_320" data-name="Group 320" transform="translate(2939 8785)">
                <path id="Path_316" data-name="Path 316" d="M-1212.34,1052.011a67.059,67.059,0,0,1-47.73-19.771,67.059,67.059,0,0,1-19.77-47.73,67.059,67.059,0,0,1,19.77-47.73,67.058,67.058,0,0,1,47.73-19.77,67.058,67.058,0,0,1,47.73,19.77,67.058,67.058,0,0,1,19.77,47.73,67.058,67.058,0,0,1-19.77,47.73A67.059,67.059,0,0,1-1212.34,1052.011Zm0-132.355a64.927,64.927,0,0,0-64.854,64.854,64.927,64.927,0,0,0,64.854,64.855,64.928,64.928,0,0,0,64.855-64.855A64.928,64.928,0,0,0-1212.34,919.656Z" transform="translate(-1659.16 -9702.01)" fill="#264694"></path>
                <g id="Group_285" data-name="Group 285" transform="translate(-2918.241 -8739.247)">
                    <path id="Path_279" data-name="Path 279" d="M-1076.727,1133.858h-25l-5.556,5.556h33.334Z" transform="translate(1141.951 -1107.525)" fill="#ccdcff"></path>
                    <path id="Path_280" data-name="Path 280" d="M-1202.472,1091.82v-8.333l-8.333-2.778h-2.778v16.667a5.975,5.975,0,0,0,5.556,5.556h2.778Z" transform="translate(1214.916 -1071.043)" fill="#ccdcff"></path>
                    <g id="Group_274" data-name="Group 274" transform="translate(4.111 20.778)">
                        <path id="Path_281" data-name="Path 281" d="M-1004.812,1118.92c.229,0,.455.012.68.03a8.311,8.311,0,0,0-6.236-2.807,8.334,8.334,0,0,0-8.334,8.334,8.314,8.314,0,0,0,.475,2.778h5.08A8.333,8.333,0,0,1-1004.812,1118.92Z" transform="translate(1077.036 -1116.142)" fill="#ccdcff"></path>
                        <path id="Path_282" data-name="Path 282" d="M-1199.169,1127.253a8.334,8.334,0,0,1,8.334-8.334c.229,0,.456.012.68.03a8.311,8.311,0,0,0-6.236-2.807,8.334,8.334,0,0,0-8.333,8.334,8.355,8.355,0,0,0,.254,2.046,5.038,5.038,0,0,0,2.523.732Z" transform="translate(1204.724 -1116.142)" fill="#ccdcff"></path>
                    </g>
                    <path id="Path_288" data-name="Path 288" d="M-1088.454,1077.811a41.965,41.965,0,0,1-6.566-4.209c-3.719-2.723-7.232-5.295-11.734-5.295H-1122.7v4.17h17.123C-1098.195,1072.477-1097.807,1077.811-1088.454,1077.811Z" transform="translate(1152.533 -1062.529)" fill="#ccdcff"></path>
                    <path id="Path_320" data-name="Path 320" d="M-1181.592,1140.083a9.677,9.677,0,0,1-9.666-9.666,9.677,9.677,0,0,1,9.666-9.666,9.678,9.678,0,0,1,9.667,9.666A9.678,9.678,0,0,1-1181.592,1140.083Zm0-16.667a7.009,7.009,0,0,0-7,7,7.009,7.009,0,0,0,7,7,7.009,7.009,0,0,0,7-7A7.009,7.009,0,0,0-1181.592,1123.415Z" transform="translate(1199.592 -1098.527)" fill="#264694"></path>
                    <path id="Path_321" data-name="Path 321" d="M-995.568,1140.083a9.677,9.677,0,0,1-9.666-9.666,9.677,9.677,0,0,1,9.666-9.666,9.678,9.678,0,0,1,9.667,9.666A9.678,9.678,0,0,1-995.568,1140.083Zm0-16.667a7.009,7.009,0,0,0-7,7,7.008,7.008,0,0,0,7,7,7.009,7.009,0,0,0,7-7A7.009,7.009,0,0,0-995.568,1123.415Z" transform="translate(1071.903 -1098.527)" fill="#264694"></path>
                    <path id="Path_326" data-name="Path 326" d="M-1082.947,1149.99h-36.112a1.333,1.333,0,0,1-1.333-1.333,1.333,1.333,0,0,1,1.333-1.333h36.112a1.333,1.333,0,0,1,1.333,1.333A1.333,1.333,0,0,1-1082.947,1149.99Z" transform="translate(1150.948 -1116.769)" fill="#264694"></path>
                    <path id="Path_327" data-name="Path 327" d="M-1091.372,1076.226a1.331,1.331,0,0,1-.573-.13,42.977,42.977,0,0,1-6.779-4.337c-3.693-2.7-6.881-5.037-10.947-5.037h-14.613v2.837a1.333,1.333,0,0,1-1.333,1.333,1.333,1.333,0,0,1-1.333-1.333v-4.17a1.333,1.333,0,0,1,1.333-1.333h15.946c4.937,0,8.792,2.822,12.52,5.552a41.125,41.125,0,0,0,6.354,4.082,1.332,1.332,0,0,1,.629,1.776A1.332,1.332,0,0,1-1091.372,1076.226Z" transform="translate(1155.45 -1059.613)" fill="#264694"></path>
                    <path id="Path_328" data-name="Path 328" d="M-1124.831,1083.106h-8.333a1.333,1.333,0,0,1-1.333-1.333,1.333,1.333,0,0,1,1.333-1.333h7.782l2-2v-7.368c-5.233-7.413-15.442-7.414-23.668-7.414-4.3,0-7.764-2.534-11.428-5.216-3.959-2.9-8.053-5.9-13.573-5.9h-19.2l-22,8.249a1.332,1.332,0,0,1-.468.085h-1.445v15.334a4.641,4.641,0,0,0,4.223,4.224h2.778a1.333,1.333,0,0,1,1.333,1.333,1.333,1.333,0,0,1-1.333,1.333h-2.778a7.257,7.257,0,0,1-6.888-6.889V1059.55a1.333,1.333,0,0,1,1.333-1.333h2.536l22-8.249a1.336,1.336,0,0,1,.468-.085h19.445c6.39,0,11.042,3.406,15.147,6.41,3.445,2.522,6.421,4.7,9.854,4.7,8.507,0,20.158,0,26.11,8.927a1.337,1.337,0,0,1,.224.739V1079a1.33,1.33,0,0,1-.39.942l-2.778,2.778A1.334,1.334,0,0,1-1124.831,1083.106Z" transform="translate(1217.833 -1049.884)" fill="#264694"></path>
                </g>
            </g>
        </svg>
    )
}