import { P } from 'components/common/P';
import styled from 'styled-components';
import { Button } from '../common/Button';

export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  & > * {flex-grow: ${(props) => props.grow ? 1 : 0};}
`;

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PriceComparisonWrapper = styled(FlexColumn)`
  border: 1.5px solid ${(props) => props.selected ? props.theme.primaryColour : '#8FA7E1'};
  border-radius: ${(props) => props.theme.formControlBorderRadius};
  margin-bottom: 1em;
  padding: 10px;
  position: relative;

  @media (max-width: 374px) {
    padding: 5px;
  }

  @media (min-width: 768px) {
    padding: 25px 40px;
  }

  &.cheapest::before {
    position: absolute;
    top: -1.2em;
    left: 10px;
    content: 'Lowest price';
    font-family: ${props => props.theme.button.fontFamily};
    font-size: ${props => props.theme.multiPrice.mobile.lowestPriceBannerFontSize};
    letter-spacing: 1px;
    line-height: 1em;
    color: #fff;
    background-color: ${(props) => props.theme.primaryColour};
    border-radius: ${(props) => props.theme.formControlBorderRadius};
    padding: 0.6em 0.5em;

    @media (max-width: 374px) {
      left: 5px;
    }

    @media (min-width: 768px) {
      font-size: 22px;
      padding: 0.6em 1.5em;
      left: 40px;
    }

  }

`;

export const QuoteSummaryWrapper = styled.div`

  display: grid;
  place-items: center;
  grid-template-rows: 1fr;
  grid-template-columns: 85px 1fr 85px;
  column-gap: 20px;

  @media (max-width: 374px) {
    column-gap: 5px;
  }

  @media (min-width: 768px) {
    grid-template-columns: 150px auto 1fr;
  }

  @media (min-width: 1055px) {
    column-gap: 100px;
  }

  .price-comparison-underwriter-logo {
    max-width: 85px;
    width: 100%;

    @media (min-width: 768px) {
      max-width: initial;
    }
  }
`;

export const QuoteDetailsWrapper = styled.div`

display: grid;
grid-template-columns: auto 84px auto;
grid-template-rows: repeat(4, auto);
row-gap: 10px;
column-gap: 10px;
padding: 20px;
margin: 10px;
border-radius: ${(props) => props.theme.formControlBorderRadius};
border: 1px solid ${(props) => props.theme.primaryColour};


@media (min-width: 768px) {
  padding: 30px;
  grid-template-columns: minmax(125px, max-content) minmax(105px, max-content) minmax(125px, max-content) auto;
  ${(props) => props.standalone ? 'max-width: 440px; margin: 15px auto; margin-bottom: 25px; grid-template-columns: minmax(125px, max-content) 105px 125px' : ''};
}

 & a {
   display: block;
   align-self: center;

   color: ${(props) => props.theme.primaryColour};

   & > span {
    text-decoration-line: underline;
   }
 }
`;

export const ContainedQuoteDetailsWrapper = styled(QuoteDetailsWrapper)`
  padding: 10px;
  margin: 0;
  margin-top: 12px;
  border-radius: 0;
  border: none;  
  border-top: 2px solid #758abf;
  transition: 0.4s ease-in;
  opacity: ${({ state }) => (state === 'entered' ? 1 : 0)};
  height:  ${({ state }) => (state === 'entering' || state === 'entered' ? 'auto' : 0)};
  display:  ${({ state }) => (state === 'entering' || state === 'entered' ? 'grid' : 'none')};
  pointer-events: ${({ state }) => (state === 'entering' || state === 'entered' ? 'initial' : 'none')};
  transform: translateY(
    ${({ state }) => (state === 'entering' || state === 'entered' ? 0 : -10)}px
  );

  @media (min-width: 768px) {
    padding: 30px 30px 5px 30px;
  }
`

export const EditBlock = styled.div`
  grid-column: 2 / span 2;
  justify-self: start;
  align-self: center;
  font-size: 12px;

  span {
    padding-left: 5px;    
    font-family: ${(props) => props.theme.fontFamily};
  }

  @media (min-width: 768px) {
    font-size: 14px;
  }
`;

export const NameBlock = styled.div`
  border-right: 2px solid #bdd0ff;
  padding-right: 10px;

  @media (min-width: 768px) {
    grid-column: 1 / span 1;
  }
`;

export const DOBBlock = styled.div`
  border-right: 2px solid #bdd0ff;
  padding-left: 5px;
  @media (min-width: 768px) {
    padding-right: 10px;
  }
`;

export const LicenceHeldBlock = styled.div`
  padding-left: 5px;

  @media (min-width: 768px) {
    border-right: ${props => props.hideBorder ? 'none' : '2px solid #bdd0ff'};
    padding-right: 10px;
  }
`;

export const FullRowBlock = styled.div`
  grid-column: 1 / span 3;

  @media (min-width: 768px) {
    grid-column: ${props => props.alwaysFullRow ? '1 / span 3' : '4 / span 1'};
 }
`;

export const QuoteDetailsHeader = styled.h3`
  font-family: ${props => props.theme.fontFamily};
  font-size: 14px;
  line-height: 1em;
  color: ${(props) => props.theme.priceComparison.headerColour};
  margin: 0;
  justify-self: start;

  @media (min-width: 768px) {
    font-size: 22px;
  }

`;

export const QuoteDetailsLabel = styled.p`
    font-family: ${props => props.theme.fontFamily};
    font-size: 12px;
    line-height: 1.2em;
    text-align: start;
    margin: 0;

    @media (min-width: 768px) {
      font-size: ${props => props.theme.multiPrice.fontSize};
    }
`;

export const QuoteDetailsValue = styled.p`
  font-size: 12px;
  line-height: 1.2em;
  text-align: start;
  font-weight: bold;
  font-family: ${props => props.theme.fontFamily};
  color: ${props => props.theme.primaryTextColour};
  margin: 0;

  @media (min-width: 768px) {
    font-size: 16px;
  }
`;

export const TotalPriceWrapper = styled.div`
  font-size: 14px
  color: ${(props) => props.theme.priceComparison.textColour};
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  text-align: left;
  font-weight: bold;
  align-items: center;
  column-gap: 20px;

  @media (max-width: 374px) {
    font-size: 0.8em;
  }

  @media (min-width: 768px) {    
    font-size: 22px;
  }

  p {
    margin: 0;
    font-size: 12px;
    white-space: nowrap;

    @media (min-width: 768px) {
      font-size: 18px;
    }
  }
`;

export const TotalPriceLabel = styled.span`
  font-family: ${(props) => props.theme.fontFamily};
`

export const PaymentBreakdownIconWrapper = styled.div`
  @media (max-width: 768px) {
    padding-top: 3px;
  }
`;

export const TotalPriceMainBlock = styled.div`
  display: block;

  & > p {
    font-family: ${(props) => props.theme.fontFamily};
  }
`;

export const TotalPriceValueWrapper = styled.span`
  font-weight: bold;
  font-size: 26px;
  line-height: 1;
  font-family: ${(props) => props.theme.headerFontFamily};

  
  @media (min-width: 768px) {
    font-size: 44px;
  }
`;
export const CoverTypeText = styled(P)`
  font-size: ${props => props.theme.multiPrice.mobile.coverTypeFontSize} !important;
  @media (max-width: 376px) {
  font-size: ${props => props.theme.multiPrice.mobile.mobileCoverTypeFontSize} !important;
  }
`

export const QuoteInfoWrapper = styled.div`
  align-self: center;
  text-align: left;

  p {
    margin: 0;
  }

  @media (max-width: 768px) {
    margin-top: 10px;
    text-align: center;
    padding-left: 0;
  }
`;

export const SelectWrapper = styled.div`
  justify-self: end;
`;

export const PriceComparisonSelectButton = styled(Button)`
  padding: 1.75em 5.35em;
  height: auto;
  font-size: 18px;
  background: ${props => props.theme.multiPrice.button.backgroundColour};
  color: ${props => (props.disabled ? props.theme.multiPrice.button.disabledFont : props.theme.button.textColour)};

  //animation
  transition: 0.5s;
  opacity: ${({ state }) => (state === 'entering' || state === 'entered' ? 1 : 0)};
  // width:  ${({ state }) => (state === 'entering' || state === 'entered' ? '200px' : 0)};
  pointer-events: ${({ state }) => (state === 'entering' || state === 'entered' ? 'initial' : 'none')};
  display: ${({ state }) => (state === 'exited' ? 'none' : 'block')};

  @media (max-width: 767px) {
    padding: 0.85em 0.8em;
    letter-spacing: 1px;
    font-size: 18px;
    width:  ${({ state }) => (state === 'entering' || state === 'entered' ? '100%' : 0)};
  }

  @media (max-width: 344px) {
    font-size: 14px;
  }

`;

export const PriceComparisonContinueButton = styled(Button)`
  padding: 1.3em 2em;
  height: auto;
  font-size: ${props => props.theme.multiPrice.continueButtonFontSize}
  margin-top: 20px;
  grid-column: 1 / span 4;
  background: ${props => props.theme.multiPrice.button.backgroundColour};
  color: ${props => (props.disabled ? props.theme.multiPrice.button.disabledFont : props.theme.button.textColour)};

  @media (max-width: 767px) {
    font-size: ${props => props.theme.multiPrice.mobile.continueButtonFontSize}
    padding: 1em 0.8em;
    letter-spacing: 1px;
    margin-top: 10px;
    grid-column: 1 / span 3;
  }
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  
  `;

export const ImageWrapper = styled.div`
  display: flex;
  margin: auto;
  max-wdith: 100px;
`;

export const AmendDetailsLink = styled.div`
  text-align: left;
  & > a {
    font-size: 16px;
    display: inline;
  }  
`