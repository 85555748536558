import { P } from "components/common/P";
import styled from "styled-components";

export const KlarnaText = styled(P)`
    margin:0;
    margin-bottom: 8px;
    font-size: 14px;
    @media(max-width: 768px) {
        font-size: 14px;
    }
`