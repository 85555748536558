import axios from 'axios'
import { VehicleDetails } from 'components/VehicleDetail/VehicleDetail.types'
import config from 'config'
import { store } from './store'

export type SaveNewVehicleRequest = {
  vehicleId: string | undefined
  vehicleBodyType: string | null | undefined
  vehicleValueBandId: string
}

export type GetCustomerVehicleResponse = {
  customerVehicle: VehicleDetails[]
}

export type GetAccountExistsResponse = {
  isWebUser: boolean
  accountExists: boolean
}

export type QuoteHasCustomerAccount = {
  accountExists: boolean
}

export type SaveNewOccupationRequest = {
  occupationId: number
}

export type SavePassportDetailsRequest = {
  passportLine1: string
  passportLine2: string | undefined
  passportDate: string | undefined
}

export type GetCustomerDetailsResponse = {
  title: string
  forename: string
  surname: string
  dateOfBirth: string
  licenceType: string
  licenceNumber: string
  licenceHeldDuration: number
  licenceHeldDurationDescription: string
  residencyType: number
  fullAddress: string
  email: string
  telephoneNumber: string
  addressKey: string
  ukResidencyDuration: string
  occupationId: number
  occupationName: string
  occupationCode: string
  residencyTypeDescription: string
  passportLine1: string
  passportLine2: string
  passportDate: string
}

export type SaveCustomerRegistrationRequest = {
  drivingLicenceNumber: string
}

const customerAccountHttp = axios.create({
  baseURL: config.CUSTOMER_ACCOUNT_API_URL,
  headers: {
    'x-api-key': config.CUSTOMER_ACCOUNT_API_KEY,
  },
  withCredentials: true,
})

export default class CustomerAccountApi {
  static saveNewVehicleToCustomer(data: SaveNewVehicleRequest) {
    return customerAccountHttp.post('/customer/vehicle/add', data)
  }

  static saveNewOccupationToCustomer(data: SaveNewOccupationRequest) {
    return customerAccountHttp.post('/customer/occupation/add', data)
  }

  static saveNewPassportToCustomer(data: SavePassportDetailsRequest) {
    return customerAccountHttp.post('/customer/passport/add', data)
  }

  static getCustomerAccountId() {
    return customerAccountHttp.get<string>('/customer/getid')
  }

  static getCustomerDetails() {
    return customerAccountHttp.get<GetCustomerDetailsResponse>(
      '/customer/details',
    )
  }

  static getCustomerVehicles() {
    return customerAccountHttp.get<GetCustomerVehicleResponse>('/vehicles')
  }

  static getIsVehicleAlreadyInCustomerAccount(data: string) {
    return customerAccountHttp.get<boolean>(
      `/vehicles/vehicleassociatedwithcustomer/${data}`,
    )
  }

  static saveCustomerLicenceDetails(licenceNumber: string) {
    const data: SaveCustomerRegistrationRequest = {
      drivingLicenceNumber: licenceNumber,
    }
    return customerAccountHttp.post('/customer/drivinglicence/add', data)
  }

  static getFeatureFlag(featureFlag: string) {
    return customerAccountHttp.get<boolean>(`/featureflag/get/${featureFlag}`)
  }

  static CheckMobileHasAccount(mobileNumber: string) {
    return customerAccountHttp.get<GetAccountExistsResponse>(
      `/customer/registration/${mobileNumber}`,
    )
  }

  static CheckQuoteHasCustomerAccount(encryptedQuoteId: string) {
    return customerAccountHttp.get<QuoteHasCustomerAccount>(
      `/quote/doescustomerhaveaccountfromquoteid/${encryptedQuoteId}`,
    )
  }
}
