import styled from 'styled-components';
import { SummarySection } from 'containers/QuoteSummary/SummarySectionStyles';

interface StyledSummaryProps {
  isIndividual?: boolean
}

export const StyledSummary = styled(SummarySection)<StyledSummaryProps>`
  ${(props) => {
    if(props.isIndividual){
      return `
        @media (max-width: 767px) {
          border: 1px solid ${props.theme.inputBorderColour};
          padding: 18px;
        }
      `
    }
  }}
`