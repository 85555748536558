import { useField } from 'formik';
import React from 'react';
import Icon from './components/Icon';
import { TextFieldStyled, TextFieldWIthIcon } from './TextField.styles';

export interface Props
  extends Pick<
    React.HTMLProps<HTMLInputElement>,
    | 'onChange'
    | 'onBlur'
    | 'placeholder'
    | 'onFocus'
    | 'type'
    | 'pattern'
    | 'id'
    | 'maxLength'
    | 'tabIndex'
    | 'onClick'
  > {
  name: string;
  uppercase?: boolean;
  shouldRegister?: boolean;
  icon?: React.ReactNode;
}

const TextField: React.FC<Props> = ({
  placeholder = '',
  shouldRegister = true,
  icon: IconChildren,
  onBlur,
  ...props
}) => {
  const [field, meta, helpers] = useField(props);

  if (props.uppercase) {
    field.onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      helpers.setValue(e.target.value.toUpperCase(), false);
    };
  }

  field.onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    helpers.setValue(field.value?.trim(), true);

    helpers.setTouched(true, false);
    if (onBlur) {
      onBlur(event);
    }
  };

  // TODO: Revisit if there is a nicer way to do logic below
  if (IconChildren) {
    return (
      <>
        <Icon>{IconChildren}</Icon>
        {shouldRegister && (
          <TextFieldWIthIcon
            placeholder={placeholder}
            autoComplete="off"
            autoCorrect="off"
            {...field}
            {...props}
          />
        )}
        {!shouldRegister && (
          <TextFieldWIthIcon
            placeholder={placeholder}
            autoComplete="off"
            autoCorrect="off"
            {...props}
          />
        )}
      </>
    );
  }
  return (
    <>
      {shouldRegister && (
        <TextFieldStyled
          placeholder={placeholder}
          autoComplete="off"
          autoCorrect="off"
          {...field}
          {...props}
        />
      )}
      {!shouldRegister && (
        <TextFieldStyled
          placeholder={placeholder}
          autoComplete="off"
          autoCorrect="off"
          {...props}
        />
      )}
    </>
  );
};

export default TextField;
