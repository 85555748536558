export const HANDLE_EMAIL_VALIDATION = 'src/EmailQuoteModal/HANDLE_EMAIL_VALIDATION';

export const SEND_EMAIL_QUOTE = "src/EmailQuoteModal/SEND_EMAIL_QUOTE";
export const SEND_EMAIL_QUOTE_SUCCESS =
  "src/EmailQuoteModal/SEND_EMAIL_QUOTE_SUCCESS";
export const SEND_EMAIL_QUOTE_FAILURE =
  "src/EmailQuoteModal/SEND_EMAIL_QUOTE_FAILURE";

export const UPDATE_EMAIL_RISK_DATA = 'src/EmailQuoteModal/UPDATE_EMAIL_RISK_DATA';
export const UPDATE_EMAIL_RISK_DATA_SUCCESS = 'src/EmailQuoteModal/UPDATE_EMAIL_RISK_DATA_SUCCESS';
export const UPDATE_EMAIL_RISK_DATA_FAILURE = 'src/EmailQuoteModal/UPDATE_EMAIL_RISK_DATA_FAILURE';

export const SEND_EMAIL_REL = 'QuoteReminders';