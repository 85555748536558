import { setQueryString, setScheme } from 'containers/App/actions'
import { updateRiskData } from 'containers/RiskDataCollectionPage/actions'
import { useQuote } from 'hooks'
import { useDispatch, useSelector } from 'react-redux'
import { history } from 'index'
import queryString from 'query-string'
import { useMemo } from 'react'
import {
  COMMERCIAL_VEHICLE_SCHEME,
  IMPOUNDED_COMMERCIAL_VEHICLE_SCHEME,
} from '../../containers/Vehicle/constants'
import { InitialStateType } from '../../initialState'
import { Scheme } from '../../types/global'

const useScheme = () => {
  const scheme = useSelector((state: InitialStateType) => state.scheme.selected)
  const { createQuote } = useQuote()
  const dispatch = useDispatch()
  const underwriterId = useSelector(
    (state: InitialStateType) => state.price.UnderwriterId,
  )
  const riskData = useSelector((state: InitialStateType) => state.riskData)
  const isCustomerQuote = useSelector(
    (state: InitialStateType) => state.quote.isCustomerAccountQuote,
  )
  const isVanScheme =
    scheme === COMMERCIAL_VEHICLE_SCHEME ||
    scheme === IMPOUNDED_COMMERCIAL_VEHICLE_SCHEME

  const isImpounded = riskData.Impounded

  const isFirstLearner =
    scheme === 'VehicleLearnerScheme' && underwriterId === 16

  const isValidSchemeForProvisionalicence = useMemo(
    () =>
      scheme === 'VehicleMotorcycleLearnerAndFullLicenceScheme' ||
      scheme === 'VehicleLearnerScheme',
    [scheme],
  )

  const changeScheme = async (schemeToMoveTo: Scheme) => {
    // Change scheme in state
    dispatch(setScheme(schemeToMoveTo))
    // Clear fields depending on scheme
    switch (schemeToMoveTo) {
      case 'VehicleCommercialVehicleCourierScheme':
        dispatch(
          updateRiskData({
            UseOfVehicle: '',
            Courier: true,
          }),
        )
        break
      default:
        break
    }

    // Create quote
    const quoteResponse = await createQuote(schemeToMoveTo)

    // Redirect to new quote id
    const parsedQueryString = queryString.parse(window.location.search)
    const redirectQueryString = `?q=${quoteResponse.QuoteId.Value}${
      parsedQueryString.ref ? `&ref=${parsedQueryString.ref}` : ''
    }`
    dispatch(setQueryString(redirectQueryString))
    const redirectPath = isCustomerQuote
      ? `/quote/driver-details/logged-in${redirectQueryString}`
      : `/quote/driver-details${redirectQueryString}`

    history.replace(redirectPath)
    return quoteResponse
  }

  return {
    scheme,
    isVanScheme,
    isImpounded,
    isFirstLearner,
    changeScheme,
    isValidSchemeForProvisionalicence,
  }
}

export default useScheme
