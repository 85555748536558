export const DEFAULT_ACTION = 'app/PaymentPage/DEFAULT_ACTION';
export const RESET_PAYMENT_OPTIONS = 'app/PaymentPage/RESET_PAYMENT_OPTIONS';
export const FRAUD_FAILURE = 'src/JudoPay/FRAUD_FAILURE';
export const SET_PAYMENT_IN_PROGRESS = 'src/PaymentPage/SET_PAYMENT_IN_PROGRESS';
export const SET_PAYMENT_NOT_IN_PROGRESS = 'src/PaymentPage/SET_PAYMENT_NOT_IN_PROGRESS';

export const HANDLE_MOBILE_VALIDATION = 'app/PaymentPage/HANDLE_MOBILE_VALIDATION';

export const CLEAR_PAYMENT_ERROR = 'app/PaymentPage/CLEAR_PAYMENT_ERROR';

export const PRICE_CHECK_PAYMENT_PAGE = 'app/PaymentPage/PRICE_CHECK_PAYMENT_PAGE';

export const PRICE_MATCH = 'app/PaymentPage/PRICE_MATCH';