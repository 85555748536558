import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import DrivingLicenceNumber from '../../components/DrivingLicenceNumber'
import DrivingLicenceFullNumber from '../../components/DrivingLicenceNumber/DrivingLicenceFullNumber'
import { Label } from '../../components/common/Label'
import { Grid } from 'unsemantic'
import {
  updateRiskData,
  updateValidation,
  updateQuote,
  drivingLicenceCheck,
  saveCustomerDrivingLicence,
  passportCheck,
  isButtonDisabled,
} from '../RiskDataCollectionPage/actions'
import { PassportLineOne } from '../../components/PassportLineOne'
import { PassportLineTwo } from '../../components/PassportLineTwo'
import PassportExpiryDate from '../../components/PassportExpiryDate'
import DrivingLicenceExample from '../../images/driving-licence-example.png'
import LearnerDrivingLicenceExample from '../../images/provisional-driving-licence-example.png'
import PassportExample from '../../images/passport-example.gif'
import { MoreDetailsHelperImage, MoreDetailsButton } from './styles'
import { Message } from '../../components/Message'
import { ButtonSpinner } from '../../components/common/ButtonSpinner'
import logFullStoryCustomEvent from '../../utils/fullstory-custom-event'
import GridContainerWrapper from 'components/@common/GridContainerWrapper'
import { getIsCustomerAccountQuote } from 'containers/RiskDataCollectionPage/selector'
import { useSaveNewDrivingLicenceToCustomer } from 'components/DrivingLicenceNumber/services/DrivingLicenceNumber'
import { select } from 'redux-saga/effects'

export class MoreDetails extends React.Component {
  constructor(props) {
    super(props)
    this.handleValidation = this.handleValidation.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleContinueClick = this.handleContinueClick.bind(this)
    this.handleDateSet = this.handleDateSet.bind(this)
    this.handleDateError = this.handleDateError.bind(this)
    this.isValid = this.isValid.bind(this)
    this.state = {
      DrivingLicenceNumber: '',
      DrivingLicenceNumberFirstPart: '',
      PassportLineOne: '',
      PassportLineTwo: '',
      PassportExpiryDate: '',
      Title: this.props.riskData.Title,
      Forename: this.props.riskData.Forename,
      Surname: this.props.riskData.Surname,
      DateOfBirth: this.props.riskData.DateOfBirth,
    }
  }

  componentDidMount() {
    if (!this.props.validation.IdentityCheck) {
      this.props.history.push(`/quote/driving-licence${this.props.queryString}`)
    }
    logFullStoryCustomEvent('Identity Check')
  }

  componentDidUpdate() {
    if (this.isValid()) {
      this.moreDetailsSubmit.scrollIntoView({ behavior: 'smooth' })
    }
  }

  handleValidation = (field, message) => {
    this.props.actions.updateValidation({
      [`${field}Error`]: message,
    })
  }

  handleDateSet = (name, value) => {
    this.setState({ [name]: value })
  }

  handleDateError = (name, value) => {
    this.handleValidation(name, value)
  }

  handleChange = (event) => {
    const fieldName = event.target.name
    const fieldValue = event.target.value

    this.setState({ [fieldName]: fieldValue })
  }

  handleContinueClick = () => {
    this.props.actions.isButtonDisabled({
      buttonName: 'moreDetails',
      disabled: true,
    })

    if (this.props.validation.IdentityCheck === 'drivingLicence') {
      this.props.actions.updateRiskData({
        DrivingLicenceNumber: this.state.DrivingLicenceNumber,
        DrivingLicenceNumberFirstPart: this.state.DrivingLicenceNumberFirstPart,
      })
      this.props.actions.drivingLicenceCheck()

      // if customer account need to save the driving licence
      const isCustomerAccount = this.props.isCustomerAccount
      if (isCustomerAccount) {
        this.props.actions.saveCustomerDrivingLicence()
      }
    } else {
      this.props.actions.updateRiskData({
        PassportLineOne: this.state.PassportLineOne,
        PassportLineTwo: this.state.PassportLineTwo,
        PassportExpiryDate: this.state.PassportExpiryDate,
      })
      this.props.actions.passportCheck()
    }
  }

  isValid = () => {
    return (
      this.state.DrivingLicenceNumber ||
      (this.state.PassportLineOne &&
        this.state.PassportLineTwo &&
        this.state.PassportExpiryDate)
    )
  }
  render() {
    const isLearner = this.props.riskData.Learner

    return (
      <GridContainerWrapper>
        <Message
          heading='We need more information about you'
          content="Some of your details didn't quite match up. Please can you provide us some more info below."
          type='info'
        />
        {this.props.validation.IdentityCheck === 'drivingLicence' && (
          <>
            <MoreDetailsHelperImage
              src={
                isLearner ? LearnerDrivingLicenceExample : DrivingLicenceExample
              }
            />
            <Grid desktop='100'>
              <Label>Driving licence number</Label>
              {this.props.scheme ===
              'VehicleMotorcycleLearnerAndFullLicenceScheme' ? (
                <DrivingLicenceFullNumber
                  onChange={this.handleChange}
                  value={this.state.DrivingLicenceNumber}
                  handleValidation={this.handleValidation}
                  error={this.props.validation.fields.DrivingLicenceNumberError}
                  className={
                    this.props.myLicenceStatus.DisabledBySetting
                      ? `uw_${this.props.price.Underwriter}`
                      : this.props.myLicenceStatus.Enabled
                      ? `ab_mylicence_on uw_${this.props.price.Underwriter}`
                      : `ab_mylicence_off uw_${this.props.price.Underwriter}`
                  }
                />
              ) : (
                <DrivingLicenceNumber
                  onChange={this.handleChange}
                  value={this.state}
                  handleValidation={this.handleValidation}
                  error={this.props.validation.fields.DrivingLicenceNumberError}
                  className={
                    this.props.myLicenceStatus.DisabledBySetting
                      ? `uw_${this.props.price.Underwriter}`
                      : this.props.myLicenceStatus.Enabled
                      ? `ab_mylicence_on uw_${this.props.price.Underwriter}`
                      : `ab_mylicence_off uw_${this.props.price.Underwriter}`
                  }
                />
              )}
            </Grid>
          </>
        )}
        {this.props.validation.IdentityCheck === 'passport' && (
          <Grid desktop='100'>
            <MoreDetailsHelperImage src={PassportExample} />
            <Label>Passport Line One</Label>
            <PassportLineOne
              onChange={this.handleChange}
              value={this.state.PassportLineOne}
              handleValidation={this.handleValidation}
              error={this.props.validation.fields.PassportLineOneError}
            />
            <Label>Passport Line Two</Label>
            <PassportLineTwo
              onChange={this.handleChange}
              value={this.state.PassportLineTwo}
              handleValidation={this.handleValidation}
              error={this.props.validation.fields.PassportLineTwoError}
            />
            <Label>Passport Expiry Date</Label>
            <PassportExpiryDate
              handleDateSet={this.handleDateSet}
              value={this.state.PassportExpiryDate}
              error={this.props.validation.fields.PassportExpiryDateError}
              setDateError={this.handleDateError}
            />
          </Grid>
        )}

        {this.isValid() && (
          <Grid desktop='100'>
            <MoreDetailsButton
              id='TeslaSubmitDrivingLicenceNextButton'
              ref={(el) => {
                this.moreDetailsSubmit = el
              }}
              onClick={this.handleContinueClick}
              disabled={
                !this.props.validation.ValidForm ||
                this.props.moreDetailsButtonDisabled
              }
              className={
                this.props.myLicenceStatus.DisabledBySetting
                  ? `uw_${this.props.price.Underwriter}`
                  : this.props.myLicenceStatus.Enabled
                  ? `ab_mylicence_on uw_${this.props.price.Underwriter}`
                  : `ab_mylicence_off uw_${this.props.price.Underwriter}`
              }
            >
              {this.props.moreDetailsButtonDisabled
                ? 'Please wait'
                : 'Continue'}
              <ButtonSpinner disabled={this.props.moreDetailsButtonDisabled} />
            </MoreDetailsButton>
          </Grid>
        )}
      </GridContainerWrapper>
    )
  }
}

export const mapStateToProps = (state) => {
  return {
    riskData: state.riskData,
    validation: state.validation.riskDataCollectionPage,
    location: state.location,
    queryString: state.queryString,
    driverLicenceButtonDisabled: state.formButtons.driverLicence.disabled,
    moreDetailsButtonDisabled: state.formButtons.moreDetails.disabled,
    myLicenceStatus: state.myLicence,
    price: state.price,
    scheme: state.scheme.selected,
    isCustomerAccount: state.quote.isCustomerAccountQuote,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        updateValidation,
        updateRiskData,
        updateQuote,
        drivingLicenceCheck,
        saveCustomerDrivingLicence,
        passportCheck,
        isButtonDisabled,
      },
      dispatch,
    ),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MoreDetails)
