import {
  CHECK_MARKETING_PREFERENCES_OPT_IN_STATE,
  CHECK_MARKETING_PREFERENCES_OPT_IN_STATE_AND_FORWARD,
  CHECK_MARKETING_PREFERENCES_OPT_IN_STATE_SUCCESS,
  CHECK_MARKETING_PREFERENCES_OPT_IN_STATE_FAILURE,
  SET_MARKETING_PREFERENCES_OPT_IN_STATE,
  SET_MARKETING_PREFERENCES_OPT_IN_STATE_SUCCESS,
  SET_MARKETING_PREFERENCES_OPT_IN_STATE_FAILURE,
  SAVE_MARKETING_PREFERENCES_OPT_IN_STATE,
  SAVE_MARKETING_PREFERENCES_OPT_IN_STATE_SUCCESS,
  SAVE_MARKETING_PREFERENCES_OPT_IN_STATE_FAILURE,
  RESET_MARKETING_PREFERENCES_OPT_IN_STATE
} from './constants';

export function checkMarketingOptInState() {
  return {
    type: CHECK_MARKETING_PREFERENCES_OPT_IN_STATE,
  };
}

export function checkMarketingOptInStateAndForward() {
  return {
    type: CHECK_MARKETING_PREFERENCES_OPT_IN_STATE_AND_FORWARD,
  };
}

export function checkMarketingOptInStateSuccess(data) {
  return {
    type: CHECK_MARKETING_PREFERENCES_OPT_IN_STATE_SUCCESS,
    data
  };
}

export function checkMarketingOptInStateFailure() {
  return {
    type: CHECK_MARKETING_PREFERENCES_OPT_IN_STATE_FAILURE,
  };
}

export function setMarketingOptInState(type, optIn) {
  var vals = {};
  switch (type) {
    case "sms":
      vals.TelephoneNumberOptIn = optIn;
      break;
    case "email":
      vals.EmailAddressOptIn = optIn;
      break;
    default: break;
  }

  return {
    type: SET_MARKETING_PREFERENCES_OPT_IN_STATE,
    vals
  };
}

export function resetMarketingPreferences() {
  return {
    type: RESET_MARKETING_PREFERENCES_OPT_IN_STATE,
  };
}

export function setMarketingOptInStateSuccess(data) {
  return {
    type: SET_MARKETING_PREFERENCES_OPT_IN_STATE_SUCCESS,
    data
  };
}

export function setMarketingOptInStateFailure() {
  return {
    type: SET_MARKETING_PREFERENCES_OPT_IN_STATE_FAILURE,
  };
}

export function saveMarketingPreferences() {
  return {
    type: SAVE_MARKETING_PREFERENCES_OPT_IN_STATE,
  };
}

export function saveMarketingPreferencesSuccess() {
  return {
    type: SAVE_MARKETING_PREFERENCES_OPT_IN_STATE_SUCCESS,
  };
}

export function saveMarketingPreferencesFailure() {
  return {
    type: SAVE_MARKETING_PREFERENCES_OPT_IN_STATE_FAILURE,
  };
}
