import initialState from '../../../initialState';
import { GET_QUOTE_SUMMARY_SUCCESS, REQUEST_QUOTE_SUCCESS, UPDATE_DURATION } from '../constants';
import { 
    PRIVATE_CAR, 
    IMPOUNDED_PRIVATE_CAR_SCHEME, 
    COMMERCIAL_VEHICLE, 
    IMPOUNDED_COMMERCIAL_VEHICLE_SCHEME, 
    SET_VEHICLE, 
    VEHICLE_LEARNER_SCHEME,
    MOTORCYCLE,
    MOTORCYCLE_VEHICLE_SCHEME 
} from '../../Vehicle/constants';
import { 
    DURATION_OBJECT_DEFAULT, 
    DURATION_OBJECT_LEARNER, 
    DURATION_OBJECT_IMPOUNDED, 
    DURATION_OBJECT_MOTORCYCLE, 
    POLICY_DURATION_HOURS,
    POLICY_DURATION_WEEKS,
    POLICY_DURATION_DAYS,
} from '../../../components/PolicyDurationModal/constants';
import { GET_ANALYSIS_DATA_SUCCESS } from '../../PolicyConfirmationPage/constants';

const isDurationAValidOption = (currentType, currentValue, newDurationSet) => {
  currentValue = parseInt(currentValue, 10);
  const isDurationAPreset = newDurationSet.presets.find(p => p.durationLength === currentValue && p.durationType === currentType);
  const isDurationAExtraOption = newDurationSet.durationExtraOptionsSet.find(o => o.type === currentType && o.value ===currentValue);

  if(isDurationAPreset || isDurationAExtraOption){
    return true;
  }

  if(currentType === POLICY_DURATION_HOURS && newDurationSet.hourDisplayOptions.indexOf(currentValue) !== -1){
     return true;
  }

  if(currentType === POLICY_DURATION_WEEKS && newDurationSet.weekDisplayOptions.indexOf(currentValue) !== -1){
    return true;
  }

  if(currentType === POLICY_DURATION_DAYS && newDurationSet.dayDisplayOptions.indexOf(currentValue) !== -1){
    return true;
  }

  return false;
}

function durationReducer(state = initialState.duration, action) {
  switch (action.type) {
    case GET_QUOTE_SUMMARY_SUCCESS:
      let durationObj = {};
      switch(action.quoteSummary.Scheme){
          case IMPOUNDED_COMMERCIAL_VEHICLE_SCHEME:
          case IMPOUNDED_PRIVATE_CAR_SCHEME:
            durationObj = DURATION_OBJECT_IMPOUNDED;
            break;
          case VEHICLE_LEARNER_SCHEME:
            durationObj = DURATION_OBJECT_LEARNER;
            break;
          case MOTORCYCLE_VEHICLE_SCHEME:
            durationObj = DURATION_OBJECT_MOTORCYCLE;
            break;
          default: 
            durationObj = DURATION_OBJECT_DEFAULT;
            break;
      }
      return {
        ...state,
        ...durationObj,
      }

    case SET_VEHICLE:
      let durationObject = {};

      switch (action.vehicle.VehicleType) {
        case PRIVATE_CAR:
          durationObject = action.vehicle.Impounded ? DURATION_OBJECT_IMPOUNDED : DURATION_OBJECT_DEFAULT;
          break;
        case COMMERCIAL_VEHICLE:
          durationObject = action.vehicle.Impounded ? DURATION_OBJECT_IMPOUNDED : DURATION_OBJECT_DEFAULT;
          break;
        case MOTORCYCLE:
          durationObject = DURATION_OBJECT_MOTORCYCLE;
          break;
        default:
          durationObject = DURATION_OBJECT_DEFAULT;
      }
      
      if(action.vehicle.IsLearnerVehicle){
        durationObject = DURATION_OBJECT_LEARNER;
      }

      if(!isDurationAValidOption(state.type, state.value, durationObject)){
        durationObject.type = POLICY_DURATION_DAYS;
        durationObject.value = durationObject.dayDisplayOptions[0];
      }

      return {
        ...state,
        ...durationObject,
      }

    case REQUEST_QUOTE_SUCCESS:
      return {
        ...state,
        value: action.quote.PolicyDuration,
        type: action.quote.PolicyDurationType
      }

    case GET_ANALYSIS_DATA_SUCCESS:
      return {
        ...state,
        value: action.response.Duration,
        type: action.response.DurationType,
      }

    case UPDATE_DURATION:
      return {
        ...state,
        value: action.duration.value,
        type: action.duration.type,
      }

    default:
      return state;
  }
}

export default durationReducer;