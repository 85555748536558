import { useField } from 'formik';
import React, { FormEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRiskData } from 'hooks';
import { requestOccupations } from '../../containers/Occupation/actions';
import { updateRiskData } from '../../containers/RiskDataCollectionPage/actions';
import { InitialStateType } from '../../initialState';
import AutoComplete from '../@common/AutoComplete';
import TextField from '../@common/TextField';

const Occupation: React.FC = () => {
  const [editing, setEditing] = useState(false);
  const [occupationSelected, setOccupationSelected] = useState(false);
  const [occupationList, setOccupationList] = useState<
    { id: number; value: string }[]
  >([]);
  const [field, meta, helpers] = useField('occupation');

  const dispatch = useDispatch();

  const occupationState = useSelector(
    (state: InitialStateType) => state.occupation,
  );

  const { occupation: occupationRiskData } = useRiskData();

  const handleOccupationSelect = (
    event:
      | React.TouchEvent<HTMLLIElement>
      | React.MouseEvent<HTMLLIElement, MouseEvent>,
  ) => {
    const selectedOccupation = occupationState.SearchResults.find(
      (o: { Id: number }) => o.Id === parseInt(event.currentTarget.id, 10),
    );
    setOccupationSelected(true);
    dispatch(updateRiskData({ Occupation: selectedOccupation }));

    helpers.setValue(selectedOccupation?.OccupationName!, true);
    helpers.setTouched(true);
  };

  const handleFieldBlur = () => {
    helpers.setTouched(true);
    if (!occupationSelected) {
      helpers.setError(
        'Sorry we need you to select an occupation from the list. Please try again.',
      );
    }
    setEditing(false);
  };

  const handleFieldInteraction = () => {
    setEditing(true);
  };

  const handleChange = (e: FormEvent<HTMLInputElement>) => {
    setOccupationSelected(false);
    helpers.setValue(e.currentTarget.value);
    if (e.currentTarget.value.length >= 3) {
      dispatch(requestOccupations(e.currentTarget.value));
    }
  };

  useEffect(() => {
    const formattedOccupations = occupationState.SearchResults.map(
      (occupation) => ({
        id: occupation.Id,
        value: occupation.OccupationName,
      }),
    );
    setOccupationList(formattedOccupations);
  }, [occupationState.SearchResults]);

  useEffect(() => {
    if (
      (!meta.error && !occupationRiskData.Id && meta.touched)
      || meta.value !== occupationRiskData.OccupationName
    ) {
      helpers.setError(
        'Sorry we need you to select an occupation from the list. Please try again.',
      );
    }
  }, [meta.error, meta.value, occupationRiskData, meta.touched]);

  return (
    <>
      <TextField
        name="occupation"
        placeholder="I am a..."
        onBlur={handleFieldBlur}
        onFocus={handleFieldInteraction}
        onChange={(e) => handleChange(e)}
      />
      {editing && occupationList?.length > 0 && field.value && (
        <AutoComplete
          id="Occupation"
          searchTerm={field.value}
          onClick={handleOccupationSelect}
          options={occupationList}
        />
      )}
    </>
  );
};

export default Occupation;
