import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useFormikContext } from 'formik';
import { MotorcycleFormData } from 'components/@forms/DriverDetails/MotorcycleForm/MotorcycleForm.types';
import Button from 'components/@common/Button';
import { LICENCE_REGEXP } from 'services/constants';
import { ManualBuilderLink, PreviousRiddenInput } from './styles'
import  vehicleApi from '../../api/vehicleApi';
import { requestPreviousManualBuilder, requestPreviousVehicleSuccess, requestPreviousVehicleFailed } from 'containers/Vehicle/actions';
import { VehicleParagraph } from 'containers/Vehicle/styles';
import FormField from 'components/@common/FormField';
import { useVehicle } from 'hooks';
import { COMMERCIAL_VEHICLE, PRIVATE_CAR } from 'containers/Vehicle/constants';
import { ErrorMessage } from 'components/common/ErrorMessage';

const PreviousRiddenMotorcycle: React.FC = () => {
  const dispatch = useDispatch();
  const { setFieldValue, setFieldError, setFieldTouched, values, touched, errors } = useFormikContext<MotorcycleFormData>();
  const [isValidReg, setValidReg] = useState<boolean>(false); 
  const [regSearched, setRegSearched] = useState<boolean>(false);
  const { previousVehicle } = useVehicle();  

  const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    // Clear out field value and error for searched
    setFieldValue('previousRiddenVehicleSearched', false, true);
    setFieldValue('previousRiddenVehicleFound', false, true);
    setFieldValue('previousRiddenVehicle', event.currentTarget.value, false);
    setFieldError('previousRiddenVehicle', '');
    setFieldTouched('previousRiddenVehicle', true);
    setValidReg(!!LICENCE_REGEXP.test(event.currentTarget.value));

    // added so the vehicle make and model are removed when the reg changes
    previousVehicle.VehicleFound = false;
    if (touched.previousRiddenVehicle && previousVehicle.RegistrationNumber && previousVehicle.RegistrationNumber !== event.currentTarget.value) {
      setFieldTouched('previousRiddenVehicle', false, false);
    }
  };

  const searchPreviousVehicle = async () => {
    try {
      setRegSearched(true);
      setFieldValue('previousRiddenVehicleSearched', true, true);
      const previousVehicleRequest = await vehicleApi.getVehicle(`api/vehicles/registrationNumber/${values.previousRiddenVehicle}`)
      setFieldError('previousRiddenVehicle', '');
    
      if (previousVehicleRequest.data.VehicleType === COMMERCIAL_VEHICLE || previousVehicleRequest.data.VehicleType === PRIVATE_CAR) {
        setRegSearched(false);
        
        setFieldError('previousRiddenVehicle', 'This registration is not for a motorcycle.')
        
        // Added to set previousRiddenVehicleSearched error state if the search fails 
        setFieldValue('previousRiddenVehicleSearched', false, true);
        previousVehicle.VehicleFound = false;
        return dispatch(requestPreviousVehicleFailed(values.previousRiddenVehicle, 'NotFound'));
      }
      dispatch(requestPreviousVehicleSuccess(previousVehicleRequest.data));
    } catch (e) {
      setRegSearched(false);
      dispatch(requestPreviousVehicleFailed(values.previousRiddenVehicle, true));
    };
  };

  return (
    <FormField 
      name='previousRiddenVehicle' 
      label="Previous ridden vehicle"
    >
      <PreviousRiddenInput
        id='RegistrationInput'
        placeholder='My previous reg is...'
        onChange={handleChange}
        name='RegistrationInput'
        value={values.previousRiddenVehicle}
        autoComplete='off'
        autoCorrect='off'
      />
      {previousVehicle.VehicleFound && previousVehicle.Make && previousVehicle.Model && (
        <VehicleParagraph>
          Vehicle:&nbsp;
          {previousVehicle.Make}
          {' '}
          {previousVehicle.Model}
        </VehicleParagraph>
      )}
      {previousVehicle.error && previousVehicle.error !== 'NotFound' && !errors.previousRiddenVehicle && (
        <ManualBuilderLink onClick={() => dispatch(requestPreviousManualBuilder())}>Couldn't find a motorcycle for this registration?</ManualBuilderLink>
      )}
      <Button
        type='button'
        onClick={() => searchPreviousVehicle()}
        disabled={!isValidReg || regSearched}>
        Search
      </Button>
      {!errors.previousRiddenVehicle && errors.previousRiddenVehicleSearched && <ErrorMessage>{errors.previousRiddenVehicleSearched}</ErrorMessage>}
    </FormField>
  );
};

export default PreviousRiddenMotorcycle;
