import styled from 'styled-components';

export const VehicleButtonWrapper = styled.div`
  text-align: center;
`

export const VehicleTypeParagraph = styled.p`
  font-family: ${props => props.theme.labelFontFamily};
  font-size: 16px;
  text-align: center;
`

export const HyperlinkButton = styled.a`
  color: #264b94 !important;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`

export const VehicleParagraph = styled.p`
  font-family: ${props => props.theme.labelFontFamily};
  font-size:${props => props.theme.labelFontSize};
  text-align: center;
`