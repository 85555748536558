import defaultTheme from "./default";

let brightsideTheme = {...defaultTheme};   
const primaryColour = '#ff7514';
const secondaryColour = '#007B50';
brightsideTheme.primaryColour = primaryColour;
brightsideTheme.primaryTextColour = '#333';
brightsideTheme.headerFontColour = primaryColour;
brightsideTheme.breadcrumb = {
  ...defaultTheme.breadcrumb,
    activeColor: "#ffffff",
    activeBorderColor: primaryColour,
    defaultColor: "#ffffff",
    defaultBorderColor: "#CCCCCC",
    defaultBarColor: "#CCCCCC",
    completeColor: primaryColour,
    completeBorderColor: primaryColour,
    completeBarColor: primaryColour
  }
  brightsideTheme.spinner = {
    ...defaultTheme.spinner,
    primaryColour: primaryColour,
    textColour: primaryColour
  }
  brightsideTheme.durationOptions ={
    ...defaultTheme.durationOptions,
    primaryColour: primaryColour,
    secondaryColour: secondaryColour
  }
  brightsideTheme.button = {
    ...defaultTheme.button,
      backgroundColour: secondaryColour
  }
  brightsideTheme.header = {
    ...defaultTheme.header,
    background: primaryColour,
    borderBottom: "none",
  }
    brightsideTheme.headerLinkStyled={
      ...defaultTheme.headerLinkStyled,
      padding: "0px",
      hover: {
        textDecoration: "underline",
        color: '#fff',
        backgroundColour: primaryColour,
        padding: "0px"
      }
    }
  
  brightsideTheme.gradient = {
    ...defaultTheme.gradient,
    background: '#FDECC8'
  }
  brightsideTheme.icon = {
    ...defaultTheme.icon,
    color: primaryColour,
    backgroundColor: '#E9F0FF'
  }
  brightsideTheme.GbIcon = '#fff';
  brightsideTheme.formControlValidIcon = {
    ...defaultTheme.formControlValidIcon,
    colour: '#fff',
    backgroundColour: primaryColour
  }
  brightsideTheme.headerFontColour = primaryColour;
  brightsideTheme.inputBorderColour = '#a8a8a8';
  brightsideTheme.successColour = primaryColour;
  brightsideTheme.marketingPreferencesColour = secondaryColour;
  brightsideTheme.svg = {
    ...defaultTheme.svg,
    primaryColour: '#fe7a12',
    accentColour: '#FFC59C',
  }

export default brightsideTheme;
