import Button from 'components/@common/Button';
import { H4 } from 'components/common/H4';
import styled from 'styled-components';

export const KlarnaButtonStyled = styled(Button)`
    background-color: #ffb3c7;
    width: 180px;
    ::hover {
        background-color: #f0a5b7;
    }
`
