import { REQUEST_FEATURE_TOGGLES_SUCCESS } from "../constants";
import initialState from '../../../initialState';

const mapFeatureToggles = (featureToggles) => {
  return featureToggles.reduce(function(output, toggle) {
    output[toggle.Name] = toggle.Enabled;
    return output;
}, {})
}

function featureTogglesReducer(state = initialState.features, action) {
  switch (action.type) {
    case REQUEST_FEATURE_TOGGLES_SUCCESS:
      const { SystemApplicationFeatures } = action.featureToggles;
      return mapFeatureToggles(SystemApplicationFeatures);
    default:
      return state;
  }
}

export default featureTogglesReducer;