import React, { PropsWithChildren, ReactNode, useRef, useState } from 'react'
import { Redirect, useHistory } from 'react-router-dom'
import { getCookie, CA_LOGGED_IN_COOKIE } from 'utils/cookie-helpers'
import { UserManager, UserManagerSettings } from 'oidc-client-ts'
import config from 'config'
import VehicleCarousel from 'components/VehicleCarousel'
import { NewVehicleDetails } from 'components/VehicleDetail/VehicleDetail.types'
import Form from 'components/@templates/Form'
import { QuoteParamsSchema } from 'components/@forms/DriverDetails/SubForms/QuoteParametersForm/QuoteParametersForm.validation'
import { IdentityFormData } from 'components/@forms/DriverDetails/SubForms/IdentityCheckForm/IdentityCheck.types'
import {
  useDuration,
  useVehicle,
  useScheme,
  useIdentity,
  useConfusedQuickQuote,
} from 'hooks'
import { InitialStateType } from 'initialState'
import { useSelector, useDispatch } from 'react-redux'
import { updatePostcode } from 'containers/Address/actions'
import moment from 'moment'
import {
  updateQuote,
  updateRiskData,
  updateValidation,
} from 'containers/RiskDataCollectionPage/actions'
import {
  BaseDriverFormData,
  DriverFormData,
  DurationFormData,
  FullDriverFormData,
  PolicyStartDateFormData,
} from 'components/@forms/DriverDetails/DriverDetails.types'
import GridContainerWrapper from 'components/@common/GridContainerWrapper'
import { Grid } from 'unsemantic'
import CustomerAccountApi, {
  SaveCustomerRegistrationRequest,
  SaveNewOccupationRequest,
} from 'api/customerAccount'
import { useQuery } from 'react-query'
import CustomerDriverDetails from 'components/@forms/CustomerDriverDetails'
import { ReasonForPurchaseSchema } from 'components/@forms/DriverDetails/@CommonFields/ReasonForPurchase/ReasonForPurchase.validation'
import { QuoteJourneyPosition, Scheme } from 'types/global'
import {
  clearPrice,
  setQuoteJourneyPosition,
  updateConfusedQuickQuoteOptIn,
  updateDurationValue,
} from 'containers/App/actions'
import { ConfusedQuickQuoteSchema } from 'components/@forms/DriverDetails/@CommonFields/ConfusedQuickQuoteOptIn/ConfusedQuickQuoteOptIn.validation'
import { updateVehicleValue } from 'containers/Vehicle/actions'
import { PrivateCarFormData } from 'components/@forms/DriverDetails/PrivateCarForm/PrivateCarForm.types'
import { CommercialVehicleFormData } from 'components/@forms/DriverDetails/CommercialVehicleForm/CommercialVehicleForm.types'

import './styles.css'
import { CustomerCommercialVehicleSchema } from 'components/@forms/DriverDetails/CommercialVehicleForm/CommercialVehicleForm.validation'
import { useSaveNewOccupationToCustomer } from 'components/Occupation/services/Occupation'
import { IDENTITY_ENUM, SchemeEnum } from '@enums'
import {
  DrivingLicenceSchema,
  PassportSchema,
} from 'components/@forms/DriverDetails/SubForms/IdentityCheckForm/IdentityCheck.validation'
import { useSaveNewDrivingLicenceToCustomer } from 'components/DrivingLicenceNumber/services/DrivingLicenceNumber'

interface LoggedInProviderProps {
  children?: ReactNode
}

const LoggedInProvider = ({
  children,
}: PropsWithChildren<LoggedInProviderProps>) => {
  const loggedInCookie = getCookie(CA_LOGGED_IN_COOKIE)

  if (loggedInCookie) {
    const cookieSessionObject = JSON.parse(loggedInCookie)
    if (!cookieSessionObject) {
      return <Redirect to='/quote/driver-details' />
    }
  } else {
    return <Redirect to='/quote/driver-details' />
  }
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>
}

// Preventing return from submitting the form
const checkKeyDown = (e: React.KeyboardEvent<HTMLFormElement>) => {
  if (e.key === 'Enter') e.preventDefault()
}

const LoggedIn = () => {
  const { scheme, changeScheme } = useScheme()
  const dispatch = useDispatch()
  const { identityCheck, licenceNumber } = useIdentity()
  // const { identityCheck } = useIdentity()
  const history = useHistory()
  const queryString: string = useSelector(
    (state: InitialStateType) => state.queryString,
  )
  const vehicleData = useSelector(
    (state: InitialStateType) => state.vehicle.selected,
  )

  const [formSubmitted, updateFormSubmitted] = useState(false)

  const authConfig: UserManagerSettings = {
    authority: config.CUSTOMER_ACCOUNT_ID_SERVER_URL,
    client_id: 'teslaClient',
    redirect_uri: `${config.HOST_PATH}/auth/callback`,
    response_type: 'code',
    filterProtocolClaims: true,
    loadUserInfo: true,
    scope: 'openid profile api',
    post_logout_redirect_uri: config.SITE_URL,
  }

  const mgr = new UserManager(authConfig)

  // const SigninOidc = () => {
  //   mgr.signinRedirect()
  // }

  const { data: vehicles } = useQuery('getVehicles', () =>
    CustomerAccountApi.getCustomerVehicles(),
  )

  const { data: customerDetails } = useQuery('getCustomerDetails', () =>
    CustomerAccountApi.getCustomerDetails(),
  )

  const riskData = useSelector((state: InitialStateType) => state.riskData)

  const { duration, durationType } = useDuration()
  const { vehicleValue } = useVehicle()
  const driverDetailsData = useSelector(
    (state: InitialStateType) => state.riskData,
  )
  const postcode = useSelector(
    (state: InitialStateType) => state.address.Postcode,
  )

  const durationValues: DurationFormData = {
    duration,
    durationType,
  }

  const saveNewOccupationMutation = useSaveNewOccupationToCustomer()
  const saveNewDrivingLicenceToCustomerMutation =
    useSaveNewDrivingLicenceToCustomer()

  const {
    showConfusedQuickQuoteOptIn,
    confusedQuickQuote,
    validReasonsForPurchase,
  } = useConfusedQuickQuote()

  // const saveNewPassportMutation = useSaveNewPassportDetailsToCustomer()

  const startDateTimeValues: PolicyStartDateFormData = {
    policyStartTime: driverDetailsData.PolicyStartDate.manuallyEdited
      ? driverDetailsData.PolicyStartDate.value || ''
      : '',
    manuallyChanged: driverDetailsData.PolicyStartDate.manuallyEdited,
  }

  let validSchema = QuoteParamsSchema
  // validSchema = validSchema.concat(privateCarSchema)
  validSchema = validSchema.concat(ReasonForPurchaseSchema)

  if (scheme !== 'VehiclePrivateCarBaseScheme') {
    validSchema = validSchema.concat(CustomerCommercialVehicleSchema)
  }

  let identityValues: IdentityFormData = {}

  switch (identityCheck) {
    case IDENTITY_ENUM.DRIVING_LICENCE:
      identityValues = {
        drivingLicenceNumber: licenceNumber,
      }
      validSchema = validSchema.concat(DrivingLicenceSchema)
      break
    case IDENTITY_ENUM.PASSPORT:
      identityValues = {
        passportLineOne: driverDetailsData.PassportLineOne,
        passportLineTwo: driverDetailsData.PassportLineTwo,
        passportExpiryDate: driverDetailsData.PassportExpiryDate || undefined,
      }
      validSchema = validSchema.concat(PassportSchema)
      break
    default:
      break
  }

  let initialValues: BaseDriverFormData = {
    firstName: driverDetailsData.Forename,
    surname: driverDetailsData.Surname,
    title: driverDetailsData.Title.value,
    dateOfBirth: driverDetailsData.DateOfBirth
      ? moment(driverDetailsData!.DateOfBirth).format('DD/MM/YYYY')
      : '',
    vehicleValue,
    postcode,
    addressKey: driverDetailsData.AddressKey,
    emailAddress: driverDetailsData.EmailAddress,
    mobile: driverDetailsData.Mobile,
  }

  if (showConfusedQuickQuoteOptIn) {
    const isValidReasonForPurchase = validReasonsForPurchase.includes(
      driverDetailsData.ReasonForPurchase?.value || '',
    )
    initialValues = {
      ...initialValues,
      confusedQuickQuoteOptIn: isValidReasonForPurchase
        ? `${confusedQuickQuote?.OptIn}`
        : '',
    } as PrivateCarFormData
    if (isValidReasonForPurchase) {
      validSchema = validSchema.concat(ConfusedQuickQuoteSchema)
    }
  }

  // @ts-ignore
  const formData: FullDriverFormData<DriverFormData> = {
    ...durationValues,
    ...startDateTimeValues,
    ...identityValues,
    ...initialValues,
    reasonForPurchase: driverDetailsData.ReasonForPurchase?.value,
    reasonForPurchaseOtherText: driverDetailsData.ReasonForPurchaseOtherText,
  }

  const handleSubmit = async (data: FullDriverFormData<DriverFormData>) => {
    updateFormSubmitted(true)
    dispatch(
      setQuoteJourneyPosition(
        QuoteJourneyPosition.CustomerAccountDetailsSubmit,
      ),
    )
    // dispatch(isButtonDisabled({ buttonName: 'driverDetails', disabled: true }))
    // if (!(affiliate && affiliate.MarketingPreferencesDisabled)) {
    //   dispatch(saveMarketingPreferences())
    // }

    dispatch(clearPrice())
    let payload = {}

    const durationData = {
      value: data.duration,
      type: data.durationType,
    }

    const policyStartData = {
      PolicyStartDate: {
        value: data.policyStartTime,
        manuallyEdited: data.manuallyChanged,
      },
    }

    const privateCarData = { ...(data as PrivateCarFormData) }

    const commercialVehicleData = {
      ...(data as CommercialVehicleFormData),
    }

    const identityData = {
      ...(data as IdentityFormData),
    }

    const occupationSchemes: Scheme[] = [
      'VehicleCommercialVehicleBaseScheme',
      'VehicleLearnerScheme',
    ]

    // Return if the occupation doesn't match what is in redux
    // We had issues previously with desyncing the occupation and redux
    // TODO: Remove this when we rewrite the redux logic

    // if (
    //   occupationSchemes.indexOf(scheme as Scheme) > -1 &&
    //   commercialVehicleData.occupation !== riskData.Occupation.OccupationName
    // ) {
    //   // dispatch(
    //   //   isButtonDisabled({ buttonName: 'driverDetails', disabled: false }),
    //   // )
    //   return
    // }

    if (
      commercialVehicleData.useOfVehicle &&
      commercialVehicleData.useOfVehicle === 'CourierOrHaulage'
    ) {
      await changeScheme(SchemeEnum.COURIER)
    }

    switch (scheme) {
      case 'VehiclePrivateCarBaseScheme':
        payload = {
          ...policyStartData,
          Forename: customerDetails?.data.forename,
          Surname: customerDetails?.data.surname,

          AddressKey: customerDetails?.data.addressKey,
          DateOfBirth: moment(customerDetails?.data.dateOfBirth).format(
            'yyyy-MM-DD',
          ),
          EmailAddress: customerDetails?.data.email,
          Mobile: customerDetails?.data.telephoneNumber,
          LicenceType: {
            ...riskData.LicenceType,
            value: customerDetails?.data.licenceType,
          },
          LicenceHeldDuration: {
            ...riskData.LicenceHeldDuration,
            value: customerDetails?.data.licenceHeldDurationDescription,
          },

          // DrivingLicenceNumber: customerDetails?.data.licenceNumber!.substring(
          //   12,
          //   16,
          // ),
          // DrivingLicenceNumberFirstPart:
          //   customerDetails?.data.licenceNumber!.substring(0, 12),
          Title: {
            ...riskData.Title,
            value: customerDetails?.data.title,
          },
          ResidencyType: {
            ...riskData.ResidencyType,
            value: customerDetails?.data.residencyTypeDescription,
          },
          // UKResidencyDuration: {
          //   ...riskData.UkResidencyDuration,
          //   value: customerDetails?.data.ukResidencyDuration,
          // },
          ReasonForPurchase: {
            ...riskData.ReasonForPurchase,
            value: privateCarData.reasonForPurchase,
          },
          ReasonForPurchaseOtherText: privateCarData.reasonForPurchaseOtherText,
        }

        if (
          customerDetails?.data.licenceNumber &&
          customerDetails?.data.licenceType === 'FullUkLicence'
        ) {
          payload = {
            ...payload,
            DrivingLicenceNumber:
              customerDetails?.data.licenceNumber!.substring(12, 16),
            DrivingLicenceNumberFirstPart:
              customerDetails?.data.licenceNumber!.substring(0, 12),
          }
        }

        break
      case 'VehicleCommercialVehicleBaseScheme':
        payload = {
          ...policyStartData,
          Forename: customerDetails?.data.forename,
          Surname: customerDetails?.data.surname,

          AddressKey: customerDetails?.data.addressKey,
          DateOfBirth: moment(customerDetails?.data.dateOfBirth).format(
            'yyyy-MM-DD',
          ),
          EmailAddress: customerDetails?.data.email,
          Mobile: customerDetails?.data.telephoneNumber,
          LicenceType: {
            ...riskData.LicenceType,
            value: customerDetails?.data.licenceType,
          },
          LicenceHeldDuration: {
            ...riskData.LicenceHeldDuration,
            value: customerDetails?.data.licenceHeldDuration,
          },
          // DrivingLicenceNumber: customerDetails?.data.licenceNumber!.substring(
          //   12,
          //   16,
          // ),
          // DrivingLicenceNumberFirstPart:
          //   customerDetails?.data.licenceNumber!.substring(0, 12),
          Title: {
            ...riskData.Title,
            value: customerDetails?.data.title,
          },
          ResidencyType: {
            ...riskData.ResidencyType,
            value: customerDetails?.data.residencyType,
          },
          // UKResidencyDuration: {
          //   ...riskData.UkResidencyDuration,
          //   value: customerDetails?.data.ukResidencyDuration,
          // },
          UseOfVehicle: {
            ...riskData.UseOfVehicle,
            value: commercialVehicleData.useOfVehicle,
          },
          BodyType: {
            ...riskData.BodyType,
            value: vehicleData.BodyType,
          },
          Occupation:
            driverDetailsData.Occupation.Id > 0
              ? driverDetailsData.Occupation
              : {
                  Id: customerDetails?.data.occupationId,
                  OccupationCode: customerDetails?.data.occupationCode,
                  OccupationName: customerDetails?.data.occupationName,
                },
          ReasonForPurchase: {
            ...riskData.ReasonForPurchase,
            value: privateCarData.reasonForPurchase,
          },
          ReasonForPurchaseOtherText: privateCarData.reasonForPurchaseOtherText,
        }

        if (
          customerDetails?.data.licenceNumber &&
          customerDetails?.data.licenceType === 'FullUkLicence'
        ) {
          payload = {
            ...payload,
            DrivingLicenceNumber:
              customerDetails?.data.licenceNumber!.substring(12, 16),
            DrivingLicenceNumberFirstPart:
              customerDetails?.data.licenceNumber!.substring(0, 12),
          }
        }

        break
      case 'VehicleCommercialVehicleCourierScheme':
        payload = {
          ...policyStartData,
          Forename: customerDetails?.data.forename,
          Surname: customerDetails?.data.surname,

          AddressKey: customerDetails?.data.addressKey,
          DateOfBirth: moment(customerDetails?.data.dateOfBirth).format(
            'yyyy-MM-DD',
          ),
          EmailAddress: customerDetails?.data.email,
          Mobile: customerDetails?.data.telephoneNumber,
          LicenceType: {
            ...riskData.LicenceType,
            value: customerDetails?.data.licenceType,
          },
          LicenceHeldDuration: {
            ...riskData.LicenceHeldDuration,
            value: customerDetails?.data.licenceHeldDuration,
          },
          // DrivingLicenceNumber: customerDetails?.data.licenceNumber!.substring(
          //   12,
          //   16,
          // ),
          // DrivingLicenceNumberFirstPart:
          //   customerDetails?.data.licenceNumber!.substring(0, 12),
          Title: {
            ...riskData.Title,
            value: customerDetails?.data.title,
          },
          ResidencyType: {
            ...riskData.ResidencyType,
            value: customerDetails?.data.residencyType,
          },
          // UKResidencyDuration: {
          //   ...riskData.UkResidencyDuration,
          //   value: customerDetails?.data.ukResidencyDuration,
          // },
          BodyType: {
            ...riskData.BodyType,
            value: vehicleData.BodyType,
          },
          Occupation:
            driverDetailsData.Occupation.Id > 0
              ? driverDetailsData.Occupation
              : {
                  Id: customerDetails?.data.occupationId,
                  OccupationCode: customerDetails?.data.occupationCode,
                  OccupationName: customerDetails?.data.occupationName,
                },
        }
        if (
          customerDetails?.data.licenceNumber &&
          customerDetails?.data.licenceType === 'FullUkLicence'
        ) {
          payload = {
            ...payload,
            DrivingLicenceNumber:
              customerDetails?.data.licenceNumber!.substring(12, 16),
            DrivingLicenceNumberFirstPart:
              customerDetails?.data.licenceNumber!.substring(0, 12),
          }
        }
        break
      default:
        // TODO: What to do in this case? Just return?
        break
    }

    if (
      driverDetailsData.Occupation.Id > 0 &&
      customerDetails?.data.occupationId! <= 0
    ) {
      const saveNewOccupationRequest: SaveNewOccupationRequest = {
        occupationId: driverDetailsData.Occupation.Id,
      }

      saveNewOccupationMutation.mutate(saveNewOccupationRequest)
    }

    // if (identityData.passportLineOne && !customerDetails?.data.passportLine1) {
    //   const saveNewPassportRequest: SavePassportDetailsRequest = {
    //     passportLine1: identityData.passportLineOne,
    //     passportLine2: identityData.passportLineTwo?.toString(),
    //     passportDate: identityData.passportExpiryDate?.toString(),
    //   }

    //   saveNewPassportMutation.mutate(saveNewPassportRequest)
    // }

    switch (identityCheck) {
      case 'drivingLicence':
        {
          payload = {
            ...payload,
            DrivingLicenceNumber: identityData.drivingLicenceNumber!.substring(
              12,
              16,
            ),
            DrivingLicenceNumberFirstPart:
              identityData.drivingLicenceNumber!.substring(0, 12),
          }

          const saveDrivingLicenceRequest: SaveCustomerRegistrationRequest = {
            drivingLicenceNumber: identityData.drivingLicenceNumber!,
          }

          saveNewDrivingLicenceToCustomerMutation.mutate(
            saveDrivingLicenceRequest,
          )
        }
        break
      case 'passport':
        payload = {
          ...payload,
          PassportLineOne: identityData.passportLineOne,
          PassportLineTwo: identityData.passportLineTwo,
          PassportExpiryDate: identityData.passportExpiryDate,
        }
        break
      default:
        break
    }

    if (
      scheme === 'VehiclePrivateCarBaseScheme' &&
      privateCarData.confusedQuickQuoteOptIn !== null &&
      validReasonsForPurchase.includes(riskData.ReasonForPurchase?.value || '')
    ) {
      dispatch(
        updateConfusedQuickQuoteOptIn(
          privateCarData.confusedQuickQuoteOptIn === 'true',
        ),
      )
    } else {
      dispatch(updateConfusedQuickQuoteOptIn(null))
    }

    dispatch(updateDurationValue(durationData))
    dispatch(updateRiskData(payload))
    // TODO: Re-evaluate this with new form logic later in the flow. As this is a bit hacky.
    // Manually updating validation for all fields as identity checks weren't progressing
    // due to not using new form logic
    dispatch(
      updateValidation({
        AddressError: '',
        BodyTypeError: '',
        DateOfBirthError: '',
        DrivingLicenceNumberError: '',
        EmailAddressError: '',
        ForenameError: '',
        HasVehicleGotAnnualInsuranceEnumerationError: '',
        LicenceHeldDurationError: '',
        LicenceTypeError: '',
        MobileError: '',
        OccupationError: '',
        OwnerOfVehicleError: '',
        PassportExpiryDateError: '',
        PassportLineOneError: '',
        PassportLineTwoError: '',
        PostcodeError: '',
        ResidencyTypeError: '',
        SurnameError: '',
        TitleError: '',
        UkResidencyDurationError: '',
        UseOfVehicleError: '',
      }),
    )
    dispatch(
      updateVehicleValue({
        ...vehicleData.vehicleValue,
        value: data.vehicleValue,
      }),
    )
    dispatch(updatePostcode(data.postcode))
    dispatch(
      updateQuote({
        partialValidation: true,
        getPrice: true,
        callback: () => {
          history.push(`/quote/driving-licence${queryString}`)
        },
      }),
    )
  }

  return (
    <div>
      <LoggedInProvider />
      {vehicles && (
        <>
          <div className='your-garage-titles'>
            <h2>Select a vehicle from your garage</h2>
            <p>Select a vehicle from your garage</p>
          </div>
          <VehicleCarousel
            key='VehicleCarouselComponent'
            carouselItems={vehicles.data.customerVehicle as NewVehicleDetails[]}
            includeNewVehicle
          />
        </>
      )}
      <GridContainerWrapper>
        <Grid>
          <Form
            initialValues={formData}
            validationSchema={validSchema}
            onSubmit={(data) => handleSubmit(data)}
            onKeyDown={(e) => checkKeyDown(e)}
          >
            {customerDetails && (
              <CustomerDriverDetails
                customerDetails={customerDetails.data}
                formSubmitted={formSubmitted}
              />
            )}
          </Form>
        </Grid>
      </GridContainerWrapper>
    </div>
  )
}
export default LoggedIn
