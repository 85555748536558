import React from 'react'
import Button from 'components/@common/Button'
import config from 'config'
import { CA_LOGGED_IN_COOKIE, getCookie } from 'utils/cookie-helpers'
import { I, IconWrapper } from 'containers/PolicyConfirmationPage/styles'
import useCustomerAccountFeatureFlag from 'hooks/useCustomerAccountFeatureFlag'
import useCheckQuoteHasCustomer from 'hooks/useCheckQuoteHasCustomer'

import { StyledCustomerAccountQuote } from './CustomerAccountQuote.styles'

export interface Props {
  quoteId: string
}

const CustomerAccountQuote: React.FC<Props> = ({ quoteId }) => {
  // const { data: featureFlagEnabled, isLoading: featureFlagLoaded } =
  //   useCustomerAccountFeatureFlag('RegisterFromQuote')
  const loggedInCookie = getCookie(CA_LOGGED_IN_COOKIE)

  let loggedIn = false
  if (loggedInCookie && JSON.parse(loggedInCookie)) {
    loggedIn = true
  }

  const {
    query: {
      data: checkQuoteHasCustomerData,
      isLoading: quoteHasCustomerLoading,
    },
  } = useCheckQuoteHasCustomer(quoteId, loggedIn)

  const featureFlagLoaded = false
  const featureFlagEnabled = { data: true }

  const createAccountClick = () => {
    window.location.href = `${config.CUSTOMER_ACCOUNT_URL_HOME}register/create-account?qId=${quoteId}`
  }

  const myAccountClick = () => {
    window.location.href = `${config.CUSTOMER_ACCOUNT_URL_HOME}register/create-account?qId=${quoteId}`
  }

  const signInClick = () => {
    window.location.href = `${config.CUSTOMER_ACCOUNT_URL_HOME}login?qId=${quoteId}`
  }

  return (
    <>
      {!featureFlagLoaded && featureFlagEnabled && featureFlagEnabled.data && (
        <StyledCustomerAccountQuote>
          {(!loggedInCookie ||
            (loggedInCookie &&
              !quoteHasCustomerLoading &&
              checkQuoteHasCustomerData &&
              checkQuoteHasCustomerData?.data &&
              !checkQuoteHasCustomerData?.data.accountExists)) && (
            <div>
              <h2>Create your account</h2>
              <ul>
                <li>
                  <IconWrapper>
                    <I className='fas fa-check-circle' />
                    Faster checkout
                  </IconWrapper>
                </li>
                <li>
                  <IconWrapper>
                    <I className='fas fa-check-circle' />
                    View all your policies and details in one place
                  </IconWrapper>
                </li>
                <li>
                  <IconWrapper>
                    <I className='fas fa-check-circle' />
                    Access all your policy documents quickly and easily
                  </IconWrapper>
                </li>
              </ul>
              <Button type='button' onClick={createAccountClick}>
                Create account
              </Button>
            </div>
          )}
          {loggedInCookie && JSON.parse(loggedInCookie) && (
            <div style={{ marginTop: 24 }}>
              <Button type='button' onClick={myAccountClick}>
                My Account
              </Button>
            </div>
          )}

          {loggedInCookie &&
            !quoteHasCustomerLoading &&
            checkQuoteHasCustomerData &&
            checkQuoteHasCustomerData?.data &&
            checkQuoteHasCustomerData?.data.accountExists && (
              <div style={{ marginTop: 24 }}>
                <Button type='button' onClick={signInClick}>
                  Sign in
                </Button>
              </div>
            )}
        </StyledCustomerAccountQuote>
      )}
      <div />
    </>
  )
}

export default CustomerAccountQuote
