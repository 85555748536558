import styled from 'styled-components';

export const BaseSelect = styled.select`
    background: #FFF;
    border: 1px solid ${props => props.theme.inputBorderColour};
    border-radius: ${props => props.theme.formControlBorderRadius};
    padding: 0 ${props => props.theme.formControlHorizontalPadding};
    margin-bottom: ${props => props.theme.formControlBottomMargin};
    color: ${props => props.isValueInOptions ? props.theme.primaryTextColour : '#777'};
    font-size: ${props => props.theme.generalFontSize};
    height: ${props => props.theme.formControlHeight};
    width: 100%;
    font-family: ${props => props.theme.fontFamily};
    font-style: ${props => props.theme.fontStyle};
    font-weight: ${props => props.theme.fontWeight};

    &.placeholder,
    option[value=""] {
        color: #777;
    }

    option:not([value=""]) {
        color: ${props => props.theme.primaryTextColour};
    }
`;