import styled from 'styled-components';

export const Label = styled.label`
  font-size: 13px;
  color: #000;
`;

export const Value = styled.p`
  margin: 0;
  color: ${(props) => props.theme.primaryTextColour};
  font-weight: bold;
  font-size: 14px;
`;
