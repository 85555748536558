export const useOfVehicleOptions = [{
    id: 1,
    value: 'SocialDomesticAndPleasure',
    description: 'Social domestic and pleasure',
},
{
    id: 2,
    value: 'SocialDomesticAndPleasureIncludingCommuting',
    description: 'Social domestic and pleasure including commuting' 
}, 
{
    id: 3,
    value: 'CarriageOfMyOwnGoodsInConnectionWithMyBusiness',
    description: 'Carriage of my own goods in connection with my business'
},
{
    id: 4,
    value: 'CourierOrHaulage',
    description: 'Courier or haulage'
},
{
    id: 5,
    value: 'CarriageOfPassengersForHireAndReward',
    description: 'Carriage of passengers for hire and reward'
}];
    
export const useOfMotorcycleOptions = [
    {
        id: 2,
        value: 'SocialDomesticAndPleasureOnly',
        description: 'Social Domestic and Pleasure (SDP) only'
    },
    {
        id: 3,
        value: 'CommutingToWorkRequired',
        description: 'Commuting to work required'
    },
    {
        id: 4,
        value: 'BusinessUseRequired',
        description: 'Business use required'
    },
    {
        id: 6,
        value: 'CourierDeliveryUse',
        description: 'Courier/Delivery use',
    },
];