import { H2 } from 'components/common/H2';
import { P } from 'components/common/P';
import config from 'config';
import { useABExperiment, usePrice } from 'hooks';
import React, { useMemo } from 'react';
import {
  CrossedOutPrice,
  DiscountedPrice,
  DiscountedPriceWrapper,
  PriceDetailsWithUnderwriterWrapper,
  PriceDetailsWrapper,
  PriceInfoKlarnaWrapper, PriceWrapper,
  UnderwriterImageWrapper,
} from './PriceDetails.styles';

const PriceDetails = () => {
  const { price, coverTypeWording, totalPriceFixed } = usePrice();

  const affiliateDiscount = price.campaign.Discount;

  const positiveDiscountValue = useMemo(() => Math.abs(affiliateDiscount), [affiliateDiscount])

  const hasAffiliateDiscount = useMemo(() => positiveDiscountValue > 0, [positiveDiscountValue])

  const priceHeader = useMemo(() => {
    if (hasAffiliateDiscount) {
      return 'Your discounted price'
    }
    return 'Total Price'
  }, [hasAffiliateDiscount])

  const totalPriceDisplay = useMemo(() => {
    if (hasAffiliateDiscount) {
      return <CrossedOutPrice><del>£{(+totalPriceFixed + positiveDiscountValue).toFixed(2)}</del></CrossedOutPrice>
    }
    return <H2>£{totalPriceFixed}</H2>;
  }, [hasAffiliateDiscount, totalPriceFixed, positiveDiscountValue])

  const coverTypeFullWording = useMemo(() => {
    if (hasAffiliateDiscount) {
      return `${coverTypeWording} - includes £${positiveDiscountValue.toFixed(2)} discount`
    }
    return coverTypeWording;
  }, [coverTypeWording, hasAffiliateDiscount, positiveDiscountValue])

  const newTotalPrice = useMemo(() => {
    if (hasAffiliateDiscount) {
      return +totalPriceFixed;
    }
    return 0;
  }, [hasAffiliateDiscount, totalPriceFixed])

  let underwriterName = price.Underwriter;
  if (underwriterName === 'Kgm') {
    underwriterName = underwriterName.toUpperCase();
  }

  if (price.TotalPrice > 0 && price.Underwriter) {
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <PriceDetailsWithUnderwriterWrapper>
          <UnderwriterImageWrapper>
            <img
              // @ts-ignore
              src={require(`../../../../images/Underwriters/${underwriterName}LogoForUnderwriterSelection.png`)}
              alt={price.Underwriter}
              className='price-comparison-underwriter-logo'
            />
          </UnderwriterImageWrapper>
          <PriceInfoKlarnaWrapper>
            <PriceDetailsWrapper>
              <P>{priceHeader}</P>
              <PriceWrapper>
                {newTotalPrice > 0 && (
                  <DiscountedPriceWrapper>
                    <DiscountedPrice>£{newTotalPrice.toFixed(2)}</DiscountedPrice>
                  </DiscountedPriceWrapper>
                )}
                {totalPriceDisplay}
              </PriceWrapper>
              <P>{coverTypeFullWording}</P>
            </PriceDetailsWrapper>
          </PriceInfoKlarnaWrapper>
        </PriceDetailsWithUnderwriterWrapper>
      </div>
    );
  }
  return null;
};

export default PriceDetails;
