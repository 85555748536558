
import { RESET_PAYMENT_OPTIONS, HANDLE_MOBILE_VALIDATION, FRAUD_FAILURE, SET_PAYMENT_IN_PROGRESS, SET_PAYMENT_NOT_IN_PROGRESS, CLEAR_PAYMENT_ERROR, PRICE_MATCH, PRICE_CHECK_PAYMENT_PAGE } from './constants';

export function resetPaymentOptions(){
  return {
    type: RESET_PAYMENT_OPTIONS
  };
}

export function handleMobileValidation(message){
  return {
    type: HANDLE_MOBILE_VALIDATION,
    message
  }
}

export function fraudFailure() {
  return{
    type: FRAUD_FAILURE
  }
}

export function setPaymentInProgress(){
  return {
      type: SET_PAYMENT_IN_PROGRESS
  }
}

export function setPaymentNotInProgress(){
  return {
      type: SET_PAYMENT_NOT_IN_PROGRESS
  }
}

export function clearPaymentError() {
  return {
    type: CLEAR_PAYMENT_ERROR
  }
}


export function priceCheckPaymentPage(){
  return {
    type: PRICE_CHECK_PAYMENT_PAGE
  }
}

export function priceMatch(isPriceMatching) {
  return {
    type: PRICE_MATCH,
    isPriceMatching
  }
}