import styled from 'styled-components';
import { P } from 'components/common/P';


export const LegalText = styled(P)`
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: 620px;
    font-size:14px;
    font-family: ${props => props.theme.fontFamily};
    @media (max-width: 768px) {
        font-size:12px;
    }
`

export const BrandonText = styled(P)`
    font-size:22px;
    font-family: ${props => props.theme.headerFontFamily};
    color: ${props => props.theme.primaryTextColour};
    @media (max-width: 768px) {
        font-size:14px;
    }
`

export const TotalPriceText = styled(BrandonText)`
    font-size:44px;
    @media (max-width: 768px) {
        font-size:26px;
    }
`

export const PriceWrapper = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 5px;
  align-items: baseline;
  justify-content: center;

  p {
    margin: 0;
    line-height: 1em;
  }
  
  svg {
    color: ${(props) => props.theme.primaryColour};
  }
`


export const PriceBreakdownDiscount = styled(LegalText)`
    color: green;
    display: inline;
`
