import { useScheme } from 'hooks';
import React, { useMemo } from 'react'
import { Scheme } from 'types/global';
import { SchemeSwitchButton, SchemeSwitchIcon, SchemeSwitchInformationWrapper, SchemeSwitchText, SchemeSwitchWrapper } from './SchemeSwitchMessage.styles';

interface Props {
    scheme: Scheme;
    onClick?: () => void;
}

const SchemeSwitchMessage: React.FC<Props> = ({ scheme, onClick }) => {
    const { changeScheme } = useScheme()

    const handleClick = async () => {
        await changeScheme(scheme);
        if (onClick) {
            onClick();
        }
    }

    const schemeLabel = useMemo(() => {
        switch (scheme) {
            case 'VehicleCommercialVehicleCourierScheme':
                return 'courier';
            default:
                return '';
        }
    }, [scheme])

    return (
        <SchemeSwitchWrapper>
            <SchemeSwitchInformationWrapper>
                <SchemeSwitchIcon className='fa fa-exclamation-triangle' />
                <SchemeSwitchText>
                    We have different insurance that's designed specifically for {schemeLabel} drivers
                </SchemeSwitchText>
            </SchemeSwitchInformationWrapper>
            <div>
                <SchemeSwitchButton width={'95%'} type="button" onClick={handleClick}>
                    SEE {schemeLabel} INSURANCE
                </SchemeSwitchButton>
            </div>
        </SchemeSwitchWrapper>
    )
}

export default SchemeSwitchMessage
