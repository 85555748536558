/* eslint-disable max-len */
import config from 'config'
import React, { useCallback } from 'react'
import { useAddons, useComponentVisible, useScheme } from '../../../hooks'
import { LinkContainer } from '../Addons.styles'
import {
  AddonMoreInfoBody,
  AddonMoreInfoText,
  AddonMoreInfoTitle,
  AddonMoreInfoWrapper,
  PaymentPageLinkContainer,
  Wrapper,
} from './AddonMoreInfo.styles'
import AddonMoreInfomItem from './AddonMoreInfoItem'

interface Props {
  type: string
  isPaymentPage: boolean
}

export const AddonMoreInfo: React.FC<Props> = ({ type, isPaymentPage }) => {
  const { isComponentVisible, ref, setIsComponentVisible } =
    useComponentVisible(false)
  const { scheme } = useScheme()
  const {
    excess: { staticDocuments: excessDocuments },
    breakdown: { staticDocuments: breakdownDocuments },
    legalexpenses: { staticDocuments: legalexpensesDocuments },
  } = useAddons()

  const documentStartDate = new Date().toISOString().slice(0, 10)

  const handleClick = useCallback(() => {
    setIsComponentVisible(!isComponentVisible)
  }, [isComponentVisible])

  const AddonMoreInfoContent = () => {
    if (type === 'excess') {
      return (
        <>
          <AddonMoreInfoTitle>
            More info about excess reduction
          </AddonMoreInfoTitle>
          <AddonMoreInfoBody>
            <div>
              <AddonMoreInfoText>
                If you are unfortunate enough to make a claim under your short
                term motor insurance policy, you are responsible for the excess
                shown under the policy. You will need to pay this amount in
                order to get your vehicle repaired or replaced.
              </AddonMoreInfoText>
              <AddonMoreInfoText>
                In the event that the incident was your fault, partly your fault
                or the third party cannot be traced, then your excess reduction
                policy will enable you to recover most of the excess you have
                paid.
              </AddonMoreInfoText>
              <AddonMoreInfoText>
                You will need to provide evidence of the amount you have paid,
                or has been deducted from the settlement figure, to Coplus who
                manage all claims on our behalf; they will send you up to the
                full amount of cover under this policy.
              </AddonMoreInfoText>
              <AddonMoreInfoText>
                View full details of our excess reduction policy here:
              </AddonMoreInfoText>
            </div>
            <ul>
              <AddonMoreInfomItem
                content='Excess Reduction IPID'
                doc={`${config.BASE_API_URL}/api/scheme/${scheme}/documents/${excessDocuments.ipid}/versions/${documentStartDate}`}
              />
              <AddonMoreInfomItem
                content='Excess Reduction Policy Wording'
                doc={`${config.BASE_API_URL}/api/scheme/${scheme}/documents/${excessDocuments.policyWording}/versions/${documentStartDate}`}
              />
            </ul>
          </AddonMoreInfoBody>
        </>
      )
    }

    if (type === 'breakdown') {
      return (
        <>
          <AddonMoreInfoTitle>
            More info about UK Breakdown Cover
          </AddonMoreInfoTitle>
          <AddonMoreInfoBody>
            <div>
              <AddonMoreInfoText>
                UK Breakdown Cover provided by the AA, covers all aspects detailed
                below:
              </AddonMoreInfoText>
              <AddonMoreInfoText>
                Roadside: For customers who need assistance ¼ mile or more away
                from their home address as detailed in the motor insurance
                policy and recovery to a local repairer.
              </AddonMoreInfoText>
              <AddonMoreInfoText>
                At Home: For customers who need assistance in the event they
                breakdown at their home address as detailed in the motor
                insurance policy and recovery to a local repairer.
              </AddonMoreInfoText>
              <AddonMoreInfoText>
                National Recovery: For customers who if we are unable to repair
                the vehicle, need their vehicle to be recovered to a single
                destination of their choice.
              </AddonMoreInfoText>
              <AddonMoreInfoText>
                Please note, the vehicle you want to recover must be taxed and have a valid MOT. View full details of our breakdown cover here:
              </AddonMoreInfoText>
            </div>
            <ul>
              <AddonMoreInfomItem
                content='Breakdown IPID'
                doc={`${config.BASE_API_URL}/api/scheme/${scheme}/documents/${breakdownDocuments.ipid}/versions/${documentStartDate}`}
              />
              <AddonMoreInfomItem
                content='Breakdown Policy Wording'
                doc={`${config.BASE_API_URL}/api/scheme/${scheme}/documents/${breakdownDocuments.policyWording}/versions/${documentStartDate}`}
              />
            </ul>
          </AddonMoreInfoBody>
        </>
      )
    }

    if (type === 'legalexpenses') {
      return (
        <>
          <AddonMoreInfoTitle>
            More info about legal expenses cover
          </AddonMoreInfoTitle>
          <AddonMoreInfoBody>
            <div>
              <AddonMoreInfoText>
                If you are unfortunate enough to be involved in an accident that
                wasn’t your fault, this cover provides up to £100,000 in legal
                costs to help you recover any uninsured losses that occur; for
                example
              </AddonMoreInfoText>
              <AddonMoreInfoText>Loss of earnings</AddonMoreInfoText>
              <AddonMoreInfoText>Travel expenses</AddonMoreInfoText>
              <AddonMoreInfoText>Damage to personal effects</AddonMoreInfoText>
              <AddonMoreInfoText>
                Seek compensation for any injuries you or your passengers may
                have received as a result of the accident
              </AddonMoreInfoText>
              <AddonMoreInfoText>Towing and storage</AddonMoreInfoText>
              <AddonMoreInfoText>
                Attempt recovery of all your out of pocket expenses from any
                negligent third party
              </AddonMoreInfoText>
              <AddonMoreInfoText>
                The success of your claim will depend upon being able to prove
                that the third party was at fault.
              </AddonMoreInfoText>
            </div>
            <ul>
              <AddonMoreInfomItem
                content='Legal Expenses IPID'
                doc={`${config.BASE_API_URL}/api/scheme/${scheme}/documents/${legalexpensesDocuments.ipid}/versions/${documentStartDate}`}
              />
              <AddonMoreInfomItem
                content='Legal Expenses Policy Wording'
                doc={`${config.BASE_API_URL}/api/scheme/${scheme}/documents/${legalexpensesDocuments.policyWording}/versions/${documentStartDate}`}
              />
            </ul>
          </AddonMoreInfoBody>
        </>
      )
    }

    return (
      <>
        <AddonMoreInfoTitle>Failed to load information</AddonMoreInfoTitle>
        <div>
          <AddonMoreInfoText>Please try again.</AddonMoreInfoText>
        </div>
      </>
    )
  }

  return (
    <Wrapper ref={ref}>
      {isPaymentPage && (
        <PaymentPageLinkContainer
          id={`addon-detailslink-${type}`}
          onClick={handleClick}
        >
          Full details
        </PaymentPageLinkContainer>
      )}
      {!isPaymentPage && (
        <LinkContainer id={`addon-detailslink-${type}`} onClick={handleClick}>
          View full details <i className='fa fa-external-link-alt fa-xs' />
        </LinkContainer>
      )}
      {isComponentVisible && (
        <AddonMoreInfoWrapper id={`addon-detailsmodal-${type}`}>
          <AddonMoreInfoContent />
          <hr />
          <div>
            <PaymentPageLinkContainer
              id={`addon-closedetails-${type}`}
              onClick={() => setIsComponentVisible(false)}
            >
              Close
            </PaymentPageLinkContainer>
          </div>
        </AddonMoreInfoWrapper>
      )}
    </Wrapper>
  )
}

export default AddonMoreInfo
