import React from 'react';
import { withTheme } from 'styled-components';
import TextField from '../@common/TextField';

interface Props {
  theme: any;
}

const MobileIcon: React.FC<Props> = ({ theme }) => (
  <svg
    id="Group_250"
    data-name="Group 250"
    xmlns="http://www.w3.org/2000/svg"
    width="16.734"
    height="29.141"
    viewBox="0 0 16.734 29.141"
  >
    <path
      id="Path_244"
      data-name="Path 244"
      d="M-803.89,610.287H-816.3a2.1,2.1,0,0,1-2.163-2.164V583.31a2.1,2.1,0,0,1,2.163-2.164h12.407a2.1,2.1,0,0,1,2.164,2.164v24.813A2.1,2.1,0,0,1-803.89,610.287ZM-816.3,582.371a.874.874,0,0,0-.938.938v24.813a.874.874,0,0,0,.938.938h12.407a.875.875,0,0,0,.938-.938V583.31a.874.874,0,0,0-.938-.938Z"
      transform="translate(818.46 -581.146)"
      fill={theme.icon.color}
    />
    <path
      id="Path_245"
      data-name="Path 245"
      d="M-802.339,600.087h-15.508a.613.613,0,0,1-.613-.613.613.613,0,0,1,.613-.613h15.508a.613.613,0,0,1,.613.613A.613.613,0,0,1-802.339,600.087Z"
      transform="translate(818.46 -595.76)"
      fill={theme.icon.color}
    />
    <path
      id="Path_246"
      data-name="Path 246"
      d="M-802.339,715.246h-15.508a.613.613,0,0,1-.613-.613.613.613,0,0,1,.613-.613h15.508a.613.613,0,0,1,.613.613A.613.613,0,0,1-802.339,715.246Z"
      transform="translate(818.46 -690.757)"
      fill={theme.icon.color}
    />
    <path
      id="Path_247"
      data-name="Path 247"
      d="M-780.277,591.23h-3.1a.613.613,0,0,1-.613-.613.613.613,0,0,1,.613-.613h3.1a.613.613,0,0,1,.613.613A.613.613,0,0,1-780.277,591.23Z"
      transform="translate(790.026 -588.453)"
      fill={theme.icon.color}
    />
  </svg>
);

const MobileIconWithTheme = withTheme(MobileIcon);

interface MobileProps {
  onBlur?: () => void;
}

const MobileTelephone: React.FC<MobileProps> = ({ onBlur }) => (
  <TextField
    id="MobileNumber"
    onBlur={onBlur}
    type="tel"
    maxLength={12}
    name="mobile"
    placeholder="07..."
    icon={<MobileIconWithTheme />}
  />
);

export default MobileTelephone;
