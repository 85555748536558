import { BaseDriverFormData } from 'components/@forms/DriverDetails/DriverDetails.types';
import { useFormikContext, useField } from 'formik';
import { useAddressSearch } from 'hooks';

import React, {
  useCallback, useEffect, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { scroller } from 'react-scroll';
import {
  requestAddressSuccess,
  selectAddress,
} from '../../containers/Address/actions';
import { InitialStateType } from '../../initialState';
import { Option } from '../../types/global';
import { mapValueToOption } from '../../utils/map-value-to-option';
import Address from './Address';

const AddressContainer: React.FC = () => {
  const [addressSearchError, setAddressSearchError] = useState(false);
  const addressStore = useSelector(
    (state: InitialStateType) => state.address.SearchResults,
  );
  const [addressOptions, setAddressOptions] = useState<Option[]>(addressStore.map(
    (address: { AddressKey: string; Address: string }) => mapValueToOption(
      address.AddressKey,
      address.AddressKey,
      address.Address,
    ),
  ));
  const [isLoading, setIsLoading] = useState(false);

  const { setFieldValue } = useFormikContext<BaseDriverFormData>();
  const [field,, helpers] = useField('postcode');
  const postcodeValue = field.value;
  const [postcodeOnClick, setPostcodeOnClick] = useState(field.value);
  const {
    query: { refetch, status, isFetching },
  } = useAddressSearch(postcodeValue);

  const dispatch = useDispatch();

  const mapAddressesFromStore = useCallback(() => {
    if (addressStore?.length > 0) {
      const mappedAddresses = addressStore.map(
        (address: { AddressKey: string; Address: string }) => mapValueToOption(
          address.AddressKey,
          address.AddressKey,
          address.Address,
        ),
      );
      setAddressOptions(mappedAddresses);
    }
  }, [addressStore]);

  useEffect(() => {
    if (addressStore.length > 0) {
      mapAddressesFromStore();
    }
  }, [addressStore]);

  const handleSelect = (
    _fieldName: string,
    _fieldValue: string,
    label: string,
  ) => {
    dispatch(selectAddress(label));
  };

  const handleAddressResult = useCallback(
    ({ isSuccess, isError, data }) => {
      if (isSuccess) {
        const addressSummaries = data.data.AddressSummaries as {
          AddressKey: string;
          Address: string;
        }[];
        if (addressSummaries?.length > 0) {
          if (
            JSON.stringify(addressSummaries) !== JSON.stringify(addressStore)
          ) {
            dispatch(requestAddressSuccess(addressSummaries));
          } else {
            mapAddressesFromStore();
          }
          setAddressSearchError(false);

          scroller.scrollTo('AddressSelect', {
            smooth: true,
          });
        } else {
          setAddressOptions([]);
          setAddressSearchError(true);
          scroller.scrollTo('AddressError', {
            smooth: true,
          });
        }
      } else if (isError) {
        setAddressOptions([]);
        setAddressSearchError(true);
      }
    },
    [dispatch, addressStore, mapAddressesFromStore],
  );

  const handleClick = async () => {
    if (postcodeValue) {
      if (postcodeOnClick !== postcodeValue) {
        setPostcodeOnClick(postcodeValue);
        setFieldValue('addressKey', '');
      }
      refetch().then((response) => {
        handleAddressResult(response);
      });
    }
  };

  const handleChange = () => {
    // setIsPostcodeEdited(true);
    setFieldValue('addressKey', '');
    setAddressOptions([]);
  };

  const handleBlur = () => {
    if (postcodeValue !== postcodeOnClick) {
      // Clear out addresses on blur as rerendering issues occurring
      if (addressStore?.length > 0) {
        dispatch(requestAddressSuccess([]));
      }
    }
    helpers.setValue(field.value?.trim(), true);

    helpers.setTouched(true, false);
  };

  useEffect(() => {
    setIsLoading(status === 'loading' || isFetching);
  }, [status, isFetching]);

  return (
    <Address
      isLoading={isLoading}
      options={addressOptions}
      onClick={handleClick}
      onSelect={handleSelect}
      error={addressSearchError}
      onChange={handleChange}
      onBlur={handleBlur}
    />
  );
};

export default AddressContainer;
