import { H3 } from 'components/common/H3';
import styled from 'styled-components';

export const BannerWrapper = styled.div`
    background-color:  #779e00;

    display: flex;
    justify-content: center;
    padding-top: 12px;
    padding-bottom: 12px;
`

export const BannerText = styled(H3)`
    color: ${({ theme }) => theme.buttonTextColour};
    margin: 0;
    font-weight: bold;

    @media (max-width: 768px) {
        font-size: 12px;
    }
`