import { useSelector } from 'react-redux';
import { InitialStateType } from '../../initialState';

const useSystemFeatures = () => {
  const systemFeatures = useSelector((state: InitialStateType) => state.features);
  return {
    ...systemFeatures,
  };
};

export default useSystemFeatures;