/* eslint-disable max-len */
import {
  BreakdownCover,
  ExcessReduction,
  InitialStateType,
  LegalExpensesCover,
} from 'initialState'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

type AddonSelection = {
  // name of property to be sent in requests
  type:
    | 'BreakdownIncluded'
    | 'ExcessReductionIncluded'
    | 'LegalExpensesIncluded'
  selected: boolean
  wording: string
}

export const getAddonSelection = (
  state: InitialStateType,
): AddonSelection[] => [
  {
    type: 'BreakdownIncluded',
    selected: state.price.BreakdownCover.BreakdownIncluded,
    wording: 'UK Breakdown Cover',
  },
  {
    type: 'ExcessReductionIncluded',
    selected: state.price.ExcessReduction.ExcessReductionIncluded,
    wording: 'Excess Reduction',
  },
  {
    type: 'LegalExpensesIncluded',
    selected: state.price.LegalExpensesCover.LegalExpensesIncluded,
    wording: 'Legal Expenses',
  },
]

const getExcessReduction = (
  reducedExcessStoreData: ExcessReduction,
  compulsoryExcess: number,
) => {
  const handleReducedExcess = (
    compExcess: number,
    reductionExcess: number,
  ): string => {
    const reducedExcess = compExcess - reductionExcess
    const hasDecimalPlaces = reducedExcess - Math.floor(reducedExcess) !== 0

    if (hasDecimalPlaces) {
      return reducedExcess.toFixed(2)
    }

    return reducedExcess.toString()
  }

  const hasExcessAddonPremium =
    !!reducedExcessStoreData.ExcessReductionTotalPremium
  const coverLevel = reducedExcessStoreData.ExcessReductionCoverLevel
  const isExcessEnabled = reducedExcessStoreData.ExcessReductionIncluded
  const excessAmount = hasExcessAddonPremium
    ? handleReducedExcess(
        compulsoryExcess,
        reducedExcessStoreData.ExcessReductionCoverLevel,
      )
    : compulsoryExcess.toFixed(2)

  return {
    type: 'excess',
    heading: 'Add Excess Reduction Cover?',
    hasTotalPremium: hasExcessAddonPremium,
    selected: isExcessEnabled,
    ipt: reducedExcessStoreData.ExcessReductionIPT?.toFixed(2),
    premium: reducedExcessStoreData.ExcessReductionPremiumGross?.toFixed(2),
    totalPrice: reducedExcessStoreData.ExcessReductionTotalPremium?.toFixed(2),
    excessAmount,
    coverLevel,
    staticDocuments: {
      policyWording: 'CoplusMotorExcessReductionPolicyWording',
      ipid: 'CoplusMotorExcessReductionInsuranceProductInformationDocument',
    },
    hasError: reducedExcessStoreData.HasError,
    price: `£${reducedExcessStoreData.ExcessReductionTotalPremium?.toFixed(2)}`,
  }
}

const getBreakdownCover = (breakdownStoreData: BreakdownCover) => {
  const hasBreakdownPremium = !!breakdownStoreData.BreakdownTotalPremium
  const isBreakdownEnabled = breakdownStoreData.BreakdownIncluded

  return {
    type: 'breakdown',
    heading: 'Add UK Breakdown Cover?',
    hasTotalPremium: hasBreakdownPremium,
    selected: isBreakdownEnabled,
    ipt: breakdownStoreData.BreakdownIPT?.toFixed(2),
    premium: breakdownStoreData.BreakdownPremiumGross?.toFixed(2),
    totalPrice: breakdownStoreData.BreakdownTotalPremium?.toFixed(2),
    staticDocuments: {
      policyWording: 'AABreakdownPolicyWording',
      ipid: 'AABreakdownInsuranceProductInformationDocument',
    },
    hasError: breakdownStoreData.HasError,
    price: `£${breakdownStoreData.BreakdownTotalPremium?.toFixed(2)}`,
  }
}

const getLegalExpensesCover = (legalexpensesStoreData: LegalExpensesCover) => {
  const hasLegalExpensesPremium = !!legalexpensesStoreData.LegalExpensesPremium
  const isLegalExpensesEnabled = legalexpensesStoreData.LegalExpensesIncluded

  return {
    type: 'legalexpenses',
    heading: 'Add Legal Expenses Cover?',
    hasTotalPremium: hasLegalExpensesPremium,
    selected: isLegalExpensesEnabled,
    ipt: legalexpensesStoreData.LegalExpensesIPT?.toFixed(2),
    premium: legalexpensesStoreData.LegalExpensesPremiumGross?.toFixed(2),
    totalPrice: legalexpensesStoreData.LegalExpensesPremium?.toFixed(2),
    staticDocuments: {
      policyWording: 'LegalExpensesInsurancePolicyWording',
      ipid: 'LegalExpensesInsuranceProductInformationDocument',
    },
    hasError: legalexpensesStoreData.HasError,
    price: `£${legalexpensesStoreData.LegalExpensesPremium?.toFixed(2)}`,
  }
}

const useAddons = () => {
  const [availableAddons, setAvailableAddons] = useState<any[]>([])

  const breakdownStoreData = useSelector(
    (state: InitialStateType) => state.price.BreakdownCover,
  )
  const breakdown = useMemo(
    () => getBreakdownCover(breakdownStoreData),
    [breakdownStoreData],
  )

  const compulsoryExcess = useSelector(
    (state: InitialStateType) => state.price.CompulsoryExcess,
  )
  const reducedExcessStoreData = useSelector(
    (state: InitialStateType) => state.price.ExcessReduction,
  )
  const excess = useMemo(
    () => getExcessReduction(reducedExcessStoreData, compulsoryExcess),
    [compulsoryExcess, reducedExcessStoreData],
  )

  const legalexpensesStoreData = useSelector(
    (state: InitialStateType) => state.price.LegalExpensesCover,
  )
  const legalexpenses = useMemo(
    () => getLegalExpensesCover(legalexpensesStoreData),
    [legalexpensesStoreData],
  )

  const selectedAddons = useSelector(getAddonSelection)

  useEffect(() => {
    setAvailableAddons(
      [breakdown, legalexpenses, excess].filter((i) => i.hasTotalPremium),
    )
  }, [breakdown, excess, legalexpenses])

  return {
    availableAddons,
    selectedAddons,
    excess,
    breakdown,
    legalexpenses,
  }
}

export default useAddons
