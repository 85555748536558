import styled from 'styled-components';

interface WrapperProps {
  hasPrice?: boolean;
}

export const Wrapper = styled.div<WrapperProps>`
  border-radius: ${(props) => props.theme.formControlBorderRadius};
  border: 1px solid ${(props) => props.theme.inputBorderColour};
  padding: 16px 24px;
`;

export const BreakdownIconWrapper = styled.div`
  margin-left: 8px;
  display: flex;
  align-items: center;
`;

export const DriverDetailItemsWrapper = styled.div`
  text-align: left;
`;

export const DriverDetailsHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
  h3 {
    margin: 0;
    color: ${({ theme }) => theme.primaryColour};
    font-weight: bold;
  }
`;

export const DriverDetailsInlineWrapper = styled.div`
  display: flex;
  margin-bottom: 8px;
`;

export const DriverDetailItemWrapper = styled.div`
  margin-right: 16px;
  flex: 1 1 0px;
`;
