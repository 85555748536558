import initialState from 'initialState';
import { ExperimentAction } from './experiment.actions';

const experimentReducer = (state = initialState.experiments, action: ExperimentAction) => {
  switch (action.type) {
    case 'SHOW_EXPERIMENT': {
      return {
        ...state,
        ...action.payload,
      };
    }
    default:
      return state;
  }
};

export default experimentReducer;
