import moment from 'moment'
import { call, put, select, takeLatest } from 'redux-saga/effects'
import fetchAntiForgeryTokenSaga from '../../App/sagas/antiForgerySaga'
import fetchLinksSaga from '../../App/sagas/linksSaga'
import getOpenSchemeSaga from '../../App/sagas/schemeSaga'
// eslint-disable-next-line
import queryString from 'query-string'
import {
  browserName,
  browserVersion,
  isAndroid,
  isChrome,
  isFirefox,
  isMobile,
  isMobileSafari,
  isOpera,
} from 'react-device-detect'
import { setExternalData } from 'store/external/external.actions'
import { QuoteJourneyPosition } from 'types/global'
import { history } from '../../../'
import quoteApi from '../../../api/quoteApi'
import { handleQueryString } from '../../../services/handlers'
import { showExperiment } from '../../../store/experiment/experiment.actions'
import { fetchAddresses } from '../../Address/saga'
import { getPostcode } from '../../Address/selector'
import CustomerAccountApi from 'api/customerAccount'
import {
  isLoading,
  setCampaignActive,
  setQueryString,
  setQuoteJourneyPosition,
  setTempUnderwriter,
  updateCampaignData,
  updateDurationValue,
} from '../../App/actions'
import { getAffiliate } from '../../App/sagas/affiliateSaga'
import featureTogglesSaga from '../../App/sagas/featureTogglesSaga'
import { setReasonForTempcoverPurchases } from 'store/driverDetailsFormFields/driverDetailsFormFields.actions'
import {
  createQuoteSaga,
  fetchQuote,
  fetchQuoteSummary,
} from '../../App/sagas/quoteSaga'
import legalDeclarationSaga from '../../LegalDeclarationModal/saga'
import { requestMyLicenceStatus } from '../../MyLicence/actions'
import {
  COMMERCIAL_VEHICLE,
  MOTORCYCLE,
  PRIVATE_CAR,
} from '../../Vehicle/constants'
import fetchVehicleSaga, {
  createUnknownVehicleFromUnknownReg,
  setVehicleSaga,
} from '../../Vehicle/saga'
import { updateRiskData } from '../actions'
import {
  CONFIRM_RISK_DATA_COLLECTION_PAGE,
  DRIVING_LICENCE_CHECK,
  INIT_RISK_DATA_COLLECTION_PAGE,
  PASSPORT_CHECK,
  REQUEST_IDENTITY_CHECK_SUCCESS,
  SAVE_CUSTOMER_DRIVING_LICENCE,
  DOES_ACCOUNT_EXIST,
} from '../constants'
import {
  getQueryString,
  getQuoteId,
  getQuoteStatus,
  getSelectedScheme,
  getIsCustomerAccountQuote,
  getVehicle,
} from '../selector'
import {
  drivingLicenceCheckSaga,
  identitySaga,
  passportCheckSaga,
  saveCustomerDrivingLicenceSaga,
  doesCustomerAccountExist,
} from './identity'
import { updateRiskDataSaga } from './riskData'
import { underwriterSaga } from './underwriter'
import { formValidation, updateRequiredFieldsSaga } from './validation'
import { updateIsCustomerAccountQuote } from 'containers/App/actions'
import { getCookie, CA_LOGGED_IN_COOKIE } from 'utils/cookie-helpers'
import { setNewCustomerAccountVehicleFromHomePage } from 'containers/Vehicle/actions'

function* initRiskDataCollection() {
  const allowedApple = isMobileSafari && browserVersion >= 11
  const allowedAndroid =
    isAndroid &&
    ((isOpera && browserVersion >= 46) ||
      (isChrome && browserVersion >= 52) ||
      (isFirefox && browserVersion >= 36) ||
      (browserName === 'Samsung Browser' && browserVersion >= 6.2) ||
      (browserName === 'Android Browser' && browserVersion >= 53))
  // eslint-disable-next-line
  const approvedList = isMobile && (allowedApple || allowedAndroid)
  const loggedInCookie = getCookie(CA_LOGGED_IN_COOKIE)
  let customerAccount = loggedInCookie && JSON.parse(loggedInCookie)
  const isCustomerAccountAndOnLoggedInPage =
    customerAccount &&
    (!history.location.pathname.includes('/quote/driver-details') ||
      history.location.pathname === '/quote/driver-details/logged-in')

  yield put(updateIsCustomerAccountQuote(isCustomerAccountAndOnLoggedInPage))

  yield put(setNewCustomerAccountVehicleFromHomePage(false))
  try {
    const parsedQueryString = queryString.parse(window.location.search)
    if (parsedQueryString.experimentoverride) {
      const experimentObject = JSON.parse(parsedQueryString.experimentoverride)
      yield put(showExperiment(experimentObject))
    }
    if (parsedQueryString.click_ref) {
      const splitClickRef = parsedQueryString.click_ref.split('x')
      const quidcoData = {
        QuidcoUserId: splitClickRef[0],
        QuidcoClickId: splitClickRef[1],
      }
      yield put(setExternalData(quidcoData))
    }
    if (parsedQueryString.u) {
      yield put(setTempUnderwriter(parsedQueryString.u))
      var x =
        '?q=' +
        parsedQueryString.q +
        (parsedQueryString.ref ? '&ref=' + parsedQueryString.ref : '')
      history.push(`/quote/driving-licence${x}`)
    }

    yield put(isLoading(true))
    yield* fetchAntiForgeryTokenSaga()
    yield* fetchLinksSaga()
    yield* getOpenSchemeSaga()
    yield* featureTogglesSaga()

    if (parsedQueryString.ref) {
      yield* getAffiliate(parsedQueryString.ref)
      customerAccount = false
    }

    let quoteId
    let noRegCustomerAccount = false
    if (parsedQueryString.q) {
      quoteId = parsedQueryString.q
      yield put(
        setQueryString(
          '?q=' +
            quoteId +
            (parsedQueryString.ref ? '&ref=' + parsedQueryString.ref : ''),
        ),
      )
      if (customerAccount) {
        yield put(
          setQuoteJourneyPosition(
            QuoteJourneyPosition.CustomerAccountDirectAccess,
          ),
        )
      } else {
        yield put(
          setQuoteJourneyPosition(QuoteJourneyPosition.CustomerDirectAccess),
        )
      }

      yield* fetchQuoteSummary(quoteId)
      yield put(requestMyLicenceStatus())
      yield* fetchQuote()
      const quoteStatus = yield select(getQuoteStatus)
      const queryString = yield select(getQueryString)
      if (quoteStatus === 'Complete') {
        history.replace(`/policy/confirmation${queryString}`)
        return
      }
      const postcode = yield select(getPostcode)
      if (postcode) {
        yield* fetchAddresses()
        yield* updateRiskDataSaga({
          payload: { partialValidation: true, getPrice: true },
        }) //bit hacky, need to only call if i have a address
      }
      yield* formValidation()
      // This needs to be called to check if the campaign is valid on refresh
      yield* initialiseQuote()
      yield put(isLoading(false))
      return
    }
    let params = {
      duration: handleQueryString().Duration,
      durationType: handleQueryString().DurationType,
      campaignRef: handleQueryString().CampaignRef,
      isImpounded: handleQueryString().Impounded,
      isExpat: handleQueryString().Expat,
      isLearner: handleQueryString().Learner,
      isMotorbike: handleQueryString().Motorbike,
      isCourier: handleQueryString().Courier,
    }
    if (params.isImpounded) {
      //Force duration to 30 days for impounded
      params.duration = 30
      params.durationType = 'days'
    }

    yield* setStateFromParams(params)

    if (customerAccount) {
      // add customer account to params
      params.noRegCustomerAccount = true
      const getCustomerIdResponse = yield call(
        CustomerAccountApi.getCustomerAccountId,
      )
      params.customerId = getCustomerIdResponse.data
    }

    if (
      handleQueryString().UnknownVehicle.Make ||
      handleQueryString().UnknownVehicle.GrossWeight
    ) {
      yield* createNewQuoteFromUnknownVehicle(params)
      yield put(requestMyLicenceStatus())
      if (customerAccount && !handleQueryString().RegistrationNumber) {
        customerAccount = false
      }
    } else {
      let vehicleReg = handleQueryString().RegistrationNumber
      const isLearner = handleQueryString().Learner
      const isMotorbike = handleQueryString().Motorbike
      const isImpounded = handleQueryString().Impounded
      const isExpat = handleQueryString().Expat
      const isCourier = handleQueryString().Courier

      const requestStatus = yield* fetchVehicleSaga({
        registrationNumber: vehicleReg,
      })
      const vehicle = yield select(getVehicle)

      if (requestStatus === 200 || customerAccount) {
        if (customerAccount && requestStatus === 200) {
          // we set customer account to false her so it doesnt redirect away to logged in page foranything not a private car or commercial
          if (
            !(
              vehicle.searched.VehicleType === COMMERCIAL_VEHICLE ||
              vehicle.searched.VehicleType === PRIVATE_CAR
            ) ||
            isLearner ||
            isExpat ||
            isImpounded
          ) {
            customerAccount = false
            params.customerId = null
          } else {
            // check if this vehicle is already assigned to the customer
            const getIsVehicleAlreadyInCustomerAccount = yield call(
              CustomerAccountApi.getIsVehicleAlreadyInCustomerAccount,
              vehicleReg,
            )
            yield put(
              setNewCustomerAccountVehicleFromHomePage(
                !getIsVehicleAlreadyInCustomerAccount.data,
              ),
            )
          }
        }

        if (vehicle.searched.VehicleType === MOTORCYCLE && isImpounded) {
          history.replace({
            pathname: `/vehicle/invalid-reg`,
            search:
              window.location.search +
              '&VehicleType=' +
              vehicle.searched.VehicleType,
            state: { error: 'VehicleNotAllowed' },
          })
          return
        }
        if (
          (vehicle.searched.VehicleType === COMMERCIAL_VEHICLE ||
            vehicle.searched.VehicleType === MOTORCYCLE) &&
          isLearner
        ) {
          history.replace({
            pathname: `/vehicle/invalid-reg`,
            search:
              window.location.search +
              '&VehicleType=' +
              vehicle.searched.VehicleType,
            state: { error: 'VehicleNotAllowed' },
          })
          return
        }
        if (
          (vehicle.searched.VehicleType === COMMERCIAL_VEHICLE ||
            vehicle.searched.VehicleType === MOTORCYCLE) &&
          isExpat
        ) {
          history.replace({
            pathname: `/vehicle/invalid-reg`,
            search:
              window.location.search +
              '&VehicleType=' +
              vehicle.searched.VehicleType,
            state: { error: 'VehicleNotAllowed' },
          })
          return
        }
        if (
          (vehicle.searched.VehicleType === COMMERCIAL_VEHICLE ||
            vehicle.searched.VehicleType === PRIVATE_CAR) &&
          isMotorbike
        ) {
          history.replace({
            pathname: `/vehicle/invalid-reg`,
            search:
              window.location.search +
              '&VehicleType=' +
              vehicle.searched.VehicleType,
            state: { error: 'VehicleNotAllowed' },
          })
          return
        }
        if (vehicle.searched.VehicleType !== COMMERCIAL_VEHICLE && isCourier) {
          history.replace({
            pathname: `/vehicle/invalid-reg`,
            search:
              window.location.search +
              '&VehicleType=' +
              vehicle.searched.VehicleType,
            state: { error: 'VehicleNotAllowed' },
          })
          return
        }

        yield* createNewQuoteFromReg(params)
        yield put(requestMyLicenceStatus())
      } else {
        let error
        if (requestStatus === 400) {
          error = 'InvalidReg'
        } else {
          error = 'VehicleNotFound'
        }
        history.replace({
          pathname: `/vehicle/invalid-reg`,
          search: window.location.search,
          state: { error: error },
        })
        return
      }
    }

    //fields required for learner scheme vs normal journey
    yield updateRequiredFieldsSaga()
    quoteId = yield select(getQuoteId)
    yield put(
      setQueryString(
        '?q=' +
          quoteId +
          (parsedQueryString.ref ? '&ref=' + parsedQueryString.ref : ''),
      ),
    )
    const redirectQueryString = yield select(getQueryString)
    yield* initialiseQuote()
    yield put(updateIsCustomerAccountQuote(customerAccount))

    // if(!approvedList) {
    if (!customerAccount) {
      history.replace(`/quote/driver-details${redirectQueryString}`)
    } else {
      history.replace(`/quote/driver-details/logged-in${redirectQueryString}`)
    }
    // } else {
    //   yield put(updateStep(1));
    //   history.push(`/quote/select-path?q=${quoteId}`);
    // }

    yield put(isLoading(false))
  } catch (e) {
    yield put(isLoading(false))
  }
}
function* confirmRiskDataCollectionPage({ callback }) {
  try {
    yield call(underwriterSaga)
    yield call(identitySaga)
  } catch (e) {}
}

export default function* riskDataCollectionSaga() {
  yield takeLatest(INIT_RISK_DATA_COLLECTION_PAGE, initRiskDataCollection)
  yield takeLatest(
    CONFIRM_RISK_DATA_COLLECTION_PAGE,
    confirmRiskDataCollectionPage,
  )
  yield takeLatest(REQUEST_IDENTITY_CHECK_SUCCESS, legalDeclarationSaga)
  yield takeLatest(DRIVING_LICENCE_CHECK, drivingLicenceCheckSaga)
  yield takeLatest(PASSPORT_CHECK, passportCheckSaga)
  yield takeLatest(
    SAVE_CUSTOMER_DRIVING_LICENCE,
    saveCustomerDrivingLicenceSaga,
  )
  yield takeLatest(DOES_ACCOUNT_EXIST, doesCustomerAccountExist)
}

function* setStateFromParams({
  duration,
  durationType,
  campaignRef,
  isImpounded,
  isCourier,
  isExpat,
  isLearner,
  isMotorbike,
  noRegCustomerAccount,
  customerId,
}) {
  yield put(
    updateDurationValue({
      value: duration,
      type: durationType,
    }),
  )
  yield put(
    updateRiskData({
      PolicyStartDate: {
        value: moment().format('YYYY-MM-DDTHH:mm'),
        manuallyEdited: false,
      },
      Impounded: isImpounded,
      Expat: Boolean(isExpat),
      Courier: Boolean(isCourier),
      Learner: isLearner,
      Motorbike: isMotorbike,
      NoRegCustomerAccount: noRegCustomerAccount,
      CustomerId: customerId,
      ...(isLearner
        ? {
            LicenceType: {
              value: 'ProvisionalUkLicence',
              description: 'Provisional UK licence',
            },
          }
        : {
            LicenceType: {
              value: 'FullUkLicence',
              description: 'Full UK licence',
            },
            LicenceHeldDuration: { value: '', description: null },
          }),
      ...(isExpat
        ? {
            ResidencyType: { value: '', description: null },
          }
        : {
            ResidencyType: {
              value: 'PermanentUkResident',
              description: 'Permanent UK Resident',
            },
          }),
    }),
  )
  yield put(
    updateCampaignData({
      CampaignRef: campaignRef,
    }),
  )
}

function* createNewQuoteFromReg(params) {
  yield* setStateFromParams(params)
  const vehicle = yield select(getVehicle)
  yield* setVehicleSaga(vehicle.searched)
  yield* createQuoteSaga()
}

function* createNewQuoteFromUnknownVehicle(params) {
  const { UnknownVehicle, RegistrationNumber } = handleQueryString()

  if (RegistrationNumber) {
    yield* createNewQuoteFromReg(params)
  } else {
    yield* setStateFromParams(params)
    yield* createUnknownVehicleFromUnknownReg(UnknownVehicle)
    yield* createQuoteSaga()
  }
}
function* initialiseQuote() {
  const { CampaignRef } = handleQueryString()

  const scheme = yield select(getSelectedScheme)
  const isCustomerAccountQuote = yield select(getIsCustomerAccountQuote)
  const data = {
    CampaignRef,
    Scheme: scheme,
    IsCustomerAccountQuote:
      isCustomerAccountQuote != null ? isCustomerAccountQuote : false,
  }

  const response = yield call(quoteApi.initialiseQuote, data)
  if (response.data.QuoteVerificationServiceResponse) {
    yield put(
      setCampaignActive({
        isCampaignActive:
          response.data.QuoteVerificationServiceResponse.CampaignActive,
        CampaignRef,
      }),
    )
  }
  if (response.data.QuoteDetailsFormValuesServiceResponse) {
    yield put(
      setReasonForTempcoverPurchases(
        response.data.QuoteDetailsFormValuesServiceResponse
          .ReasonForTempcoverPurchaseList,
      ),
    )
  }
}
