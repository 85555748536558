import * as yup from 'yup';
import { handleDateOfBirthValidation } from 'utils/driver-details/handle-date-of-birth-validation';
import { useOfMotorcycleOptions } from 'components/UseOfVehicle/constants';
import { VEHICLE_OWNERS } from 'components/VehicleOwner/constants';
import { motorcycleOvernightParkingOptions } from 'components/MotorcycleOvernightParking/constants';
import { motorcycleExperienceOptions } from 'components/MotorcycleRidingExperience/constants';
import { motorcycleEntitlementOptions } from 'components/MotorcycleEntitlement/constants';
import { motorcycleReasonOptions } from 'components/ReasonForCover/constants';
import { LICENCE_REGEXP } from 'services/constants';
import { MobileValidation } from '../../../MobileTelephone/Mobile.validation';
import { EmailValidation } from '../../../Email/Email.validation';
import {
  DateOfBirthValidation,
  FirstNameValidation,
  SurnameValidation,
  TitleValidation,
  LicenceTypeValidation,
} from '../@CommonFields';
import { VALID_DATE_OF_BIRTH_WORDING } from '../DriverDetailsForm.constants';

const MotorcycleFormSchema = yup.object().shape({
  licenceType: LicenceTypeValidation.required('Please select a licence type'),
  title: TitleValidation.required('Please select a title'),
  firstName: FirstNameValidation.required('Please enter a valid first name'),
  surname: SurnameValidation.required('Please enter a valid surname'),
  occupation: yup
    .string()
    .min(3, 'Sorry, we need to know your occupation')
    .typeError('Sorry, we need to know your occupation')
    .required('Sorry, we need to know your occupation'),
  dateOfBirth: DateOfBirthValidation.test('DOB', VALID_DATE_OF_BIRTH_WORDING, async (value) => handleDateOfBirthValidation(value, 17.5, 80)).required('Please enter a date of birth'),
  vehicleValue: yup
    .string()
    .required(),
  postcode: yup
    .string()
    .matches(
      /([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})/i,
      { message: 'Please enter a valid postcode' },
    )
    .required('Please enter a valid postcode'),
  addressKey: yup
    .string()
    .typeError('Sorry, we need to know your address')
    .required('Sorry, we need to know your address'),
  useOfVehicle: yup
    .string()
    .oneOf(useOfMotorcycleOptions.map((o) => o.value.toString()), 'Sorry, we need to know why you\'re using your vehicle')
    .required('Sorry, we need to know why you\'re using your vehicle'),
  ownerOfVehicle: yup
    .string()
    .oneOf(VEHICLE_OWNERS.map((o) => o.value.toString()), 'Please select a vehicle owner option')
    .required('Please select a vehicle owner option'),
  overnightParking: yup
    .string()
    .oneOf(motorcycleOvernightParkingOptions.map((o) => o.value.toString()), 'Please choose a location')
    .required('Please choose a location'),
  ridingExperience: yup
    .string()
    .oneOf(motorcycleExperienceOptions.map((o) => o.value.toString()), 'Please choose your riding experience')
    .required('Please choose your riding experience'),
  previousRiddenVehicle: yup
    .string()
    .when('ridingExperience', {
      is: 'HasRiddenOnTheRoadInTheLastTwoYears',
      then: yup.string().matches(LICENCE_REGEXP, { message: 'Please enter a valid motorbike registration' }).required('Please enter a valid motorbike registration'),
      otherwise: yup.string().nullable().notRequired(),
    }),
  previousRiddenVehicleSearched: yup
    .boolean()
    .when('ridingExperience', {
      is: 'HasRiddenOnTheRoadInTheLastTwoYears',
      then: yup.boolean().required('Please search motorbike registration').oneOf([true], 'Please search motorbike registration'),
      otherwise: yup.boolean().nullable().notRequired(),
    }),
  motorcycleEntitlement: yup
    .string()
    .oneOf(motorcycleEntitlementOptions.map((o) => o.value.toString()), 'Please select an entitlement')
    .required('Please select an entitlement'),
  reasonForPurchase: yup
    .string()
    .oneOf(motorcycleReasonOptions.map((o) => o.value.toString()), 'Please select a reason for cover')
    .required('Please select a reason for cover'),
  emailAddress: EmailValidation.required('Please enter a valid email'),
  mobile: MobileValidation.required('Please enter a valid phone number'),
});

export default MotorcycleFormSchema;
