import http from './http';

export default class emailQuoteApi {
    static sendQuoteEmail(endpoint, data) {
        return http.post(endpoint, data, {
            headers: {
                'Content-Type': 'application/tempcover.quoteremindersrepresentation+json'
            }
        });
    }
}