import styled from 'styled-components';

export const P = styled.p`  
   font-family: ${props => props.theme.labelFontFamily};
   font-size:${props => props.theme.labelFontSize};
   paddding-top: 10px;
   margin-top:25px;
   @media (max-width: 768px) {
      font-size:${props => props.theme.mobileLabelFontSize};
      
   }
`

export const Span = styled.span`  
   font-size:22px;
   font-weight:bold;
   color:${props => props.theme.primaryTextColour};   
   @media (max-width: 768px) {
      font-size:16px;
   }
`

export const SectionWrapper = styled.div`
    border-bottom: solid 1px #CCCCCC;    
`;

export const Wrapper = styled.div`
    background-color:${props => props.theme.primaryColour};
    color:#fff;
    padding: 5px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    padding-left: 0;
`;

export const PriceSpan = styled(Span)`    
    color:#fff;
    @media (max-width: 768px) {
        font-size:22px;
        font-weight:bold   
    }    
`;


export const Row = styled.div`
  display: flex;
  padding: 18px 10px 5px;
  flex-wrap: no-wrap;
  box-sizing: border-box;

  & > * {
    flex: 0 0 auto;
    padding: 0 10px;
    box-sizing: border-box;
  }
`;

export const TableHeading = styled.div`
  width: 30%;
  display: flex;

  & > p{
    margin: 0;
    padding-top: 6px;

    @media (min-width: 768px){
      padding-top: 4.5px;
    }
  }

  @media (min-width: 768px){
    width: 20%;
  }
`;

export const TableValue = styled.div`
  width: 70%;

  @media (min-width: 768px){
    width: 80%;
  }
`;

export const DynamicRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  justify-content: space-between;

  @media (min-width: 561px){
    justify-content: flex-start;
  }
`;

export const PriceP = styled.p`  
  font-family: ${props => props.theme.labelFontFamily};
  font-size:${props => props.theme.labelFontSize};
  margin: 0;
  margin-right: 15px;
  padding-top: 2px;
  @media (max-width: 768px) {
    font-size:${props => props.theme.mobileLabelFontSize};
    
  }
`;

export const CoverP = styled.p`
  font-family: ${(props) => props.theme.fontFamily};
  margin: 0;
  padding-top: 2px;
  font-size: 14px;
`;
