import styled from 'styled-components';

interface SummarySectionProps {
  cursor?: string;
}

interface SumamryBorderMobileProps {
  noBottomRadius?: boolean;
}

export const SummaryBorderMobile = styled.div<SumamryBorderMobileProps>`
  position: relative;
  border: 1px solid ${props => props.theme.inputBorderColour};
  border-radius: ${props => props.theme.formControlBorderRadius};
  background: #fff;
  margin-top: 10px;

  @media (max-width: 768px) {
    ${props => props.noBottomRadius ? 'border-bottom-left-radius: 0; border-bottom-right-radius: 0;' : ''};
  }
`;

export const SummaryBorderLeftMobile = styled.div`
  border-left: 1px solid ${props => props.theme.inputBorderColour};
`;

export const SummaryBorderBottomMobile = styled.div`
  border-bottom: 1px solid ${props => props.theme.inputBorderColour};
`;

export const SummaryBorderMiddleMobile = styled.div`
  border-bottom: 1px solid ${props => props.theme.inputBorderColour};
`;

export const SummarySection = styled.div<SummarySectionProps>`
  position: relative;
  border: 1px solid ${props => props.theme.inputBorderColour};
  border-radius: ${props => props.theme.formControlBorderRadius};
  background: #fff;
  margin: 10px 0;
  padding: 20px;
  cursor: ${props => props.cursor || "pointer"};
  text-align: left;

  & .quote-summary-title {
    font-family: ${props => props.theme.fontFamily};
    font-size: 14px;
    margin: 0;

    &.vehicle {
      text-align: center;
      @media (max-width: 767px) {
        text-align: left;
      }
    }
  }

  & .quote-summary-text {
    font-size: 22px;
    font-family: ${props => props.theme.labelFontFamily};
    color: ${props => props.theme.primaryTextColour};
    margin: 0;

    &.vehicle {
      text-align: center;
      @media (max-width: 767px) {
        text-align: left;
      }
    }
  }

  & .registration {
    position: relative;
    font-family: ${props => props.theme.regFont};
    color: #000;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 40px;
    line-height: 48px;
    margin: 10px 0 0 0;
    text-align: center;
    border: 1px solid ${props => props.theme.primaryColour};
    border-radius: ${props => props.theme.formControlBorderRadius};
    height: 50px;
    box-sizing: border-box;

    & .gb-icon {
      position: absolute;
      left: -1px;
      top: -1px;
      width: 50px;
      height: 50px;
      font-family: 'BrandonText-Bold', sans-serif;
      font-size: 20px;
      font-weight: 600;
      text-align: center;
      letter-spacing: 1px;
      line-height: 50px;
      background-color: ${props => props.theme.primaryColour};
      color: #fff;
      border-radius: ${props => props.theme.formControlBorderRadius};
      box-sizing: border-box;
    }
  }

  & .fa-pencil-alt {
    position: absolute;
    top: 20px;
    right: 30px;
    color: ${props => props.theme.editIconColour};;
    cursor: pointer;
  }

  @media screen and (max-width: 526px ) {
    padding: 13px;
    margin: 0;
    border: none;
  
    & .quote-summary-title {
      font-size: 10px;
    }
    & .quote-summary-text {
      font-size: 12px;
    }
    & .registration {
      font-size: 24px;
      line-height: 28px;
      height: 30px;
      padding-left: 28px;
  
      & .gb-icon {
        width: 30px;
        height: 30px;
        font-size: 12px;
        line-height: 30px;
      }
    }
    & .fa-pencil-alt {
      top: 8px;
      right: 8px;
      font-size: 12px;
    }
  }
  
  @media screen and (max-width: 767px) and (min-width: 527px) {
    padding: 6px;
    margin: 0;
    border: none;
  
    & .quote-summary-title {
      font-size: 10px;
    }
    & .quote-summary-text {
      font-size: 12px;
    }
    & .registration {
      font-size: 24px;
      line-height: 28px;
      height: 30px;
      padding-left: 28px;
  
      & .gb-icon {
        width: 30px;
        height: 30px;
        font-size: 12px;
        line-height: 30px;
      }
    }
    & .fa-pencil-alt {
      top: 8px;
      right: 8px;
      font-size: 12px;
    }
  }
  `;

export const SummarySectionSmall = styled(SummarySection)`
  margin-right:15px;
  cursor: default;
`