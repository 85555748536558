import React from 'react';
import { ProgressionOption } from '../common/ProgressionOption';
import { GridContainer, Grid, GridClear } from 'unsemantic';
import { withTheme } from 'styled-components';
import { ProgressionFlexGrid } from '../common/ProgressionOption/ProgressionFlexGrid';
import { ProgressionHeaderImage } from '../common/ProgressionOption/ProgressionHeaderImage';
import { ProgressionHeader } from '../common/ProgressionOption/ProgressionHeader';
import { H2 } from '../common/H2';
import { ProgressionContent } from '../common/ProgressionOption/ProgressionContent';
import { H4 } from '../common/H4';

class ManualPathSelection extends React.Component {
    render() {
        const { startManualForm, queryString, theme } = this.props;
        return (
            <ProgressionOption id='TeslaTypeDetailsNextButton' onClick={() => startManualForm(queryString)}>
                <GridContainer>
                    <Grid desktop='100' mobile='100' tablet='100' parent>
                        <ProgressionHeader>
                            <H2>Type in your details</H2>
                        </ProgressionHeader>
                    </Grid>
                    <GridClear />
                    <ProgressionFlexGrid>
                        <div>
                            <ProgressionHeaderImage>
                                <svg xmlns="http://www.w3.org/2000/svg" width="68.707" height="51.051" viewBox="0 0 68.707 51.051">
                                    <g id="Group_118" data-name="Group 118" transform="translate(2478.815 99.942)">
                                        <rect id="Rectangle_280" data-name="Rectangle 280" width="4.024" height="10.729" transform="matrix(0.504, -0.864, 0.864, 0.504, -2424.236, -91.704)" fill={theme.primaryColour} />
                                        <path id="Path_202" data-name="Path 202" d="M-2312.051-52.482a.608.608,0,0,1-.305-.083.607.607,0,0,1-.3-.487l-.579-9.654a.605.605,0,0,1,.081-.341l20.958-35.93a1.794,1.794,0,0,1,1.728-.96,2.305,2.305,0,0,1,.926.276l6.951,4.054a1.816,1.816,0,0,1,.98,1.721,2.231,2.231,0,0,1-.27.925l-20.957,35.93a.612.612,0,0,1-.258.239l-8.689,4.248A.607.607,0,0,1-2312.051-52.482Zm.036-10.113.514,8.564,7.708-3.769,20.863-35.767a.671.671,0,0,0-.271-.993l-6.952-4.054a.671.671,0,0,0-1,.248Zm8.653,5.259h0Z" transform="translate(-128.5 0)" fill={theme.primaryColour} />
                                        <path id="Path_203" data-name="Path 203" d="M-2297.784,48.175a.6.6,0,0,1-.3-.083l-9.268-5.406a.606.606,0,0,1-.218-.828.606.606,0,0,1,.829-.218l9.268,5.406a.605.605,0,0,1,.218.828A.6.6,0,0,1-2297.784,48.175Z" transform="translate(-132.727 -107.222)" fill={theme.primaryColour} />
                                        <path id="Path_204" data-name="Path 204" d="M-2432.709,110.137h-45.5a.606.606,0,0,1-.606-.606.606.606,0,0,1,.606-.606h45.5a.606.606,0,0,1,.606.606A.606.606,0,0,1-2432.709,110.137Z" transform="translate(0 -159.028)" fill={theme.primaryColour} />
                                    </g>
                                </svg>

                            </ProgressionHeaderImage>
                        </div>
                        <div>
                            <ProgressionContent>
                                <H4>We just need a little bit of information to get you a quote</H4>
                            </ProgressionContent>
                        </div>
                    </ProgressionFlexGrid>
                </GridContainer>
            </ProgressionOption>
        )
    }
}


export default withTheme(ManualPathSelection);