import { call, put, select } from 'redux-saga/effects';
import schemeApi from '../../../api/schemeApi';
import {
  requestOpenSchemes, requestOpenSchemesSuccess, requestOpenSchemesFailure
} from '../actions';
import { getOpenSchemesSelector } from '../selector';

export default function* getOpenSchemeSaga() {
  try {
    yield put(requestOpenSchemes());
    const openSchemesLink = yield select(getOpenSchemesSelector);
    const response = yield call(schemeApi.getOpenSchemes, openSchemesLink.Href);

    yield put(requestOpenSchemesSuccess(response.data));
  } catch (e) {
    yield put(requestOpenSchemesFailure(e));
  }
}
