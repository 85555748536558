import styled from 'styled-components';

export const AmendLink = styled.span`
  font-size: 11px;
  font-weight: bold;
  font-family: ${(props) => props.theme.labelFontFamily};
  a {
    color: ${(props) => props.theme.drivingLicence.primaryColour};
    position: relative;
    left: 81%;
    bottom: -14px;
    & .amend-space {
    padding-left: 5px;
    margin-right: 5px;
  }
  @media (max-width: 768px) {
    left: 77%;
  }
  }
`;

export const EditWrapper = styled.span`
  font-size: 12px;
  font-family: ${({ theme }) => theme.labelFontFamily};
  color: ${({ theme }) => theme.drivingLicence.primaryColour};
  span {
    margin-left: 4px;
  }
`;
