import styled from 'styled-components';

export const ProgressionFlexGrid = styled.div `
  display: flex;
  margin: 20px 0 0;

  & > div {
    display: flex;
    align-items: center;

    &:first-child {
        flex: 0 0 98px;
    }
  }
`;