import React from 'react';
import { ButtonSpinner } from '../../common/ButtonSpinner';
import { ButtonStyled } from './Button.styles';

export interface Props
  extends Omit<React.HTMLProps<HTMLButtonElement>, 'ref' | 'as'> {
  disabled?: boolean;
  onClick?: () => void;
  fullWidth?: boolean;
  type: 'button' | 'submit' | 'reset';
  children: React.ReactNode;
  loading?: boolean;
  isSpinnerAbsolute?: boolean;
}

const Button: React.FC<Props> = ({
  children,
  onClick,
  disabled,
  type,
  fullWidth = false,
  loading = false,
  isSpinnerAbsolute = true,
  ...props
}) => (
  <ButtonStyled
    fullWidth={fullWidth}
    type={type}
    onClick={onClick}
    disabled={disabled}
    {...props}
  >
    <>
      {children}
      {loading && <ButtonSpinner isSpinnerAbsolute={isSpinnerAbsolute} disabled={loading} />}
    </>
  </ButtonStyled>
);

export default Button;
