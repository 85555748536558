import moment from 'moment';
import { LicenceDuration } from 'types/global';
import { LICENCE_HELD_DURATIONS } from '../../services/constants';

moment.locale('en-GB');

export const handleLicenceDurations = (dateOfBirth: string, dateOfBirthFormat = 'yyyy-MM-DD', licenceDurations: LicenceDuration[] = LICENCE_HELD_DURATIONS) => {
  const assumedPassedDate = moment().subtract(17, 'years');

  const yearDiff = moment(assumedPassedDate).diff(
    moment(dateOfBirth, dateOfBirthFormat),
    'years',
    true,
  );
  const filteredOptions = licenceDurations.filter(
    (option) => yearDiff >= option.yearDiff,
  );

  return filteredOptions;
};
