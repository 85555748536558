import { usePrice, useAddons } from 'hooks';
import React, { useMemo } from 'react';
import { BrandonText, PriceWrapper, TotalPriceText, LegalText, PriceBreakdownDiscount } from './PriceBreakdown.styles';

const PriceBreakdown = () => {
    const { totalPriceFixed, price } = usePrice();
    const { excess, breakdown, legalexpenses } = useAddons();

    const priceBreakdownText = useMemo(() => {
        const prices: string[] =
            [
                `Insurer premium £${(price.InsurancePremium + price.Ipt).toFixed(2)}`,
                `Tempcover fee £${price.AdminFee.toFixed(2)}`,
            ];
        if (breakdown && breakdown.selected) {
            prices.push(`UK Breakdown Cover £${(price.BreakdownCover.BreakdownPremiumGross + price.BreakdownCover.BreakdownIPT).toFixed(2)}`);
        }
        if (excess && excess.selected) {
            prices.push(`Excess Reduction £${(price.ExcessReduction.ExcessReductionPremiumGross + price.ExcessReduction.ExcessReductionIPT).toFixed(2)}`);
        }
        if (legalexpenses && legalexpenses.selected) {
            prices.push(`Legal Expenses Cover £${(price.LegalExpensesCover.LegalExpensesPremiumGross + price.LegalExpensesCover.LegalExpensesIPT).toFixed(2)}`);
        }
        if (price.AffiliateAdminFee > 0) {
            prices.push(`Introducer fee £${price.AffiliateAdminFee.toFixed(2)}`);
        }

        return prices.join(', ');
    }, [price, breakdown, excess, legalexpenses]);

    return (
        <>
            <PriceWrapper>
                <BrandonText>Total</BrandonText>
                <TotalPriceText>£{totalPriceFixed}</TotalPriceText>
            </PriceWrapper>
            <LegalText>
                The total payable of £{totalPriceFixed} for your insurance comprises of {priceBreakdownText}. {' '}
                {!!(Math.abs(price.campaign.Discount) > 0) &&
                    <>
                        Discount applied  <PriceBreakdownDiscount>£{price.campaign.Discount}</PriceBreakdownDiscount>.
                    </>
                }
            </LegalText>
        </>
    )
}

export default PriceBreakdown;
