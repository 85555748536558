import styled from 'styled-components';

export const AutoCompleteOptionStyled = styled.li`
  color: ${({ theme }) => theme.primaryTextColour};
  font-size: ${({ theme }) => theme.generalFontSize};
  font-family: ${({ theme }) => theme.fontFamily};
  font-style: ${({ theme }) => theme.fontStyle};
  font-weight: ${({ theme }) => theme.fontWeight};
  padding: 0 10px 2px;

  &:hover {
    background-color: #1e8fff;
    color: #fff;
    cursor: pointer;
  }
`;

export const ClickThroughSpanWrapper = styled.span`
  pointer-events: none;
`;

export const ClickThroughSpan = styled.span`
  font-weight: 530;
  text-decoration: underline;
`;
