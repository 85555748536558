import { KLARNA_FAILURE, PRICE_CHECK, START_KLARNA } from "./Klarna.constants"

export const priceCheck = () => {
    return {
        type: PRICE_CHECK
    }
}

export const startKlarnaPayment = (shouldStartKlarnaPayment: boolean) => {
    return {
        type: START_KLARNA,
        shouldStartKlarnaPayment
    }
}

export const klarnaFailureAction = (hasKlarnaFailed: boolean) => {
    return {
        type: KLARNA_FAILURE,
        hasKlarnaFailed
    }
}