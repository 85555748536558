import React from 'react';
import DrivingLicenceScan from '../DrivingLicenceScan';
import QuoteSummary from '../../containers/QuoteSummary';
import { history } from '../..';
import { PathSelectionP, ProgressionStepWidth } from './styles';
import ManualPathSelection from '../ManualPathSelection';
import GridContainerWrapper from 'components/@common/GridContainerWrapper';

const startManualForm = (queryString) => {
  history.push(`/quote/driver-details${queryString}`)
}

export const RiskCollectionPathSelection = ({ queryString }) => {
  return (
    <GridContainerWrapper>
      <QuoteSummary />
      <PathSelectionP>To get a quote add your details using one of the options below.</PathSelectionP>
      <ProgressionStepWidth>
        <DrivingLicenceScan />
        <PathSelectionP>OR</PathSelectionP>
        <ManualPathSelection startManualForm={startManualForm} queryString={queryString} />
      </ProgressionStepWidth>
    </GridContainerWrapper>
  );
};
