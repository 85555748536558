import defaultTheme from "./default";

let aPlanTheme = { ...defaultTheme };
const primaryColour = '#ff3131';
const secondaryColour = '#219e3e';
aPlanTheme.primaryColour = primaryColour;
aPlanTheme.primaryTextColour = '#333';
aPlanTheme.headerFontColour = primaryColour;
aPlanTheme.breadcrumb = {
  ...defaultTheme.breadcrumb,
  activeColor: "#ffffff",
  activeBorderColor: primaryColour,
  defaultColor: "#ffffff",
  defaultBorderColor: "#CCCCCC",
  defaultBarColor: "#CCCCCC",
  completeColor: primaryColour,
  completeBorderColor: primaryColour,
  completeBarColor: primaryColour
}
aPlanTheme.spinner = {
  ...defaultTheme.spinner,
  primaryColour: primaryColour,
  textColour: primaryColour
}
aPlanTheme.durationOptions = {
  ...defaultTheme.durationOptions,
  primaryColour: primaryColour,
  secondaryColour: secondaryColour
}
aPlanTheme.button = {
  ...defaultTheme.button,
  backgroundColour: secondaryColour,
  addon:{
    backgroundColour: secondaryColour,
    disabledBackground: secondaryColour,
    disabledBackground: "#808080",
    disabledFont: '#fff'
  }
}
aPlanTheme.header = {
  ...defaultTheme.header,
  background: primaryColour,
  borderBottom: "none",
}
  aPlanTheme.headerLinkStyled={
    ...defaultTheme.headerLinkStyled,
    padding: "0px",
    hover: {
      textDecoration: "underline",
      color: '#fff',
      backgroundColour: primaryColour,
      padding: "0px"
    }
  }

aPlanTheme.gradient = {
  ...defaultTheme.gradient,
  background: '#FCE9E1'
}
aPlanTheme.icon = {
  ...defaultTheme.icon,
  color: primaryColour,
  backgroundColor: '#E9F0FF'
}
aPlanTheme.GbIcon = '#fff';
aPlanTheme.formControlValidIcon = {
  ...defaultTheme.formControlValidIcon,
  colour: '#fff',
  backgroundColour: primaryColour
}
aPlanTheme.headerFontColour = primaryColour;
aPlanTheme.inputBorderColour = '#ccc';
aPlanTheme.successColour = primaryColour;
aPlanTheme.marketingPreferencesColour = secondaryColour;
aPlanTheme.svg = {
  ...defaultTheme.svg,
  primaryColour: primaryColour,
  accentColour: '#FFBABF',
}
aPlanTheme.icons ={
  border: "",
  height: '33px',
  width: '43px'
}


export default aPlanTheme;
