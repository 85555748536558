import React from "react";
import { Wrapper } from "./Wrapper";
import { ProvisionalLicenceButton, ProvisionalLicenceIcon } from "./styles";
import { LabelContent } from "./LabelContent";
import { Grid, GridContainer } from "unsemantic";
import { useRiskData } from "hooks";

export const ProvisionalLicence = ({vehicleReg}) => {
  const {riskData} = useRiskData()
  const {DurationType, Duration} = riskData
  const PolicyDurationType = DurationType && DurationType.toLowerCase();


  let learnerDuration = 7;
  const learnerDurationType = 'days';

  switch (PolicyDurationType) {
    case 'day':
    case 'days':
      if (Duration && Duration > 7) {
        learnerDuration = Duration;
      }
      break;
    case 'week':
    case 'weeks':
      learnerDuration = Duration * 7;
      break;
    default:
      break;
  }

  return (
    <Wrapper>
      <GridContainer>
        <Grid desktop="20" mobile="20" mobile-prefix="5" tablet="20">
          <ProvisionalLicenceIcon className="fa fa-exclamation-triangle" />
        </Grid>
        <Grid desktop="80" mobile="75" tablet="80">
          <LabelContent>
            We have different insurance that's designed specifically for learner drivers
          </LabelContent>
        </Grid>
      </GridContainer>
      <Grid desktop="100">
        <a href={`/?registration=${vehicleReg}&duration=${learnerDuration}+${learnerDurationType}&learner=true`}>
          <ProvisionalLicenceButton  type="button" onClick={() => {}}>
            SEE PROVISIONAL LICENCE INSURANCE
          </ProvisionalLicenceButton>
        </a>
      </Grid>
    </Wrapper>
  );
};
