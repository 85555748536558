import FormField from 'components/@common/FormField';
import Select from 'components/@common/Select';
import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { handleLicenceDurations } from 'utils/driver-details/handle-licence-durations';
import { LicenceDuration } from 'types/global';
import { SelectType } from '../types';
import { LICENCE_HELD_DURATIONS } from './LIcenceHeldDuration.constants';
import { PrivateCarFormData } from '../../PrivateCarForm/PrivateCarForm.types';
import { CommercialVehicleFormData } from '../../CommercialVehicleForm/CommercialVehicleForm.types';

interface Props extends SelectType {
  licenceDurations?: LicenceDuration[]
  label?: string;
  placeholder?: string;
 }

const LicenceHeldDuration: React.FC<Props> = ({
  onSelect,
  licenceDurations = LICENCE_HELD_DURATIONS,
  label = 'Licence held duration',
  placeholder = 'Please select your licence held duration',
}) => {
  const {
    values, setFieldValue,
  } = useFormikContext<PrivateCarFormData | CommercialVehicleFormData>();
  const [validLicenceDurations, setValidLicenceDurations] = useState(
    licenceDurations,
  );

  const dateOfBirthValue = values.dateOfBirth;
  const licenceHeldDurationValue = values.licenceHeldDuration;

  useEffect(() => {
    if (dateOfBirthValue) {
      const dateFormat = 'DD/MM/YYYY';
      setValidLicenceDurations(handleLicenceDurations(dateOfBirthValue,
        dateFormat, licenceDurations));
    }
  }, [dateOfBirthValue]);

  useEffect(() => {
    if (validLicenceDurations && licenceHeldDurationValue) {
      // Check if the value is no longer in the validLicenceDurations.
      // If not then clear out the formik value
      if (validLicenceDurations.filter((l) => l.value === licenceHeldDurationValue).length === 0) {
        setFieldValue('licenceHeldDuration', '', false);
      }
    }
  }, [licenceHeldDurationValue, validLicenceDurations]);

  return (
    <FormField
      name="licenceHeldDuration"
      label={label}
      isValidIcon={false}

    >
      <Select
        id="LicenceHeldDuration"
        onChange={onSelect}
        name="licenceHeldDuration"
        options={validLicenceDurations}
        placeholder={placeholder}
      />
    </FormField>
  );
};

export default LicenceHeldDuration;
