import React from 'react';

export const Motorbike = ({width, height}) => {
    const opts = {};

    if(width){
        opts.width = width;
    }

    if(height){
        opts.height = height;
    }

    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 134.997 134.998" {...opts}>
            <g id="Group_318" data-name="Group 318" transform="translate(2711 8785)">
                <path id="Path_318" data-name="Path 318" d="M-220.215,1052.008a67.057,67.057,0,0,1-47.729-19.77,67.057,67.057,0,0,1-19.77-47.729,67.057,67.057,0,0,1,19.77-47.729,67.056,67.056,0,0,1,47.729-19.77,67.056,67.056,0,0,1,47.729,19.77,67.057,67.057,0,0,1,19.77,47.729,67.057,67.057,0,0,1-19.77,47.729A67.057,67.057,0,0,1-220.215,1052.008Zm0-132.352a64.926,64.926,0,0,0-64.853,64.853,64.926,64.926,0,0,0,64.853,64.853,64.926,64.926,0,0,0,64.853-64.853A64.926,64.926,0,0,0-220.215,919.656Z" transform="translate(-2423.286 -9702.01)" fill="#264694"></path>
                <g id="Group_283" data-name="Group 283" transform="translate(-2679.95 -8740.448)">
                    <path id="Path_286" data-name="Path 286" d="M-10.031,1067.921c2.757,0,5.514-2.757,5.514-2.757,0-8.272-19.3-11.029-19.3-11.029a2.7,2.7,0,0,0-2.757,2.757C-21.06,1056.891-10.031,1062.406-10.031,1067.921Z" transform="translate(74.773 -1052.811)" fill="#ccdcff"></path>
                    <path id="Path_287" data-name="Path 287" d="M-127.533,1088.981l2.758,2.758-8.272,13.786h-16.544c-2.757,0-5.514-2.757-5.514-2.757l-5.515-11.029a27.475,27.475,0,0,1-11.029-2.758,9.794,9.794,0,0,1-5.514-8.271,29.982,29.982,0,0,0,13.787,2.757c2.757,0,8.272,2.757,8.272,2.757l5.514,13.787h11.029Z" transform="translate(178.488 -1071.114)" fill="#ccdcff"></path>
                    <path id="Path_324" data-name="Path 324" d="M-171.821,1139.94a9.606,9.606,0,0,1-9.6-9.595,9.606,9.606,0,0,1,9.6-9.6,9.606,9.606,0,0,1,9.595,9.6A9.606,9.606,0,0,1-171.821,1139.94Zm0-16.544a6.957,6.957,0,0,0-6.949,6.949,6.957,6.957,0,0,0,6.949,6.949,6.957,6.957,0,0,0,6.949-6.949A6.957,6.957,0,0,0-171.821,1123.4Z" transform="translate(181.416 -1098.691)" fill="#264694"></path>
                    <path id="Path_325" data-name="Path 325" d="M-3.513,1139.94a9.606,9.606,0,0,1-9.595-9.595,9.606,9.606,0,0,1,9.595-9.6,9.606,9.606,0,0,1,9.6,9.6A9.606,9.606,0,0,1-3.513,1139.94Zm0-16.544a6.957,6.957,0,0,0-6.949,6.949,6.957,6.957,0,0,0,6.949,6.949,6.957,6.957,0,0,0,6.949-6.949A6.957,6.957,0,0,0-3.513,1123.4Z" transform="translate(65.497 -1098.691)" fill="#264694"></path>
                    <path id="Path_345" data-name="Path 345" d="M-139.731,1149.971h-13.786a1.323,1.323,0,0,1-1.323-1.323,1.323,1.323,0,0,1,1.323-1.323h13.786a1.323,1.323,0,0,1,1.323,1.323A1.323,1.323,0,0,1-139.731,1149.971Z" transform="translate(163.112 -1116.995)" fill="#264694"></path>
                    <path id="Path_346" data-name="Path 346" d="M-135.976,1085.618H-152.52c-3.212,0-6.128-2.823-6.45-3.145a1.327,1.327,0,0,1-.248-.344l-5.164-10.329a29.013,29.013,0,0,1-10.788-2.866,11.066,11.066,0,0,1-6.246-9.455,1.323,1.323,0,0,1,.628-1.125,1.322,1.322,0,0,1,1.287-.058,28.508,28.508,0,0,0,13.2,2.618c2.7,0,7.3,2.137,8.58,2.757h6.8a7.28,7.28,0,0,1,6.683-5.515H-132.6l-1.8-3.6a1.325,1.325,0,0,1-.14-.592,3.966,3.966,0,0,1,4.08-4.08,1.314,1.314,0,0,1,.187.013,57,57,0,0,1,9.908,2.477c6.986,2.495,10.529,5.813,10.529,9.862a1.322,1.322,0,0,1-.388.936c-.322.322-3.237,3.144-6.45,3.144h-3.953l2.5,15a1.324,1.324,0,0,1-1.088,1.523,1.325,1.325,0,0,1-1.522-1.088l-2.757-16.544a1.323,1.323,0,0,1,.3-1.072,1.323,1.323,0,0,1,1.009-.468h5.515c1.508,0,3.241-1.2,4.153-1.981-.778-5.446-13.045-8.426-18.014-9.156a1.3,1.3,0,0,0-1.343,1.154l2.6,5.2a1.322,1.322,0,0,1-.058,1.287,1.323,1.323,0,0,1-1.125.628h-13.787a4.606,4.606,0,0,0-4.192,4.191,1.323,1.323,0,0,1-1.323,1.323h-8.272a1.328,1.328,0,0,1-.591-.139c-1.458-.728-5.679-2.618-7.681-2.618a33.2,33.2,0,0,1-12.027-1.87,8.445,8.445,0,0,0,4.347,4.878,26.21,26.21,0,0,0,10.438,2.618,1.323,1.323,0,0,1,1.183.731l5.412,10.825c.788.732,2.751,2.231,4.433,2.231h15.8l7.355-12.259-1.639-1.64h-9.933l-5.127,5.127a1.324,1.324,0,0,1-1.871,0,1.323,1.323,0,0,1,0-1.871l5.515-5.515a1.324,1.324,0,0,1,.936-.387h11.029a1.323,1.323,0,0,1,.935.387l2.757,2.758a1.323,1.323,0,0,1,.2,1.616l-8.272,13.786A1.323,1.323,0,0,1-135.976,1085.618Z" transform="translate(181.416 -1049.884)" fill="#264694"></path>
                </g>
            </g>
        </svg>
    )
}