import React from 'react';

export const AA = ({ width, height }) => {
    const opts = {};

    if (width) {
        opts.width = width;
    }

    if (height) {
        opts.height = height;
    }

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
            <g id="Group_1052" data-name="Group 1052" transform="translate(0 0)">
                <rect id="Rectangle_1310" data-name="Rectangle 1310" width="30" height="30" transform="translate(0 0)" fill="#ffd401" />
                <path id="Path_1164" data-name="Path 1164" d="M16.328,21.75,9.289,37.28h2.97l1.462-2.91h3.848l-.006,2.91h2.945V21.75ZM14.713,31.5l2.874-6.088L17.574,31.5Z" transform="translate(-6.439 -15.076)" fill="#1a1818" />
                <path id="Path_1165" data-name="Path 1165" d="M52.889,21.75,45.85,37.28h2.97l1.462-2.91H54.13l-.006,2.91h2.946V21.75ZM51.274,31.5l2.874-6.088L54.135,31.5Z" transform="translate(-31.781 -15.076)" fill="#1a1818" />
            </g>
        </svg>
    )
}






