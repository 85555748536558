import { DurationPreset, DurationType } from 'types/global';

export const POLICY_DURATION_HOURS = 'hours';
export const POLICY_DURATION_DAYS = 'days';
export const POLICY_DURATION_WEEKS = 'weeks';

const EXTRA_DAY_OPTIONS_DEFAULT: {id: number; value: number;
  type: DurationType; description: string;}[] = [
    {
      id: 1,
      value: 8,
      type: POLICY_DURATION_DAYS,
      description: '8 days',
    },
    {
      id: 2,
      value: 9,
      type: POLICY_DURATION_DAYS,
      description: '9 days',
    },
    {
      id: 3,
      value: 10,
      type: POLICY_DURATION_DAYS,
      description: '10 days',
    },
    {
      id: 4,
      value: 11,
      type: POLICY_DURATION_DAYS,
      description: '11 days',
    },
    {
      id: 5,
      value: 12,
      type: POLICY_DURATION_DAYS,
      description: '12 days',
    },
    {
      id: 6,
      value: 13,
      type: POLICY_DURATION_DAYS,
      description: '13 days',
    },
    {
      id: 7,
      value: 14,
      type: POLICY_DURATION_DAYS,
      description: '14 days',
    },
    {
      id: 8,
      value: 15,
      type: POLICY_DURATION_DAYS,
      description: '15 days',
    },
    {
      id: 9,
      value: 16,
      type: POLICY_DURATION_DAYS,
      description: '16 days',
    },
    {
      id: 10,
      value: 17,
      type: POLICY_DURATION_DAYS,
      description: '17 days',
    },
    {
      id: 11,
      value: 18,
      type: POLICY_DURATION_DAYS,
      description: '18 days',
    },
    {
      id: 12,
      value: 19,
      type: POLICY_DURATION_DAYS,
      description: '19 days',
    },
    {
      id: 13,
      value: 20,
      type: POLICY_DURATION_DAYS,
      description: '20 days',
    },
    {
      id: 14,
      value: 21,
      type: POLICY_DURATION_DAYS,
      description: '21 days',
    },
    {
      id: 15,
      value: 22,
      type: POLICY_DURATION_DAYS,
      description: '22 days',
    },
    {
      id: 16,
      value: 23,
      type: POLICY_DURATION_DAYS,
      description: '23 days',
    },
    {
      id: 17,
      value: 24,
      type: POLICY_DURATION_DAYS,
      description: '24 days',
    },
    {
      id: 18,
      value: 25,
      type: POLICY_DURATION_DAYS,
      description: '25 days',
    },
    {
      id: 19,
      value: 26,
      type: POLICY_DURATION_DAYS,
      description: '26 days',
    },
    {
      id: 20,
      value: 27,
      type: POLICY_DURATION_DAYS,
      description: '27 days',
    },
    {
      id: 21,
      value: 28,
      type: POLICY_DURATION_DAYS,
      description: '28 days',
    },
  ];

const DURATION_PRESETS_DEFAULT: DurationPreset[] = [
  {
    id: 1,
    durationLength: 1,
    durationType: POLICY_DURATION_DAYS,
    description: '1 Day',
  },
  {
    id: 2,
    durationLength: 2,
    durationType: POLICY_DURATION_DAYS,
    description: '2 Days',
  },
  {
    id: 3,
    durationLength: 1,
    durationType: POLICY_DURATION_WEEKS,
    description: '1 Week',
  },
];

const HOUR_OPTIONS_DEFAULT = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
const DAY_OPTIONS_DEFAULT = [1, 2, 3, 4, 5, 6, 7];
const WEEK_OPTIONS_DEFAULT = [1, 2, 3, 4];

const DURATION_PRESETS_IMPOUNDED = [
  {
    id: 1,
    durationLength: 30,
    durationType: POLICY_DURATION_DAYS,
    description: '30 Days',
  },
];

const DURATION_PRESETS_MOTORBIKE = [
  {
    id: 1,
    durationLength: 1,
    durationType: POLICY_DURATION_DAYS,
    description: '1 Day',
  },
  {
    id: 2,
    durationLength: 2,
    durationType: POLICY_DURATION_DAYS,
    description: '2 Days',
  },
  {
    id: 3,
    durationLength: 1,
    durationType: POLICY_DURATION_WEEKS,
    description: '1 Week',
  },
];

export const LEARNER_HOUR_OPTIONS=[12];
const DAY_OPTIONS_MOTORCYCLE = [1, 2, 3, 4, 5, 6, 7];

export const DURATION_OBJECT_DEFAULT = {
  presets: DURATION_PRESETS_DEFAULT,
  durationExtraOptionsSet: EXTRA_DAY_OPTIONS_DEFAULT,
  hourDisplayOptions: HOUR_OPTIONS_DEFAULT,
  dayDisplayOptions: DAY_OPTIONS_DEFAULT,
  weekDisplayOptions: WEEK_OPTIONS_DEFAULT,
  isEditable: true,
};

export const DURATION_OBJECT_LEARNER = {
  presets: DURATION_PRESETS_DEFAULT,
  durationExtraOptionsSet: EXTRA_DAY_OPTIONS_DEFAULT,
  hourDisplayOptions: LEARNER_HOUR_OPTIONS,
  dayDisplayOptions: DAY_OPTIONS_DEFAULT,
  weekDisplayOptions: WEEK_OPTIONS_DEFAULT,
  isEditable: true,
}

export const DURATION_OBJECT_IMPOUNDED = {
  presets: DURATION_PRESETS_IMPOUNDED,
  durationExtraOptionsSet: [],
  hourDisplayOptions: [],
  dayDisplayOptions: [30],
  weekDisplayOptions: [],
  isEditable: false,
};

export const DURATION_OBJECT_MOTORCYCLE = {
  presets: DURATION_PRESETS_MOTORBIKE,
  durationExtraOptionsSet: [],
  hourDisplayOptions: [],
  dayDisplayOptions: DAY_OPTIONS_MOTORCYCLE,
  weekDisplayOptions: [],
  isEditable: true,
};
