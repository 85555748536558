import { store } from 'api/store'
import config from 'config'
import { UserManager, UserManagerSettings } from 'oidc-client-ts'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { setAuthAccessToken } from 'store/auth/auth.actions'

const LoggedInCallback = () => {
  const [userToken, setUserToken] = useState('')
  const authConfig: UserManagerSettings = {
    authority: config.CUSTOMER_ACCOUNT_ID_SERVER_URL,
    client_id: 'teslaClient',
    redirect_uri: `${config.HOST_PATH}/auth/callback`,
    response_type: 'code',
    filterProtocolClaims: true,
    loadUserInfo: true,
    scope: 'openid profile api',
    post_logout_redirect_uri: config.SITE_URL,
  }
  // @ts-ignore
  const mgr = new UserManager(authConfig)

  const dispatch = useDispatch()

  useEffect(() => {
    mgr.signinRedirectCallback().then((user) => {
      dispatch(setAuthAccessToken({ accessToken: user.access_token }))
      // eslint-disable-next-line @typescript-eslint/dot-notation
      store.authToken = user.access_token
      setUserToken(user.access_token)
      // redirect back
      window.location.href = '/'
    })
  })

  const callApi = () => {
    fetch(`${config.CUSTOMER_ACCOUNT_API_URL}/customer/details`, {
      headers: {
        Authorization: `Bearer ${userToken}`,
        'x-api-key': config.CUSTOMER_ACCOUNT_API_KEY,
      },
    })
  }

  return (
    <div>
      <div>Hi</div>
      <button type='button' onClick={callApi}>
        Press me
      </button>
    </div>
  )
}

export default LoggedInCallback
