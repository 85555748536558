declare global {
  interface Window {
      FS: {
        event: (eventName: string, body: any) =>  void
      };
  }
}

const logFullStoryCustomEvent = (eventName: string, body?: any) => {
    if (window.FS) {
      window.FS.event(eventName, body);
    }
  
    return null;
  };
  
  export default logFullStoryCustomEvent;
  