import React from 'react';
import PropTypes from 'prop-types';
import { BaseSelect } from './BaseSelect';

export const Select = ({
  id,
  value,
  options,
  error,
  onChange,
  placeholder,
  name,
  style,
  myRef
}) => {
  const className = (value === '' || value === 'Uninitialised' || value === null ? 'placeholder' : '');
  let isValueInOptions = true;
  if(placeholder){
    isValueInOptions = options.filter(option => option.value === value).length > 0;
  }
  
  return (
    <BaseSelect
      ref={myRef}
      id={id}
      error={error}
      onChange={onChange}
      value={value}
      name={name}
      style={style}
      className={className}
      isValueInOptions={isValueInOptions}
    >
      {placeholder && <option value=''>{placeholder}</option> }
      {options && options.map(option => (
        <option key={option.id} value={option.value}>
          {option.description}
        </option>
      ))}
    </BaseSelect>
  );
};

Select.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      // Using any as we have instances where we are getting numbers / strings for value and ID
      value: PropTypes.any,
      id: PropTypes.any,
      description: PropTypes.string
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string,
  placeholder: PropTypes.string
};
