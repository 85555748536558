
export enum SchemeEnum {
  PRIVATE_CAR = 'VehiclePrivateCarBaseScheme',
  PRIVATE_CAR_IMPOUNDED = 'VehiclePrivateCarImpoundedScheme',
  COMMERCIAL_VEHICLE = 'VehicleCommercialVehicleBaseScheme',
  COMMERCIAL_VEHICLE_IMPOUNDED = 'VehicleCommercialVehicleImpoundedScheme',
  LEARNER = 'VehicleLearnerScheme',
  MOTORCYCLE = 'VehicleMotorcycleLearnerAndFullLicenceScheme',
  COURIER = 'VehicleCommercialVehicleCourierScheme',
};

