import {
  useTimer,
} from 'hooks';
import { TimeUnits } from 'hooks/useTimer/useTimer.types';
import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { FixedWidthTime, TimerText } from './TimerBanner.styles';

const UpdatedTimerBanner = () => {
  const { timeLeft, hasHitZero } = useTimer();
  const iniitalTimeValue = useRef<TimeUnits | null>(null);
  iniitalTimeValue.current = timeLeft;

  const [showTimer, setShowTimer] = useState(true);
  const timerCheck = useCallback(() => {
    // Don't want to show timer if less than 3 minutes on clock
    // On first render
    if (iniitalTimeValue.current && iniitalTimeValue.current.minutes <= 2) {
      setShowTimer(false);
    }
  }, [iniitalTimeValue]);

  useEffect(() => {
    timerCheck();
  }, [timerCheck]);

  return (
    <>
      {showTimer && (
        <TimerText>
          Policy price guaranteed for
          {' '}
          <FixedWidthTime>
            {hasHitZero ? '00' : timeLeft.minutes}
            :
            {hasHitZero ? '00' : timeLeft.seconds}
          </FixedWidthTime>
        </TimerText>
      )}

    </>
  );
}

export default UpdatedTimerBanner;
