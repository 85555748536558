import { useSelector } from 'react-redux';
import { InitialStateType } from 'initialState';

const useFormButtons = () => {
  const formButtons = useSelector((state: InitialStateType) => state.formButtons);
  return {
    formButtons,
  };
};

export default useFormButtons;
