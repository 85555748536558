import { useSelector } from 'react-redux';
import { InitialStateType } from 'initialState';
import quoteApi from 'api/quoteApi';
import { GET_PRICE_REL } from 'containers/App/constants';
import { useQuery } from 'react-query';
import { getAddonSelection } from '../useAddons/useAddons';
import { removeDecimalPoint } from 'utils/handle-decimal-value';

const useFetchPrice = (calculateNextPrice = false) => {
  // TODO: For future, when we revisit this, remove the fetch link stuff
  const priceLink = useSelector(
    (state: InitialStateType) =>
      state.links.filter((link) => link.Rel === GET_PRICE_REL)[0]
  );
  const quoteId = useSelector(
    (state: InitialStateType) => state.riskData.QuoteId
  );

  const excessReductionIncluded = useSelector(
    (state: InitialStateType) =>
      state.price.ExcessReduction.ExcessReductionIncluded
  );

  const legalexpensesIncluded = useSelector(
    (state: InitialStateType) => state.price.LegalExpensesCover.LegalExpensesIncluded
  );

  const breakdownIncluded = useSelector(
    (state: InitialStateType) => state.price.BreakdownCover.BreakdownIncluded
  );

  const query = useQuery(
    'fetchPrice',
    () =>
      quoteApi.getPrice(
        priceLink.Href,
        {
          value: quoteId,
          CalculateNextPrice: calculateNextPrice,
          ExcessReductionIncluded: excessReductionIncluded,
          BreakdownIncluded: breakdownIncluded,
          LegalExpensesIncluded: legalexpensesIncluded,
          QuoteJourneyPosition: 'CustomerPricePostEnrichment'
        },
        {
          accept: 'application/tempcover.PricingRepresentation+json',
        }
      ),
    {
      enabled: true,
      refetchOnWindowFocus: false,
    }
  );

  return query;
};

const usePrice = () => {
  const selectedAddons = useSelector(getAddonSelection);
  const price = useSelector((state: InitialStateType) => state.price);

  const totalPrice = useSelector(
    (state: InitialStateType) => state.price.TotalPrice
  );
  const affiliateCashbackValue = useSelector(
    (state: InitialStateType) => state.price.AffiliateCashbackValue
  );

  const wordingParts = [
    ...selectedAddons
      .filter((a) => a.selected)
      .map((selected) => selected.wording),
  ];

  const encryptedString = useSelector(
    (state: InitialStateType) => state.price.EncryptedString,
  );

  const totalPriceFixed = totalPrice > 0 ? totalPrice.toFixed(2) : '0.00';
  const totalPriceWithoutDecimal = removeDecimalPoint(totalPrice || 0.00)

  return {
    price,
    totalPrice,
    useFetchPrice,
    totalPriceFixed,
    affiliateCashbackValue,
    totalPriceWithoutDecimal,
    coverTypeWording: wordingParts.length
      ? `${price.CoverType} with ${wordingParts.join(' and ')}`
      : price.CoverType,
    encryptedString
  };
};

export default usePrice;
