/* eslint-disable max-len */
import useRiskData from 'hooks/useRiskData';
import useScheme from 'hooks/useScheme';
import { CTM_REF } from 'services/affiliateConstants';
import { handleQueryString } from 'services/handlers';
import {
  COMMERCIAL_VEHICLE_SCHEME,
  PRIVATE_CAR_SCHEME,
} from '../../containers/Vehicle/constants';

const useWhitelabelLogo = () => {
  const { scheme } = useScheme();
  const { riskData } = useRiskData();
  const {
    Ref, Expat, Learner, Impounded, Motorbike,
  } = handleQueryString();
  const FormattedRef = ((Ref as string) || '').toUpperCase();

  const showAdditionalImage = FormattedRef === CTM_REF
      && (((scheme === COMMERCIAL_VEHICLE_SCHEME || scheme === PRIVATE_CAR_SCHEME) && riskData.Expat === false)
      || (!scheme && !Learner && !Expat && !Motorbike && !Impounded));
  const queryStringRef = FormattedRef;

  return {
    showAdditionalImage,
    queryStringRef,
  };
};

export default useWhitelabelLogo;
