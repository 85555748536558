import { useStripe } from '@stripe/react-stripe-js'
import paymentApi from 'api/paymentApi'
import Button from 'components/@common/Button'
import { Message } from 'components/Message'
import config from 'config'
import {
  setPaymentInProgress,
  setPaymentNotInProgress,
} from 'containers/PaymentPage/actions'
import { createStripePaymentFailure } from 'containers/Stripe/actions'
import {
  useABExperiment,
  usePayment,
  usePrice,
  useQueryString,
  useRiskData,
} from 'hooks'
import { history } from 'index'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import logFullStoryCustomEvent from 'utils/fullstory-custom-event'
import {
  klarnaFailureAction,
  priceCheck,
  startKlarnaPayment,
} from './Klarna.actions'
import { KlarnaErrorButtonWrapper, KlarnaErrorWrapper } from './Klarna.styles'
import {
  KlarnaOption as KlarnaOptionType,
  KlarnaOptionFullInfo,
} from './Klarna.types'
import KlarnaOption from './KlarnaOption'

interface Props {
  loadKlarna: boolean
  onSuccess?: () => void
  onFailure?: () => void
}
const KlarnaContainer: React.FC<Props> = ({ loadKlarna, onFailure }) => {
  const [isKlarnaButtonDisabled, setIsKlarnaButtonDisabled] = useState(false)
  const [isKlarnaButtonLoading, setIsKlarnaButtonLoading] = useState(false)
  const [clientSecret, setClientSecret] = useState('')
  const [isKlarnaAvailable, setIsKlarnaAvailable] = useState(true)
  const { variant: multiPriceVariant } = useABExperiment(
    config.AB_TESTS.PRICE_COMPARISON_EXPERIMENT_ID,
    'Price comparison experiment',
  )
  const {
    totalPriceWithoutDecimal,
    totalPrice,
    encryptedString,
    price: { BreakdownCover, ExcessReduction, LegalExpensesCover },
  } = usePrice()
  const {
    payment: { shouldStartKlarnaPayment, klarnaFailure },
  } = usePayment()
  const { quoteId } = useRiskData()
  const { queryString } = useQueryString()
  const dispatch = useDispatch()

  const [klarnaOptionsArray, setKlarnaOptionsArray] =
    useState<KlarnaOptionFullInfo>([
      {
        name: 'pay_later',
        klarnaDetails: {
          displayName: '',
          logo: '',
          isAllowed: false,
        },
      },
    ])
  const [sourceId, setSourceId] = useState('')

  const stripeActions = useStripe()

  const initialiseKlarna = useCallback(async () => {
    if (stripeActions && loadKlarna) {
      setIsKlarnaAvailable(true)
      try {
        const sourceResponse = await stripeActions.createSource({
          type: 'klarna',
          amount: totalPriceWithoutDecimal,
          currency: 'gbp',
          klarna: {
            // @ts-ignore
            product: 'payment',
            // @ts-ignore
            purchase_country: 'GB',
          },
          source_order: {
            items: [
              {
                type: 'sku',
                description: 'Tempcover - motor insurance',
                quantity: 1,
                amount: totalPriceWithoutDecimal,
                currency: 'gbp',
              },
            ],
          },
        })

        const { source } = sourceResponse

        if (source && source.klarna && source.klarna.client_token) {
          setSourceId(source.id)
          setClientSecret(source.client_secret)
          // @ts-ignore
          Klarna.Payments.init({
            client_token: source.klarna.client_token,
          })
          const availableCategories =
            source.klarna.payment_method_categories!.split(',')

          for (const category of availableCategories) {
            // Using switch statement as we need to A/B this and are only going to end up using one.
            // TODO: After experiment remove one of these
            switch (category) {
              case 'pay_later':
                // @ts-ignore
                Klarna.Payments.load(
                  {
                    container: `#klarna_${category}_container`,
                    payment_method_category: category,
                  },
                  (res: any) => {
                    if (res.show_form) {
                      /*
                       * This payment method category can be used: allow the customer
                       * to choose it in your interface
                       */
                      const newOptionsArray = klarnaOptionsArray.map(
                        (klarnaOptionDetails) => ({
                          ...klarnaOptionDetails,
                          klarnaDetails: {
                            ...klarnaOptionDetails.klarnaDetails,
                            displayName: source.klarna!
                              .pay_later_name as KlarnaOptionType,
                            logo: source.klarna!.pay_later_asset_urls_standard,
                            isAllowed: true,
                          },
                        }),
                      )
                      setKlarnaOptionsArray(newOptionsArray)
                    } else {
                      // This payment method category is not available
                      const newOptionsArray = klarnaOptionsArray.map(
                        (klarnaOptionDetails) => ({
                          ...klarnaOptionDetails,
                          klarnaDetails: {
                            ...klarnaOptionDetails.klarnaDetails,
                            isAllowed: false,
                          },
                        }),
                      )
                      setKlarnaOptionsArray(newOptionsArray)
                    }
                  },
                )
                break
              default:
                break
            }
          }
        } else {
          throw 'no correct response'
        }
      } catch (err) {
        setIsKlarnaAvailable(false)
        setIsKlarnaButtonDisabled(false)
        setIsKlarnaButtonLoading(false)
      }
    }
  }, [loadKlarna, stripeActions, totalPriceWithoutDecimal])

  const handlePaymentClick = useCallback(
    async (paymentCategory: 'pay_later' | 'pay_over_time') => {
      // Need pre-klarna price check
      setIsKlarnaButtonDisabled(true)
      logFullStoryCustomEvent('Klarna - Start Payment')

      try {
        // @ts-ignore
        Klarna.Payments.authorize(
          {
            payment_method_category: paymentCategory,
          },
          async (res: any) => {
            if (res.approved && encryptedString) {
              dispatch(setPaymentInProgress())
              setIsKlarnaButtonLoading(true)
              // Need price check here too, as price could have changed
              dispatch(priceCheck())
              //  payment authorised
            } else if (res.error) {
              throw 'Error'
            } else {
              setIsKlarnaButtonDisabled(false)
              setIsKlarnaButtonLoading(false)
            }
          },
        )
      } catch (err) {
        dispatch(setPaymentNotInProgress())
        dispatch(
          createStripePaymentFailure(
            'We are sorry there has been an error with your payment. Please try again.',
          ),
        )
        setIsKlarnaButtonDisabled(false)
        setIsKlarnaButtonLoading(false)
        if (onFailure) {
          onFailure()
        }
      }
    },
    [
      setIsKlarnaButtonDisabled,
      encryptedString,
      queryString,
      dispatch,
      priceCheck,
    ],
  )

  const handleKlarnaPayment = useCallback(async () => {
    if (encryptedString) {
      try {
        const paymentResponse = await paymentApi.createKlarnaPayment({
          source: sourceId,
          amount: totalPrice,
          QuoteId: {
            Value: quoteId,
          },
          ValidationData: {
            Value: encryptedString,
          },
          AllowPriceComparison: Boolean(multiPriceVariant),
          BreakdownIncluded: BreakdownCover.BreakdownIncluded,
          ExcessReductionIncluded: ExcessReduction.ExcessReductionIncluded,
          LegalExpensesIncluded: LegalExpensesCover.LegalExpensesIncluded,
          ClientSecret: clientSecret,
        })
        if (paymentResponse.data.PaymentStatus === 200) {
          history.push(`/policy/confirmation${queryString}`)
        } else {
          throw 'Error!'
        }
      } catch (err) {
        dispatch(setPaymentNotInProgress())
        dispatch(startKlarnaPayment(false))
        dispatch(
          createStripePaymentFailure(
            'We are sorry there has been an error with your payment. Please try again.',
          ),
        )
        if (onFailure) {
          onFailure()
        }
        setIsKlarnaButtonDisabled(false)
        setIsKlarnaButtonLoading(false)
      }
    }
  }, [
    totalPrice,
    sourceId,
    quoteId,
    encryptedString,
    BreakdownCover,
    ExcessReduction,
    clientSecret,
  ])

  useEffect(() => {
    if (shouldStartKlarnaPayment) {
      handleKlarnaPayment()
    } else {
      setIsKlarnaButtonDisabled(false)
      setIsKlarnaButtonLoading(false)
    }
  }, [shouldStartKlarnaPayment])

  useEffect(() => {
    if (klarnaFailure) {
      setIsKlarnaButtonDisabled(false)
      setIsKlarnaButtonLoading(false)
      if (onFailure) {
        onFailure()
      }
      // Set klarna failure to false after handling error
      dispatch(klarnaFailureAction(false))
    }
  }, [klarnaFailure, onFailure])

  useEffect(() => {
    initialiseKlarna()
  }, [stripeActions, loadKlarna, initialiseKlarna])

  if (!isKlarnaAvailable) {
    return (
      <KlarnaErrorWrapper>
        <Message
          type='error'
          heading='Klarna issue'
          content='Unfortunately we are having issues with Klarna. We apologise and advise you try again later or pay with another method.'
        />
        {/* This assumes Klarna will be in a modal. 
                    If in the future it isn't then we will have to re-visit this */}
        <KlarnaErrorButtonWrapper>
          <Button type='button' onClick={onFailure}>
            Return to payment page
          </Button>
        </KlarnaErrorButtonWrapper>
      </KlarnaErrorWrapper>
    )
  }
  // Loop through options that we have in an array.
  //  Pass through object that relates to that klarna option
  return (
    <>
      {klarnaOptionsArray.map((klarnaOptionDetails) => (
        <KlarnaOption
          name={klarnaOptionDetails.name}
          optionDetails={klarnaOptionDetails.klarnaDetails}
          onClick={handlePaymentClick}
          isLoading={isKlarnaButtonLoading}
          isButtonDisabled={isKlarnaButtonDisabled}
        />
      ))}
    </>
  )
}

export default KlarnaContainer
