import React from 'react';
import PropTypes from 'prop-types';
import {P, Span, SectionWrapper} from './styles.js';
import { Grid, GridContainer, GridClear, } from 'unsemantic';
import Moment from 'moment';

const PolicyEndSection = ({policyEndDate}) => {  
    return(
        <GridContainer> 
            <SectionWrapper>
            <Grid desktop='20' tablet='20' mobile='30'>
                <P>Policy end date and time</P>
            </Grid>
            <Grid desktop='40' tablet='40' mobile='35'>
                <P><Span>{Moment(policyEndDate).format('DD/MM/YYYY')}</Span></P>
            </Grid>   
            <Grid desktop='40' tablet='40' mobile='35'>
                <P><Span>{Moment(policyEndDate).format('HH:mm')}</Span></P>
            </Grid> 
            <GridClear/>
            </SectionWrapper>                  
        </GridContainer>
    )
}

PolicyEndSection.propTypes = {
    policyEndDate: PropTypes.string
};

export default PolicyEndSection;
