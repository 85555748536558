import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';

export const BreakdownCover = ({ width = null, height = null }) => {
    const opts = {};
    const { svg } = useContext(ThemeContext);

    if(width){
        opts.width = width;
    }

    if(height){
        opts.height = height;
    }

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="105.72" height="105.719" viewBox="0 0 105.72 105.719">
            <g id="Group_558" data-name="Group 558" transform="translate(0)">
                <path id="Path_527" data-name="Path 527" d="M1261.542,126.965c-6.107,6.108-15.22,3.044-21.366,9.113s-3.131,12.2-3.131,12.2l6.146-6.069.028-3.025c4.581-4.581,13.742-1.527,21.376-9.161l25.957-25.957-4.581-1.527-24.43,24.43" transform="translate(-1211.332 -77.541)" fill={svg.accentColour}/>
                <path id="Path_528" data-name="Path 528" d="M1286.358,265.392H1283.3v9.161l-6.108,6.108a8.5,8.5,0,0,0,2.391.663l6.77-6.77Z" transform="translate(-1242.257 -200.699)" fill={svg.accentColour}/>
                <g id="Group_555" data-name="Group 555" transform="translate(0 0)">
                    <path id="Path_526" data-name="Path 526" d="M1186.719,105.719a52.86,52.86,0,0,1-37.378-90.237A52.86,52.86,0,1,1,1224.1,90.237a52.514,52.514,0,0,1-37.378,15.482m0-103.647a50.788,50.788,0,1,0,50.788,50.788,50.845,50.845,0,0,0-50.788-50.788" transform="translate(-1133.859 0)" fill={svg.primaryColour}/>
                    <path id="Path_529" data-name="Path 529" d="M1246.958,156.065a10.588,10.588,0,0,1-4.288-.849,1.036,1.036,0,0,1-.269-1.659l5.8-5.8V140.08l-7.731-.049-5.841,5.767a1.036,1.036,0,0,1-1.657-.28c-.034-.068-3.268-6.872,3.333-13.39,3.287-3.246,7.308-4.044,11.2-4.815,3.768-.748,7.326-1.454,10.165-4.292l24.43-24.43a1.038,1.038,0,0,1,1.06-.251l4.581,1.527a1.036,1.036,0,0,1,.655.655l1.527,4.581a1.035,1.035,0,0,1-.25,1.06l-24.43,24.43c-2.832,2.832-3.544,6.391-4.3,10.159-.781,3.9-1.588,7.942-4.864,11.217a12.686,12.686,0,0,1-9.123,4.1m-1.9-2.234c1.991.426,5.739.492,9.558-3.327,2.832-2.832,3.544-6.391,4.3-10.159.781-3.9,1.588-7.942,4.864-11.218l23.977-23.977-1.16-3.482-3.482-1.161-23.977,23.977c-3.283,3.284-7.322,4.085-11.227,4.86-3.754.745-7.3,1.448-10.144,4.257-3.843,3.794-3.8,7.541-3.388,9.537l4.947-4.885a1.055,1.055,0,0,1,.735-.3l8.945.057a1.036,1.036,0,0,1,1.274,1.008v9.161a1.035,1.035,0,0,1-.3.733Z" transform="translate(-1208.193 -74.328)" fill={svg.primaryColour}/>
                </g>
            </g>
        </svg>
    )
}


