import React from 'react';
import { GridContainer, Grid } from 'unsemantic';
import { StyledGridContainerWrapper } from './GridContainerWrapper.styles';

interface GridContainerWrapperProps {
  pageBottomPadding?: boolean
  overflowX?: string
}

const GridContainerWrapper: React.FC<GridContainerWrapperProps> = ({
  pageBottomPadding = false, overflowX, children
}) => (
  <StyledGridContainerWrapper pageBottomPadding={pageBottomPadding} overflowX={overflowX}>
    <GridContainer>
      <Grid
        desktop-prefix="20"
        desktop="60"
        desktop-suffix="20"
        tablet="100"
        parent
      >
        <Grid
          desktop="90"
          desktop-prefix="5"
          tablet="70"
          tablet-prefix="15"
          mobile="100"
          parent
        >
          {children}
        </Grid>
      </Grid>
    </GridContainer>
  </StyledGridContainerWrapper>

);

export default GridContainerWrapper;
