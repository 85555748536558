import {
    CREATE_QUOTE_SUCCESS, REQUEST_QUOTE_SUCCESS
  } from '../constants';
  
  import initialState from '../../../initialState';
  
  function correlationReducer(state = initialState.correlationId, action) {
    switch (action.type) {   
      case CREATE_QUOTE_SUCCESS:
        return action.quote.CorrelationId
      case REQUEST_QUOTE_SUCCESS:
        return action.quote.QuoteDrivers[0].CorrelationId
      default:
        return state;
    }
  }
  
  export default correlationReducer;
  