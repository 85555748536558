import moment from 'moment';
import * as yup from 'yup';

export const DrivingLicenceSchema = yup.object().shape({
  drivingLicenceNumber: yup
    .string()
    .matches(
      /^[A-Z9]{5}\d{6}[A-Z9]{2}\d[A-Z]{2}$/,
      'Please enter a valid driving licence number',
    )
    .required('Please enter a valid driving licence number'),
});

export const PassportSchema = yup.object().shape({
  passportLineOne: yup
    .string()
    .matches(
      /^([P|p])(<)([G|g][B|b][R|r])([A-Za-z]{2})[(A-Za-z)<]{37}$/,
      'Please ensure your passport line one is valid',
    )
    .required('Please ensure your passport line one is valid'),
  passportLineTwo: yup    
    .string()
    .matches(
      /^([0-9]{10})([G|g][B|b][R|r])([0-9]{7})([Mm|Ff<])([0-9]{7})(([0-9<]){16})$/,
      'Please ensure your passport line two is valid',
    )
    .required('Please ensure your passport line two is valid'),
  passportExpiryDate: yup
    .string()
    .typeError('Please enter a valid expiry date')
    .test('passportExpiryDate', 'Please enter a valid expiry date', (value) => {
      const expiryDate = moment(value, 'D/M/YYYY');
      return !!value && /\d+\/\d+\/\d+/.test(value) && expiryDate.isValid();
    })
    .required('Please enter a valid expiry date')
});
