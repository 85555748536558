import {
  REQUEST_POLICY,
  REQUEST_POLICY_SUCCESS,
  REQUEST_POLICY_FAILURE,
  UPDATE_POLICY,
  UPDATE_POLICY_SUCCESS,
  UPDATE_POLICY_FAILURE,
  UPDATE_POLICY_CONTACT_DETAILS,
  UPDATE_VALIDATION_POLICY_CONFIRMATION,
  TOGGLE_FORM_IS_VALID_POLICY_CONFIRMATION,
  ENABLE_POLICY_HAS_EMAIL_ADDRESS,
  UPDATE_REASON_FOR_TEMPCOVER,
  UPDATE_REASON_FOR_TEMPCOVER_SUCCESS,
  UPDATE_REASON_FOR_TEMPCOVER_FAILURE,
  GET_DOCUMENTS_SUCCESS,
  INIT_POLICY_CONFIRMATION,
  GET_ANALYSIS_DATA,
  GET_ANALYSIS_DATA_SUCCESS,
  GET_ANALYSIS_DATA_FAILURE,
  UPDATE_ANALYSIS_DATA,
  UPDATE_ANALYSIS_DATA_SUCCESS,
  UPDATE_ANALYSIS_DATA_FAILURE
} from './constants';

export function initPolicyConfirmationPage() {
  return {
    type: INIT_POLICY_CONFIRMATION
  }
}

export function requestPolicy() {
  return {
    type: REQUEST_POLICY
  };
}

export function enablePolicyHasEmailAddress() {
  return {
    type: ENABLE_POLICY_HAS_EMAIL_ADDRESS
  };
}

export function requestPolicySuccess(policy, policyId) {
  return {
    type: REQUEST_POLICY_SUCCESS,
    policy,
    policyId
  };
}

export function requestPolicyFailure(message) {
  return {
    type: REQUEST_POLICY_FAILURE,
    message
  };
}

export function updatePolicy() {
  return {
    type: UPDATE_POLICY
  };
}

export function updatePolicySuccess() {
  return {
    type: UPDATE_POLICY_SUCCESS
  };
}

export function updatePolicyFailure(message) {
  return {
    type: UPDATE_POLICY_FAILURE,
    message
  };
}

export function updatePolicyContactDetails(contactDetails) {
  return {
    type: UPDATE_POLICY_CONTACT_DETAILS,
    contactDetails
  };
}

export function updateValidation(fields) {
  return {
    type: UPDATE_VALIDATION_POLICY_CONFIRMATION,
    fields
  }
}

export function toggleFormIsValid(form) {
  return {
    type: TOGGLE_FORM_IS_VALID_POLICY_CONFIRMATION,
    form
  }
}

export function updateReasonForTempcover(reasonForTempcover) {
  return {
    type: UPDATE_REASON_FOR_TEMPCOVER,
    reasonForTempcover
  }
}

export function updateReasonForTempcoverSuccess(response) {
  return {
    type: UPDATE_REASON_FOR_TEMPCOVER_SUCCESS,
    response
  }
}

export function updateReasonForTempcoverFailure(error) {
  return {
    type: UPDATE_REASON_FOR_TEMPCOVER_FAILURE,
    error
  }
}

export function getDocumentsSuccess(response) {
  return {
    type: GET_DOCUMENTS_SUCCESS,
    response
  }
}

export function getAnalysisData() {
  return {
    type: GET_ANALYSIS_DATA
  }
}

export function getAnalysisDataSuccess(response) {
  return {
    type: GET_ANALYSIS_DATA_SUCCESS,
    response
  }
}

export function getAnalysisDataFailure() {
  return {
    type: GET_ANALYSIS_DATA_FAILURE
  }
}

export function updateAnalysisData(policyId){
  return{
    type: UPDATE_ANALYSIS_DATA,
    policyId
  }
}

export function updateAnalysisDataSuccess(){
  return{
    type: UPDATE_ANALYSIS_DATA_SUCCESS
  }
}

export function updateAnalysisDataFailure(){
  return{
    type: UPDATE_ANALYSIS_DATA_FAILURE
  }
}