import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import Modal from '../@common/Modal';
import { VehicleBuilder } from '../../components/VehicleBuilder';
import { RegistrationWrapper } from '../../components/VehicleRegistration/styles';
import VehicleRegistration from '../../components/VehicleRegistration';
import { VehicleButtonWrapper } from '../../containers/Vehicle/styles';
import Button from '../../components/@common/Button';
import vehicleApi from '../../api/vehicleApi';
import { useVehicle } from '../../hooks';
import { transformVariants, transformVehicleType } from '../../containers/Vehicle/selector';
import { MOTORCYCLE } from '../../containers/Vehicle/constants';
import { requestPreviousVehicleSuccess, updatePreviousVehicle } from '../../containers/Vehicle/actions';
import { H2 } from '../common/H2';

interface PreviousVehicleProps {
    id: number;
    value: string;
    description: string;
}

export const PreviousRiddenModal: React.FC = () => {
    const dispatch = useDispatch();
    const { previousVehicle } = useVehicle();
    const [makes, setMakes] = useState<PreviousVehicleProps[]>([]);
    const [models, setModels] = useState<PreviousVehicleProps[]>([]);
    const [variants, setVariants] = useState<PreviousVehicleProps[]>([]);
    const [manualBuilderError, setManualBuilderError] = useState<string>('');
    const [isBuilderOpen, setManualBuilderOpen] = useState<boolean>(false);

    const hideModal = () => {
      setManualBuilderOpen(false);
    }

    const showModal = () => {
      setManualBuilderOpen(true);
    }

    useEffect(() => {
      if (
          previousVehicle.RegistrationNumber 
          && previousVehicle.ManuallyBuilt
          && !previousVehicle.Make
        ) {
        showModal();
        fetchMakes();
      }
    }, [previousVehicle])
  
    const handlePreviousSelect = async(event: React.ChangeEvent<HTMLSelectElement>) => {
      const index = event.target.selectedIndex;
      switch (event.target.name) {
        case 'makes':
          dispatch(updatePreviousVehicle({ 
            Make: event.target.value,
            Model: '', 
            Abi: '', 
            Variant: '' 
          }))
          await fetchModels(event.target.value);
          break;
        case 'models':
          dispatch(updatePreviousVehicle({ 
            Model: event.target.value, 
            Abi: '', 
            Variant: '' 
          }))
          await fetchVariants(event.target.value) 
          break;  
        case 'variants':
          dispatch(updatePreviousVehicle({
            Abi: event.target.value,
            //@ts-ignore
            Variant: event.target[index].text
          }))
          break;
        default:
          break;
      }
    }
  
    const fetchMakes = async () => {
      try {
        const motorcycleMakes = await vehicleApi.getMakes(`api/vehicles/Motorcycle/makes`);
        setMakes(transformVehicleType(motorcycleMakes.data.Makes));
        setManualBuilderError('');
      } catch (e) {
        setManualBuilderError('Failed to fetch makes, try again');
      }
    };
  
    const fetchModels = async (make: string) => {
      try {
        const motorcycleModels = await vehicleApi.getModels(`api/vehicles/Motorcycle/makes/${btoa(make)}/models`)
        setModels(transformVehicleType(motorcycleModels.data.Models))
        setManualBuilderError('');
      } catch (e) {
        setManualBuilderError('Failed to fetch models, try again');
      }
    }
  
    const fetchVariants = async (model: string) => {
      try {
        const motorcycleVariants = await vehicleApi.getVariants(`api/vehicles/Motorcycle/makes/${btoa(previousVehicle.Make)}/models/${btoa(model)}/variants`);
        setVariants(transformVariants(motorcycleVariants.data.Variants, MOTORCYCLE))
        setManualBuilderError('');
      } catch (e) {
        setManualBuilderError('Failed to fetch variants, try again');
      }
    }
  
    const submitManualBike = async () => {
      try {
        const response =  await vehicleApi.createUnknownVehicle('api/vehicles/manuallyBuilt/motorcycle', {
          AbiCode: previousVehicle.Abi,
          isRegistrationKnown: true,
          Registration: previousVehicle.RegistrationNumber
        })
        dispatch(updatePreviousVehicle({ 
          VehicleId: response.data.VehicleId,
          VehicleBuilt: true,
        }))
        dispatch(requestPreviousVehicleSuccess({
          ...previousVehicle,
          VehicleId: response.data.VehicleId,
        }))
        hideModal();
      } catch (e) {
        console.log(e)
      }
    }

    return (
      <Modal
        id="previousRiddenModal"
        isVisible={isBuilderOpen}
        onClose={hideModal}
        fullscreen={window.innerWidth <= 500}
      >
        <H2>Create previous vehicle</H2>
        <p>To get started, select a make from the available list.</p>
        <RegistrationWrapper>
          <VehicleRegistration 
            onChange={() => null}
            disabled={true}
            value={previousVehicle.RegistrationNumber || ''} 
          />
          <VehicleBuilder
            searchedVehicle={previousVehicle}
            vehicleOptions={{ makes: makes, models: models, variants: variants }}
            handleSelect={handlePreviousSelect}
          />
          {manualBuilderError.length > 0 && (
            <p>{manualBuilderError}</p>
          )}
          {previousVehicle.Make && previousVehicle.Model && previousVehicle.Abi && (
            <VehicleButtonWrapper>
              <Button
                  type="button"
                  id="SetVehicle"
                  onClick={submitManualBike}>
                  Set vehicle
              </Button>
            </VehicleButtonWrapper>
          )}
        </RegistrationWrapper>
      </Modal>
    )
}