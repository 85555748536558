import http from '../api/http';

export default class ocrApi {
  static getOcr(endpoint, data) {
    return http.post(
      endpoint,
      data, {
        headers: {
          'Accept': 'application/tempcover.LicenceFrontOcrResultRepresentation+json',
          'Content-Type': 'application/tempcover.OcrLicenceRequestRepresentation+json'
        },
        timeout: 10000
      },
    )
  }
}