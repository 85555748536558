import FormField from 'components/@common/FormField'
import Email from 'components/Email'
import MobileTelephone from 'components/MobileTelephone/MobileTelephone'
import { checkMarketingOptInStateAndForward } from 'containers/MarketingPreferencesContainer/actions'
import {
  doesAccountExist,
  updateRiskData,
} from 'containers/RiskDataCollectionPage/actions'
import { useAffiliate, useRiskData } from 'hooks'
import { useFormikContext } from 'formik'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useAccountExists from 'hooks/useAccountExists'
import config from 'config'
import { InitialStateType } from 'initialState'
import { BaseDriverFormData } from '../../DriverDetails.types'

import './style.css'
import { ca } from 'date-fns/locale'

interface Props {
  shouldHandleBlur?: boolean
}
const MarketingPreferencesForm: React.FC<Props> = ({
  shouldHandleBlur = false,
}) => {
  const { accountExists } = useAccountExists()
  const { riskData, quoteId } = useRiskData()
  const { affiliate } = useAffiliate()

  const [redirectLink, setRedirectLink] = useState(
    `${config.CUSTOMER_ACCOUNT_URL_NOT_LOGGED_IN}?redirectQuote=${quoteId}`,
  )

  const campaignData = useSelector(
    (state: InitialStateType) => state.price.campaign,
  )

  useEffect(() => {
    if (campaignData && campaignData.Ref) {
      setRedirectLink(
        `${config.CUSTOMER_ACCOUNT_URL_NOT_LOGGED_IN}?redirectQuote=${quoteId}&utm_campaignref=${campaignData.Ref}`,
      )
    } else {
      setRedirectLink(
        `${config.CUSTOMER_ACCOUNT_URL_NOT_LOGGED_IN}?redirectQuote=${quoteId}`,
      )
    }
  }, [campaignData, quoteId])

  const dispatch = useDispatch()
  const { values } = useFormikContext<BaseDriverFormData>()
  // WE only want to do this on a variant
  // TODO: EVALUATE THIS BEING DONE ALWAYS AFTER EXPERIMENT
  const handleBlur = () => {
    if (values.emailAddress && values.mobile && shouldHandleBlur) {
      dispatch(
        updateRiskData({
          EmailAddress: values.emailAddress,
          Mobile: values.mobile,
        }),
      )
      dispatch(checkMarketingOptInStateAndForward())
    }
    if (values.mobile) {
      dispatch(
        updateRiskData({
          Mobile: values.mobile,
        }),
      )
      dispatch(doesAccountExist())
    }
  }

  return (
    <>
      <FormField name='mobile' label='Mobile number'>
        <MobileTelephone onBlur={handleBlur} />
      </FormField>
      {!affiliate.AffiliateId &&
        !riskData.Learner &&
        !riskData.Impounded &&
        !riskData.Motorbike &&
        accountExists && (
          <div className='sign-in-message'>
            <p>
              <i className='fa fa-exclamation-circle' />
              Looks like you have an account, want to{' '}
              <a href={redirectLink}>sign in</a>?
            </p>
          </div>
        )}
      <FormField name='emailAddress' label='Email'>
        <Email onBlur={handleBlur} />
      </FormField>
    </>
  )
}
export default MarketingPreferencesForm
