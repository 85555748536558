export const LICENCE_TYPES = [
  {
    id: 1,
    description: 'Full UK licence',
    value: 'FullUkLicence',
  },
  {
    id: 2,
    description: 'Full Northern Ireland licence',
    value: 'FullNorthernIrelandLicence',
  },
  {
    id: 3,
    description: 'Full EU licence',
    value: 'FullEuLicence',
  },
  {
    id: 4,
    description: 'Full International',
    value: 'FullInternational',
  },
  {
    id: 5,
    description: 'Provisional UK licence',
    value: 'ProvisionalUkLicence',
  },
];
