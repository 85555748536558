import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import {
  AmendDetailsLink,
  QuoteDetailsHeader,
  QuoteDetailsLabel,
  QuoteDetailsValue,
  EditBlock,
  NameBlock,
  DOBBlock,
  LicenceHeldBlock,
  FullRowBlock,
} from './styles';
import { useRiskData, useQueryString } from 'hooks';
import { QUOTE_URL } from '../../services/constants';

const PriceComparisonDriverDetails = ({ addressInfo, showAmendLink, standalone }) => {
  const { riskData } = useRiskData();
  const { queryString } = useQueryString();

  const editUrl = `${QUOTE_URL}/driver-details${queryString}`;

  return (
    <>
      <QuoteDetailsHeader>Your details</QuoteDetailsHeader><EditBlock><Link to={editUrl} className='PriceComparisonEditDetailsLink'> <i className="fa fa-pencil-alt"></i><span>edit</span></Link></EditBlock>
      <NameBlock><QuoteDetailsLabel>Name:</QuoteDetailsLabel><QuoteDetailsValue>{riskData.Forename}{' '}{riskData.Surname}</QuoteDetailsValue></NameBlock>
      <DOBBlock><QuoteDetailsLabel>D.O.B:</QuoteDetailsLabel><QuoteDetailsValue>{riskData.DateOfBirth
        ? moment(riskData.DateOfBirth).format(
          'DD/MM/YYYY'
        )
        : null}</QuoteDetailsValue></DOBBlock>
      <LicenceHeldBlock hideBorder={standalone}>
      {riskData.LicenceHeldDuration.description && !riskData.Motorbike && (
        <>
          <QuoteDetailsLabel>Licence held for:</QuoteDetailsLabel>
          <QuoteDetailsValue>{riskData.LicenceHeldDuration.description}</QuoteDetailsValue>
        </>
      )}
      {riskData.Motorbike && riskData.MotorcycleEntitlement.description && (
        <>
          <QuoteDetailsLabel>Motorbike entitlement:</QuoteDetailsLabel>
          <QuoteDetailsValue>{riskData.MotorcycleEntitlement.description}</QuoteDetailsValue>
        </>
      )}
      </LicenceHeldBlock>
      <FullRowBlock alwaysFullRow={standalone}><QuoteDetailsLabel>Address:</QuoteDetailsLabel><QuoteDetailsValue>{addressInfo}</QuoteDetailsValue></FullRowBlock>
      {showAmendLink && (<FullRowBlock alwaysFullRow={standalone}><AmendDetailsLink><Link to={editUrl} className='PriceComparisonAmendDetailsLink'><span>Amend details</span></Link></AmendDetailsLink></FullRowBlock>)}
    </>
  )
}

export default PriceComparisonDriverDetails