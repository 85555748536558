
import StripeElementsWrapper from 'connectors/react-stripe';
import React from 'react';
import KlarnaContainer from './KlarnaContainer';

interface Props {
    loadKlarna?: boolean;
    onSuccess?: () => void;
    onFailure?: () => void;
}

// Need to create a container above which wraps klarna in the elements wrapper
// This allows us to use stripe actions lower down.
const KlarnaStripeContainer: React.FC<Props> = ({ loadKlarna = true, onSuccess = () => { }, onFailure = () => { } }) => {
    return (
        <StripeElementsWrapper>
            <KlarnaContainer loadKlarna={loadKlarna} onSuccess={onSuccess} onFailure={onFailure} />
        </StripeElementsWrapper>
    )
};

export default KlarnaStripeContainer;
