import { Field } from 'formik';
import React from 'react';
import Label from '../../@common/Label';
import FormFieldContent from './FormFieldContent';

interface Props {
  name: string;
  label: string;
  isValidIcon?: boolean;
  validateFunc?: (value: any) => string|undefined|Promise<string|undefined>
}
const FormField: React.FC<Props> = ({
  label,
  name,
  children,
  isValidIcon = true,
  validateFunc = undefined,
}) => (
  <div>
    <Label>{label}</Label>
    <Field validate={validateFunc} name={name}>
      {/* @ts-ignore */}
      {({ field, form, meta }) => (
        <FormFieldContent
          field={field}
          meta={meta}
          form={form}
          isValidIcon={isValidIcon}
        >
          {children}
        </FormFieldContent>
      )}
    </Field>
  </div>
);
export default FormField;
