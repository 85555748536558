import {
  INIT_INVALID_REG_PAGE,
  INIT_INVALID_REG_PAGE_SUCCESS,
  INIT_INVALID_REG_PAGE_FAILURE
} from './constants';

export function initInvalidRegPage() {
  return {
    type: INIT_INVALID_REG_PAGE
  };
}

export function initInvalidRegPageSuccess() {
  return {
    type: INIT_INVALID_REG_PAGE_SUCCESS
  };
}

export function initInvalidRegPageFailure(message) {
  return {
    type: INIT_INVALID_REG_PAGE_FAILURE,
    message
  };
}
