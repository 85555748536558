import styled from 'styled-components';

export const FormControlValidIcon = styled.i`
    position: absolute;
    top: 11px;
    right: 9px;
    display: ${props => props.error === '' ? 'block' : 'none'}!important;
    color: ${props => props.theme.formControlValidIcon.colour};
    font-size: 16px;
    border: 4px solid ${props => props.theme.formControlValidIcon.backgroundColour};
    background-color: ${props => props.theme.formControlValidIcon.backgroundColour};
    border-radius: 50%;
`