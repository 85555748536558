import React from 'react';


import Button from 'components/@common/Button';
import { KlarnaOption as KlarnaOptionType, KlarnaOptionDetails } from './Klarna.types';
import { KlarnaContentContainer } from './Klarna.styles';

interface Props {
    optionDetails: KlarnaOptionDetails;
    name: KlarnaOptionType;
    onClick: (name: KlarnaOptionType) => void;
    isLoading?: boolean;
    isButtonDisabled?: boolean;
}

const KlarnaOption: React.FC<Props> = ({ optionDetails, name, onClick, isLoading = false, isButtonDisabled = false }) => {

    const handleClick = (name: KlarnaOptionType) => {
        onClick(name)
    };

    return (
        <div>
            <KlarnaContentContainer id={`klarna_${name}_container`} isAllowed={optionDetails.isAllowed} />
            {optionDetails.isAllowed &&
                <Button
                    id={`klarna_${name}_button`}
                    fullWidth
                    type="button"
                    disabled={isButtonDisabled}
                    loading={isLoading}
                    isSpinnerAbsolute={false}
                    onClick={() => handleClick(name)}
                >
                    {optionDetails.displayName}
                </Button>}
        </div>


    );
};

export default KlarnaOption;