import React from 'react';
import PropTypes from 'prop-types';
import {P, Span, SectionWrapper} from './styles.js';
import { Grid, GridContainer, GridClear } from 'unsemantic';

const VehicleDescriptionSection = ({vehicle}) => {  
    return(
            <GridContainer> 
                <SectionWrapper>
                    <Grid desktop='20' mobile='30' tablet='20'>
                        <P>Vehicle description</P>
                    </Grid>                           
                    <Grid desktop='80' mobile='70' tablet='80'>
                        <P><Span>{vehicle.Make} {vehicle.Model}</Span></P>
                    </Grid>
                    <GridClear />
                </SectionWrapper>                                            
            </GridContainer> 
    )
}

VehicleDescriptionSection.propTypes = {
    vehicle: PropTypes.object
};

export default VehicleDescriptionSection;
