import DatePicker from 'components/@common/DatePicker';
import FormField from 'components/@common/FormField';
import TextField from 'components/@common/TextField';
import { useField } from 'formik';
import { useIdentity, useScheme } from 'hooks';
import React from 'react';
import DrivingLicenceNumber from 'components/DrivingLicenceNumber/DrivingLicenceNumber';

const IdentityCheck: React.FC = () => {
  const { identityCheck } = useIdentity();
  const { scheme } = useScheme();
  const licenceExpiryStartDate = new Date();
  const [field, meta, helpers] = useField('passportExpiryDate');

  if (identityCheck === 'drivingLicence') {
    return (
      <FormField
        name="drivingLicenceNumber"
        label="Driving licence number"
      >
        <DrivingLicenceNumber fullLicence={scheme === 'VehicleMotorcycleLearnerAndFullLicenceScheme'} />
      </FormField>
    );
  } if (identityCheck === 'passport') {
    return (
      <>
        <FormField
          name="passportLineOne"
          label="Passport line one"
        >
          <TextField
            id="PassportLineOne"
            uppercase
            name="passportLineOne"
            placeholder="The first line of my passport says..."
          />
        </FormField>
        <FormField
          name="passportLineTwo"
          label="Passport line two"
        >
          <TextField
            id="PassportLineTwo"
            uppercase
            name="passportLineTwo"
            placeholder="and the second line of my passport says..."
          />
        </FormField>
        <FormField
          name="passportExpiryDate"
          label="Passport expiry date"
        >
          <DatePicker
            name="passportExpiryDate"
            startDate={licenceExpiryStartDate}
            value={field.value}
            setValue={helpers.setValue}
            setError={helpers.setError}
            setTouched={helpers.setTouched}
          />
        </FormField>
      </>
    );
  }
  return <></>;
};

export default IdentityCheck;
