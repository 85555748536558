import { SESSIONSTATES } from './services/constants'

import { DURATION_OBJECT_DEFAULT } from './components/PolicyDurationModal/constants'
import {
  DurationPreset,
  DurationType,
  IdentityCheck,
  LicenceType,
  QuoteJourneyPosition,
  Scheme,
} from './types/global'

interface SessionState {
  sessionGuid: string
  sessionQuoteId: string
  sessionState: string
  sessionLastActivityTimestamp: ''
}

interface AffiliateState {
  AffiliateId: string | null
  DisplayName: string
  AffiliateType: string | null
  Name: string
  Ref: string
  CustomerPresent: boolean | null
  PriceComparison: boolean
  MarketingPreferencesDisabled: boolean
  IsCashbackEnabled: boolean
}

interface VehicleState {
  searched: {
    VehicleId: string | null
    RegistrationNumber: string | null
    Make: string
    Model: string
    VehicleType: string | null
    Abi: string | null
    MakeAndModel: string | null
    VehicleFound: boolean | null
    VehicleBuilt: string | null
    GrossWeight: string | null
    YearOfManufacture: string | null
    vehicleValue: {
      description: string
      value: string
    }
    Impounded: boolean
    Courier: boolean
    IsLearnerVehicle: boolean
    Variant: string | null
    BodyType: string | null
  }
  selected: {
    VehicleId: string | null
    RegistrationNumber: string | null
    Make: string
    Model: string
    VehicleType: string | null
    Abi: string | null
    MakeAndModel: string | null
    VehicleFound: boolean | null
    VehicleBuilt: string | null
    GrossWeight: string | null
    YearOfManufacture: string | null
    vehicleValue: {
      description: string
      value: string
    }
    Impounded: boolean
    IsLearnerVehicle: boolean
    Variant: string | null
    BodyType: string | null
  }
  previousRiddenVehicle: {
    VehicleId: string | null
    RegistrationNumber: string | null
    Make: string
    Model: string
    VehicleType: string | null
    Abi: string | null
    MakeAndModel: string | null
    vehicleValue: {
      description: string
      value: string
    }
    VehicleFound: boolean | null
    ManuallyBuilt?: boolean
    error: boolean | string
  }
  validation: {
    grossWeightError: string
    yearOfManufactureError: string
  }
  error: boolean
  existsInGarage: boolean
  scheme: string | null
  makes: []
  models: []
  variants: []
  customerAccountCarouselReg: string
  preloadNewCustomerAccountVehicle: boolean
  customerAccountCommercialVehicleType: string
  customerAccountManuallyBuiltVehicle: boolean
}

export interface ExcessReduction {
  ExcessReductionIncluded: boolean
  ExcessReductionPremiumNet: number
  ExcessReductionPremiumGross: number
  ExcessReductionPremiumCommissionFactor: number
  ExcessReductionIPT: number
  ExcessReductionTotalPremium: number
  ExcessReductionPremiumCommissionAmount: number
  ExcessReductionCoverLevel: number
  HasError: boolean
}

export interface BreakdownCover {
  BreakdownIncluded: boolean
  BreakdownPremiumNet: number
  BreakdownPremiumGross: number
  BreakdownPremiumCommissionFactor: number
  BreakdownPremiumCommissionAmount: number
  BreakdownIPT: number
  BreakdownTotalPremium: number
  BreakdownBrokerCode: string
  BreakdownProductCode: string
  HasError: boolean
}

export interface LegalExpensesCover {
  LegalExpensesIncluded: boolean
  LegalExpensesPremiumNet: number
  LegalExpensesPremiumGross: number
  LegalExpensesPremiumCommissionFactor: number
  LegalExpensesPremiumCommissionAmount: number
  LegalExpensesIPT: number
  LegalExpensesPremium: number
  HasError: boolean
}

export interface PriceState {
  InsurancePremium: number
  Ipt: number
  AdminFee: number
  TotalPrice: number
  CompulsoryExcess: number
  commission: number
  Underwriter: string | null
  UnderwriterDisplayName: string
  UnderwriterId: number | null
  EncryptedString: string | null
  AffiliateAdminFee: number
  isUpdating: boolean
  IsAddonUpdating: boolean
  UnderwriterChanged: boolean
  UnderwriterChangedByCue: boolean
  campaign: {
    Ref: string
    Name: string
    Discount: number
    Active?: boolean
  }
  CoverType: string
  Prices: PriceState[]
  fetchNextPrice: boolean
  TotalPriceBeforeAddons: number
  BreakdownCover: BreakdownCover
  LegalExpensesCover: LegalExpensesCover
  ExcessReduction: ExcessReduction
  AffiliateCashbackValue: number | null
}

interface QuoteState {
  TotalPrice: number
  Underwriter: string | null
  QuoteJourneyPosition: QuoteJourneyPosition
  ReferenceNumber: string
  isCustomerAccountQuote: boolean
  accountExists: boolean
}

export enum SystemFeatures {
  SystemDownForMaintenance = 'SystemDownForMaintenance',
  UpsellCtmEnabled = 'UpsellCtmEnabled',
  UpsellFlowEnabled = 'UpsellFlowEnabled',
  ConfusedUpsellEnabled = 'ConfusedUpsellEnabled',
  IsCashbackEnabled = 'IsCashbackEnabled',
}

export interface RiskDataState {
  Forename: string
  Surname: string
  AddressKey: string
  DateOfBirth: string | null
  Duration: number | null
  // TODO: Clean this up, as it shouldn't be able to be both.
  DurationType: DurationType | null
  EmailAddress: string
  Mobile: string
  LicenceType: {
    value: LicenceType
    description: string
  }
  PolicyStartDate: {
    value: string | null
    manuallyEdited: boolean
  }
  PolicyEndDate: string | null
  QuoteId: string
  LicenceHeldDuration: {
    value: string
    description: string | null
  }
  DrivingLicenceNumber: string
  DrivingLicenceNumberFirstPart?: string
  PassportLineOne: string
  PassportLineTwo: string
  PassportExpiryDate: string | null
  Title: {
    value: string
    description: string
  }
  BodyType: {
    value: string
    description: string
  }
  UseOfVehicle: {
    value: string
    description: string
  }
  Occupation: {
    Id: number
    OccupationCode: string
    OccupationName: string
  }
  Expat: boolean | null
  ResidencyType: {
    value: string
    description: string
  }
  UkResidencyDuration: {
    value: string
    description: string
  }
  Learner: boolean
  OwnerOfVehicle: {
    value: string
    description: string
  }
  HasVehicleGotAnnualInsuranceEnumeration: {
    value: string
    description: string
  }
  Impounded: boolean
  Courier: boolean
  Motorbike: boolean
  MotorcycleEntitlement: {
    value: string
    description: string
  }
  MotorcycleRidingExperience: {
    value: string
    description: string
  }
  MotorcycleUseOfVehicle: {
    value: string
    description: string
  }
  MotorcycleOvernightParking: {
    value: string
    description: string
  }
  MotorcycleReasonForTempcoverPurchase: {
    value: string
    description: string
  }
  PreviouslyRiddenMotorcycleVehicle: string
  ReasonForPurchase?: {
    value: string
    description: string
  }
  ReasonForPurchaseOtherText?: string
  CustomerId: string
}

interface AddressState {
  Postcode: string
  SearchResults: []
  FailedSearch: boolean
  AddressLineOne: string | null
  AddressLineTwo: string | null
  AddressLineThree: string | null
  AddressLineFour: string | null
  SelectedAddress: string | null
}

interface ValidationState {
  riskDataCollectionPage: {
    fields: {
      ForenameError: string | null
      SurnameError: string | null
      DateOfBirthError: string | null
      PostcodeError: string | null
      AddressError: string | null
      DatePassedTestError: string
      LicenceHeldDurationError: string | null
      DrivingLicenceNumberError: string
      LicenceTypeError: string
      PassportLineOneError: string
      PassportLineTwoError: string
      PassportExpiryDateError: string
      BodyTypeError: string
      UseOfVehicleError: string
      OccupationError: string
      EmailAddressError: string | null
      MobileError: string | null
      TitleError: string | null
      ResidencyTypeError: string
      UkResidencyDurationError: string
      OwnerOfVehicleError: string
      HasVehicleGotAnnualInsuranceEnumerationError: string
      MotorcycleEntitlementError: string
      MotorcycleRidingExperienceError: string
      MotorcycleOvernightParkingError: string
      MotorcycleReasonForTempcoverPurchaseError: string
      MotorcycleUseOfVehicleError: string
      PreviouslyRiddenMotorcycleVehicleError: string
    }
    ValidForm: boolean
    ManuallyEdited: boolean
    IdentityCheck: IdentityCheck
    AllFieldsValid: boolean
    MarketingPreferencesChecked: boolean
  }
  policyConfirmationPage: {
    ValidForm: boolean
  }
}

interface DriverDetailsFormFields {
  reasonForTempcoverPurchaseOptions: {
    Description: string
    Value: string
    DisplayOrder: number
  }[]
}

interface ConfusedQuickQuote {
  OptIn: boolean | null
  RequestAccepted: boolean
  RequestedDateTime: number | null
  Pricing: {}
  PricingLoaded: boolean
  PricingLoadAttempts: number
}

export interface InitialStateType {
  tokenData: {}
  links: { Rel: string; Href: string }[]
  features: { [key in SystemFeatures]: boolean }
  step: number
  queryString: string
  session: SessionState
  affiliate: AffiliateState
  correlationId: string | null
  cameraEnabled: boolean
  vehicle: VehicleState
  price: PriceState
  quote: QuoteState
  riskData: RiskDataState
  address: AddressState
  occupation: {
    OccupationSearch: string
    SearchResults: { Id: number; OccupationName: string }[]
    SelectedOccupation: {
      Id: number
      OccupationName: string
    } | null
  }
  validation: ValidationState
  legalDeclaration: {
    ShortText: string
    FullText: string
    DisallowedOccupations: string
    PolicyWordingUrl: string
    InsuranceProductInformationUrl: string
    TempcoverContractUrl: string
  }
  policy: {
    PolicyId: string
    annualQuote: number
    annualLink: string
    reasonForCover: {
      value: string | null
      description: string
    }
    PolicyDocuments: []
    analysisSent: boolean
    callbackRequest: string
  }
  payment: {
    Secure: {}
    optionsVisible: boolean | null
    paymentError: string
    paymentButtonDisabled: boolean
    paymentInProgress: boolean
    priceMatch: boolean
    shouldStartKlarnaPayment: boolean
    klarnaPaymentOptionAvailable: boolean
    klarnaFailure: boolean
  }
  isLoading: boolean
  showLiveChat: boolean
  formButtons: {
    driverDetails: {
      disabled: boolean
    }
    driverLicence: {
      disabled: boolean
    }
    legalDeclaration: {
      disabled: boolean
    }
    vanInfo: {
      disabled: boolean
    }
    paymentButton: {
      disabled: boolean
    }
    addressSearch: {
      disabled: boolean
    }
    moreDetails: {
      disabled: boolean
    }
    marketingPreferences: {
      disabled: boolean
    }
  }
  scheme: {
    open: { Scheme: Scheme; Links: { Href: string; Rel: string }[] }[]
    selected: Scheme | ''
  }
  ui: {
    VisiblePriceBreakdown: boolean | null
  }
  myLicence: {
    Enabled: boolean
    Actioned: boolean
    DisabledBySetting: boolean
    CheckFailed: boolean
    FailReasons: string[]
  }
  marketingPreferences: {
    loaded: boolean
    error: boolean
    PreviousCustomer?: boolean
    EmailAddressOptIn?: string
    TelephoneNumberOptIn?: string
  }
  duration: {
    value: number
    type: DurationType
    presets: DurationPreset[]
    durationExtraOptionsSet: {
      id: number
      value: number
      type: DurationType
      description: string
    }[]
    hourDisplayOptions: number[]
    dayDisplayOptions: number[]
    weekDisplayOptions: number[]
    isEditable: boolean
  }
  experiments: { [key: string]: 0 | 1 }
  external: {
    [key: string]: string
  }
  driverDetailsFormFields: DriverDetailsFormFields
  confusedQuickQuote: ConfusedQuickQuote
  auth: {
    accessToken: string
  }
  customerAccountLoggedIn: boolean
}

const initialState: InitialStateType = {
  tokenData: {},
  links: [],
  features: {
    SystemDownForMaintenance: false,
    UpsellCtmEnabled: false,
    UpsellFlowEnabled: false,
    ConfusedUpsellEnabled: false,
    IsCashbackEnabled: false,
  },
  step: 0,
  queryString: '',
  session: {
    sessionGuid: '',
    sessionQuoteId: '',
    sessionState: SESSIONSTATES.NEW_SESSION,
    sessionLastActivityTimestamp: '',
  },
  affiliate: {
    AffiliateId: null,
    DisplayName: '',
    AffiliateType: null,
    Name: '',
    Ref: '',
    CustomerPresent: null,
    PriceComparison: false,
    MarketingPreferencesDisabled: false,
    IsCashbackEnabled: false,
  },
  correlationId: null,
  cameraEnabled: true,
  vehicle: {
    searched: {
      VehicleId: null,
      RegistrationNumber: null,
      Make: '',
      Model: '',
      VehicleType: null,
      Abi: null,
      MakeAndModel: null,
      VehicleFound: null,
      VehicleBuilt: null,
      GrossWeight: '',
      YearOfManufacture: null,
      vehicleValue: {
        description: '',
        value: '',
      },
      Impounded: false,
      Courier: false,
      IsLearnerVehicle: false,
      Variant: null,
      BodyType: null,
    },
    selected: {
      VehicleId: null,
      RegistrationNumber: null,
      Make: '',
      Model: '',
      VehicleType: null,
      Abi: null,
      MakeAndModel: null,
      vehicleValue: {
        description: '',
        value: '',
      },
      VehicleFound: null,
      VehicleBuilt: null,
      GrossWeight: '',
      YearOfManufacture: null,
      Impounded: false,
      IsLearnerVehicle: false,
      Variant: null,
      BodyType: null,
    },
    previousRiddenVehicle: {
      VehicleId: null,
      RegistrationNumber: null,
      Make: '',
      Model: '',
      VehicleType: null,
      Abi: null,
      MakeAndModel: null,
      vehicleValue: {
        value: '',
        description: '',
      },
      VehicleFound: null,
      ManuallyBuilt: false,
      error: false,
    },
    validation: {
      grossWeightError: '',
      yearOfManufactureError: '',
    },
    error: false,
    existsInGarage: false,
    scheme: null,
    makes: [],
    models: [],
    variants: [],
    customerAccountCarouselReg: '',
    customerAccountCommercialVehicleType: '',
    customerAccountManuallyBuiltVehicle: false,
    preloadNewCustomerAccountVehicle: false,
  },
  price: {
    InsurancePremium: 0,
    Ipt: 0,
    AdminFee: 0,
    TotalPrice: 0,
    CompulsoryExcess: 0,
    commission: 0,
    Underwriter: null,
    UnderwriterDisplayName: '',
    UnderwriterId: null,
    EncryptedString: null,
    AffiliateAdminFee: 0,
    isUpdating: false,
    IsAddonUpdating: false,
    UnderwriterChanged: false,
    UnderwriterChangedByCue: false,
    campaign: {
      Ref: '',
      Name: '',
      Discount: 0,
      Active: undefined,
    },
    CoverType: '',
    Prices: [],
    fetchNextPrice: false,
    TotalPriceBeforeAddons: 0,
    ExcessReduction: {
      ExcessReductionIncluded: false,
      ExcessReductionPremiumNet: 0,
      ExcessReductionPremiumGross: 0,
      ExcessReductionPremiumCommissionFactor: 0,
      ExcessReductionIPT: 0,
      ExcessReductionTotalPremium: 0,
      ExcessReductionPremiumCommissionAmount: 0,
      ExcessReductionCoverLevel: 0,
      HasError: false,
    },
    BreakdownCover: {
      BreakdownIncluded: false,
      BreakdownPremiumNet: 0,
      BreakdownPremiumGross: 0,
      BreakdownPremiumCommissionFactor: 0,
      BreakdownPremiumCommissionAmount: 0,
      BreakdownIPT: 0,
      BreakdownTotalPremium: 0,
      BreakdownBrokerCode: '',
      BreakdownProductCode: '',
      HasError: false,
    },
    LegalExpensesCover: {
      LegalExpensesIncluded: false,
      LegalExpensesPremiumNet: 0,
      LegalExpensesPremiumGross: 0,
      LegalExpensesPremiumCommissionFactor: 0,
      LegalExpensesPremiumCommissionAmount: 0,
      LegalExpensesIPT: 0,
      LegalExpensesPremium: 0,
      HasError: false,
    },
    AffiliateCashbackValue: null,
  },
  quote: {
    TotalPrice: 0,
    Underwriter: null,
    QuoteJourneyPosition: QuoteJourneyPosition.NotSet,
    ReferenceNumber: '',
    isCustomerAccountQuote: false,
    accountExists: false,
  },
  riskData: {
    Learner: false,
    Expat: null,
    Courier: false,
    Motorbike: false,
    Impounded: false,
    Forename: '',
    Surname: '',
    Duration: null,
    DurationType: null,
    AddressKey: '',
    DateOfBirth: null,
    EmailAddress: '',
    Mobile: '',
    CustomerId: '',
    LicenceType: {
      value: 'FullUkLicence',
      description: 'Full UK licence',
    },
    PolicyStartDate: {
      value: null,
      manuallyEdited: false,
    },
    PolicyEndDate: null,
    QuoteId: '',
    LicenceHeldDuration: {
      value: '',
      description: null,
    },
    DrivingLicenceNumber: '',
    DrivingLicenceNumberFirstPart: '',
    PassportLineOne: '',
    PassportLineTwo: '',
    PassportExpiryDate: null,
    Title: {
      value: '',
      description: '',
    },
    BodyType: {
      value: '',
      description: '',
    },
    UseOfVehicle: {
      value: '',
      description: '',
    },
    Occupation: {
      Id: 0,
      OccupationCode: '',
      OccupationName: '',
    },
    ResidencyType: {
      value: 'PermanentUkResident',
      description: 'Permanent UK Resident',
    },
    UkResidencyDuration: {
      value: '',
      description: '',
    },
    OwnerOfVehicle: {
      value: '',
      description: '',
    },
    HasVehicleGotAnnualInsuranceEnumeration: {
      value: '',
      description: '',
    },
    MotorcycleEntitlement: {
      value: '',
      description: '',
    },
    MotorcycleRidingExperience: {
      value: '',
      description: '',
    },
    MotorcycleUseOfVehicle: {
      value: '',
      description: '',
    },
    MotorcycleOvernightParking: {
      value: '',
      description: '',
    },
    MotorcycleReasonForTempcoverPurchase: {
      value: '',
      description: '',
    },
    PreviouslyRiddenMotorcycleVehicle: '',
  },
  address: {
    Postcode: '',
    SearchResults: [],
    FailedSearch: false,
    AddressLineOne: null,
    AddressLineTwo: null,
    AddressLineThree: null,
    AddressLineFour: null,
    SelectedAddress: null,
  },
  occupation: {
    OccupationSearch: '',
    SearchResults: [],
    SelectedOccupation: null,
  },
  validation: {
    riskDataCollectionPage: {
      fields: {
        ForenameError: null,
        SurnameError: null,
        DateOfBirthError: null,
        PostcodeError: null,
        AddressError: null,
        DatePassedTestError: '',
        LicenceHeldDurationError: null,
        DrivingLicenceNumberError: '',
        LicenceTypeError: '',
        PassportLineOneError: '',
        PassportLineTwoError: '',
        PassportExpiryDateError: '',
        BodyTypeError: '',
        UseOfVehicleError: '',
        OccupationError: '',
        EmailAddressError: null,
        MobileError: null,
        TitleError: null,
        ResidencyTypeError: '',
        UkResidencyDurationError: '',
        OwnerOfVehicleError: '',
        HasVehicleGotAnnualInsuranceEnumerationError: '',
        MotorcycleEntitlementError: '',
        MotorcycleRidingExperienceError: '',
        MotorcycleOvernightParkingError: '',
        MotorcycleReasonForTempcoverPurchaseError: '',
        MotorcycleUseOfVehicleError: '',
        PreviouslyRiddenMotorcycleVehicleError: '',
      },
      ValidForm: false,
      ManuallyEdited: false,
      IdentityCheck: '',
      AllFieldsValid: true,
      MarketingPreferencesChecked: false,
    },
    policyConfirmationPage: {
      ValidForm: false,
    },
  },
  confusedQuickQuote: {
    OptIn: null,
    RequestAccepted: false,
    RequestedDateTime: null,
    Pricing: {},
    PricingLoaded: false,
    PricingLoadAttempts: 0,
  },
  legalDeclaration: {
    ShortText: '',
    FullText: '',
    DisallowedOccupations: '',
    PolicyWordingUrl: '',
    InsuranceProductInformationUrl: '',
    TempcoverContractUrl: '',
  },
  policy: {
    PolicyId: '',
    annualQuote: 0,
    annualLink: '',
    reasonForCover: {
      value: null,
      description: '',
    },
    PolicyDocuments: [],
    analysisSent: false,
    callbackRequest: '',
  },
  payment: {
    Secure: {},
    optionsVisible: null,
    paymentError: '',
    paymentButtonDisabled: false,
    paymentInProgress: false,
    priceMatch: true,
    shouldStartKlarnaPayment: false,
    klarnaPaymentOptionAvailable: false,
    klarnaFailure: false,
  },
  isLoading: true,
  showLiveChat: true,
  formButtons: {
    driverDetails: {
      disabled: false,
    },
    driverLicence: {
      disabled: false,
    },
    legalDeclaration: {
      disabled: false,
    },
    vanInfo: {
      disabled: false,
    },
    paymentButton: {
      disabled: false,
    },
    addressSearch: {
      disabled: false,
    },
    moreDetails: {
      disabled: false,
    },
    marketingPreferences: {
      disabled: false,
    },
  },
  scheme: {
    open: [],
    selected: '',
  },
  ui: {
    VisiblePriceBreakdown: null,
  },
  myLicence: {
    Enabled: false,
    Actioned: false,
    DisabledBySetting: false,
    CheckFailed: false,
    FailReasons: [],
  },
  marketingPreferences: {
    loaded: false,
    error: false,
  },
  duration: {
    value: 1,
    type: 'days',
    ...DURATION_OBJECT_DEFAULT,
  },
  experiments: {},
  external: {},
  driverDetailsFormFields: { reasonForTempcoverPurchaseOptions: [] },
  auth: {
    accessToken: '',
  },
  customerAccountLoggedIn: false,
}

export default initialState
