import styled from 'styled-components';

export const Span = styled.span`  
   font-family: ${props => props.theme.labelFontFamily}
   font-size:${props => props.theme.labelFontSize}    
   @media (max-width: 768px) {
      font-size:${props => props.theme.mobileLabelFontSize}   
   }
`

export const NoWrapSpan = styled.span`
   white-space: nowrap;
`

export const Strikethrough = styled.span`
   text-decoration: line-through;
`