type ActionTypes = 'SHOW_EXPERIMENT';

type PayloadType = {
  [key: string]: number;
};

export interface ExperimentAction {
  type: ActionTypes;
  payload?: PayloadType;
}

export const showExperiment: (experiments: PayloadType) => { type: ActionTypes; payload: PayloadType } = (experiments) => ({
  type: 'SHOW_EXPERIMENT',
  payload: experiments,
});
