import styled from 'styled-components';
import { Props } from './TextField';

export const TextFieldStyled = styled.input<Props>`
  border: 1px solid ${({ theme }) => theme.inputBorderColour};
  border-radius: ${({ theme }) => theme.formControlBorderRadius};
  padding: 0 ${({ theme }) => theme.formControlHorizontalPadding};
  padding: 0 ${({ theme }) => theme.formControlHorizontalPadding};
  margin-bottom: ${({ theme }) => theme.formControlBottomMargin};
  color: ${({ theme }) => theme.primaryTextColour};
  font-size: ${({ theme }) => theme.generalFontSize};
  height: ${({ theme }) => theme.formControlHeight};
  width: 100%;
  font-family: ${({ theme }) => theme.fontFamily};
  font-style: ${({ theme }) => theme.fontStyle};
  font-weight: ${({ theme }) => theme.fontWeight};
  text-transform: ${({ uppercase }) => (uppercase ? 'uppercase' : null)};
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
  :focus{
      border: 1px solid ${({ theme }) => theme.inputActiveBorderColour} !important;
      outline: 1px solid ${({ theme }) => theme.inputActiveBorderColour} !important;
    }
  ::-ms-clear {
    display: none;
  }
  ::placeholder {
    text-transform: none;
  }
`;

export const TextFieldWIthIcon = styled(TextFieldStyled)`
  padding-left: 55px;
`;
