import styled from 'styled-components';

export const ProgressionHeader = styled.div `
  background: ${props => props.theme.primaryColour };
  border-radius: 10px;
  width: 100%;
  height:40px;
  line-height:40px;
  vertical-align:middle;
  text-transform: uppercase;
  h2 {
    color: white;
    text-align:center;
    margin-top: 0;
    font-size: ${props => props.theme.progressionStep.titleDesktopFontSize};
  }
  @media (max-width: 768px){
    h2{
      font-size:${props => props.theme.progressionStep.titleFontSize};
    }
  }
`;