import {
  CREATE_JUDO_PAYMENT,
  SEND_3D_SECURE_DATA
} from './constants';
import { call, put, takeLatest, select } from 'redux-saga/effects';
import paymentApi from '../../api/paymentApi';

import {
  createJudoPayFailure,
  send3DSecureData,
  send3DSecureDataSuccess
} from './actions';
import { getRiskData, getPriceData } from './selector';
import config from '../../config';
import { history } from '../..';
import { fraudFailure } from '../PaymentPage/actions';
import { getQueryString } from '../RiskDataCollectionPage/selector';

function* createJudoPay({ payload }) {
  const riskData = yield select(getRiskData);
  const queryString = yield select(getQueryString);
  try { 
    const priceData = yield select(getPriceData);
    const data = {
      PaymentToken: payload.token,
      QuoteId: {
        value: riskData.QuoteId
      },
      ValidationData: {
        Value: priceData.EncryptedString
      }
    };
    const response = yield call(
      paymentApi.createJudoPayment,
      '/api/policy/payment/judo',
      data
    );
    if( response.data.JudoPaymentResult) {
      switch(response.data.JudoPaymentResult.Result) {
        case 0:
          yield call(payload.callback);
          break;
        case 1:
        case 2:
        yield put(createJudoPayFailure('Sorry there has been an issue with your payment. Please try again.'));
          break;
        default:
          break;
      }     
    } else if (response.data.Judo3DsResult) {
      yield put(
        send3DSecureData(
          response.data.Judo3DsResult,
          payload.secureCallback
        )
      );
    }
  } catch (e) { 
    yield put(fraudFailure())
    history.push({
      pathname: `/quote/driving-licence`,
      search: `${queryString}`,
      state: {fraudFailure: true}
    });
  }
}

function* send3DSecure({ data, callback }) {
  try {
    data.TermUrl = `${config.WEB_URL}/JudoPayment/CallbackThreeDSecureTesla`;
    yield put(send3DSecureDataSuccess(data));
    yield call(callback);
  } catch (e) {}
}

export default function* judoPaySaga() {
  yield takeLatest(CREATE_JUDO_PAYMENT, createJudoPay);
  yield takeLatest(SEND_3D_SECURE_DATA, send3DSecure);
}
