import styled from "styled-components";

export const H2 = styled.h2`
  font-size: 22px;
  font-family: ${props => props.theme.headerFontFamily};
  color: ${props => props.theme.headerFontColour};
  text-align: center;
  margin-top: 25px;
  margin-bottom: 0;
`;
