import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Grid, GridContainer } from 'unsemantic'
import {
  updatePolicy,
  updatePolicyContactDetails,
  requestPolicy,
  updateValidation,
  updateReasonForTempcover,
  initPolicyConfirmationPage,
  updateAnalysisData,
} from './actions'
import Breadcrumb from '../../components/Breadcrumb'

import {
  REASON_FOR_PURCHASE_OPTIONS,
  hideReasonForCoverAffiliates,
} from './constants'

import { updateStep } from '../App/actions'

import { H3 } from '../../components/common/H3'
import config from '../../config'
import {
  I,
  A,
  DocumentsWrapper,
  SendReasonForCoverButton,
  SendReasonForCoverSelect,
  IconWrapper,
  MainHeading,
  SuccessfulReasonForCover,
  DocumentPostText,
  SummaryBorder,
} from './styles.js'
import { SummaryBorderMobile } from '../QuoteSummary/SummarySectionStyles'
import VehicleRegSection from './Sections/VehicleRegSection'
import VehicleDescriptionSection from './Sections/VehicleDescriptionSection'
import PolicyStartSection from './Sections/PolicyStartSection'
import PolicyEndSection from './Sections/PolicyEndSection'
import PolicyRefSection from './Sections/PolicyRefSection'
import SummaryFooter from './Sections/SummaryFooter'
import SummaryHeader from './Sections/SummaryHeader'
import DocumentHeader from './Sections/DocumentHeader'
import LoadingScreen from '../../components/LoadingScreen'
import {
  PRIVATE_CAR_SCHEME,
  COMMERCIAL_VEHICLE_SCHEME,
  PRIVATE_CAR,
} from '../Vehicle/constants'
import { noShowRefs } from '../../components/Logo'
import { LICENCE_TYPES } from '../../components/LicenceType/constants'
import { AppMarketingSection } from '../../components/AppMarketingSection'
import AnnualUpsell from '../AnnualUpsell'
import { SYSTEM_FEATURES } from '../../services/constants'
// import config from 'config'
import GridContainerWrapper from 'components/@common/GridContainerWrapper'
import { CTM_REF } from 'services/affiliateConstants'
import { getVariantAbTest } from 'utils/get-variant-ab-test'
import CustomerAccountQuote from 'containers/CustomerAccountQuote'
import MentionMe from 'containers/MentionMe'

/* eslint-disable react/prefer-stateless-function */
export class PolicyConfirmationPage extends React.Component {
  constructor(props) {
    super(props)
    this.handleSelect = this.handleSelect.bind(this)
    this.updateReasonForTempcover = this.updateReasonForTempcover.bind(this)

    this.state = {
      selectedOption: null,
    }
  }

  componentDidMount = () => {
    if (!window.isHoldingPageOn) {
      this.props.actions.initPolicyConfirmationPage()
      this.props.actions.updateStep(3)
    }
  }

  handleSelect = (event) => {
    var index = event.nativeEvent.target.selectedIndex
    this.setState({
      selectedOption: {
        value: event.target.value,
        description: event.nativeEvent.target[index].text,
      },
    })
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.policy.reasonForCover !== prevProps.policy.reasonForCover) {
      window.dataLayer.push({
        event: 'reasonForCover',
        policyConfirmationDataLayerPushEvent: [
          {
            reasonForCover: this.props.policy.reasonForCover.description,
          },
        ],
      })
    }
  }

  updateReasonForTempcover() {
    this.props.actions.updateReasonForTempcover(this.state.selectedOption)
  }

  handleReasonForCoverOptions() {
    return REASON_FOR_PURCHASE_OPTIONS.map((option) => (
      <option key={option.id} value={option.value} selected={option.selected}>
        {option.description}
      </option>
    ))
  }

  render() {
    const { value: licenceType } = this.props.riskData.LicenceType
    const isProvisional = licenceType === 'ProvisionalUkLicence'
    const isFullUK = licenceType === 'FullUkLicence'

    return (
      <>
        {this.props.loading ? (
          <LoadingScreen message='Generating your documents' />
        ) : (
          <>
            <GridContainerWrapper>
              <Breadcrumb step={4} disabled />
              <MainHeading>You're covered!</MainHeading>
              <IconWrapper>
                <I className='fas fa-check-circle'></I>
              </IconWrapper>
              <H3>
                Your policy documents have been sent to the email address
                provided.
              </H3>
              <GridContainer>
                <SummaryBorderMobile>
                  <SummaryHeader />
                  <VehicleRegSection
                    reg={this.props.vehicle.selected.RegistrationNumber}
                  />
                  <VehicleDescriptionSection
                    vehicle={this.props.vehicle.selected}
                  />
                  <PolicyStartSection
                    policyStartDate={this.props.riskData.PolicyStartDate.value}
                  />
                  <PolicyEndSection
                    policyEndDate={this.props.riskData.PolicyEndDate}
                  />
                  <PolicyRefSection policyId={this.props.policy.PolicyId} />
                  <SummaryFooter />
                </SummaryBorderMobile>
              </GridContainer>
              {!this.props.affiliate.AffiliateId &&
                !isProvisional &&
                !this.props.riskData.Motorbike &&
                !this.props.riskData.Impounded && (
                  <CustomerAccountQuote quoteId={this.props.riskData.QuoteId} />
                )}

              <MentionMe
                customerId={this.props.riskData.CustomerId}
                forename={this.props.riskData.Forename}
                surname={this.props.riskData.Surname}
                email={this.props.riskData.EmailAddress}
                price={this.props.price.TotalPrice.toFixed(2)}
              />
              <AnnualUpsell />
              <GridContainer>
                <DocumentsWrapper>
                  <DocumentHeader />
                  <Grid>
                    <ul className='fa-ul'>
                      {this.props.policy.PolicyDocuments.map(
                        (document, index) => (
                          <li key={index}>
                            <A
                              href={
                                config.BASE_API_URL +
                                '/api/motorQuotePolicies/{encryptedPolicyId}/documents/{documentTypeId}'
                                  .replace(
                                    /{[encryptedPolicyId}]*}/,
                                    this.props.riskData.QuoteId,
                                  )
                                  .replace(
                                    '{documentTypeId}',
                                    document.DocumentUnitTypeId,
                                  )
                              }
                              target='_blank'
                              id={`DocumentDownload${document.DocumentUnitTypeId}`}
                            >
                              <span className='fa-li'>
                                <i className='far fa-file-pdf' />
                              </span>
                              {document.DocumentName}
                            </A>
                          </li>
                        ),
                      )}
                    </ul>
                    <DocumentPostText>
                      Need your documents posting? No problem, just email us at{' '}
                      <a
                        id='ContactUsMailTo'
                        href='mailto:contactus@tempcover.com'
                      >
                        contactus@tempcover.com
                      </a>{' '}
                      and we'll get these out to you.{' '}
                    </DocumentPostText>
                  </Grid>
                </DocumentsWrapper>
              </GridContainer>
              {!this.props.affiliate.AffiliateId &&
              (isFullUK || isProvisional) &&
              this.props.vehicle.selected.VehicleType === PRIVATE_CAR ? (
                <GridContainer>
                  <AppMarketingSection />
                </GridContainer>
              ) : (
                <br />
              )}
            </GridContainerWrapper>
          </>
        )}
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    policy: state.policy,
    riskData: state.riskData,
    address: state.address,
    price: state.price,
    vehicle: state.vehicle,
    affiliate: state.affiliate,
    loading: state.isLoading,
    scheme: state.scheme.selected,
    features: state.features || {},
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        updatePolicy,
        updatePolicyContactDetails,
        updateValidation,
        requestPolicy,
        updateReasonForTempcover,
        initPolicyConfirmationPage,
        updateStep,
        updateAnalysisData,
      },
      dispatch,
    ),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PolicyConfirmationPage)
