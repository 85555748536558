import React from 'react';
import { Props as ButtonProps } from '../@common/Button/Button';
import { ContinueButtonStyled } from './ContinueButton.style';

interface Props extends ButtonProps {
  innerRef?: any;
}

const ContinueButton: React.FC<Props> = ({ children, innerRef, ...props }) => (
  <div ref={innerRef}>
    <ContinueButtonStyled {...props}>
      {children}
    </ContinueButtonStyled>
  </div>
);

export default ContinueButton;
