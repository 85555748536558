// @ts-ignore
import { ReactComponent as KlarnaLogo } from 'images/Payment/klarna-logo.svg';
import React from 'react';
import { KlarnaButtonStyled } from './KlarnaButton.styles';

interface Props {
    onClick: () => void;
}

const KlarnaButton: React.FC<Props> = ({ onClick }) => (
    <KlarnaButtonStyled id="KlarnaButton" type="button" onClick={onClick}>
        <KlarnaLogo width={100} height={40} />
    </KlarnaButtonStyled>
)

export default KlarnaButton
