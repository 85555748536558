import { useField, useFormikContext } from 'formik';
import React, { useEffect, useMemo } from 'react';
import { Option } from '../../../types/global';
import { SelectStyled } from './Select.styles';

interface Props extends Omit<React.HTMLProps<HTMLInputElement>, 'onChange'> {
  options: Option[];
  name: string;
  onChange?: (name: string, value: string, label: string) => void;
}

const Select: React.FC<Props> = ({
  options,
  placeholder = '',
  id,
  onChange = () => { },
  ...props
}) => {
  const [field, meta, helpers] = useField(props);
  const { validateField } = useFormikContext();

  const { value } = field;
  const { setValue, setTouched } = helpers;
  const className = value === '' || value === 'Uninitialised' || value === null
    ? 'placeholder'
    : '';

  let isValueInOptions = true;
  if (placeholder) {
    isValueInOptions = options.filter((option) => option.value === value).length > 0;
  }

  field.onChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const target = event.nativeEvent.target as HTMLSelectElement;
    const { selectedIndex: index } = event.nativeEvent
      .target as HTMLSelectElement;
    const targetOption = target[index] as HTMLOptionElement;
    const { value: optionValue, text: label } = targetOption;
    setTouched(true);
    setValue(optionValue, false);

    onChange(props.name, optionValue, label);
  };

  useEffect(() => {
    validateField(props.name);
  }, [field.value]);


  const optionsOrdered = useMemo(() => {
    if (options.filter((option) => option.displayOrder).length > 0) {
      return options.sort((a, b) => a.displayOrder! - b.displayOrder!)
    }
    return options;
  }, [options])

  return (
    <SelectStyled
      id={id}
      className={className}
      isValueInOptions={isValueInOptions}
      {...field}
    >
      {placeholder && <option value="">{placeholder}</option>}
      {optionsOrdered?.map((option) => (
        <option
          key={option.id}
          value={option.value}
          selected={option.selected}
        >
          {option.description}
        </option>
      ))}
    </SelectStyled>
  );
};

export default Select;
