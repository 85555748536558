import axios, { AxiosError } from 'axios';
import appConfig from '../config';
import { store } from './store';
import { history } from '..';
import antiForgeryApi from './antiForgeryApi';
import { shouldUseReverseProxy } from 'utils/should-use-reverse-proxy';

const useReverseProxy = shouldUseReverseProxy();

const url = useReverseProxy ? `${appConfig.REVERSE_PROXY_URL}` : `${appConfig.BASE_API_URL}`;
const http = axios.create({
  baseURL: url,
  withCredentials: true,
  headers: { 'Content-Type': 'application/json' },
});

http.interceptors.request.use(
  (config) => {
    const { antiForgeryToken } = store;
    const newConfig = { ...config };
    if (antiForgeryToken) {
      newConfig.headers['X-RequestVerificationToken'] = antiForgeryToken;
    }
    config.url = config.url?.replace(appConfig.REVERSE_PROXY_URL, '').replace(appConfig.BASE_API_URL, '')
    return config;
  },
  (error) => Promise.reject(error)
);

http.interceptors.response.use(
  (response) => response,
  async (error: AxiosError) => {
    if (error.response) {
      switch (error.response.status) {
        case 401: {
          const response = await antiForgeryApi.getAntiForgeryToken();
          const antiForgeryToken =
            response.headers['x-requestverificationtoken'];
          store.antiForgeryToken = antiForgeryToken;
          const newError = { ...error };
          if (antiForgeryToken) {
            newError.config.headers[
              'X-RequestVerificationToken'
            ] = antiForgeryToken;
          }
          return axios.request(newError.config);
        }
        case 503:
          history.push('/holding-page');
          return Promise.resolve();
        default:
          return Promise.reject(error);
      }
    } else {
      return Promise.reject(error);
    }
  }
);

export default http;
