import { IDENTITY_ENUM, SchemeEnum } from '@enums'
import Form from 'components/@templates/Form'
import { H2 } from 'components/common/H2'
import { BlueH3 } from 'components/common/H3'
import { H5 } from 'components/common/H5'
import { NoWrapSpan } from 'components/common/Span'
import { ComplianceWording } from 'components/ComplianceWording/'
import ContinueButton from 'components/ContinueButton'
import { PreviousRiddenModal } from 'components/PreviouslyRiddenMotorcycle/PreviousRiddenModal'
import { ProvisionalLicence } from 'components/ProvisionalLicence'
import SchemeSwitchMessage from 'components/SchemeSwitchMessage'
import {
  checkMarketingOptInState,
  setMarketingOptInState,
} from 'containers/MarketingPreferencesContainer/actions'
import {
  useAffiliate,
  useConfusedQuickQuote,
  useDuration,
  useIdentity,
  useScheme,
  useVehicle,
} from 'hooks'
import { InitialStateType } from 'initialState'
import moment from 'moment'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { QuoteJourneyPosition, Scheme } from 'types/global'
import VehicleSummary from 'components/VehicleSummary'
import { setQuoteJourneyPosition } from 'containers/App/actions'
import {
  createUnkownVehicle,
  setVehicleListener,
} from 'containers/Vehicle/actions'
import { MarketingPreferences } from '../../MarketingPreferences'
import { ConfusedQuickQuoteSchema } from './@CommonFields/ConfusedQuickQuoteOptIn/ConfusedQuickQuoteOptIn.validation'
import { ReasonForPurchaseSchema } from './@CommonFields/ReasonForPurchase/ReasonForPurchase.validation'
import CommercialVehicleForm from './CommercialVehicleForm'
import { CommercialVehicleFormData } from './CommercialVehicleForm/CommercialVehicleForm.types'
import commercialVehicleSchema, {
  FullVanSchema,
} from './CommercialVehicleForm/CommercialVehicleForm.validation'
import CourierForm from './CourierForm'
import { CourierFormData } from './CourierForm/CourierForm.types'
import courierSchema from './CourierForm/CourierForm.validation'
import {
  BaseDriverFormData,
  DriverFormData,
  DurationFormData,
  FullDriverFormData,
  PolicyStartDateFormData,
} from './DriverDetails.types'
import LearnerForm from './LearnerForm'
import { LearnerFormData } from './LearnerForm/LearnerForm.types'
import learnerSchema from './LearnerForm/LearnerForm.validation'
import MotorcycleForm from './MotorcycleForm'
import { MotorcycleFormData } from './MotorcycleForm/MotorcycleForm.types'
import MotorcycleFormSchema from './MotorcycleForm/MotorcycleForm.validation'
import PrivateCarForm from './PrivateCarForm'
import { PrivateCarFormData } from './PrivateCarForm/PrivateCarForm.types'
import privateCarSchema, {
  FullCarSchema,
} from './PrivateCarForm/PrivateCarForm.validation'
import IdentityCheckForm from './SubForms/IdentityCheckForm'
import { IdentityFormData } from './SubForms/IdentityCheckForm/IdentityCheck.types'
import {
  DrivingLicenceSchema,
  PassportSchema,
} from './SubForms/IdentityCheckForm/IdentityCheck.validation'
import MarketingPreferencesForm from './SubForms/MarketingPreferencesForm'
import MarketingPreferencesButton from './SubForms/MarketingPreferencesForm/MarketingPreferencesButton'
import QuoteParametersForm from './SubForms/QuoteParametersForm'
import { QuoteParamsSchema } from './SubForms/QuoteParametersForm/QuoteParametersForm.validation'

interface Props {
  onSubmit: (data: FullDriverFormData<DriverFormData>) => void
}

interface SchemeFormProps {
  scheme: Scheme | ''
}

const SchemeForm: React.FC<SchemeFormProps> = ({ scheme }) => {
  switch (scheme) {
    case 'VehiclePrivateCarBaseScheme':
    case 'VehiclePrivateCarImpoundedScheme':
      return <PrivateCarForm />
    case 'VehicleCommercialVehicleBaseScheme':
    case 'VehicleCommercialVehicleImpoundedScheme':
      return <CommercialVehicleForm />
    case 'VehicleLearnerScheme':
      return <LearnerForm />
    case 'VehicleMotorcycleLearnerAndFullLicenceScheme':
      return <MotorcycleForm />
    case 'VehicleCommercialVehicleCourierScheme':
      return <CourierForm />
    default:
      // TODO: Work out what happens if we hit this unlikely scenario
      return null
  }
}

const DriverDetails: React.FC<Props> = ({ onSubmit: onSubmitParent }) => {
  const dispatch = useDispatch()
  const handleSubmit = (data: FullDriverFormData<DriverFormData>) => {
    onSubmitParent(data)
  }
  const { scheme, isValidSchemeForProvisionalicence } = useScheme()
  const { identityCheck, licenceNumber } = useIdentity()
  const { affiliate } = useAffiliate()
  const { registrationNumber, vehicleValue } = useVehicle()
  const { duration, durationType } = useDuration()
  const {
    showConfusedQuickQuoteOptIn,
    confusedQuickQuote,
    validReasonsForPurchase,
  } = useConfusedQuickQuote()

  const driverDetailsData = useSelector(
    (state: InitialStateType) => state.riskData,
  )
  const mainContinueButtonRef = useRef<HTMLDivElement | null>(null)
  const [marketingButtonClick, setMarketingButtonClick] = useState(false)

  const marketingPreferences = useSelector(
    (state: InitialStateType) => state.marketingPreferences,
  )

  const postcode = useSelector(
    (state: InitialStateType) => state.address.Postcode,
  )

  const previousVehicleData = useSelector(
    (state: InitialStateType) => state.vehicle.previousRiddenVehicle,
  )

  const isFormButtonDisabled = useSelector(
    (state: InitialStateType) => state.formButtons.driverDetails.disabled,
  )

  // Preventing return from submitting the form
  const checkKeyDown = (e: React.KeyboardEvent<HTMLFormElement>) => {
    if (e.key === 'Enter') e.preventDefault()
  }

  useEffect(() => {
    dispatch(checkMarketingOptInState())
  }, [dispatch])

  const setOptIn = (type: string, optIn: boolean) => {
    dispatch(setMarketingOptInState(type, optIn))
  }

  const durationValues: DurationFormData = {
    duration,
    durationType,
  }

  const startDateTimeValues: PolicyStartDateFormData = {
    policyStartTime: driverDetailsData.PolicyStartDate.manuallyEdited
      ? driverDetailsData.PolicyStartDate.value || ''
      : '',
    manuallyChanged: driverDetailsData.PolicyStartDate.manuallyEdited,
  }

  let validSchema = QuoteParamsSchema

  let initialValues: BaseDriverFormData = {
    firstName: driverDetailsData.Forename,
    surname: driverDetailsData.Surname,
    title: driverDetailsData.Title.value,
    dateOfBirth: driverDetailsData.DateOfBirth
      ? moment(driverDetailsData!.DateOfBirth).format('DD/MM/YYYY')
      : '',
    vehicleValue,
    postcode,
    addressKey: driverDetailsData.AddressKey,
    emailAddress: driverDetailsData.EmailAddress,
    mobile: driverDetailsData.Mobile,
  }

  let identityValues: IdentityFormData = {}

  // if there is a pending identity check we need to update schema to reflect that
  switch (identityCheck) {
    case IDENTITY_ENUM.DRIVING_LICENCE:
      identityValues = {
        drivingLicenceNumber: licenceNumber,
      }
      validSchema = validSchema.concat(DrivingLicenceSchema)
      break
    case IDENTITY_ENUM.PASSPORT:
      identityValues = {
        passportLineOne: driverDetailsData.PassportLineOne,
        passportLineTwo: driverDetailsData.PassportLineTwo,
        passportExpiryDate: driverDetailsData.PassportExpiryDate || undefined,
      }
      validSchema = validSchema.concat(PassportSchema)
      break
    default:
      break
  }

  // @ts-ignore
  let formData: FullDriverFormData<DriverFormData> = {
    ...durationValues,
    ...startDateTimeValues,
    ...identityValues,
    ...initialValues,
  }

  const commercialVehicleBaseInitialValues = {
    ...initialValues,
    licenceHeldDuration: driverDetailsData.LicenceHeldDuration.value,
    licenceType: driverDetailsData.LicenceType.value,
    bodyType: driverDetailsData.BodyType.value,
  }

  const courierInitialValues = {
    ...commercialVehicleBaseInitialValues,
    occupation: driverDetailsData.Occupation.OccupationName,
    residencyType: driverDetailsData.ResidencyType.value,
    ukResidencyDuration: driverDetailsData.UkResidencyDuration.value,
  } as CourierFormData

  const privateCarBaseInitialValues = {
    ...initialValues,
    licenceHeldDuration: driverDetailsData.LicenceHeldDuration.value,
    licenceType: driverDetailsData.LicenceType.value,
  }

  switch (scheme) {
    case SchemeEnum.PRIVATE_CAR_IMPOUNDED:
      initialValues = privateCarBaseInitialValues as PrivateCarFormData
      validSchema = validSchema.concat(privateCarSchema)
      formData = {
        ...durationValues,
        ...startDateTimeValues,
        ...identityValues,
        ...initialValues,
      } as FullDriverFormData<PrivateCarFormData>
      break
    case SchemeEnum.PRIVATE_CAR:
      initialValues = {
        ...privateCarBaseInitialValues,
        residencyType: driverDetailsData.ResidencyType.value,
        ukResidencyDuration: driverDetailsData.UkResidencyDuration.value,
        reasonForPurchase: driverDetailsData.ReasonForPurchase?.value,
        reasonForPurchaseOtherText:
          driverDetailsData.ReasonForPurchaseOtherText,
      } as PrivateCarFormData
      if (showConfusedQuickQuoteOptIn) {
        const isValidReasonForPurchase = validReasonsForPurchase.includes(
          driverDetailsData.ReasonForPurchase?.value || '',
        )
        initialValues = {
          ...initialValues,
          confusedQuickQuoteOptIn: isValidReasonForPurchase
            ? `${confusedQuickQuote?.OptIn}`
            : '',
        } as PrivateCarFormData
        if (isValidReasonForPurchase) {
          validSchema = validSchema.concat(ConfusedQuickQuoteSchema)
        }
      }
      initialValues = {
        ...initialValues,
      } as PrivateCarFormData
      validSchema = validSchema.concat(ReasonForPurchaseSchema)
      validSchema = validSchema.concat(FullCarSchema)
      formData = {
        ...durationValues,
        ...startDateTimeValues,
        ...identityValues,
        ...initialValues,
      } as FullDriverFormData<PrivateCarFormData>
      break
    case SchemeEnum.COMMERCIAL_VEHICLE_IMPOUNDED:
      initialValues = commercialVehicleBaseInitialValues
      validSchema = validSchema.concat(commercialVehicleSchema)
      formData = {
        ...durationValues,
        ...startDateTimeValues,
        ...identityValues,
        ...initialValues,
      } as FullDriverFormData<CommercialVehicleFormData>
      break
    case SchemeEnum.COURIER:
      initialValues = courierInitialValues
      validSchema = validSchema.concat(courierSchema)
      formData = {
        ...durationValues,
        ...startDateTimeValues,
        ...identityValues,
        ...initialValues,
      } as FullDriverFormData<CourierFormData>
      break
    case SchemeEnum.COMMERCIAL_VEHICLE:
      initialValues = {
        ...durationValues,
        ...commercialVehicleBaseInitialValues,
        useOfVehicle: driverDetailsData.UseOfVehicle.value,
        occupation: driverDetailsData.Occupation.OccupationName,
        residencyType: driverDetailsData.ResidencyType.value,
        ukResidencyDuration: driverDetailsData.UkResidencyDuration.value,
      } as CommercialVehicleFormData
      initialValues = {
        ...initialValues,
        reasonForPurchase: driverDetailsData.ReasonForPurchase?.value,
        reasonForPurchaseOtherText:
          driverDetailsData.ReasonForPurchaseOtherText,
      } as CommercialVehicleFormData
      validSchema = validSchema.concat(ReasonForPurchaseSchema)
      validSchema = validSchema.concat(FullVanSchema)
      formData = {
        ...durationValues,
        ...startDateTimeValues,
        ...identityValues,
        ...initialValues,
      } as FullDriverFormData<CommercialVehicleFormData>
      break
    case SchemeEnum.LEARNER:
      initialValues = {
        ...initialValues,
        ownerOfVehicle: driverDetailsData.OwnerOfVehicle.value,
        hasVehicleGotAnnualInsuranceEnumeration:
          driverDetailsData.HasVehicleGotAnnualInsuranceEnumeration.value,
        occupation: driverDetailsData.Occupation.OccupationName,
        licenceHeldDuration: driverDetailsData.LicenceHeldDuration.value,
      } as LearnerFormData
      validSchema = validSchema.concat(learnerSchema)
      formData = {
        ...durationValues,
        ...startDateTimeValues,
        ...identityValues,
        ...initialValues,
      } as FullDriverFormData<LearnerFormData>
      break
    case SchemeEnum.MOTORCYCLE:
      initialValues = {
        ...initialValues,
        occupation: driverDetailsData.Occupation.OccupationName,
        licenceType: driverDetailsData.LicenceType.value,
        licenceHeldDuration: driverDetailsData.LicenceHeldDuration.value,
        useOfVehicle: driverDetailsData.MotorcycleUseOfVehicle.value,
        ownerOfVehicle: driverDetailsData.OwnerOfVehicle.value,
        overnightParking: driverDetailsData.MotorcycleOvernightParking.value,
        ridingExperience: driverDetailsData.MotorcycleRidingExperience.value,
        previousRiddenVehicle: previousVehicleData.RegistrationNumber || '',
        previousRiddenVehicleId: previousVehicleData.VehicleId || '',
        previousRiddenVehicleSearched: !!previousVehicleData.RegistrationNumber,
        motorcycleEntitlement: driverDetailsData.MotorcycleEntitlement.value,
        reasonForPurchase:
          driverDetailsData.MotorcycleReasonForTempcoverPurchase.value,
      } as MotorcycleFormData
      validSchema = validSchema.concat(MotorcycleFormSchema)
      formData = {
        ...durationValues,
        ...startDateTimeValues,
        ...identityValues,
        ...initialValues,
      } as FullDriverFormData<MotorcycleFormData>
      break
    default:
      // TODO: Work out what happens if we hit this unlikely scenario
      break
  }

  validSchema = validSchema.concat(QuoteParamsSchema)

  const handleMarketingPreferencesSubmit = useCallback(() => {
    setMarketingButtonClick(true)
  }, [])

  const showMarketingPreferences = useMemo(() => {
    if (
      !isValidSchemeForProvisionalicence &&
      driverDetailsData.LicenceType.value === 'ProvisionalUkLicence'
    ) {
      return false
    }
    switch (scheme) {
      case SchemeEnum.COMMERCIAL_VEHICLE:
        return driverDetailsData.UseOfVehicle.value !== 'CourierOrHaulage'
      default:
        return true
    }
  }, [driverDetailsData, scheme, isValidSchemeForProvisionalicence])

  const { vehicle } = useVehicle()

  const handleVehicleUpdate = () => {
    dispatch(
      setQuoteJourneyPosition(QuoteJourneyPosition.CustomerVehicleChanged),
    )
    if (vehicle.searched.VehicleFound) {
      dispatch(setVehicleListener(vehicle.searched))
    } else {
      dispatch(createUnkownVehicle())
    }
  }

  return (
    <>
      <PreviousRiddenModal />
      <Form
        initialValues={formData}
        validationSchema={validSchema}
        onSubmit={(data) => handleSubmit(data)}
        onKeyDown={(e) => checkKeyDown(e)}
      >
        <MarketingPreferencesForm shouldHandleBlur />
        <VehicleSummary
          id='VehicleSummaryDesktop'
          handleUpdate={handleVehicleUpdate}
          isIndividual
        />
        <QuoteParametersForm />
        <H2>Tell us about the driver</H2>
        <SchemeForm scheme={scheme} />
        {showMarketingPreferences && (
          <>
            {/* <H2>Tell us where to send your documents</H2> */}
            <IdentityCheckForm />
            <ComplianceWording />
            <>
              <H2 id='keepingInTouch_hdr'>Keeping in touch</H2>
              <H5 id='keepingInTouch_desc'>
                You will shortly receive a summary of your{' '}
                <NoWrapSpan>quote by email.</NoWrapSpan>
              </H5>
            </>
            {!marketingPreferences.loaded &&
              !affiliate.MarketingPreferencesDisabled && (
                <MarketingPreferencesButton
                  onSubmit={handleMarketingPreferencesSubmit}
                />
              )}
            {marketingPreferences.loaded &&
              !affiliate.MarketingPreferencesDisabled && (
                <>
                  {marketingPreferences.PreviousCustomer ? (
                    <BlueH3>
                      We&apos;d also like to keep you up to date with our latest
                      customer discounts and promotions. Here are your current
                      marketing preferences. If you&apos;d like to change them,
                      simply update below.
                    </BlueH3>
                  ) : (
                    <BlueH3>
                      We&apos;d also like to keep you up to date with our latest
                      customer discounts and promotions. You can change your
                      mind at anytime. Just tell us how you wish to be
                      contacted:
                    </BlueH3>
                  )}
                  <MarketingPreferences
                    id='2'
                    label='Send via email'
                    optIn={marketingPreferences.EmailAddressOptIn}
                    selected={(e: boolean) => {
                      setOptIn('email', e)
                    }}
                  />
                  <MarketingPreferences
                    id='1'
                    label='Send via text message'
                    optIn={marketingPreferences.TelephoneNumberOptIn}
                    selected={(e: boolean) => setOptIn('sms', e)}
                  />
                  <h4>
                    Please note, we will never pass your details to third
                    parties for marketing purposes without your permission
                  </h4>
                </>
              )}
            {(marketingPreferences.loaded ||
              (affiliate && affiliate.MarketingPreferencesDisabled)) && (
              <ContinueButton
                innerRef={mainContinueButtonRef}
                id='TeslaSubmitManualDetailsNextButton'
                disabled={isFormButtonDisabled}
                loading={isFormButtonDisabled}
                fullWidth
                type='submit'
              >
                Continue
              </ContinueButton>
            )}
          </>
        )}

        {scheme !== 'VehicleCommercialVehicleCourierScheme' &&
          driverDetailsData.UseOfVehicle.value === 'CourierOrHaulage' && (
            <SchemeSwitchMessage scheme='VehicleCommercialVehicleCourierScheme' />
          )}
        {!isValidSchemeForProvisionalicence &&
          driverDetailsData.LicenceType.value === 'ProvisionalUkLicence' && (
            <ProvisionalLicence vehicleReg={registrationNumber} />
          )}
      </Form>
    </>
  )
}

export default DriverDetails
