import { call, put, takeLatest, select } from 'redux-saga/effects';
import { REQUEST_OCCUPATIONS } from './constants';
import occupationApi from '../../api/occupationApi';
import { requestOccupationsSuccess, requestOccupationsFailed } from './actions';
import { searchOccupationsRelSelector } from './selector';

export function* fetchOccupations({ occupationSearch }) {
  try {
    const getOccupationsLink = yield select(searchOccupationsRelSelector);
    const addressEndpoint = `${getOccupationsLink.Href}?occupationName=${occupationSearch}`;
    const occupations = yield call(occupationApi.getOccupations, addressEndpoint);
    if (occupations.data.Occupations.length === 0) {
      yield put(requestOccupationsFailed());
    } else {
      yield put(requestOccupationsSuccess(occupations.data.Occupations));
    }
  } catch (e) {
    yield put(requestOccupationsFailed(e.message));
  }
}

export default function* occupationSaga() {
  yield takeLatest(REQUEST_OCCUPATIONS, fetchOccupations);
}
