import initialState from '../../../initialState';
import { REQUEST_AFFILIATE_SUCCESS, REQUEST_QUOTE_SUCCESS } from '../constants';
  
  function affiliateReducer(state = initialState.affiliate, action) {
    switch (action.type) {   
      case REQUEST_AFFILIATE_SUCCESS:
        return action.affiliate
      case REQUEST_QUOTE_SUCCESS:
        return action.quote.Affiliate ? action.quote.Affiliate : {...state.affiliate}
      default:
        return state;
    }
  }
  
  export default affiliateReducer;
  