import styled from 'styled-components';
import { LinkContainer } from '../AddonPriceComparisonContainer.styles';

export const Wrapper = styled.div`
  position: relative;
  display: inline-block;
  user-select: none;

  @media (max-width: 767px) {
    font-size: 18px;
  }
`;

interface Props { }

export const AddonMoreInfoWrapper = styled.div<Props>`
  position: fixed;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
  cursor: default;
  width: 70%;
  max-height: 90vh;
  background: #fff;
  color: ${(props) => props.theme.primaryTextColour};
  box-shadow: 0px 0px 15px 0px #707070;
  border-radius: ${(props) => props.theme.formControlBorderRadius};
  padding: 10px;
  z-index: 2;

  & ul {
    list-style: none;
    padding: 0;
    margin: 0;

    & li {
      font-family: ${(props) => props.theme.fontFamily};
      font-size: 14px;
      color: #000;
      text-align: left;


      &:last-child {
        border: none;
      }

      & span {
        font-family: "BrandonText-Bold", sans-serif;
        display: block;
        color: ${(props) => props.theme.primaryTextColour};
      }
    }
  }

  a, p {
    margin: 1em 0 !important;
  }

  @media (max-width: 767px) {
    font-size: 12px;

    & ul li {
      font-size: 12px;
    }
  }
`;

export const AddonMoreInfoTitle = styled.h2`
  font-family: ${(props) => props.theme.labelFontFamily}; 
  font-size: 22px;
  margin-left: 0 !important;
  margin: 5px;
`;

export const AddonMoreInfoBody = styled.div`
  font-family: ${(props) => props.theme.labelFontFamily}; 
  max-height: 60vh;
  overflow-y: auto;
`

export const AddonMoreInfoText = styled.p`
  font-family: ${(props) => props.theme.labelFontFamily}; 
  font-size: 14px;
`;

export const PaymentPageLinkContainer = styled.a`
font-size: 14px;
  cursor: pointer;
  color: ${(props) => props.theme.primaryTextColour};
  font-family: ${(props) => props.theme.fontFamily};  
  text-decoration: underline;

  :visited {
    color: ${(props) => props.theme.primaryTextColour};
  }

  @media (max-width: 768px) {
    font-size: 12px;
  }
`;