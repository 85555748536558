import initialState from 'initialState';
import { DriverDetailsFormAction } from './driverDetailsFormFields.actions';

const driverDetailsFormFieldsReducer = (state = initialState.driverDetailsFormFields, action: DriverDetailsFormAction) => {
  switch (action.type) {
    case 'SET_REASON_FOR_TEMPCOVER_PURCHASE': {
      return {
        ...state,
        reasonForTempcoverPurchaseOptions: [...state.reasonForTempcoverPurchaseOptions, ...action.payload!],
      };
    }
    default:
      return state;
  }
};

export default driverDetailsFormFieldsReducer;
