export const CREATE_PAYPAL_PAYMENT = 'src/PayPal/CREATE_PAYPAL_PAYMENT';
export const CREATE_PAYPAL_PAYMENT_SUCCESS = 'src/PayPal/CREATE_PAYPAL_PAYMENT_SUCCESS';
export const CREATE_PAYPAL_PAYMENT_FAILURE = 'src/PayPal/CREATE_PAYPAL_PAYMENT_FAILURE';

export const CANCEL_PAYPAL_PAYMENT = 'src/PayPal/CANCEL_PAYPAL_PAYMENT';
export const CANCEL_PAYPAL_PAYMENT_SUCCESS = 'src/PayPal/CANCEL_PAYPAL_PAYMENT_SUCCESS';
export const CANCEL_PAYPAL_PAYMENT_FAILURE = 'src/PayPal/CANCEL_PAYPAL_PAYMENT_FAILURE';

export const PAYPAL_PAYMENT_ERROR = 'src/PayPal/PAYPAL_PAYMENT_ERROR';
export const PAYPAL_PAYMENT_ERROR_SUCCESS = 'src/PayPal/PAYPAL_PAYMENT_ERROR_SUCCESS';
export const PAYPAL_PAYMENT_ERROR_FAILURE = 'src/PayPal/PAYPAL_PAYMENT_ERROR_FAILURE';

export const POLICY_PRE_PURCHASE_LINK = 'ProcessPolicyPrePurchase'; 
export const PAYPAL_PAYMENT_LINK = 'ProcessPayPalPurchase'; 
export const PAYMENT_FAILED_LINK = 'ProcessUnsuccessfulPayment'; 
export const PAYMENT_CANCELLATION_LINK = 'ProcessPaymentCancellation'; 


