import React, { useMemo } from 'react';
import { LinkContainer } from '../Addons.styles';

interface Props {
    content: string;
    doc: string;
}

const AddonMoreInfomItem: React.FC<Props> = ({ content, doc }) => {

  const linkId = useMemo(() => content.replaceAll(' ', '-').toLocaleLowerCase(), [content]);

 return (
  <li>
    <span>
      <LinkContainer id={`link-${linkId}`} type="application/pdf" href={doc} target="_blank">
        {content}
        {' '}
        <i className="fa fa-external-link-alt fa-xs" />
      </LinkContainer>

    </span>
  </li>
  )
};

export default AddonMoreInfomItem;
