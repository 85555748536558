import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Message } from 'components/Message';
import { InitialStateType } from 'initialState';
import { useIdentity, useRiskData } from 'hooks';

const DriverDetailsError: React.FC = () => {
  const myLicence = useSelector((state: InitialStateType) => state.myLicence);
  const { identityCheck } = useIdentity();
  const { isRiskDataValid } = useRiskData();

  useEffect(() => {
    // Scrolls user to top of page if AllFieldsValid is false to show user the error
    if (!isRiskDataValid) window.scrollTo(0,0)
  }, [isRiskDataValid])

  if (myLicence.CheckFailed && myLicence.FailReasons.includes('LicenceNumber')) {
    return (
      <Message
        heading="We have been unable to confirm your information"
        content="The driving licence number you have entered cannot be validated.  Please check and try again."
        type="error"
      />
    );
  } 
  
  if (myLicence.CheckFailed && myLicence.FailReasons.includes('LicenceCategory')) {
    return (
      <Message
        heading="Please check your licence type"
        content="We have been unable to match your driving licence type with the information you have provided. Please check and try again."
        type="error"
      />
    );
  } 
  
  if (identityCheck || !isRiskDataValid) {
    return (
      <Message
        heading="Sorry, we have been unable to confirm your information."
        content="Please double check your information and your policy start time is correct then try again."
        type="error"
      />
    );
  }

  return <></>;
};

export default DriverDetailsError;
