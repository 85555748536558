export const RESIDENCY_TYPES = [
  {
    id: 2,
    description: 'Permanent UK resident',
    value: 'PermanentUkResident',
    DisplayOrder: 0,
  },
  {
    id: 4,
    description: 'EU/EEA country',
    value: 'EuEeaCountry',
    DisplayOrder: 0,
  },
  {
    id: 5,
    description: 'Other country',
    value: 'Other',
    DisplayOrder: 0,
  },
];
