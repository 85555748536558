import config from 'config'
import { InitialStateType } from 'initialState'
import React from 'react'
import { useSelector } from 'react-redux'
import { GridContainer } from 'unsemantic'
import {
  useABExperiment,
  useAddons,
  useAffiliate,
  usePayment,
  usePrice,
} from '../../hooks'
import { BreakdownCover } from '../SVG/add-on-icons/BreakdownCover'
import { ExcessCover } from '../SVG/add-on-icons/ExcessCover'
import { LegalExpenses } from '../SVG/add-on-icons/LegalExpenses'
import AddonContainer from './AddonContainer'
import AddonPriceComparisonContainer from './AddonPriceComparisonContainer'
import {
  AddonContentList,
  AddonHeading,
  AddonSubHeading,
  AddonWrapper,
} from './Addons.styles'

export interface Excess {
  compulsory: number
  reduced: string
}

const Addons: React.FC = () => {
  const { availableAddons, excess } = useAddons()
  const { isPriceComparison } = useAffiliate()
  const { variant } = useABExperiment(
    config.AB_TESTS.PRICE_BREAKDOWN_DIRECT_EXPERIMENT_ID,
    'Price breakdown experiment',
  )
  const { price } = usePrice()
  const {
    payment: { paymentInProgress },
  } = usePayment()
  const isAddonLoading = useSelector(
    (state: InitialStateType) => state.price.IsAddonUpdating,
  )

  const fetchLogo = (type: string) => {
    switch (type) {
      case 'excess':
        return <ExcessCover />
      case 'breakdown':
        return <BreakdownCover />
      case 'legalexpenses':
        return <LegalExpenses />
      default:
        return <></>
    }
  }

  const fetchContent = (type: string) => {
    switch (type) {
      case 'excess':
        return (
          <span>
            Your policy excess is &#163;
            {price.CompulsoryExcess}
            <br />
            <br />
            Add excess reduction to reduce your excess to &#163;
            {excess.excessAmount}
            <br />
          </span>
        )
      case 'breakdown':
        return (
          <>
            <span>
              <b>Includes:</b>
            </span>
            <AddonContentList>
              <li>Roadside call out</li>
              <li>Home start</li>
              <li>National recovery</li>
            </AddonContentList>
          </>
        )
      case 'legalexpenses':
        return (
          <>
            <span>
              <b>Includes:</b>
            </span>
            <AddonContentList>
              <li>Legal Expenses TBC</li>
            </AddonContentList>
          </>
        )
      default:
        return <></>
    }
  }

  if (availableAddons && availableAddons.length) {
    if (isPriceComparison || variant !== '0') {
      return (
        <>
          <AddonSubHeading>Choose your extras</AddonSubHeading>
          <AddonWrapper>
            {availableAddons &&
              availableAddons.map((addon, i) => (
                <AddonPriceComparisonContainer
                  key={i}
                  type={addon.type}
                  price={addon.totalPrice}
                  selected={addon.selected}
                  disabled={isAddonLoading || paymentInProgress}
                  error={addon.hasError}
                  excess={
                    {
                      compulsory: price.CompulsoryExcess,
                      reduced: excess.excessAmount,
                    } as Excess
                  }
                />
              ))}
          </AddonWrapper>
        </>
      )
    }
    return (
      <>
        <AddonHeading>Choose your extras</AddonHeading>
        <GridContainer>
          {availableAddons &&
            availableAddons.map((addon, i) => (
              <AddonContainer
                key={i}
                type={addon.type}
                heading={addon.heading}
                price={addon.price}
                selected={addon.selected}
                disabled={isAddonLoading}
                content={fetchContent(addon.type)}
                logo={fetchLogo(addon.type)}
                error={addon.hasError}
              />
            ))}
        </GridContainer>
      </>
    )
  }

  return <></>
}

export default Addons
