import defaultTheme from "./default";

let strideTheme = {...defaultTheme};   
const primaryColour = '#1eb355';
const secondaryColour = '#f58600';
strideTheme.primaryColour = primaryColour;
strideTheme.primaryTextColour = '#333';
strideTheme.headerFontColour = primaryColour;
strideTheme.breadcrumb = {
  ...defaultTheme.breadcrumb,
    activeColor: "#ffffff",
    activeBorderColor: primaryColour,
    defaultColor: "#ffffff",
    defaultBorderColor: "#CCCCCC",
    defaultBarColor: "#CCCCCC",
    completeColor: primaryColour,
    completeBorderColor: primaryColour,
    completeBarColor: primaryColour
  }
  strideTheme.spinner = {
    ...defaultTheme.spinner,
    primaryColour: primaryColour,
    textColour: primaryColour
  }
  strideTheme.durationOptions ={
  ...defaultTheme.durationOptions,
  primaryColour: primaryColour,
  secondaryColour: secondaryColour
}
strideTheme.button = {
  ...defaultTheme.button,
    backgroundColour: secondaryColour
}
strideTheme.header = {
  ...defaultTheme.header,
  background: primaryColour,
  borderBottom: "none",
}
  strideTheme.headerLinkStyled={
    ...defaultTheme.headerLinkStyled,
    padding: "0px",
    hover: {
      textDecoration: "underline",
      color: '#fff',
      backgroundColour: primaryColour,
      padding: "0px"
    }
  }

strideTheme.gradient = {
  ...defaultTheme.gradient,
  background: '#E8F0FF'
}
strideTheme.icon = {
  ...defaultTheme.icon,
  color: primaryColour,
  backgroundColor: '#E9F0FF'
}
strideTheme.GbIcon = '#fff';
strideTheme.formControlValidIcon = {
  ...defaultTheme.formControlValidIcon,
  colour: '#fff',
  backgroundColour: primaryColour
}
strideTheme.headerFontColour = primaryColour;
strideTheme.inputBorderColour = '#999999';
strideTheme.successColour = primaryColour;
strideTheme.marketingPreferencesColour = secondaryColour;
strideTheme.svg = {
  ...defaultTheme.svg,
  primaryColour: primaryColour,
  accentColour: '#B5FFCE',
}

export default strideTheme;
