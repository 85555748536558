import styled from 'styled-components';
import PropTypes from 'prop-types'
import '../../../../node_modules/@fortawesome/fontawesome-free/css/all.css'

export const InputWrapper = styled.div`
    margin-bottom: 1em;
    position: relative;
    &:before {
        font-family: 'Font Awesome 5 Free';
        content: ${props => props.icon ? "'" + props.icon + "'": undefined};
        position: absolute;
        display: inline-block;
        font-weight: 900;
        font-size: 18px;
        top: 10px;
        left: 8px;
    }
`

InputWrapper.propTypes = {
    icon: PropTypes.string
}