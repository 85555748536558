import { H2 } from 'components/common/H2';
import styled from 'styled-components';

interface AddonStyleProps {
  active?: boolean;
  error?: boolean;
}

export const AddonSection = styled.div<AddonStyleProps>`
  display: flex;
  position: relative;
  border: 1px solid ${(props) => props.theme.inputBorderColour};
  border-radius: ${(props) => props.theme.formControlBorderRadius};
  text-align: left;
  overflow: visible;
  background: white;
  padding: 10px 20px;

  &:not(:first-child) {
    margin-top: ${(props) => (props.error ? '0' : '32px')};
  }

  @media (max-width: 576px) {
    padding: 8px 10px;
  }

  ${(props) => {
    if (props.active) {
      return `
      background-color: ${props.theme.gradient.background};
      background-repeat: no-repeat;
      background-image: linear-gradient(105deg, ${props.theme.gradient.background} 60%, ${props.theme.primaryColour} 40%);
      `;
    }

    return '';
  }}
`;

export const AddonPriceContainer = styled.div`
  display: flex;
  flex-basis: 50%;
  flex-grow: 1;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column; 

  @media (max-width: 768px) {
    flex-basis: 25%;
  }
`;

export const AddonLogoContainer = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  margin-right: 10px;
`;

export const AddonContentContainer = styled.div`
  flex-basis: 50%;
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    flex-basis: 60%;
  }
`;

export const AddonButtonContainer = styled.div`
  display: flex;

  @media (max-width: 576px) {
    flex-direction: column-reverse;
  }
`;

export const LinkContainer = styled.a`
  font-family: ${(props) => props.theme.fontFamily};
  font-size: 14px;
  cursor: pointer;
  color: ${(props) => props.theme.primaryTextColour};

  :visited {
    color: ${(props) => props.theme.primaryTextColour};
  }

  @media (max-width: 576px) {
    font-size: 12px;
  }
`;

export const Heading = styled.h2`
  font-size: 14px;
  font-family: ${(props) => props.theme.headerFontFamily};
  color: ${(props) => props.theme.headerFontColour};
  margin-bottom: 0;
  margin-top: 0;
  text-align: left;

  @media (max-width: 576px) {
    font-size: 12px;
  }
`;

export const Content = styled.div`
  font-family: ${(props) => props.theme.fontFamily};
  font-size: 14px;
  flex-grow: 1;

  @media (max-width: 576px) {
    font-size: 12px;
  }

  @media (max-width: 576px) {
    font-size: 12px;
  }
`;

export const Price = styled.p<AddonStyleProps>`
  font-family: ${(props) => props.theme.headerFontFamily};
  font-size: 32px;
  padding-top: 10px;
  margin: 0;
  text-align: right;
  color: ${(props) => props.theme.primaryTextColour};

  @media (max-width: 768px) {
    font-size: 25px;
  }

  ${({ active }) => active && `
    color: #fff;
  `}
`;

export const Logo = styled.div`
  width: 50%;
  max-width: 200px;
  display: block;
  font-size: 65px;
  margin-left: -6px;
  margin-top: 25px;
`;

export const AddonButton = styled.button<AddonStyleProps>`
  background: ${(props) => props.theme.button.backgroundColour};
  color: ${(props) => props.theme.button.textColour};
  border-radius: ${(props) => props.theme.formControlBorderRadius};
  font-size: ${(props) => props.theme.button.fontSize};
  text-transform: ${(props) => props.theme.button.textTransform};
  text-align: ${(props) => props.theme.button.textAlign};
  box-shadow: none;
  border: none;
  outline: none;
  cursor: pointer;
  height: ${(props) => props.theme.formControlHeight};
  width: 100px;

  :hover {
    filter: brightness(80%);
  }

  @media (max-width: 320px) {
    width: 90px;
  }

  ${({ active }) => active && `
    :hover {
      filter: none;
    }
    cursor: default;
    background: #808080;
  `}
`;

export const RemoveButton = styled.button`
  background: none !important;
  border: none;
  font-family: arial, sans-serif;
  color: #fff;
  text-decoration: underline;
  cursor: pointer;
  font-size: 14px;
  padding-right: 10px;

  @media (min-width: 380px) and (max-width: 520px) {
    right: 90px;
  }

  @media (max-width: 380px) {
    right: 78px;
  }

  :hover {
    filter: brightness(80%);
  }
`;

export const AddonHeading = styled.h2`
  font-size: 22px;
  font-family: ${(props) => props.theme.headerFontFamily};
  color: ${(props) => props.theme.headerFontColour};
  text-align: center;
  margin-top: 0;
  margin-bottom: 15px;
`;

export const AddonError = styled.p`
  color: red;
  font-size: 14px;
  margin-top: 8px;
  margin-bottom: 3px;
`;

export const ProvidedByText = styled.p<AddonStyleProps>`
  font-size: 14px;
  padding-right: 5px;

  ${({ active }) => active && `
    color: #fff;
  `}
`;

export const ProvidedByContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const AddonContentList = styled.ul`
  margin: 6px 0;
`;

export const AddonSubHeading = styled(H2)`
  font-size: 18px;
  font-family: ${(props) => props.theme.labelFontFamily};
  font-weight: 500;
  margin: 0;
`

export const AddonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 15px;
  margin-top: 15px;

  @media (max-width: 768px) {
    font-size: 15px;
  }
`