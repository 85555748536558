import defaultTheme from '../themes/default';
import aPlanTheme from '../themes/aplan';
import { WHITE_LABEL, SEMI_BRANDED, NON_BRANDED, APLAN_REF, ONE_ANSWER_REF, RIGHT_CHOICE_REF, STRIDE_REF, BRIGHTSIDE_REF, ARNOLD_CLARK_REF, MOTOR_EASY_REF, QUIDCO_REF, CTM_REF } from './affiliateConstants';
import QueryString from 'query-string';
import brightsideTheme from '../themes/brightside';
import oneAnswerTheme from '../themes/oneAnswerTheme';
import rightChoiceTheme from '../themes/rightChoiceTheme';
import strideTheme from '../themes/strideTheme';
import arnoldClarkTheme from '../themes/arnoldClarkTheme';
import motorEasyTheme from '../themes/motorEasyTheme';
import quidcoTheme from '../themes/quidcoTheme';
import compareTheMarketTheme from 'themes/compareTheMarketTheme';

class themeService {
    static getTheme(affiliate) {
        const parsedQuerystring = QueryString.parse(window.location.search);
        switch (affiliate.AffiliateType) {
            case WHITE_LABEL:
                switch (affiliate.Ref) {
                    case APLAN_REF:
                        return aPlanTheme;
                    case BRIGHTSIDE_REF:
                        return brightsideTheme;
                    case ONE_ANSWER_REF:
                        return oneAnswerTheme;
                    case RIGHT_CHOICE_REF:
                        return rightChoiceTheme;
                    case STRIDE_REF:
                        return strideTheme;
                    case ARNOLD_CLARK_REF:
                        return arnoldClarkTheme;
                    case MOTOR_EASY_REF:
                        return motorEasyTheme;
                    case QUIDCO_REF:
                        return quidcoTheme;
                    case CTM_REF:
                        return compareTheMarketTheme;
                    default:
                        return defaultTheme;
                }
            case SEMI_BRANDED:
            case NON_BRANDED:
            default:
                switch (parsedQuerystring.ref) {
                    case APLAN_REF:
                        return aPlanTheme;
                    case BRIGHTSIDE_REF:
                        return brightsideTheme;
                    case ONE_ANSWER_REF:
                        return oneAnswerTheme;
                    case RIGHT_CHOICE_REF:
                        return rightChoiceTheme;
                    case STRIDE_REF:
                        return strideTheme;
                    case ARNOLD_CLARK_REF:
                        return arnoldClarkTheme;
                    case MOTOR_EASY_REF:
                        return motorEasyTheme;
                    case QUIDCO_REF:
                            return quidcoTheme;
                    default:
                        return defaultTheme
                }
        }
    }
}

export default themeService;