import initialState from '../../../initialState';
import {
    SET_UI_ELEMENT_VISIBILITY,
    SET_UI_ELEMENT_MESSAGE_VISIBILITY
} from '../constants';
import { SHOW_BREAKDOWN_ICON } from '../../PaymentBreakdownIcon/constants';

function uiReducer(state = initialState.ui, action) {
    switch (action.type) {
        case SET_UI_ELEMENT_VISIBILITY:
            return {
                ...state,
                [action.element]: {
                    ...state[action.element],
                    isVisible: action.visibility
                }
            }
        case SET_UI_ELEMENT_MESSAGE_VISIBILITY:
            return {
                ...state,
                [action.element]: {
                    ...state[action.element],
                    messageIsVisible: action.visibility
                }
            }
        case SHOW_BREAKDOWN_ICON:
            const currentlyVisible = state.VisiblePriceBreakdown;
            return {
                ...state,
                VisiblePriceBreakdown: currentlyVisible === action.iconKey ? null : action.iconKey
            }
        default:
            return state;
    }
}

export default uiReducer;