import React from 'react';
import { LogoProps } from '..';
// @ts-ignore
import DigiCertSeal from '../../../images/digicertseal.svg';

const Digicert: React.FC<LogoProps> = ({ width, height }) => (
  <img src={DigiCertSeal} alt="Digitcert seal" width={width} height={height} />
);

export default Digicert;
