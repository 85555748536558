import config from 'config'
import { useABExperiment } from 'hooks'
import { InitialStateType } from 'initialState'
import React from 'react'
import { useSelector } from 'react-redux'
import RiskDataDiscountBanner from './RiskDataDiscountBanner'

const RiskDataDiscountBannerContainer = () => {
  const campaign = useSelector(
    (state: InitialStateType) => state.price.campaign,
  )
  if (
    window.location.pathname.includes('/quote/driver-details') &&
    campaign.Active
  ) {
    return <RiskDataDiscountBanner />
  }
  return null
}
export default RiskDataDiscountBannerContainer
