import styled from "styled-components";

interface Props {
    isAllowed: boolean;
}

export const KlarnaContentContainer = styled.div<Props>`
    min-height: 210px;
    opacity: ${({ isAllowed }) => isAllowed ? 1 : 0};
`

export const KlarnaOptionHeaderWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`

export const KlarnaErrorWrapper = styled.div`
    margin-top: 24px;
`

export const KlarnaErrorButtonWrapper = styled.div`
    margin-top: 16px;
`