import { SchemeEnum } from '@enums'
import { call, put, select, takeLatest } from 'redux-saga/effects'
import { history } from '../../..'
import quoteApi from '../../../api/quoteApi'
import appConfig from 'config'
import {
  requestConfusedQuickQuote,
  setUnderwriterChanged,
} from '../../App/actions'
import { requestPrice } from '../../App/sagas/priceSaga'
import { getAffiliateFromState, getConfusedQuickQuote, getDurationData, getSystemFeatures } from '../../App/selector'
import { requestMyLicenceStatus } from '../../MyLicence/actions'
import {
  COMMERCIAL_VEHICLE_SCHEME,
  IMPOUNDED_COMMERCIAL_VEHICLE_SCHEME,
  MOTORCYCLE_VEHICLE_SCHEME,
  PRIVATE_CAR_SCHEME,
  VEHICLE_LEARNER_SCHEME,
} from '../../Vehicle/constants'
import {
  clearIdentityCheck,
  confirmRiskCollectionPage,
  enableAllButtons,
  updateQuoteFailure,
  updateQuoteSuccess,
} from '../actions'
import { UPDATE_QUOTE } from '../constants'
import {
  getQueryString,
  getRiskData,
  getSelectedScheme,
  getVehicle,
  updateQuoteLinkSelector,
  getIsCustomerAccountQuote,
} from '../selector'
import { underwriterSaga } from './underwriter'

export function* updateRiskDataSaga(action) {
  const riskData = yield select(getRiskData)
  const duration = yield select(getDurationData)
  const queryString = yield select(getQueryString)
  try {
    const updateQuoteLink = yield select(updateQuoteLinkSelector)
    const vehicleInfo = yield select(getVehicle)
    const scheme = yield select(getSelectedScheme)
    const { OptIn } = yield select(getConfusedQuickQuote)
    const { ConfusedUpsellEnabled } = yield select(getSystemFeatures)
    const { AffiliateId } = yield select(getAffiliateFromState)
    const isCustomerAccountQuote = yield select(getIsCustomerAccountQuote)

    const confusedQuickQuoteValid = !AffiliateId && ConfusedUpsellEnabled && scheme === PRIVATE_CAR_SCHEME

    let privateCarDetails = {}
    let vanDetails = {}
    let learnerDetails = {}
    let motorbikeDetails = {}

    if (scheme === PRIVATE_CAR_SCHEME) {
      privateCarDetails = {
        Expat: riskData.Expat,
        ReasonForPurchase: riskData.ReasonForPurchase?.value ?? 'NotSet',
        ReasonForPurchaseOtherText: riskData.ReasonForPurchaseOtherText,
      }
    }

    if (scheme === COMMERCIAL_VEHICLE_SCHEME) {
      vanDetails = {
        BodyType: riskData.BodyType.value,
        UseOfVehicle: riskData.UseOfVehicle.value,
        Occupation: riskData.Occupation,
        ReasonForPurchase: riskData.ReasonForPurchase?.value ?? 'NotSet',
        ReasonForPurchaseOtherText: riskData.ReasonForPurchaseOtherText,
      }
    }

    if (scheme === SchemeEnum.COURIER) {
      vanDetails = {
        BodyType: riskData.BodyType.value,
        Occupation: riskData.Occupation,
      }
    }

    if (scheme === IMPOUNDED_COMMERCIAL_VEHICLE_SCHEME) {
      vanDetails = {
        BodyType: riskData.BodyType.value,
      }
    }

    if (scheme === VEHICLE_LEARNER_SCHEME) {
      learnerDetails = {
        OwnerOfVehicle: riskData.OwnerOfVehicle.value,
        HasVehicleGotAnnualInsuranceEnumeration:
          riskData.HasVehicleGotAnnualInsuranceEnumeration.value,
        Occupation: riskData.Occupation,
      }
    }

    if (scheme === MOTORCYCLE_VEHICLE_SCHEME) {
      motorbikeDetails = {
        Motorbike: true,
        Occupation: riskData.Occupation,
        OwnerOfVehicle: riskData.OwnerOfVehicle.value,
        UseOfVehicle: riskData.UseOfVehicle.value,
        MotorcycleUseOfVehicle: riskData.MotorcycleUseOfVehicle.value,
        MotorcycleEntitlement: riskData.MotorcycleEntitlement.value,
        MotorcycleRidingExperience: riskData.MotorcycleRidingExperience.value,
        MotorcycleOvernightParking: riskData.MotorcycleOvernightParking.value,
        MotorcycleReasonForTempcoverPurchase:
          riskData.MotorcycleReasonForTempcoverPurchase.value,
        MotorcycleVehicleValueBand: vehicleInfo.selected.vehicleValue.value,
        PreviouslyRiddenMotorcycleVehicle:
          vehicleInfo.previousRiddenVehicle.VehicleId || null,
      }
    }

    const quoteData = {
      ...privateCarDetails,
      ...vanDetails,
      ...learnerDetails,
      ...motorbikeDetails,
      Title: riskData.Title.value,
      Forename: riskData.Forename,
      Surname: riskData.Surname,
      AddressKey: riskData.AddressKey,
      VehicleAbi: vehicleInfo.selected.Abi,
      Duration: duration.value,
      Telephone: riskData.Mobile,
      Email: riskData.EmailAddress,
      DateOfBirth: riskData.DateOfBirth,
      LicenceHeldDuration:
        riskData.LicenceHeldDuration && riskData.LicenceHeldDuration.value
          ? riskData.LicenceHeldDuration.value
          : 'NotSet',
      VehicleId: vehicleInfo.selected.VehicleId,
      DurationType: duration.type,
      LicenceType: riskData.LicenceType.value,
      QuoteId: {
        Value: riskData.QuoteId,
      },
      VehicleValueBandId: vehicleInfo.selected.vehicleValue.value,
      Impounded: riskData.Impounded,
      LicenceNumber: (
        riskData.DrivingLicenceNumberFirstPart + riskData.DrivingLicenceNumber
      ).toUpperCase(),
      ResidencyType: riskData.ResidencyType.value,
      UkResidencyDuration:
        riskData.UkResidencyDuration && riskData.UkResidencyDuration.value
          ? riskData.UkResidencyDuration.value
          : 'NotSet',
    }

    if (riskData.PolicyStartDate.manuallyEdited) {
      quoteData.PolicyStartDate = riskData.PolicyStartDate.value
      quoteData.UserSelectedPolicyStartDate = true
    } else {
      quoteData.UserSelectedPolicyStartDate = false
    }

    if (OptIn !== null && confusedQuickQuoteValid) {
      quoteData.ConfusedQuickQuoteOptIn = OptIn
    }

    let quoteEndpoint = updateQuoteLink.Href
    if (isCustomerAccountQuote) {
      switch (scheme) {
        case 'VehiclePrivateCarBaseScheme':
          quoteEndpoint = `${appConfig.BASE_API_URL}/api/schemes/VehiclePrivateCarBaseScheme/quote`
          break
        case 'VehicleCommercialVehicleBaseScheme':
          quoteEndpoint = `${appConfig.BASE_API_URL}/api/schemes/VehicleCommercialVehicleBaseScheme/quote`
          break
        case 'VehicleCommercialVehicleCourierScheme':
          quoteEndpoint = `${appConfig.BASE_API_URL}/api/schemes/VehicleCommercialVehicleCourierScheme/quote`
        default:
          break
      }
    }

    const partialValidation = action ? action.payload.partialValidation : true
    const quote = yield call(
      quoteApi.updateQuote,
      quoteEndpoint,
      quoteData,
      partialValidation,
      scheme,
    )

    yield put(updateQuoteSuccess(quote.data))
    if (action) {
      if (action.payload.getPrice) {
        yield* requestPrice()
        yield* underwriterSaga()
      }
      if (action.payload.confirmRiskData) {
        yield put(confirmRiskCollectionPage(action.payload.callback))
      }
      if (action.payload.requestConfusedQuickQuote) {
        if (OptIn && confusedQuickQuoteValid) {
          yield put(requestConfusedQuickQuote())
        }
      }
      if (action.payload.clearValidation) {
        yield put(clearIdentityCheck())
      }
      if (action.payload.detailsChanged) {
        yield put(setUnderwriterChanged())
      }
      if (action.payload.callback) {
        action.payload.callback()
        yield put(enableAllButtons())
      }
    }
    yield put(requestMyLicenceStatus())
  } catch (e) {
    yield put(updateQuoteFailure(e.message))
    if (isCustomerAccountQuote) {
      history.push(`/quote/driver-details/logged-in${queryString}`)
    } else {
      history.push(`/quote/driver-details${queryString}`)
    }

    yield put(enableAllButtons())
  }
}

export default function* fetchRiskDataSaga() {
  yield takeLatest(UPDATE_QUOTE, updateRiskDataSaga)
}
