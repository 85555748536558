import GridContainerWrapper from 'components/@common/GridContainerWrapper'
import { stat } from 'fs'
import { getCookie, CA_LOGGED_IN_COOKIE } from 'utils/cookie-helpers'
import { useQueryString } from 'hooks'
import { InitialStateType } from 'initialState'
import DriverDetails from 'pages/DriverDetails'
import LoggedIn from 'pages/DriverDetails/LoggedIn'
import LoggedInCallback from 'pages/DriverDetails/LoggedInCallback'
import DrivingLicence from 'pages/DrivingLicence'
import Payment from 'pages/Payment'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Route, Switch } from 'react-router-dom'
import styled from 'styled-components'
import { Grid } from 'unsemantic'
import { UserManager, UserManagerSettings } from 'oidc-client-ts'
import config from 'config'
import { store } from 'api/store'
import Breadcrumb from '../../components/Breadcrumb'
import LoadingScreen from '../../components/LoadingScreen'
import { RiskCollectionPathSelection } from '../../components/RiskCollectionPathSelection'
import DrivingLicenceCamera from '../DrivingLicenceCamera'
import { Secure } from '../JudoPay/Secure'
import LegalDeclarationPage from '../LegalDeclarationPage'
import MoreDetails from '../MoreDetails'
import MoreInfoVan from '../VanDetails/MoreInfoVan'
import { initRiskDataCollectionPage } from './actions'
import RiskDataDiscountBanner from './components/RiskDataDiscountBanner'
import { BreadCrumbWrapper } from './RiskData.styles'

const MainContainer = styled.div`
  text-align: center;
`

const RiskDataCollectionPage = () => {
  const dispatch = useDispatch()
  const { queryString } = useQueryString()
  const step = useSelector((state: InitialStateType) => state.step)
  const loading = useSelector((state: InitialStateType) => state.isLoading)

  useEffect(() => {
    // @ts-ignore
    if (!window.isHoldingPageOn) {
      setTimeout(() => {
        dispatch(initRiskDataCollectionPage())
      }, 1000)
    }
  }, [dispatch])

  if (loading) {
    return (
      <Grid desktop='100' parent>
        <LoadingScreen />
      </Grid>
    )
  }

  return (
    <Grid desktop='100' parent>
      <MainContainer>
        <RiskDataDiscountBanner />
        <GridContainerWrapper>
          <BreadCrumbWrapper>
            <Breadcrumb step={step} />
          </BreadCrumbWrapper>
        </GridContainerWrapper>
        <Switch>
          {/* @ts-ignore as will need to re-write component */}
          <Route path='/quote/scan' component={DrivingLicenceCamera} />
          <Route
            path='/quote/driver-details/logged-in-callback'
            component={LoggedInCallback}
          />
          <Route
            path='/quote/driver-details/logged-in'
            // @ts-ignore as will need to re-write component
            component={LoggedIn}
          />
          <Route
            path='/quote/driver-details/van-info'
            // @ts-ignore as will need to re-write component
            component={MoreInfoVan}
          />
          <Route
            path='/quote/driver-details/more-info'
            // @ts-ignore as will need to re-write component
            component={MoreDetails}
          />
          <Route path='/quote/driver-details' component={DriverDetails} />
          {/* @ts-ignore as will need to re-write component */}
          <Route path='/quote/payment/3d-secure' component={Secure} />
          <Route path='/quote/payment' component={Payment} />
          <Route path='/quote/driving-licence' component={DrivingLicence} />
          <Route
            path='/quote/legal-declaration'
            component={LegalDeclarationPage}
          />
          <Route
            path='/quote/select-path'
            render={() => (
              <RiskCollectionPathSelection queryString={queryString} />
            )}
          />
        </Switch>
      </MainContainer>
    </Grid>
  )
}

export default RiskDataCollectionPage
