import React from 'react';
import {
  AutoCompleteOptionStyled,
  ClickThroughSpan,
  ClickThroughSpanWrapper,
} from './AutoCompleteOption.styles';

interface Props {
  option: { id: number; value: string };
  onClick: (
    e:
      | React.TouchEvent<HTMLLIElement>
      | React.MouseEvent<HTMLLIElement, MouseEvent>
  ) => void;
  searchTerm: string;
  properties: {
    id: string;
    value: string;
  };
}

interface HighlightTextProps {
  id: string;
  text: string;
  searchTerm: string;
}

const HighlightText: React.FC<HighlightTextProps> = ({
  id,
  text,
  searchTerm,
}) => {
  const parts = text.split(new RegExp(`(${searchTerm})`, 'gi'));
  return (
    <ClickThroughSpanWrapper>
      {parts.map((part, i) => {
        if (part.toLowerCase() === searchTerm.toLowerCase()) {
          return (
            <ClickThroughSpan
              key={`${text + i}`}
              id={id}
            >
              {part}
            </ClickThroughSpan>
          );
        }

        if (part.length) {
          return (
            <span
              key={`${text + i}`}
              id={id}
            >
              {part}
            </span>
          );
        }

        return null;
      })}
    </ClickThroughSpanWrapper>
  );
};

const AutoCompleteOption: React.FC<Props> = ({
  option,
  searchTerm,
  properties,
  onClick,
}) => {
  const { id, value } = properties;
  return (
    // TODO: FIX TYPING
    <AutoCompleteOptionStyled
      // @ts-ignore
      id={option[id]}
      // @ts-ignore
      value={option[id]}
      onMouseDown={onClick}
      onTouchStart={onClick}
    >
      <HighlightText
        // @ts-ignore
        id={option[id]}
        // @ts-ignore
        text={option[value]}
        searchTerm={searchTerm}
      />
    </AutoCompleteOptionStyled>
  );
};

export default AutoCompleteOption;
